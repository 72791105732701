import schemas from '/functions/shared/schemas'
import organizationsProvider from '/src/data/providers/organizations'

const organizationRepo = {
  updateOrganization: async (data) => {
    const result = await organizationsProvider.updateOrganization(data)

    // validate the result
    const validatedResult = schemas.updateOrganization.validateSync(result)

    return validatedResult
  },
  getOrganization: async () => {
    try {
      const result = await organizationsProvider.getOrganization()

      // validate the result
      const validatedResult = schemas.getOrganization.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error @organizationRepo -> getOrganization', error)
      throw error
    }
  }
}

export default organizationRepo
