import dayjs from 'dayjs'

export const convertFirestoreDateToTenure = (firestoreDate) => {
  const { _seconds, _nanoseconds } = firestoreDate

  // Convert Firestore date to dayjs object
  const startDate = dayjs.unix(_seconds).add(_nanoseconds / 1e9, 'second')

  // Calculate the difference in months
  const currentDate = dayjs()
  const monthsDifference = currentDate.diff(startDate, 'month', true)

  // Calculate the tenure in years
  const tenureInYears = (monthsDifference / 12).toFixed(1)

  // Return tenure in the format "x.y years"
  return Number(tenureInYears)
}
