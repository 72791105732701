import { Box, Typography } from '@mui/material'
import { LightTooltip } from '/src/components/light-tooltip'
import Proptypes from 'prop-types'

// Display ellipsis if the text is too long and show the full text on hover
export const DefaultCellWrapper = ({ children, width, tooltip, value }) => {
  if (tooltip) {
    return (
      <LightTooltip
        title={
          <Typography variant="body2" sx={{ maxWidth: 300 }}>
            {value}
          </Typography>
        }
      >
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: width,
            display: 'block'
          }}
        >
          {children}
        </Box>
      </LightTooltip>
    )
  }

  return (
    <Box
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: width,
        display: 'block'
      }}
    >
      {children}
    </Box>
  )
}

DefaultCellWrapper.propTypes = {
  children: Proptypes.node,
  width: Proptypes.number,
  tooltip: Proptypes.bool,
  value: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
    Proptypes.node
  ])
}
