import React from 'react'
import { Stack, Box, SvgIcon, Typography } from '@mui/material'
import AddReactionIcon from '@mui/icons-material/AddReaction'
import Slider from './slider'
import CommentModal from './comment-modal'

export const SelfScoring = ({
  feedback,
  handleChange,
  nameWidth,
  handleCommentSave,
  currentQuestion,
  commentMin
}) => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mt: 2,
          mx: 1.2
        }}
      >
        <SvgIcon
          color="primary"
          sx={{
            mr: 1.2
          }}
        >
          <AddReactionIcon />
        </SvgIcon>
        <Typography
          variant="body1"
          sx={{ width: nameWidth ? nameWidth : 'max-content', marginRight: 4 }}
        >
          {feedback.fullName}
        </Typography>
        <Box flexGrow={1} mr={commentMin ? 1.75 : 0.8}>
          <Slider onChange={handleChange(feedback.uid)} person={feedback} />
        </Box>
        {commentMin !== null ? (
          <Box mr={-1}>
            <CommentModal
              handleSave={handleCommentSave(feedback.uid)}
              value={feedback.comment}
              disabled={!feedback.isApplicable}
              person={feedback}
              data-testid={feedback.uid}
              currentQuestion={currentQuestion}
            />
          </Box>
        ) : null}
      </Stack>
    </>
  )
}
