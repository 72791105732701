import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'

function PageIndicator() {
  const { filtered } = useUsersTableContext()
  return (
    <Stack p={3} alignItems="flex-end">
      <Typography variant="body2">{filtered.length} Total</Typography>
    </Stack>
  )
}

export default PageIndicator
