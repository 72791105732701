import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { LOGO_MODES, Logo } from '/src/components/logo'
import { RouterLink } from '/src/components/router-link'
import { Scrollbar } from '/src/components/scrollbar'
import { usePathname } from '/src/hooks/use-pathname'
import { paths } from '/src/constants/paths'
import { TenantSwitch } from '/src/layouts/dashboard/tenant-switch'
import { SideNavSection } from '/src/layouts/dashboard/vertical-layout/side-nav-section'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { SIDE_NAV_WIDTH, Drawer } from '/src/layouts/dashboard/vertical-layout'
import { useSettings } from '/src/hooks/use-settings'

import { motion } from 'framer-motion'

const useCssVars = () => {
  const theme = useTheme()

  return useMemo(() => {
    if (theme.palette.mode === 'dark') {
      return {
        '--nav-bg': theme.palette.neutral[900],
        '--nav-color': theme.palette.neutral[100],
        '--nav-border-color': theme.palette.neutral[700],
        '--nav-logo-border': theme.palette.neutral[700],
        '--nav-section-title-color': theme.palette.neutral[400],
        '--nav-item-color': theme.palette.neutral[400],
        '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
        '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
        '--nav-item-active-color': theme.palette.text.primary,
        '--nav-item-disabled-color': theme.palette.neutral[600],
        '--nav-item-icon-color': theme.palette.neutral[500],
        '--nav-item-icon-active-color': theme.palette.primary.main,
        '--nav-item-icon-disabled-color': theme.palette.neutral[700],
        '--nav-item-chevron-color': theme.palette.neutral[700],
        '--nav-scrollbar-color': theme.palette.neutral[400]
      }
    } else {
      return {
        '--nav-bg': theme.palette.customColors[900],
        '--nav-color': theme.palette.customColors.textColor,
        '--nav-border-color': theme.palette.divider,
        '--nav-logo-border': theme.palette.customColors[200],
        '--nav-section-title-color': theme.palette.customColors.textColor,
        '--nav-item-color': theme.palette.customColors.textColor,
        '--nav-item-hover-bg': theme.palette.action.hover,
        '--nav-item-active-bg': theme.palette.action.selected,
        '--nav-item-active-color': theme.palette.text.primary[900],
        '--nav-item-disabled-color': theme.palette.customColors[400],
        '--nav-item-icon-color': theme.palette.customColors.textColor,
        '--nav-item-icon-active-color': theme.palette.textColor,
        '--nav-item-icon-disabled-color': theme.palette.customColors[400],
        '--nav-item-chevron-color': theme.palette.customColors.textColor,
        '--nav-scrollbar-color': theme.palette.customColors[900]
      }
    }
  }, [theme])
}

export const SideNav = (props) => {
  const { sections = [], isOpen, setDrawerIsOpen } = props
  const pathname = usePathname()
  const cssVars = useCssVars()
  const theme = useTheme()
  const settingsContext = useSettings()

  const handleDrawerIsOpen = () => setDrawerIsOpen((prev) => !prev)

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          borderRightColor: 'var(--nav-border-color)',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
          color: 'var(--nav-color)',
          width: SIDE_NAV_WIDTH,
          zIndex: 100000
        }
      }}
      variant="permanent"
    >
      <Scrollbar
        sx={{
          'height': '100%',
          '& .simplebar-content': {
            height: '100%'
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)'
          }
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <Stack alignItems="center" py={2}>
            <Stack
              sx={{
                columnGap: 1,
                width: '80%',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                direction: 'row',
                flexDirection: 'row',
                p: 2,
                my: 1,
                borderRadius: 1,
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.neutral[400]
                    : theme.palette.background.default,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center',
                boxShadow: theme.shadows[24]
              }}
            >
              <Stack
                component={RouterLink}
                href={paths.index}
                sx={{
                  height: 35,
                  width: 35
                }}
              >
                <Logo
                  mode={
                    theme.palette.mode === 'dark'
                      ? LOGO_MODES.BLACK
                      : LOGO_MODES.DEFAULT
                  }
                  companyLogo={
                    settingsContext.customizationConfig.organizationLogo
                  }
                />
              </Stack>
              {isOpen && (
                <TenantSwitch
                  name={
                    settingsContext.customizationConfig.organizationLogo &&
                    settingsContext.customizationConfig.organizationName
                  }
                />
              )}
            </Stack>
          </Stack>
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2
            }}
          >
            {sections.map((section, index) => (
              <SideNavSection
                isOpen={isOpen}
                setDrawerIsOpen={setDrawerIsOpen}
                items={section?.items}
                key={index}
                pathname={pathname}
                subheader={section?.subheader}
              />
            ))}
            <IconButton
              sx={{
                pt: isOpen ? 0.65 : 0.25,
                pb: isOpen ? 0 : 1,
                px: isOpen ? 0.75 : 0.9,
                color:
                  theme.palette.mode === 'light' &&
                  theme.palette.customColors.textColor,
                alignSelf: isOpen ? 'flex-end' : 'center'
                // marginTop: isOpen ? '0 !important' : ''
              }}
              onClick={handleDrawerIsOpen}
            >
              <motion.div
                animate={isOpen ? 'open' : 'closed'}
                variants={{
                  open: {
                    rotate: 0
                  },
                  closed: {
                    rotate: 180
                  }
                }}
                transition={{
                  duration: 0.3,
                  ease: 'easeInOut',
                  type: 'tween'
                }}
              >
                <ChevronLeftIcon />
              </motion.div>
            </IconButton>
          </Stack>
          {settingsContext.customizationConfig.organizationLogo ? (
            <Stack
              flexDirection={isOpen ? 'row' : 'column'}
              alignItems="center"
              justifyContent="center"
              columnGap={2}
              marginBottom={3}
              spacing={1}
            >
              <Stack flexDirection="row" alignItems="center" columnGap={1}>
                <Logo
                  width={30}
                  mode={
                    cssVars['--nav-color'] === '#ffffff' ||
                    theme.palette.mode === 'dark'
                      ? LOGO_MODES.WHITE
                      : LOGO_MODES.BLACK
                  }
                />
                {isOpen ? (
                  <Typography variant="subtitle1">Incompass</Typography>
                ) : null}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </Scrollbar>
    </Drawer>
  )
}

SideNav.propTypes = {
  color: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
  sections: PropTypes.array
}
