import { alpha } from '@mui/material/styles'
import { isColorLight } from '/src/utils/color'

export const withAlphas = (color) => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5),
    alpha75: alpha(color.main, 0.75)
  }
}

export const neutral = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927'
}

export const autoColorsByCustomColor = (color) => {
  const neutralExtract = (color) => {
    return {
      50: alpha(color, 0.05),
      100: alpha(color, 0.1),
      200: alpha(color, 0.2),
      300: alpha(color, 0.3),
      400: alpha(color, 0.4),
      500: alpha(color, 0.5),
      600: alpha(color, 0.6),
      700: alpha(color, 0.7),
      800: alpha(color, 0.8),
      900: alpha(color, 1)
    }
  }

  const textColor = isColorLight(color) ? '#000000' : '#ffffff'

  return { textColor, ...neutralExtract(color) }
}

export const defaultLightColors = withAlphas({
  lightest: '#c3d0f0',
  light: '#8698e1',
  main: '#3D4CCB',
  dark: '#2e3a9b',
  darkest: '#1f276b',
  contrastText: '#ffffff'
})

export const defaultDarkColors = withAlphas({
  lightest: '#C3C8F0',
  light: '#A6ADE1',
  main: '#99A0E4',
  dark: '#6F75B1',
  darkest: '#4B5074',
  contrastText: '#ffffff'
})

export const success = withAlphas({
  lightest: '#F0FDF9',
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  darkest: '#134E48',
  contrastText: '#FFFFFF'
})

export const info = withAlphas({
  lightest: '#ECFDFF',
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  darkest: '#164C63',
  contrastText: '#FFFFFF'
})

export const warning = withAlphas({
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  darkest: '#7A2E0E',
  contrastText: '#FFFFFF'
})

export const error = withAlphas({
  lightest: '#FEF3F2',
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  darkest: '#7A271A',
  contrastText: '#FFFFFF'
})
