import { Box, Divider, Stack, Tab, Tabs } from '@mui/material'
import { convertToSlug } from '/src/utils/convert-to-slug'

// ** You can only pass two children to this component, one for each tab **
// ** The first child will be the first tab, the second child will be the second tab **
// ** If you want to add more tabs, you will need to add more <Tab /> component like example below  **
const TabContainer = ({
  children,
  tabValue,
  handleTabChange,
  tabLabels,
  divider = true,
  orientation = 'horizontal',
  sx
}) => {
  const handleTabsChange = (event, newValue) => handleTabChange(newValue)

  const tabLabelValues = Object.values(tabLabels)

  const multipleTabs = tabLabelValues.length > 1

  return (
    <Stack
      sx={{
        width: '100%'
      }}
      direction={orientation === 'horizontal' ? 'column' : 'row'}
    >
      {multipleTabs ? (
        <>
          <Stack
            flexGrow={1}
            sx={{
              ...sx
            }}
          >
            <Tabs
              value={tabValue}
              orientation={orientation}
              onChange={handleTabsChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="primary tabs example"
            >
              {tabLabelValues.map((label, index) => {
                return (
                  <Tab
                    key={index}
                    sx={{
                      textTransform: 'capitalize'
                    }}
                    label={tabLabels[index]}
                    data-gaid={`${convertToSlug(label)}-tab`}
                    {...a11yProps(index)}
                  />
                )
              })}
            </Tabs>
          </Stack>
          {divider ? <Divider /> : null}
        </>
      ) : null}
      <Stack flexGrow={1}>
        {children.map((child, index) => {
          return (
            <TabPanel key={index} value={tabValue} index={index}>
              {child}
            </TabPanel>
          )
        })}
      </Stack>
    </Stack>
  )
}

export default TabContainer

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ my: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
