import makeRequest from './index'

const statsProvider = {
  getReviewStats: async (data) => {
    const result = await makeRequest({ data, endpoint: 'getReviewStats' })

    return result.data
  }
}

export default statsProvider
