import { Button, ButtonProps } from '@mui/material'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'
import React from 'react'

export interface RouterButtonProps extends ButtonProps {
  text: string
  to: string
}

const RouterButton: React.FC<RouterButtonProps> = ({
  to,
  text,
  ...otherProps
}) => {
  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(
        function Link(linkProps, ref) {
          return <RouterLink ref={ref} to={to} {...linkProps} />
        }
      ),
    [to]
  )
  return (
    <Button component={CustomLink} to={to} {...otherProps}>
      {text}
    </Button>
  )
}

export default RouterButton
