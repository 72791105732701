// Constants
import { ROLES } from '/functions/shared/constants/roles'
import { ACCOUNT_STATUSES } from '/functions/shared/constants/account-statuses'
import { PERMISSION_FIELDS } from '/functions/shared/constants/permissions-fields'

const { LOCATION, DIVISION, TEAM } = PERMISSION_FIELDS

// Access controls should either meet the first or the second condition
// Checks with AND operation inside each condition and OR operation between conditions

// Access Control Settings for each functionality mapped with feature names

export const ACCESS_CONTROLS = {
  // Pages
  ADMIN_PAGES: [
    {
      roles: [ROLES.ADMIN]
    }
  ],
  MANAGER_PAGES: [
    {
      roles: [ROLES.MANAGER]
    }
  ],
  EMPLOYEE_PAGES: [
    {
      roles: [ROLES.EMPLOYEE]
    }
  ],
  ADMIN_OR_SPECIAL_PERMISSION_PAGES: [
    {
      roles: [ROLES.ADMIN]
    },
    {
      permissions: [LOCATION, DIVISION, TEAM]
    }
  ],
  // Drawer Tabs
  MY_REVIEWS_TAB: [
    {
      roles: [ROLES.EMPLOYEE]
    }
  ],
  MANAGER_ANALYTICS_TAB: [
    {
      roles: [ROLES.MANAGER]
    }
  ],
  MANAGER_REVIEW_CYCLES_TAB: [
    {
      roles: [ROLES.MANAGER]
    }
  ],
  ADMIN_ANALYTICS_TAB: [
    {
      roles: [ROLES.ADMIN]
    },
    {
      permissions: [LOCATION, DIVISION, TEAM]
    }
  ],
  ADMIN_REVIEW_CYCLES_TAB: [
    {
      roles: [ROLES.ADMIN]
    }
  ],
  ADMIN_ORGANIZATION_TAB: [
    {
      roles: [ROLES.ADMIN]
    }
  ],
  // Analytics Tabs
  ADMIN_EXECUTIVE_OVERVIEW_TAB: [
    {
      roles: [ROLES.ADMIN]
    }
  ],
  ADMIN_DASHBOARD_ANALYSIS_TAB: [
    {
      roles: [ROLES.ADMIN]
    },
    {
      permissions: [LOCATION, DIVISION, TEAM]
    }
  ],
  ADMIN_DASHBOARD_INSIGHTS_TAB: [
    {
      roles: [ROLES.ADMIN]
    },
    {
      permissions: [LOCATION, DIVISION, TEAM]
    }
  ],
  ADMIN_DASHBOARD_CALIBRATION_TAB: [
    {
      roles: [ROLES.ADMIN]
    }
  ],
  MANAGER_DASHBOARD_ANALYSIS_TAB: [
    {
      roles: [ROLES.MANAGER]
    }
  ],
  MANAGER_DASHBOARD_INSIGHTS_TAB: [
    {
      roles: [ROLES.MANAGER]
    }
  ],
  // Demo Restricted Features
  REVIEWS_TABLE_BUTTONS: [
    {
      roles: [ROLES.ADMIN],
      statuses: [ACCOUNT_STATUSES.ACTIVE] // Not Demo
    }
  ],
  MANAGE_EMPLOYEES: [
    {
      roles: [ROLES.ADMIN],
      statuses: [ACCOUNT_STATUSES.ACTIVE] // Not Demo
    }
  ]
}
