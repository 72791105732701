import { LightTooltip } from './light-tooltip'
import InfoIcon from '@mui/icons-material/Info'
import {
  IconButton,
  SvgIcon,
  Typography,
  TooltipProps,
  IconButtonProps
} from '@mui/material'

export interface InfoSignProps extends IconButtonProps {
  info: string
  size?: 'small' | 'medium' | 'large'
  tooltipProps?: TooltipProps
}

export const InfoSign = ({
  info = 'No description provided.',
  size = 'small',
  tooltipProps,
  ...others
}: InfoSignProps) => {
  return (
    <LightTooltip
      title={
        <Typography variant="body2" sx={{ maxWidth: 300 }}>
          {info}
        </Typography>
      }
      {...tooltipProps}
    >
      <IconButton {...others}>
        <SvgIcon fontSize={size}>
          <InfoIcon />
        </SvgIcon>
      </IconButton>
    </LightTooltip>
  )
}
