import { Stack } from '@mui/material'
import BackButton from '/src/components/buttons/back-button'
import NextButton from '/src/components/buttons/next-button'

const CardNavigation = ({
  currentQuestionIndex,
  handlePrevious,
  isDisabled,
  handleNext
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" mt={3}>
      <BackButton
        data-gaid="back-button"
        disabled={isDisabled || currentQuestionIndex === 0 ? true : false}
        onClick={handlePrevious}
        data-testid="back-button"
      />
      <NextButton
        data-gaid="next-button"
        disabled={isDisabled}
        onClick={handleNext}
        data-testid="next-button"
      />
    </Stack>
  )
}

export default CardNavigation
