import { Box, Stack } from '@mui/material'
import SummaryScoreBar from './summary-score-bar'
import { useDimensions } from '/src/hooks/use-dimensions'
import ScoringTableHeader from '../questions/scoring-table/header'

const SummaryScoreBars = ({ feedbacks, grades }) => {
  const [headerRef, headerDimensions] = useDimensions()
  return (
    <Stack
      justifyContent="space-around"
      alignItems="center"
      flexGrow={1}
      sx={{
        position: 'relative'
      }}
    >
      <Box
        ref={headerRef}
        sx={{
          position: 'absolute',
          top: -(headerDimensions?.height + 8),
          width: '100%'
        }}
      >
        <ScoringTableHeader grades={grades} />
      </Box>
      {feedbacks.map((person, i) => {
        return <SummaryScoreBar key={i} score={person.value} />
      })}
    </Stack>
  )
}

export default SummaryScoreBars
