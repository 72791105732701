import { Box } from '@mui/material'
import ReactMarkdown from 'react-markdown'

const DescriptionBox = (props) => {
  const { description, sx, icon, spacing, ...other } = props
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          description && `${theme.palette.primary.main}15`, // 80 in HEX is 50% in opacity
        borderRadius: description && '8px', // Rounded edges
        paddingY: description && 1, // Some padding to ensure text doesn't touch the edges
        paddingLeft: description && 3, // Some padding to ensure text doesn't touch the edges
        paddingRight: description && 5, // Some padding to ensure text doesn't touch the edges
        maxWidth: '80%', // Take up 80% of the parent container
        width: 'fit-content', // Fit content to the text
        ...sx
      }}
      {...other}
    >
      <ReactMarkdown
        components={{
          p: ({ children, ...props }) => {
            const isFirstParagraph = props.node.position.start.line === 1
            return (
              <p>
                {isFirstParagraph && icon && (
                  <span style={{ paddingRight: 15 }}>{icon}</span>
                )}
                {children}
              </p>
            )
          }
        }}
      >
        {description}
      </ReactMarkdown>
    </Box>
  )
}

export default DescriptionBox
