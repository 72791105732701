import { getComparator } from './get-comparator'
import { stableSort } from './stable-sort'
import {
  EQUALS_OPERATION_LABEL,
  GREATER_THAN_OPERATION_LABEL,
  GREATER_THAN_OR_EQUAL_TO_OPERATION_LABEL,
  LESS_THAN_OPERATION_LABEL,
  LESS_THAN_OR_EQUAL_TO_OPERATION_LABEL,
  NOT_EQUALS_OPERATION_LABEL
} from '/src/components/users-table/constants/filter-modes'

/**
 * Filters and sorts the initial data based on threshold columns and default sorting parameters.
 *
 * @param {Object} params - The parameters for filtering and sorting.
 * @param {Array} params.data - The original data array to be filtered and sorted.
 * @param {Array} params.columns - The array of column definitions.
 * @param {string} params.defaultOrder - The default sort order ('asc' or 'desc').
 * @param {string} params.defaultOrderBy - The default column key to sort by.
 * @returns {Array} The filtered and sorted data array.
 */
export const applyInitialFilters = ({
  data,
  columns,
  defaultOrder,
  defaultOrderBy
}) => {
  // Find columns that have a threshold defined
  const thresholdColumns = columns.filter((column) => column.thresHold)

  // Create initial thresholds array from the first threshold column (if any)
  const initialThresholds =
    thresholdColumns.length > 0
      ? [
          {
            key: thresholdColumns[0]?.key,
            type: thresholdColumns[0]?.thresHold?.type,
            value: thresholdColumns[0]?.thresHold?.value
          }
        ]
      : []

  // Apply filters using the initial thresholds, then sort the filtered data
  return stableSort(
    applyFilters(data, { activeThresholds: initialThresholds }),
    getComparator(defaultOrder, defaultOrderBy, undefined)
  )
}

const getThresholdFilterParamaters = (thresholdColumn) => {
  const { key, thresHold } = thresholdColumn
  const { type, value } = thresHold
  const parameters = {
    type: key, // column key
    action: 'threshold',
    payload: {
      value, // integer
      type: type // up or down
    }
  }

  return parameters
}

const addThreshold = (activeThresholds, type, payload) => {
  let newThresholds = []

  // check if threshold type already exists
  const thresholdTypeExists = activeThresholds.find(
    (threshold) => threshold.key === type
  )

  if (thresholdTypeExists) {
    // if threshold type exists, check if value is the same
    if (thresholdTypeExists.value === payload.value) {
      // if value is the same, return previous state
      newThresholds = activeThresholds
    }

    // if value is not the same, update value
    newThresholds = activeThresholds.map((threshold) => {
      if (threshold.key === type) {
        return {
          ...threshold,
          value: payload.value
        }
      }

      return threshold
    })
  }

  // if threshold type does not exist, add threshold type and value
  if (!thresholdTypeExists) {
    newThresholds = [
      ...activeThresholds,
      { key: type, value: payload.value, type: payload.type }
    ]
  }

  return newThresholds
}

const filterByThreshold = (users, threshold) => {
  const { type, value, key } = threshold

  const filtered = users.filter((user) => {
    const userValue = user[key]

    if (!value) return true

    if (type === 'up') {
      return Number(userValue) >= Number(value)
    }

    if (type === 'down') {
      return Number(userValue) <= Number(value)
    }

    return false
  })

  return filtered
}

const filterByActiveFilters = (users, activeFilters) => {
  const filtered = users.filter((user) => {
    return activeFilters.every((filter) => {
      // check if filter has a field named operation if it does, it's a numeric filter
      if (Boolean(filter.options[0]?.operation)) {
        // Apply numeric filters
        const { operation, value, key } =
          filter.options[filter.options.length - 1]

        // Convert user value to number just in case it's a string
        const userValueAsNumber = Number(user[key])

        // Round user value to one decimal place and convert it back to number
        const userValue = Number(userValueAsNumber.toFixed(1))

        const parsedValue = value ? Number(value) : null

        if (operation === EQUALS_OPERATION_LABEL) {
          return userValue === parsedValue
        } else if (operation === NOT_EQUALS_OPERATION_LABEL) {
          return userValue !== parsedValue
        } else if (operation === GREATER_THAN_OPERATION_LABEL) {
          return userValue > parsedValue
        } else if (operation === LESS_THAN_OPERATION_LABEL) {
          return userValue < parsedValue
        } else if (operation === GREATER_THAN_OR_EQUAL_TO_OPERATION_LABEL) {
          return userValue >= parsedValue
        } else if (operation === LESS_THAN_OR_EQUAL_TO_OPERATION_LABEL) {
          return userValue <= parsedValue
        }

        return false
      } else {
        // Apply non-numeric filters

        const userKey = filter.type
        const filterOptions = filter.options
        const userValue = user[userKey]

        return filterOptions.includes(userValue)
      }
    })
  })

  return filtered
}

const filterBySearchText = (users, search) => {
  const { text, searchBy } = search

  const filtered = users.filter((user) => {
    return searchBy.some((column) => {
      const userKey = column.key
      const userValue = user[userKey]

      if (!userValue) {
        return false
      }

      const lowerCasedValue = userValue.toString().toLowerCase()
      const lowerCasedPayload = text.toLowerCase()

      return lowerCasedValue.includes(lowerCasedPayload)
    })
  })

  return filtered
}

const addFilter = (activeFilters, type, payload) => {
  let newFilters = []

  // check if filter type already exists
  const filterTypeExists = activeFilters.find((filter) => filter.type === type)

  if (filterTypeExists) {
    // if filter type exists, check if option already exists
    const optionExists = filterTypeExists.options.find(
      (filterOption) => filterOption === payload
    )

    if (optionExists) {
      // if option exists, return previous state
      return
    }

    // if option does not exist, add option to filter type
    newFilters = activeFilters.map((filter) => {
      if (filter.type === type) {
        return {
          ...filter,
          options: [...filter.options, payload]
        }
      }

      return filter
    })
  }

  // if filter type does not exist, add filter type and new option
  if (!filterTypeExists) {
    newFilters = [...activeFilters, { type, options: [payload] }]
  }

  return newFilters
}

const removeFilter = (activeFilters, type, payload) => {
  let newFilters = []

  // check if filter type exists
  const filterTypeExists = activeFilters.find((filter) => filter.type === type)

  if (filterTypeExists && filterTypeExists?.options) {
    // check if filter type has operation field, if it does, it's a numeric filter
    const isNumericFilter = filterTypeExists.options.find((option) =>
      Boolean(option?.operation)
    )

    if (isNumericFilter) {
      newFilters = activeFilters.filter((filter) => filter.type !== type)
    } else {
      // if filter type exists, check if option exists
      const optionExists = filterTypeExists.options.find(
        (filterOption) => filterOption === payload
      )

      if (optionExists) {
        // if option exists, remove option from filter type
        newFilters = activeFilters.map((filter) => {
          if (filter.type === type) {
            return {
              ...filter,
              options: filter.options.filter(
                (filterOption) => filterOption !== payload
              )
            }
          }

          return filter
        })

        // if filter type has no options, remove filter type
        newFilters = newFilters.filter((filter) => filter.options.length > 0)
      }
    }
  }

  // filter type does not exist, set newFilters to previous state
  if (!filterTypeExists || !Boolean(filterTypeExists?.options)) {
    newFilters = activeFilters
  }

  return newFilters
}

const applyFilters = (users, filters) => {
  let filtered = users

  const { activeThresholds, activeFilters, search } = filters

  // filter by threshold
  if (activeThresholds?.length > 0) {
    // *** only one threshold is supported at the moment ***
    filtered = filterByThreshold(filtered, activeThresholds[0])
  }

  // filter by active filters
  if (activeFilters?.length > 0) {
    filtered = filterByActiveFilters(filtered, activeFilters)
  }

  // filter by search text
  if (search?.text?.length > 0) {
    filtered = filterBySearchText(filtered, search)
  }

  return filtered
}

export {
  applyFilters,
  addFilter,
  addThreshold,
  removeFilter,
  getThresholdFilterParamaters
}
