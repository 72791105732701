const WinterMagic = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    dataName="Layer 1"
    width="952.34971"
    height="710"
    viewBox="0 0 952.34971 710"
    style={{ width: '100%', height: 'auto' }}
    // xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>winter_magic</title>
    <circle cx="828.12113" cy="568.64225" r="86.29225" fill="#2f2e41" />
    <polygon
      points="766.65 674.925 742.805 664.031 764.211 613.9 788.056 624.794 766.65 674.925"
      fill="#2f2e41"
    />
    <rect
      x="924.78134"
      y="734.01111"
      width="26.21537"
      height="46.9692"
      transform="translate(275.77259 -416.2434) rotate(24.55435)"
      fill="#2f2e41"
    />
    <ellipse
      cx="931.05487"
      cy="785.59698"
      rx="8.1923"
      ry="21.84614"
      transform="translate(-406.86062 590.54487) rotate(-35.44565)"
      fill="#2f2e41"
    />
    <ellipse
      cx="882.47138"
      cy="771.67244"
      rx="8.1923"
      ry="21.84614"
      transform="translate(-407.78953 559.78913) rotate(-35.44565)"
      fill="#2f2e41"
    />
    <circle cx="839.18648" cy="549.67954" r="29.49229" fill="#fff" />
    <circle cx="828.54243" cy="536.42793" r="9.83076" fill="#3f3d56" />
    <path
      d="M909.15776,555.86537c6.62965-31.27814,39.99126-50.702,74.51524-43.38432s57.13679,38.60569,50.50714,69.88383-32.17443,32.26046-66.69841,24.94282S902.5281,587.1435,909.15776,555.86537Z"
      transform="translate(-123.82515 -95)"
      fill="#cfcce0"
    />
    <ellipse
      cx="1033.04003"
      cy="668.07946"
      rx="13.5449"
      ry="43.14613"
      transform="translate(216.3937 1589.69181) rotate(-88.61681)"
      fill="#2f2e41"
    />
    <ellipse
      cx="861.94055"
      cy="653.55696"
      rx="13.5449"
      ry="43.14613"
      transform="translate(63.94262 1404.47024) rotate(-88.61681)"
      fill="#2f2e41"
    />
    <path
      d="M920.584,690.745a19.66153,19.66153,0,1,0,35.767,16.34094c4.51242-9.87677-2.55973-15.58037-12.43651-20.09279S925.09638,680.86823,920.584,690.745Z"
      transform="translate(-123.82515 -95)"
      fill="#fff"
    />
    <path
      d="M366.38068,439.22818c-24.87253-6.36751-41.36726-25.85895-41.36726-25.85895s23.83162-9.16765,48.70415-2.80013S415.08483,436.428,415.08483,436.428,391.25321,445.5957,366.38068,439.22818Z"
      transform="translate(-123.82515 -95)"
      fill={color ?? '#6c63ff'}
    />
    <path
      d="M321.81075,407.05023l1.5542-.59784c.24166-.09276,24.47312-9.22966,49.42267-2.84276,24.95,6.388,41.8098,26.04264,41.97743,26.24071l1.07512,1.27073-1.5542.59783c-.24166.09277-24.47258,9.22946-49.42213,2.84256h0c-24.9505-6.38777-41.8098-26.04265-41.97743-26.24071Zm89.96637,23.03108c-4.17341-4.42614-19.07445-18.92958-39.57634-24.17927-20.52383-5.25365-40.54335.30947-46.327,2.18794,4.17341,4.42614,19.07391,18.92979,39.57688,24.17907C385.97451,437.5227,405.99457,431.95937,411.77712,430.08131Z"
      transform="translate(-123.82515 -95)"
      fill="#3f3d56"
    />
    <path
      d="M185.82969,201.81467l-8.15444,33.97684,48.92664,10.87258s-4.07722-38.054,0-42.13127Z"
      transform="translate(-123.82515 -95)"
      fill="#a0616a"
    />
    <circle cx="81.7111" cy="95.26255" r="33.97683" fill="#a0616a" />
    <path
      d="M239.23485,180.08446s3.5725-38.5714-36.237-24.55315c0,0-28.93806-4.25612-37.0917,32.04129l9.29634,35.12784,1.83648,7.72129,61.97211,3.96706s-28.2382-15.37641-29.71607-44.67641l5.46569,5.38048Z"
      transform="translate(-123.82515 -95)"
      fill="#2f2e41"
    />
    <path
      d="M179.71386,452.56371s29.89961,42.13127,50.28571,53.00386l67.95367,92.417-17.668,169.88417s43.49035,28.54054,101.93051-12.23166l-17.668-184.834L287.08065,436.25483Z"
      transform="translate(-123.82515 -95)"
      fill="#2f2e41"
    />
    <path
      d="M297.05172,767.538s-20.46165,33.67009,9.629,35.7107,49.3487-5.10152,58.97772,0,45.73781-1.0203,42.12693-17.3452-50.55232-27.54826-50.55232-27.54826Z"
      transform="translate(-123.82515 -95)"
      fill="#2f2e41"
    />
    <path
      d="M160.36632,397.47339s10.7311,50.53588,25.13071,68.604l25.71231,111.79212L127.59292,726.80267s28.59837,43.45234,98.41823,29.21117l57.11142-176.675L265.39228,425.09869Z"
      transform="translate(-123.82515 -95)"
      fill="#2f2e41"
    />
    <path
      d="M143.15417,728.01782s-31.15346,22.45773-4.39128,38.07587,47.01187,17.64268,53.93186,26.83782,42.20176,19.85031,44.93249,2.80412-36.04718-48.9965-36.04718-48.9965Z"
      transform="translate(-123.82515 -95)"
      fill="#2f2e41"
    />
    <path
      d="M308.14629,450.5251c0,6.79536-78.82626,25.82239-126.39382,19.027-22.3839-3.19387-31.21788-29.57345-34.31661-56.59183a250.74789,250.74789,0,0,1-1.4543-28.98906c.02721-6.2517.23127-12.00056.503-16.87967.52989-9.56784,1.29105-15.77882,1.29105-15.77882l-2.41919-13.27817-2.92187-16.06426-5.11013-28.13283-.4214-2.324c-25.82239-12.23166-5.43629-70.67181-5.43629-76.1081s33.97683,4.07722,33.97683,4.07722l6.79537-9.51352,21.74518,6.79537,23.68855-12.82966,22.51994,30.49761s8.005,12.38114,17.62714,30.91892c4.06362,7.8418,8.41275,16.78452,12.57143,26.35245,2.14744,4.947,4.24047,10.0707,6.21106,15.3031,2.62292,6.93132,5.04211,14.05286,7.08072,21.20159.57087,1.9842,1.12813,3.99568,1.68523,6.03429,1.38629,5.11013,2.74536,10.41047,4.05,15.806,2.2425,9.16014,4.34913,18.59212,6.30612,27.8474C303.15859,413.27285,308.14629,445.91782,308.14629,450.5251Z"
      transform="translate(-123.82515 -95)"
      fill="#3f3d56"
    />
    <path
      d="M172.239,207.251s32.61776-47.56757,67.95367-32.61777c0,0-2.71815-31.25868-24.46332-33.97683s-33.97683-9.51351-33.97683-9.51351l-19.027,9.51351v25.82239S161.36636,201.81467,172.239,207.251Z"
      transform="translate(-123.82515 -95)"
      fill="#3f3d56"
    />
    <circle cx="38.22075" cy="30.02703" r="19.02703" fill="#cfcce0" />
    <path
      d="M270.39119,291.7038,142.43457,321.97031l-5.11013-28.13283,120.49532-28.48613C261.88338,273.19315,266.23251,282.13587,270.39119,291.7038Z"
      transform="translate(-123.82515 -95)"
      fill="#cfcce0"
    />
    <path
      d="M146.48458,367.09156c.52989-9.56784,1.29105-15.77882,1.29105-15.77882l-2.41919-13.27817L276.60225,307.0069c2.62292,6.93132,5.04211,14.05286,7.08072,21.20159.57087,1.9842,1.12813,3.99568,1.68523,6.03429Z"
      transform="translate(-123.82515 -95)"
      fill="#cfcce0"
    />
    <path
      d="M295.72434,377.89621,147.43586,412.96029a250.74789,250.74789,0,0,1-1.4543-28.98906l143.43666-33.92242C291.66072,359.209,293.76735,368.64093,295.72434,377.89621Z"
      transform="translate(-123.82515 -95)"
      fill="#cfcce0"
    />
    <path
      d="M313.58258,392.08494s28.54054-2.71814,23.10425,19.027-38.05406,8.15444-38.05406,8.15444Z"
      transform="translate(-123.82515 -95)"
      fill="#a0616a"
    />
    <path
      d="M325.81424,388.00772l-17.66795,39.41313s-6.61868-2.47352-16.64865-6.26531c-3.09873-1.16886-6.52362-2.46-10.17945-3.8462-1.87553-.72035-3.81907-1.45422-5.80327-2.2153-3.27524-1.2368-6.68653-2.54146-10.16584-3.8734-1.90274-.73387-3.84628-1.48135-5.78967-2.22882-3.35687-1.30474-6.75455-2.60948-10.13863-3.92774-24.77588-9.60866-49.07628-19.3668-54.07758-22.49265-7.4477-4.66161-30.21218-45.02611-43.80291-70.00588a60.47473,60.47473,0,0,1-2.20169-54.18625c7.71961-16.68946,18.78232-20.23662,28.78525-18.95909,13.59073,1.73965,24.23221,12.59864,26.47472,26.10783l11.13073,66.75767,55.7084,28.20078,9.66316,4.89263,5.51775,2.78608,9.663,4.89272,5.50432,2.78608,9.66282,4.89263Z"
      transform="translate(-123.82515 -95)"
      fill="#3f3d56"
    />
    <path
      d="M140.98026,276.56371s14.94981-55.722,50.28572-44.84942C191.266,231.71429,155.93007,233.07336,140.98026,276.56371Z"
      transform="translate(-123.82515 -95)"
      opacity="0.1"
    />
    <path
      d="M281.10086,365.37912l-21.54145,43.6127c-3.35687-1.30474-6.75455-2.60948-10.13863-3.92774l22.01692-44.57759Z"
      transform="translate(-123.82515 -95)"
      fill="#cfcce0"
    />
    <path
      d="M296.2816,373.05792,275.51492,415.094c-3.27524-1.2368-6.68653-2.54146-10.16584-3.8734l21.26953-43.05544Z"
      transform="translate(-123.82515 -95)"
      fill="#cfcce0"
    />
    <path
      d="M311.44874,380.73663l-19.9511,40.41891c-3.09873-1.16886-6.52362-2.46-10.17945-3.8462L301.78592,375.844Z"
      transform="translate(-123.82515 -95)"
      fill="#cfcce0"
    />
    <path
      d="M931.69783,585.47891c.07851,161.66361-96.00162,218.14966-214.54529,218.20723q-4.13037.002-8.22089-.08824-8.241-.17377-16.3105-.743c-106.99333-7.52335-190.15289-66.82067-190.2259-217.16747-.07556-155.5903,198.6843-352.03883,213.59065-366.56434.013,0,.013,0,.02621-.0132.5663-.55358.85613-.83034.85613-.83034S931.61933,423.8284,931.69783,585.47891Z"
      transform="translate(-123.82515 -95)"
      fill={color ?? '#6c63ff'}
    />
    <path
      d="M709.328,778.96147l78.45323-109.7286L709.13621,791.003l-.20456,12.59487q-8.241-.17377-16.3105-.743l8.37951-161.73366-.06638-1.25147.14462-.23729.79636-15.28271L622.9142,502.35312,702.10675,612.901l.19923,3.24085,6.33023-122.19612L641.02962,367.87268,709.45578,472.471l6.53012-253.34795.02581-.84312.0004.82992L715.00269,418.901l67.19063-79.23743-67.47223,96.4434L712.9957,545.52156l62.75151-105.03079L712.75328,561.60772l-.95854,60.84007,91.06977-146.2016L711.44931,643.672Z"
      transform="translate(-123.82515 -95)"
      fill="#3f3d56"
    />
    <circle cx="273.66784" cy="25" r="5" fill="#cfcce0" />
    <circle cx="316.66784" cy="170" r="5" fill="#cfcce0" />
    <circle cx="624.66784" cy="204" r="5" fill="#cfcce0" />
    <circle cx="835.66784" cy="364" r="5" fill="#cfcce0" />
    <circle cx="704.66784" cy="478" r="5" fill="#cfcce0" />
    <circle cx="420.66784" cy="515" r="5" fill="#cfcce0" />
    <circle cx="562.66784" cy="11" r="11" fill="#cfcce0" />
    <circle cx="521.66784" cy="582" r="11" fill="#cfcce0" />
    <circle cx="773.66784" cy="192" r="11" fill="#cfcce0" />
    <circle cx="463.66784" cy="353" r="11" fill="#cfcce0" />
    <rect x="16.67924" y="708" width="857" height="2" fill="#3f3d56" />
  </svg>
)

export default WinterMagic
