import { EditableNote } from '/src/components/editable-note'
import { Stack } from '@mui/material'
import { ROLES } from '/functions/shared/constants/roles'

interface DevelopmentPlanGuidelinesProps {
  onChange: (event: any) => void
  data: {
    [key: string]: string
  }
}

export const DevelopmentPlanGuidelines = ({
  onChange,
  data
}: DevelopmentPlanGuidelinesProps) => {
  const guidelines = [
    {
      key: ROLES.EMPLOYEE,
      title: 'Employee Guideline',
      value: data[ROLES.EMPLOYEE] ?? ''
    },
    {
      key: ROLES.MANAGER,
      title: 'Manager Guideline',
      value: data[ROLES.MANAGER] ?? ''
    }
  ]

  const handleChange = (value: string, key: string) => {
    const guideline = guidelines.find((guideline) => guideline.key === key)

    if (guideline) {
      onChange({
        target: {
          name: 'guidelines',
          key: guideline.key,
          value,
          type: 'text'
        }
      })
    }
  }

  return (
    <Stack spacing={2}>
      {guidelines.map((guideline) => (
        <EditableNote
          key={guideline.key}
          title={guideline.title}
          value={guideline.value}
          onChange={(value) => handleChange(value, guideline.key)}
          editingPermission={true}
          NotePaperProps={{
            sx: {
              minHeight: 150,
              maxHeight: '47vh',
              boxShadow: 5,
              backgroundColor: (theme: any) =>
                `${theme.palette.primary.main}10`,
              cursor: 'text'
            }
          }}
        />
      ))}
    </Stack>
  )
}
