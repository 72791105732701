import { Box, LinearProgress } from '@mui/material'
import React from 'react'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'

function InfiniteScrollLoading() {
  const { scrollLoading } = useUsersTableContext()
  return (
    <Box
      visibility={scrollLoading ? 'visible' : 'hidden'}
      sx={{ width: '100%' }}
    >
      <LinearProgress />
    </Box>
  )
}

export default InfiniteScrollLoading
