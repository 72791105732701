import { FAQ_IDS } from './faqIds.js'
import { ROLES } from '/functions/shared/constants/roles'

const faqs = [
  {
    faqId: 'General 1',
    question: 'Are my reviews anonymous?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      'Yes, all reviews are completely anonymous, including for managers and administrators. You, your colleagues, and the administrators will only have access to aggregate scores and comments, ensuring confidentiality and encouraging open and honest communication.'
  },
  {
    faqId: 'General 2',
    question: 'Who can see my results?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      'Your results will be shared with your managers, providing an opportunity for a productive conversation about your personal and professional growth. The CEO and the HR team will also be able to see your result.'
  },
  {
    faqId: 'General 3',
    question: 'When will I see my results?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      'Once everyone completes their reviews and the review period closes, Incompass aggregates the reviews, and prepares the results within a few days.'
  },
  {
    faqId: 'General 5',
    question:
      'What should I do if I am unsure how to assess someone against a particular behavior?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      "If you genuinely can't assess a colleague for a particular behavior, you can de-select them by unticking the box to the left of their name. You should only select people you work closely with and, therefore, can make an assessment on how they showcase the Hostelworld behaviors. However, if there is a behavior you are struggling to assess, you can choose not to include it."
  },
  {
    faqId: FAQ_IDS.QUESTIONS,
    question: 'How does Incompass work?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      'Incompass works through incentive-compatible peer reviews. Once all participants completes reviewing their colleagues, Incompass takes a macroscopic view at all the peer reviews and produces optimal scores for all participants, leveraging the wisdom of the crowd and minimizing bias through advanced statistical methods.'
  },
  {
    faqId: 'General 7',
    question:
      'What should I do if I need to change the colleagues I have selected?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      'If you have already begun your assessment and need to change your selection click on the 3 dots next to "Save & Leave" in the assessment and this will ask you to reselect colleagues.'
  },
  {
    faqId: FAQ_IDS.SELECTION,
    question: 'How should I approach deciding which colleagues to select?',
    visibleTo: [ROLES.EMPLOYEE],
    answer:
      "The selection page will be pre-populated for you, but you should think carefully about who to include. If it doesn't make sense to include someone who has been pre-selected, you can remove them. Remember to include those that you can give meaningful feedback to. If you don't know enough people for review, you can submit a request to decrease the number by clicking 'Struggling to choose eight colleagues?' on the top-right side of the page. This will trigger a request for your manager to review."
  }
]

export default faqs
