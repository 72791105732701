// MUI Components
import { Box, Typography, Stack, Divider, Container } from '@mui/material'

// Custom Components
import { Seo } from '/src/components/seo'
import StatTables from '/src/sections/dashboard/admin/reviews/stats/stat-tables'
import LoadingOverlay from '/src/components/loading-overlay'
import { AnimatedLogo } from '/src/components/animated-logo'
import { Skeletons } from '/src/components/skeletons'
import TabContainer from '/src/components/tab-container'
import { LoadingError } from '/src/components/loading-error'
import { RequestsInbox } from '/src/components/requests-inbox'
import { SeverityPill } from '/src/components/severity-pill'
import AccessControls from '/src/sections/dashboard/admin/reviews/evaluation-form/access-controls-tab'
import EvaluationForm from '/src/sections/dashboard/admin/reviews/evaluation-form/evaluation-form'

// HOCS
import { withReviewGuard } from '/src/hocs/with-review-guard'

// Hooks
import { useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { useUser } from '/src/hooks/use-user'
import useGetRequest, { Endpoints } from '/src/hooks/requests/use-get-request'

// Constants
import { ROLES } from '/functions/shared/constants/roles'

// Data Mappings
import requestsModal from '/src/data/modals/requests-modal'
import { EVALUATION_FORM_METHODS } from '/src/constants/evaluation-form-methods'

import { getStatus } from '/src/components/reviews-table/helpers'

const PageLoadingContent = () => (
  <Stack
    sx={{
      px: {
        xs: 3,
        md: 0
      }
    }}
  >
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      sx={{
        pb: 3
      }}
    >
      <Skeletons variant="rounded" count={1} width={350} height={50} />
    </Stack>
    <Divider />
    <Stack>
      <LoadingOverlay pt={10}>
        <AnimatedLogo width={60} />
      </LoadingOverlay>
    </Stack>
  </Stack>
)

const PageErrorContent = ({ message }) => (
  <LoadingError message={message || 'Something went wrong'} />
)

const HeaderSection = ({ evaluation }) => {
  const { statusColor, status } = getStatus({
    evaluation: evaluation,
    role: ROLES.ADMIN
  })

  return (
    <Box sx={{ pb: 3 }}>
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="flex-start"
        spacing={1}
      >
        <Stack alignItems="center" direction="row" justifyContent="flex-start">
          <Typography variant="h3" mr={2}>
            {`Completion - ${evaluation.name}`}
          </Typography>
          <SeverityPill color={statusColor}>{status}</SeverityPill>
        </Stack>
      </Stack>
    </Box>
  )
}

const Completion = ({
  companyStats,
  isLoadingRequests,
  errorRequests,
  refetchRequests,
  updatedEmployeeRequests,
  evaluation
}) => (
  <Stack>
    <StatTables stats={companyStats} />
    <Box mb={15}>
      <RequestsInbox
        evaluation={evaluation}
        userRole={ROLES.ADMIN}
        isLoading={isLoadingRequests}
        error={errorRequests}
        refetch={refetchRequests}
        requests={updatedEmployeeRequests}
      />
    </Box>
  </Stack>
)

const Configurations = ({ children, ...others }) => (
  <Stack
    sx={{
      mt: {
        xs: 1,
        md: 2
      }
    }}
  >
    <EvaluationForm {...others} />
  </Stack>
)

const PageContent = ({
  handleTabChange,
  tabValue,
  evaluation,
  companyStats,
  isLoadingRequests,
  errorRequests,
  refetchRequests,
  updatedEmployeeRequests,
  employees
}) => (
  <>
    <Box
      sx={{
        px: {
          xs: 3,
          md: 0
        }
      }}
    >
      <HeaderSection {...{ evaluation }} />
      <Divider />
    </Box>
    <Stack>
      <TabContainer
        handleTabChange={handleTabChange}
        tabValue={tabValue}
        tabLabels={{
          0: 'Completion',
          1: 'Cycle Settings',
          2: 'Reports & Access Controls'
        }}
        sx={{
          px: {
            xs: 3,
            md: 0
          }
        }}
      >
        {/* !! THE ORDER IS MATTER, THE ORDER NEEDS TO BE CONSISTENT WITH tabLabels OBJECT !!! */}
        <Box
          sx={{
            px: {
              xs: 3,
              md: 0
            }
          }}
        >
          <Completion
            {...{
              companyStats,
              isLoadingRequests,
              errorRequests,
              refetchRequests,
              updatedEmployeeRequests,
              evaluation
            }}
          />
        </Box>
        <Configurations
          {...{
            evaluation,
            method: EVALUATION_FORM_METHODS.UPDATE,
            behaviors: evaluation?.questions,
            companyEmployees: employees
          }}
        />
        <Box>
          <AccessControls evaluation={evaluation} employees={employees} />
        </Box>
      </TabContainer>
    </Stack>
  </>
)

const Page = () => {
  const { evaluationId } = useParams()
  const { state } = useLocation()
  const [tabValue, setTabValue] = useState(state.activeTab || 0)

  const handleTabChange = (tab) => setTabValue(tab)

  const {
    isLoading: isLoadingCompanyStats,
    error: errorCompanyStats,
    data: companyStats
  } = useGetRequest({
    endpoint: Endpoints.GET_REVIEW_STATS,
    data: {
      evaluationId,
      userRole: ROLES.ADMIN
    }
  })

  // userRoles is used to determine which schema to use for validation
  const userRoles = useUser().user.userRoles
  const {
    isLoading: isLoadingEvaluation,
    error: errorEvaluation,
    data: evaluation
  } = useGetRequest({
    endpoint: Endpoints.GET_EVALUATION,
    data: { evaluationId },
    repoData: { userRoles }
  })

  const {
    isLoading: isLoadingRequests,
    data: employeeRequests,
    error: errorRequests,
    refetch: refetchRequests
  } = useGetRequest({
    endpoint: Endpoints.GET_EMPLOYEE_REQUESTS,
    data: { userRole: ROLES.ADMIN, evaluationId }
  })

  const {
    isLoading: employeesLoading,
    data: employees,
    error: employeesError
  } = useGetRequest({
    endpoint: Endpoints.GET_COMPANY_PEOPLE,
    data: { includeDeletedUsers: false }
  })

  const isLoading =
    isLoadingCompanyStats ||
    isLoadingEvaluation ||
    isLoadingRequests ||
    employeesLoading

  // Add NOT_HANDLED status to AWAITING_RESPONSE requests
  const updatedEmployeeRequests =
    !isLoading &&
    requestsModal.crossCheckStatuses({
      mappedRequests: employeeRequests,
      evaluation
    })

  const errors = [
    errorCompanyStats,
    errorEvaluation,
    errorRequests,
    employeesError
  ]

  const error = errors.find((error) => error !== undefined)

  return (
    <>
      <Seo title="Results & Stats" />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            px: {
              xs: 0,
              md: 3
            }
          }}
        >
          {isLoading && <PageLoadingContent />}
          {!isLoading && error && <PageErrorContent message={error.message} />}
          {!isLoading && !error && (
            <PageContent
              handleTabChange={handleTabChange}
              tabValue={tabValue}
              {...{
                evaluation,
                companyStats,
                isLoadingRequests,
                errorRequests,
                refetchRequests,
                updatedEmployeeRequests,
                employees
              }}
            />
          )}
        </Container>
      </Box>
    </>
  )
}

export default withReviewGuard(Page)
