import React from 'react'

const UsersTableContext = React.createContext(undefined)

function UsersTableProvider({ children, value }) {
  return (
    <UsersTableContext.Provider value={value}>
      {children}
    </UsersTableContext.Provider>
  )
}

function useUsersTableContext() {
  const context = React.useContext(UsersTableContext)
  if (context === undefined) {
    throw new Error(
      'useCounterContext must be used within a UsersTableProvider'
    )
  }
  return context
}

export { UsersTableProvider, useUsersTableContext }
