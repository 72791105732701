import React from 'react'
import {
  CircularProgress,
  Typography,
  ButtonProps,
  TooltipProps,
  SxProps,
  IconButton
} from '@mui/material'
import { LightTooltip } from '/src/components/light-tooltip'

interface PrimaryIconButtonProps extends ButtonProps {
  isLoading?: boolean
  tooltipText?: string
  tooltipProps?: TooltipProps
  disableTooltip?: boolean
  sx?: SxProps
  icon: React.ReactElement
}

const PrimaryIconButton: React.FC<PrimaryIconButtonProps> = ({
  isLoading = false,
  tooltipText,
  tooltipProps,
  disableTooltip,
  icon,
  sx,
  ...otherProps
}) => (
  <LightTooltip
    title={<Typography variant="body2">{tooltipText}</Typography>}
    disableHoverListener={disableTooltip || !tooltipText}
    data-testid="primary-outlined-icon-button-tooltip"
    {...tooltipProps}
  >
    <span data-testid="primary-outlined-icon-button-wrapper">
      <IconButton
        sx={{
          height: '50px',
          width: '55px',
          color: 'primary.main',
          border: '0.5px solid',
          borderRadius: 1,
          ...sx
        }}
        data-testid="primary-outlined-icon-button"
        {...otherProps}
      >
        {isLoading ? (
          <CircularProgress
            data-testid="primary-outlined-icon-button-circular-progress"
            size={20}
          />
        ) : (
          icon
        )}
      </IconButton>
    </span>
  </LightTooltip>
)

export default PrimaryIconButton
