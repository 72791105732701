import { array, boolean, number, object, string } from 'yup'
import { ROLES } from '../../constants/roles.js'

export const dateSchema = object()
  .shape({
    _seconds: number().required('Seconds field is required.'),
    _nanoseconds: number().nullable().defined('Nanoseconds field is required.')
  })
  .default(undefined)
  .noUnknown()

export const completionStatsSchema = array()
  .of(
    object()
      .shape({
        completion: number().required('Completion field is required.'),
        timestamp: dateSchema
      })
      .noUnknown()
      .required('Completion stats field is required.')
  )
  .ensure()

export const questionsSchema = array().of(
  object()
    .shape({
      questionId: string().nullable().defined('Question ID field is required.'),
      description: string()
        .nullable()
        .defined('Description field is required.'),
      helperLink: string().nullable().defined('Helper link field is required.'),
      title: string().required('Title field is required.'),
      leadershipBehavior: boolean().optional()
    })
    .noUnknown()
    .required('Questions field is required.')
)

export const reportConfigSchema = array().of(
  object()
    .shape({
      name: string().required('Name field is required.'),
      display: boolean().required('Display field is required.'),
      description: string()
        .nullable()
        .defined('Description field is required.'),
      type: string().nullable().defined('Type field is required.')
    })
    .noUnknown()
)

export const dataConfigSchema = object()
  .shape({
    selectMin: number().integer().required('Select min field is required.'),
    selectMax: number().integer().required('Select max field is required.'),
    requestMin: number()
      .integer()
      .nullable()
      .defined('Request min field is required.'),
    enableMandatoryInclusionForDirectReports: boolean().required(
      'Enable mandatory inclusion for direct reports field is required.'
    ),
    enableMandatoryInclusionForDirectManager: boolean().required(
      'Enable mandatory inclusion for direct manager field is required.'
    ),
    enableSelfScoring: boolean().required(
      'Enable self scoring field is required.'
    ),
    commentMin: number()
      .integer()
      .nullable()
      .defined('Comment min field is required.'),
    grades: array()
      .of(string().required('Grades field is required.'))
      .required('Grades field is required.'),
    enableMandatoryCommentsForLowestAndHighest: boolean().required(
      'Enable mandatory comments for lowest and highest field is required.'
    ),
    displaySeniorityInReview: boolean().required(
      'Display seniority in review field is required.'
    ),
    enableReorderingByScore: boolean().optional(),
    guidelines: object()
      .shape({
        [ROLES.EMPLOYEE]: string()
          .strict()
          .optional('Employee field is required.'),
        [ROLES.MANAGER]: string()
          .strict()
          .optional('Manager field is required.')
      })
      .optional()
      .noUnknown()
  })
  .noUnknown()

export const evaluationConfigSchema = object()
  .shape({
    reportConfig: reportConfigSchema.required(
      'Report config field is required.'
    ),
    dataConfig: dataConfigSchema.required('Data config field is required.'),
    // optional fields
    totalBonus: number().optional(),
    behaviorPercentages: array()
      .of(
        object()
          .shape({
            behavior: string().required('Behavior field is required.'),
            percentage: number().required('Percentage field is required.')
          })
          .noUnknown()
      )
      .optional(),
    maxBonus: number().strict().optional().nullable(),
    minBonus: number().strict().optional().nullable(),
    minRating: number().strict().optional().nullable(),
    maxRaise: number().strict().optional().nullable(),
    minRaise: number().strict().optional().nullable(),
    mode: string().strict().optional().nullable(),
    currency: string().strict().optional().nullable(),
    behaviorWeights: array().of(
      object()
        .shape({
          behavior: string().strict().required('Behavior field is required.'),
          type: string()
            .strict()
            .required('Type field is required.')
            .oneOf(['Competency', 'Value']),
          weight: number().strict().required('Weight field is required.')
        })
        .noUnknown(true)
    )
  })
  .noUnknown()
  .required('Evaluation config field is required.')

export const evaluationSchema = object()
  .shape({
    company: string().required('Company field is required.'),
    description: string().required('Description field is required.'),
    name: string().required('Name field is required.'),
    questions: questionsSchema.required('Questions field is required.'),
    status: string().required('Status field is required.'),
    uid: string().required('UID field is required.'),
    startDate: dateSchema.nullable().defined('Start date field is required.'),
    endDate: dateSchema.nullable().defined('End date field is required.'),
    expectedEndDate: dateSchema
      .nullable()
      .defined('Expected end date field is required.'),
    users: array().of(string().required('Users field is required.')).ensure(),
    createdDate: dateSchema.required('Created date field is required.'),
    expectedStartDate: dateSchema
      .nullable()
      .defined('Expected start date field is required.'),
    completionStats: completionStatsSchema.required(
      'Completion stats field is required.'
    ),
    evaluationConfig: evaluationConfigSchema.required(
      'Evaluation config field is required.'
    )
  })
  .noUnknown()
