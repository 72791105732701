import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '/src/layouts/dashboard'
import ErrorBoundary from '/src/components/sentry-error-boundary'

// Redirect to homepage according to user role
import RedirectHandler from '/src/pages/dashboard/index.jsx'

// Admin
import AdminDashboard from '/src/pages/dashboard/admin/index.jsx'
import AdminEvaluationList from '/src/pages/dashboard/admin/reviews.jsx'
import CycleDetails from '/src/pages/dashboard/admin/cycle-details.jsx'

// Manager
import ManagerDashboard from '/src/pages/dashboard/manager/index.jsx'
import ManagerEvaluationList from '/src/pages/dashboard/manager/reviews.jsx'
import ManagerProgressPage from '/src/pages/dashboard/manager/progress.jsx'

// Reviews
import MyReviews from '/src/pages/dashboard/my-reviews.jsx'
import QuestionsPage from '/src/pages/dashboard/reviews/questions.jsx'
import EmployeeResultsPage from '/src/pages/dashboard/reviews/results.jsx'
import DashboardLoading from '/src/components/dashboard-loading'

// Lazy imported admin pages
const CreateEvaluation = lazy(() => import('/src/pages/dashboard/admin/create'))
const UpdateEvaluation = lazy(() =>
  import('/src/pages/dashboard/admin/update.jsx')
)
const AdminManagement = lazy(() =>
  import('/src/pages/dashboard/admin/management')
)

// Lazy imported reviews pages
const SelectionPage = lazy(() =>
  import('/src/pages/dashboard/reviews/selection.jsx')
)
const SubmitPage = lazy(() => import('/src/pages/dashboard/reviews/submit.jsx'))

export const dashboardRoutes = [
  {
    path: '/',
    element: (
      <DashboardLayout>
        <ErrorBoundary>
          <Suspense fallback={<DashboardLoading />}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <RedirectHandler />
      },
      {
        path: 'admin',
        children: [
          {
            index: true,
            element: <AdminDashboard />
          },
          {
            path: 'reviews',
            element: <AdminEvaluationList />
          },
          {
            path: ':evaluationId/details',
            element: <CycleDetails />
          },
          {
            path: ':evaluationId/update',
            element: <UpdateEvaluation />
          },
          {
            path: 'create',
            element: <CreateEvaluation />
          },
          {
            path: 'management',
            element: <AdminManagement />
          }
        ]
      },
      {
        path: 'manager',
        children: [
          {
            index: true,
            element: <ManagerDashboard />
          },
          {
            path: 'reviews',
            element: <ManagerEvaluationList />
          },
          {
            path: 'reviews/:evaluationId/progress',
            element: <ManagerProgressPage />
          }
        ]
      },
      {
        path: 'reviews',
        children: [
          {
            index: true,
            element: <MyReviews />
          },
          {
            path: ':evaluationId',
            element: <SelectionPage />
          },
          {
            path: ':evaluationId/questions',
            element: <QuestionsPage />
          },
          {
            path: ':evaluationId/submit',
            element: <SubmitPage />
          },
          {
            path: ':evaluationId/results',
            element: <EmployeeResultsPage />
          }
        ]
      }
    ]
  }
]
