import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  CardHeader,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material'
import { Scrollbar } from '/src/components/scrollbar'
import { useState } from 'react'
import { capitalizeFirstLetter } from '/src/utils/capitalize-first-letter'

export const CompletionStats = (props) => {
  const { completionStats, totalUsers } = props

  const properties = Object.keys(completionStats)

  const [property, setProperty] = useState(properties[0])

  const stats = completionStats[property]

  if (properties.length === 0) return null

  return (
    <Card
      sx={{
        height: '100%'
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="baseline"
        px={2}
      >
        <CardHeader
          title={`Completion  by ${capitalizeFirstLetter(property)}`}
        />

        {properties.length > 1 ? (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-evenly"
          >
            {properties.map((prop) => {
              return (
                <Button
                  data-gaid={`completion-stats-by-${prop}`}
                  key={prop}
                  onClick={() => setProperty(prop)}
                  size="small"
                  variant={prop === property ? 'contained' : 'outlined'}
                >
                  {capitalizeFirstLetter(prop)}
                </Button>
              )
            })}
          </Stack>
        ) : null}
      </Stack>
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: {
            xs: '100%',
            md: '50%',
            lg: '40%'
          },
          px: 5,
          py: 2
        }}
      >
        <Typography color="text.secondary" variant="body2">
          Total
        </Typography>
        <Typography sx={{ mb: 3 }} variant="h5">
          {totalUsers} Employees
        </Typography>
        <Scrollbar
          component={Stack}
          spacing={2}
          sx={{
            maxHeight: 270,
            overflowY: 'auto'
          }}
        >
          {stats.map((stat) => {
            return (
              <Stack key={stat.id} spacing={1} sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">{stat.type}</Typography>
                <Stack alignItems="center" direction="row" spacing={3}>
                  <LinearProgress
                    sx={{ flexGrow: 1 }}
                    color="success"
                    value={stat.amount}
                    variant="determinate"
                  />
                  <Typography textAlign="end" width={50}>
                    {stat.amount}%
                  </Typography>
                </Stack>
              </Stack>
            )
          })}
        </Scrollbar>
      </Box>
    </Card>
  )
}

CompletionStats.propTypes = {
  completionStats: PropTypes.object.isRequired,
  totalUsers: PropTypes.number.isRequired
}
