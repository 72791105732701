import ApexChart from 'react-apexcharts'
import { alpha, styled, Theme } from '@mui/material/styles'

export const Chart = styled(ApexChart)(({ theme }: { theme: Theme }) => ({
  '& .apexcharts-xaxistooltip': {
    'backgroundColor': theme.palette.background.paper,
    'boxShadow': theme.shadows[16],
    'borderRadius': theme.shape.borderRadius,
    'border': 0,
    '&::before, &::after': {
      display: 'none'
    }
  },
  '& .apexcharts-tooltip': {
    'padding': theme.spacing(1),
    '&.apexcharts-theme-light, &.apexcharts-theme-dark': {
      'backdropFilter': 'blur(12px)',
      'background':
        theme.palette.mode === 'dark'
          ? alpha(theme.palette.grey[800], 0.75)
          : alpha(theme.palette.grey[50], 0.75),
      'border': 0,
      'boxShadow': 'none',
      '& .apexcharts-tooltip-title': {
        // background: alpha(theme.palette.neutral[900], 0.8),
        background: alpha('#111927', 0.8),
        border: 0,
        color: theme.palette.common.white,
        margin: 0
      },
      '& .apexcharts-tooltip-series-group': {
        // background: alpha(theme.palette.neutral[900], 0.7),
        background: alpha('#111927', 0.7),
        border: 0,
        color: theme.palette.common.white
      }
    }
  }
}))
