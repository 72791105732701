import { auth } from '/src/config/firebase'
import { baseUrl } from '/src/config/firebase'

const makeRequest = async ({ data, endpoint, isPublic }) => {
  // Getting the user idToken
  const idToken = isPublic ? '' : await auth.currentUser.getIdToken()

  // Getting the project id
  const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID

  // Getting the dynamic server name
  const serverName = isPublic ? 'publicServer' : 'server'

  const response = await fetch(`${baseUrl}/${serverName}/${endpoint}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${idToken}`,
      'Content-Type': 'application/json',
      'Environment': projectId
    },
    body: JSON.stringify(data)
  })

  const result = await response.json()
  if (result.code !== 200) throw new Error(result.message)

  return result
}

export default makeRequest
