import PropTypes from 'prop-types'
import { Box, Stack, Typography } from '@mui/material'

export const TenantSwitch = (props) => {
  const companyName = props.name || 'Incompass'
  const justifyFontSize = () => {
    if (companyName.length <= 15) {
      return '16px'
    } else if (15 < companyName.length && companyName.length <= 20) {
      return '14px'
    } else if (20 < companyName.length && companyName.length <= 25) {
      return '12px'
    } else {
      return '10px'
    }
  }
  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2} {...props}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            color="black"
            variant="h6"
            sx={{
              whiteSpace: 'break-spaces',
              textAlign: 'center'
            }}
            fontSize={justifyFontSize() + ' !important'}
          >
            {companyName}
          </Typography>
        </Box>
      </Stack>
    </>
  )
}

TenantSwitch.propTypes = {
  sx: PropTypes.object,
  name: PropTypes.string
}
