import { Stack, Typography } from '@mui/material'
import React from 'react'

const ScoringTableHeader = ({ grades, ...otherProps }) => (
  <Stack direction="row" {...otherProps}>
    {grades.map((text, index) => (
      <Typography
        key={index}
        data-testid="header-element"
        sx={{
          // Align text based on position
          textAlign:
            index === 0
              ? 'left'
              : index === grades.length - 1
              ? 'right'
              : 'center',
          // calculate width based on number of grades
          width: `${100 / grades.length}%`,
          fontWeight: 'bold',
          fontSize: grades && grades.length > 4 ? '0.8rem' : '1rem'
        }}
      >
        {text}
      </Typography>
    ))}
  </Stack>
)

export default ScoringTableHeader
