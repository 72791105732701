type ChartType =
  | 'bar'
  | 'radar'
  | 'pie'
  | 'scatter'
  | 'line'
  | 'rangeBar'
  | 'area'
  | 'heatmap'

export const ChartTypes: Record<string, ChartType> = {
  BAR: 'bar',
  RADAR: 'radar',
  PIE: 'pie',
  SCATTER: 'scatter',
  LINE: 'line',
  RANGE_BAR: 'rangeBar',
  AREA: 'area',
  HEATMAP: 'heatmap'
}
