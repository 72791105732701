import { Stack } from '@mui/material'
import { UserListSearch } from './user-list-search'
import { CsvDownloadButton } from '/src/components/users-table/components/csv-download-button'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'
import { UserListFilter } from './user-list-filters'
import { useMemo } from 'react'
import DrawerFiltersButton from '/src/components/filtering-drawer/drawer-filters-button'
import SecondaryIconButton from '/src/components/buttons/secondary-icon-button'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import TocIcon from '@mui/icons-material/Toc'
import GreyOutlinedButton from '/src/components/buttons/grey-outlined-button'

const Filters = () => {
  const {
    columns,
    gridView,
    csv,
    drawerFilters,
    handleOpenDrawerFilters,
    users,
    search,
    askAnything,
    toggleGridView,
    showGridView,
    setDisplayAskAnything,
    displayAskAnything,
    exportAllData
  } = useUsersTableContext()

  // get true if any columns have filter
  const hasFilter = useMemo(() => {
    return columns.some((column) => column.filter)
  }, [columns])
  // if no csv, search and no filter - return null
  if (!csv && !search && !hasFilter) {
    return null
  }
  // if no csv, search and has filter - return filter
  if (!csv && !search && hasFilter) {
    return (
      <Stack my={2} mx={2}>
        <UserListFilter />
      </Stack>
    )
  }
  // otherwise return the values which are true
  return (
    <Stack mt={2} mx={2}>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        sx={{
          mb: '32px !important'
        }}
      >
        {drawerFilters ? (
          <DrawerFiltersButton
            filters={drawerFilters}
            handleOpenDrawerFilters={handleOpenDrawerFilters}
          />
        ) : null}
        {search ? (
          <UserListSearch
            sx={{
              flexGrow: 1
            }}
          />
        ) : null}
        {gridView ? (
          <GreyOutlinedButton
            data-gaid={showGridView ? 'grid-view' : 'table-view'}
            startIcon={
              showGridView ? (
                <TocIcon color="action" />
              ) : (
                <ScatterPlotIcon color="action" />
              )
            }
            onClick={toggleGridView}
            sx={{
              minWidth: '180px'
            }}
          >
            {showGridView ? 'See Table View' : 'See Grid View'}
          </GreyOutlinedButton>
        ) : null}
        {askAnything ? (
          <SecondaryIconButton
            data-gaid="ask-anything"
            icon={
              <AutoAwesomeIcon
                sx={{
                  color: displayAskAnything ? 'primary.main' : 'neutral.500'
                }}
              />
            }
            onClick={() => setDisplayAskAnything((prev) => !prev)}
            tooltipText="Ask Anything"
          />
        ) : null}
        {csv ? (
          <CsvDownloadButton
            rows={users}
            columns={columns}
            exportAllData={exportAllData}
          />
        ) : null}
      </Stack>
      <UserListFilter />
    </Stack>
  )
}
export { Filters }
