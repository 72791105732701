import makeRequest from './index'

const demoProvider = {
  createDemoAccount: async (data) => {
    const result = await makeRequest({ data, endpoint: 'createDemoAccount' })

    return result.data
  },
  upgradeRequest: async (data) => {
    const result = await makeRequest({ data, endpoint: 'upgradeRequest' })

    return result.data
  }
}

export default demoProvider
