import { useCallback, useState, useRef } from 'react'
import { pdf } from '@react-pdf/renderer'
import html2canvas from 'html2canvas'
import EmployeeResultsPdfDocument from '/src/components/pdf-previewer/documents/employee-results-pdf-document'
import { toast } from 'react-toastify'

const useEmployeeResultsPdf = ({
  result = {},
  fullName = '',
  isEmployee = false
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dataCardsRef = useRef({})

  // Remove results without reviews
  result.resultsByQuestion = result.resultsByQuestion.filter(
    (result) => result.reviewsReceived
  )

  const generatePDF = useCallback(
    async ({ withComments }) => {
      try {
        // Initialize images array
        const images = []

        // Get all elementRefs
        const allRefs = Object.values(dataCardsRef.current)

        // Check if all elementRefs are available before proceeding
        const allRefsAvailable = allRefs.every((ref) => ref.current)
        if (!allRefsAvailable) {
          throw new Error('Not all refs are available')
        }

        // Loop through all elementRefs and generate images
        for (const elementRef of allRefs) {
          // Turning the elements into a canvas
          const element = elementRef.current

          const canvas = await html2canvas(element, {
            // Window width to use when rendering Element, which may affect things like Media queries
            windowWidth: '1600px',
            // Canvas background color, if none is specified in DOM. Set null for transparent
            backgroundColor: null,
            // Callback function which is called when the Document has been cloned for rendering, can be used to modify the contents that will be rendered without affecting the original source document.
            onclone: (document, element) => {
              try {
                Array.from(element.children).forEach((div) => {
                  div.firstChild.style.color = 'black'
                  div.style.backgroundColor = 'transparent'
                  div.firstChild.querySelector('button').style.display = 'none'
                })
              } catch (error) {
                console.warn('Error capturing content:', error)
                throw new Error(error)
              }
            }
          })

          // Convert canvas to base64 image and push to images array
          const base64Image = canvas.toDataURL('image/png')
          images.push(base64Image)
        }

        // Generate PDF
        const blob = await pdf(
          <EmployeeResultsPdfDocument
            {...{
              result,
              fullName,
              isEmployee,
              withComments,
              images
            }}
          />
        ).toBlob()

        // Create a URL for the blob
        const url = URL.createObjectURL(blob)

        return window.open(url, '_blank')
      } catch (error) {
        console.error('An error occurred while generating PDF:', error)
        throw new Error(error)
      }
    },
    [result, fullName, isEmployee]
  )

  const generateEmployeeResultsPDF = async ({ withComments }) => {
    setIsLoading(true)

    await toast.promise(generatePDF({ withComments }), {
      pending: 'Generating PDF...',
      success: {
        render() {
          setIsLoading(false)
          return 'PDF generated successfully'
        }
      },
      error: {
        render() {
          setIsLoading(false)
          return 'Error generating PDF'
        }
      }
    })
  }

  return {
    generateEmployeeResultsPDF,
    isLoading,
    dataCardsRef
  }
}

export default useEmployeeResultsPdf
