// Hooks
import { useState } from 'react'
import { useDimensions } from '/src/hooks/use-dimensions'

// MUI components
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Typography,
  Paper,
  Collapse
} from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

// Custom components
import Slider from '/src/sections/dashboard/reviews/questions/slider'
import { LabelsWithDragAndDrop } from './labels-with-drag-and-drop'
import { LightTooltip } from '/src/components/light-tooltip'

// Constants
import { CONFIG_FIELDS } from '/src/constants/evaluation-config'
import { EVALUATION_STATES } from '/src/constants/statuses'

// Icons
import ArrowRightIcon from '@untitled-ui/icons-react/build/cjs/ArrowRight'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import InfoIcon from '@mui/icons-material/Info'

// Others
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import TextButton from '/src/components/buttons/text-button'
import SecondaryButton from '/src/components/buttons/secondary-button'

const DEFAULT_MIN = 3
const MAXIMUM_GRADE_NUMBER = 4

export const EvaluationDetailsStep = (props) => {
  const { onBack, onNext, formik, ...other } = props

  // We only use this state in this component to control the switch
  // therefore we control it locally
  const [localValues, setLocalValues] = useState({
    requestMin: formik.values.evaluationConfig?.dataConfig.requestMin !== null,
    commentMin: formik.values.evaluationConfig?.dataConfig.commentMin !== null
  })

  const handleLocalChanges = (field) => (e) => {
    setLocalValues({ ...localValues, [field]: e.target.checked })
    // Update formik values if the input is not checked
    if (!e.target.checked) {
      formik.setFieldValue(`evaluationConfig.dataConfig.${field}`, null)

      // If the commentMin is unchecked, we also set the mandatory comments for lowest and highest to false
      if (field === 'commentMin') {
        formik.setFieldValue(
          `evaluationConfig.dataConfig.enableMandatoryCommentsForLowestAndHighest`,
          false
        )
      }
    } else {
      formik.setFieldValue(`evaluationConfig.dataConfig.${field}`, DEFAULT_MIN)
    }
  }

  const [gradeText, setGradeText] = useState('')

  const formSettings = formik.values.formSettings

  const handleAddGrade = (e) => {
    e.preventDefault()
    if (gradeText) {
      formik.setFieldValue(
        'evaluationConfig.dataConfig.grades',
        formik.values.evaluationConfig.dataConfig.grades.concat(gradeText)
      )
      setGradeText('')
    }
  }

  const handleGradeDelete = (index) => {
    formik.setFieldValue(
      'evaluationConfig.dataConfig.grades',
      formik.values.evaluationConfig.dataConfig.grades.filter(
        (grade, i) => i !== index
      )
    )
  }

  // Example feedback data
  const [person, setPerson] = useState(examplePerson)

  // We use this to get the width of the slider column to set the width of the labels
  const [sliderRef, sliderDimensions] = useDimensions()

  // We use this state to control the tooltip
  const [openTooltip, setOpenTooltip] = useState(false)

  return (
    <Stack spacing={3} {...other}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={2} sx={{ alignSelf: 'flex-start', minWidth: 400 }}>
          <Typography variant="h5">Review Details</Typography>
          <Typography variant="caption" color="textSecondary">
            Provide information about the review cycle, such as name and cycle
            timeline.
          </Typography>
          <TextField
            fullWidth
            name="name"
            type="text"
            label="Name"
            multiline
            maxRows={5}
            placeholder="Please type a name for the evaluation"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            {...formSettings[CONFIG_FIELDS.NAME]}
          />
          <TextField
            fullWidth
            name="description"
            type="text"
            label="Description"
            placeholder="Please type a description for the evaluation"
            multiline
            maxRows={10}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            {...formSettings[CONFIG_FIELDS.DESCRIPTION]}
          />
        </Stack>
        <div>
          <Typography variant="subtitle1">
            When would you like to start and end the review process?
          </Typography>
        </div>
        <Stack alignItems="center" direction="row" maxWidth={400} spacing={3}>
          <Stack>
            <MobileDatePicker
              name="startDate"
              disablePast={
                formik.values?.status === EVALUATION_STATES.TEMPLATE
                  ? true
                  : false
              }
              label="Start Date"
              type="date"
              minDate={
                formik.values?.status === EVALUATION_STATES.TEMPLATE
                  ? dayjs()
                  : dayjs(formik.values.startDate)
              }
              value={dayjs(formik.values.startDate)}
              showToolbar={false}
              onChange={(date) =>
                formik.setFieldValue('startDate', new Date(date))
              }
              slotProps={{
                textField: {
                  error:
                    formik.touched.startDate &&
                    Boolean(formik.errors.startDate),
                  helperText:
                    formik.touched.startDate &&
                    formik.errors.startDate &&
                    'Start date is not valid'
                }
              }}
              {...formSettings[CONFIG_FIELDS.START_DATE]}
            />
          </Stack>
          <Stack>
            <MobileDatePicker
              name="endDate"
              disabled={!formik.values.startDate}
              label="End Date"
              type="date"
              value={dayjs(formik.values.endDate)}
              minDate={dayjs(formik.values.startDate)}
              showToolbar={false}
              onChange={(date) =>
                formik.setFieldValue('endDate', new Date(date))
              }
              slotProps={{
                textField: {
                  error:
                    formik.touched.endDate && Boolean(formik.errors.endDate),
                  helperText:
                    formik.touched.endDate &&
                    formik.errors.endDate &&
                    'End date is not valid'
                }
              }}
              {...formSettings[CONFIG_FIELDS.END_DATE]}
            />
          </Stack>
        </Stack>

        <Stack spacing={1} pt={4}>
          <Stack spacing={2} pb={2}>
            <Typography variant="h5">Feedback Configurations</Typography>
            <Typography variant="caption" color="textSecondary">
              Configure the feedback settings for the review cycle, such as the
              number of comments and colleagues required.
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <div>
              <Typography variant="subtitle2">
                Allow participants to review themselves.
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Individual contributors will not be asked to review themselves
                for leadership behaviors.
              </Typography>
            </div>
            <FormControlLabel
              control={
                <Switch
                  name="evaluationConfig.dataConfig.enableSelfScoring"
                  checked={
                    formik.values.evaluationConfig.dataConfig.enableSelfScoring
                  }
                  onChange={formik.handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  {...formSettings[CONFIG_FIELDS.ENABLE_SELF_SCORING]}
                />
              }
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">
              Require participants to review their manager.
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  name="evaluationConfig.dataConfig.enableMandatoryInclusionForDirectManager"
                  checked={
                    formik.values.evaluationConfig.dataConfig
                      .enableMandatoryInclusionForDirectManager
                  }
                  onChange={formik.handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  {...formSettings[
                    CONFIG_FIELDS.ENABLE_MANDATORY_INCLUSION_FOR_DIRECT_MANAGER
                  ]}
                />
              }
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">
              Require participants to review their direct reports.
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  name="evaluationConfig.dataConfig.enableMandatoryInclusionForDirectReports"
                  checked={
                    formik.values.evaluationConfig.dataConfig
                      .enableMandatoryInclusionForDirectReports
                  }
                  onChange={formik.handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  {...formSettings[
                    CONFIG_FIELDS.ENABLE_MANDATORY_INCLUSION_FOR_DIRECT_REPORTS
                  ]}
                />
              }
            />
          </Stack>
          <Stack spacing={3} pt={3}>
            <div>
              <Typography variant="subtitle2">
                How many colleagues should the participants choose to review?
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Set the minimum and maximum number of colleagues that
                participants can select to review.
              </Typography>
            </div>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              width={540}
              spacing={2}
            >
              <TextField
                fullWidth
                name="evaluationConfig.dataConfig.selectMin"
                label="Minimum"
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none'
                    }
                }}
                inputProps={{
                  pattern: '[0-9]*'
                }}
                value={formik.values.evaluationConfig?.dataConfig?.selectMin}
                onChange={formik.handleChange}
                error={
                  formik.touched.evaluationConfig?.dataConfig?.selectMin &&
                  Boolean(formik.errors.evaluationConfig?.dataConfig?.selectMin)
                }
                helperText={
                  formik.touched.evaluationConfig?.dataConfig?.selectMin &&
                  formik.errors.evaluationConfig?.dataConfig?.selectMin
                }
                {...formSettings[CONFIG_FIELDS.SELECT_MIN]}
              />
              <TextField
                fullWidth
                label="Maximum"
                type="number"
                name="evaluationConfig.dataConfig.selectMax"
                InputLabelProps={{
                  shrink: true
                }}
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none'
                    }
                }}
                inputProps={{
                  pattern: '[0-9]*'
                }}
                value={formik.values.evaluationConfig.dataConfig?.selectMax}
                onChange={formik.handleChange}
                error={
                  formik.touched.evaluationConfig?.dataConfig?.selectMax &&
                  Boolean(formik.errors.evaluationConfig?.dataConfig?.selectMax)
                }
                helperText={
                  formik.touched.evaluationConfig?.dataConfig?.selectMax &&
                  formik.errors.evaluationConfig?.dataConfig?.selectMax
                }
                {...formSettings[CONFIG_FIELDS.SELECT_MAX]}
              />
            </Stack>
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">
                  Allow participants to select fewer colleagues to review upon
                  request to their managers.
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      name="allowRequestMin"
                      checked={localValues.requestMin}
                      onChange={handleLocalChanges('requestMin')}
                      inputProps={{ 'aria-label': 'controlled' }}
                      {...formSettings[CONFIG_FIELDS.ALLOW_REQUEST_MIN]}
                    />
                  }
                />
              </Stack>
              <Collapse in={localValues.requestMin}>
                <TextField
                  label="Minimum by request"
                  type="number"
                  name="evaluationConfig.dataConfig.requestMin"
                  disabled={!localValues.requestMin}
                  InputLabelProps={{
                    shrink: true
                  }}
                  sx={{
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none'
                      },
                    'width': 400
                  }}
                  inputProps={{
                    min: 5,
                    max:
                      formik.values.evaluationConfig?.dataConfig?.selectMin - 1,
                    step: 1,
                    pattern: '[0-9]*'
                  }}
                  value={
                    formik.values.evaluationConfig?.dataConfig?.requestMin || ''
                  }
                  onChange={formik.handleChange}
                  error={
                    formik.touched.evaluationConfig?.dataConfig?.requestMin &&
                    Boolean(
                      formik.errors.evaluationConfig?.dataConfig?.requestMin
                    )
                  }
                  helperText={
                    formik.touched.evaluationConfig?.dataConfig?.requestMin &&
                    formik.errors.evaluationConfig?.dataConfig?.requestMin
                  }
                  {...formSettings[CONFIG_FIELDS.REQUEST_MIN]}
                />
              </Collapse>
            </Stack>
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">
                  Allow participants to provide written feedback to their
                  colleagues.
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      name="allowCommentMin"
                      checked={localValues.commentMin}
                      onChange={handleLocalChanges('commentMin')}
                      inputProps={{ 'aria-label': 'controlled' }}
                      {...formSettings[CONFIG_FIELDS.ALLOW_COMMENT_MIN]}
                    />
                  }
                />
              </Stack>
              <Collapse in={localValues.commentMin}>
                <TextField
                  label="Minimum Number of Written Feedback per Behavior"
                  type="number"
                  name="evaluationConfig.dataConfig.commentMin"
                  disabled={!localValues.commentMin}
                  InputLabelProps={{
                    shrink: true
                  }}
                  sx={{
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none'
                      },
                    'width': 400
                  }}
                  inputProps={{
                    min: 0,
                    max: formik.values.evaluationConfig?.dataConfig?.requestMin
                      ? formik.values.evaluationConfig?.dataConfig?.requestMin
                      : formik.values.evaluationConfig?.dataConfig?.selectMin,
                    step: 1,
                    pattern: '[0-9]*'
                  }}
                  value={
                    formik.values.evaluationConfig?.dataConfig?.commentMin !==
                    null
                      ? formik.values.evaluationConfig?.dataConfig?.commentMin
                      : ''
                  }
                  onChange={(event) => {
                    if (
                      event.target.value !== '' &&
                      Number(event.target.value) < 2
                    ) {
                      formik.setFieldValue(
                        'evaluationConfig.dataConfig.enableMandatoryCommentsForLowestAndHighest',
                        false
                      )
                    }
                    formik.handleChange(event)
                  }}
                  error={
                    formik.touched.evaluationConfig?.dataConfig?.commentMin &&
                    Boolean(
                      formik.errors.evaluationConfig?.dataConfig?.commentMin
                    )
                  }
                  helperText={
                    formik.touched.evaluationConfig?.dataConfig?.commentMin &&
                    formik.errors.evaluationConfig?.dataConfig?.commentMin
                  }
                  {...formSettings[CONFIG_FIELDS.COMMENT_MIN]}
                />
              </Collapse>
              <Collapse
                in={formik.values.evaluationConfig?.dataConfig?.commentMin >= 2}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2">
                    Require comments for lowest and highest scored people.
                  </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        name="evaluationConfig.dataConfig.enableMandatoryCommentsForLowestAndHighest"
                        checked={
                          formik.values.evaluationConfig.dataConfig
                            .enableMandatoryCommentsForLowestAndHighest
                        }
                        onChange={formik.handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        {...formSettings[
                          CONFIG_FIELDS
                            .ENABLE_MANDATORY_COMMENTS_FOR_LOWEST_AND_HIGHEST
                        ]}
                      />
                    }
                  />
                </Stack>
              </Collapse>
            </Stack>
          </Stack>
        </Stack>
        <Stack pt={3} spacing={3}>
          <Stack spacing={2}>
            <Typography variant="h5">Set Score Labels</Typography>
            <Typography variant="caption" color="textSecondary">
              {`
                  Set the labels to be used on the scoring pages. You can drag
                  and drop the grades to reorder them and add up to
                  ${MAXIMUM_GRADE_NUMBER}.`}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            mb={2}
            component="form"
            onSubmit={handleAddGrade}
            sx={{
              width: 550
            }}
          >
            <TextField
              fullWidth
              label="Add new label"
              type="text"
              onChange={(e) => setGradeText(e.target.value)}
              value={gradeText}
              error={
                formik.touched.evaluationConfig?.dataConfig?.grades &&
                Boolean(formik.errors.evaluationConfig?.dataConfig?.grades)
              }
              helperText={
                formik.touched.evaluationConfig?.dataConfig?.grades &&
                formik.errors.evaluationConfig?.dataConfig?.grades
              }
              disabled={
                formik.values.evaluationConfig?.dataConfig?.grades?.length ===
                MAXIMUM_GRADE_NUMBER
              }
              {...formSettings[CONFIG_FIELDS.GRADES]}
            />

            {formik.values.evaluationConfig?.dataConfig?.grades?.length !==
            MAXIMUM_GRADE_NUMBER ? (
              <IconButton
                color="primary"
                onClick={handleAddGrade}
                {...formSettings[CONFIG_FIELDS.GRADES]}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setOpenTooltip(true)}
                onMouseEnter={() => setOpenTooltip(true)}
                onMouseLeave={() => setOpenTooltip(false)}
              >
                <LightTooltip
                  title={`You can't add more than ${MAXIMUM_GRADE_NUMBER} labels.`}
                  open={openTooltip}
                  onClose={() => setOpenTooltip(false)}
                >
                  <InfoIcon color="warning" />
                </LightTooltip>
              </IconButton>
            )}
          </Stack>
          <Paper elevation={5} sx={{ p: 1 }}>
            <LabelsWithDragAndDrop
              onDelete={handleGradeDelete}
              onChange={(newLabels) =>
                formik.setFieldValue(
                  'evaluationConfig.dataConfig.grades',
                  newLabels
                )
              }
              width={sliderDimensions.width}
              labels={formik.values.evaluationConfig?.dataConfig?.grades || []}
              {...formSettings[CONFIG_FIELDS.GRADES]}
            />
            <Stack direction="row" alignItems="center" mt={2}>
              <Checkbox
                sx={{
                  transition: 'all 0.3s ease-in-out'
                }}
              />
              <Box
                sx={{
                  lineHeight: 1
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    transition: 'all 0.3s ease-in-out',
                    minWidth: 'max-content',
                    lineHeight: 'inherit'
                  }}
                >
                  {person.fullName}
                </Typography>
                {formik.values.evaluationConfig.dataConfig
                  .displaySeniorityInReview ? (
                  <Typography
                    variant="caption"
                    sx={{
                      transition: 'all 0.3s ease-in-out',
                      minWidth: 'max-content',
                      lineHeight: 'inherit',
                      color: 'text.secondary'
                    }}
                  >
                    {person.seniority}
                  </Typography>
                ) : null}
              </Box>
              <Stack flexGrow={1} mx={4} ref={sliderRef}>
                <Slider
                  person={person}
                  onChange={(value, e) => setPerson({ ...person, value })}
                />
              </Stack>
              <TextButton
                endIcon={
                  person.comment &&
                  person.comment.length > 0 && <CheckCircleOutlineIcon />
                }
              >
                Give Feedback
              </TextButton>
            </Stack>
          </Paper>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="subtitle2">
              Display the seniority of the reviewed person.
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  name="evaluationConfig.dataConfig.displaySeniorityInReview"
                  checked={
                    formik.values.evaluationConfig.dataConfig
                      .displaySeniorityInReview
                  }
                  onChange={formik.handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  {...formSettings[CONFIG_FIELDS.DISPLAY_SENIORITY_IN_REVIEW]}
                />
              }
            />
          </Stack>
        </Stack>
        <div>
          <SecondaryButton
            sx={{
              mt: 3
            }}
            endIcon={
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            }
            onClick={onNext}
          >
            Continue
          </SecondaryButton>
        </div>
      </LocalizationProvider>
    </Stack>
  )
}

EvaluationDetailsStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  formik: PropTypes.object
}

const examplePerson = {
  fullName: 'Emily Griffin',
  seniority: 'Senior',
  comment: '',
  value: 0,
  isApplicable: true,
  uid: '4f1e93a3-0522-4378-82be-5099196bfa27',
  marks: [
    {
      value: 0
    },
    {
      value: 2.5
    },
    {
      value: 5
    },
    {
      value: 7.5
    },
    {
      value: 10
    },
    {
      value: 12.5
    },
    {
      value: 15
    },
    {
      value: 17.5
    },
    {
      value: 20
    },
    {
      value: 22.5
    },
    {
      value: 25
    },
    {
      value: 27.5
    },
    {
      value: 30
    },
    {
      value: 32.5
    },
    {
      value: 35
    },
    {
      value: 37.5
    },
    {
      value: 40
    },
    {
      value: 42.5
    },
    {
      value: 45
    },
    {
      value: 47.5
    },
    {
      value: 50
    },
    {
      value: 52.5
    },
    {
      value: 55
    },
    {
      value: 57.5
    },
    {
      value: 60
    },
    {
      value: 62.5
    },
    {
      value: 65
    },
    {
      value: 67.5
    },
    {
      value: 70
    },
    {
      value: 72.5
    },
    {
      value: 75
    },
    {
      value: 77.5
    },
    {
      value: 80
    },
    {
      value: 82.5
    },
    {
      value: 85
    },
    {
      value: 87.5
    },
    {
      value: 90
    },
    {
      value: 92.5
    },
    {
      value: 95
    },
    {
      value: 97.5
    },
    {
      value: 100
    },
    {
      value: 0
    }
  ]
}
