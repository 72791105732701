import styled from '@emotion/styled'

import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'

const LimitBar = (props) => {
  const { values, labels, helperText, sx } = props
  const theme = useTheme()

  // destructuring
  const { start, end, point: pointLabel } = labels
  const { bottom, top, point } = values

  // indicators for start&end points
  const startingValue = point || bottom
  const endingValue = point || top

  //  calculate the width depending on the value
  const interval = point ? 0 : endingValue - startingValue

  //  calculate the position on x axis depending on the value
  const positioner = startingValue

  return (
    <LimitBarContainer sx={sx}>
      <LimitBarWrapper positioner={positioner}>
        {point ? (
          <LimitBarDigit theme={theme} label={pointLabel} indicator={point} />
        ) : (
          <>
            <LimitBarDigit
              theme={theme}
              label={start}
              indicator={startingValue}
            />
            <LimitBarLine theme={theme} interval={interval} />
            <LimitBarDigit theme={theme} label={end} indicator={endingValue} />
          </>
        )}
      </LimitBarWrapper>
      <HelperText theme={theme}>{helperText}</HelperText>
    </LimitBarContainer>
  )
}

export default LimitBar

LimitBar.propTypes = {
  values: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    point: PropTypes.number
  }),
  labels: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    point: PropTypes.string
  }),
  helperText: PropTypes.string.isRequired,
  sx: PropTypes.object
}

const LimitBarContainer = styled('div')(() => ({
  position: 'relative',
  zIndex: 100,
  width: '100%',
  height: '100%'
}))

const LimitBarWrapper = styled('div')(({ positioner }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  transform: `translateX(${positioner}%)`
}))

const LimitBarDigit = styled('div')(({ theme, label }) => ({
  'width': 2,
  'height': 15,
  'backgroundColor': theme.palette.primary.dark,
  'zIndex': 100,
  'flexGrow': 0,
  '::before': {
    content: `"${label}"`,
    fontSize: 11,
    fontFamily: 'Inter',
    color: theme.palette.primary.dark,
    position: 'absolute',
    transform: 'translate(-50%,-90%)'
  }
}))

const LimitBarLine = styled('div')(({ theme, interval }) => ({
  backgroundColor: theme.palette.primary.dark,
  height: '1px',
  width: `${interval}%`
}))

const HelperText = styled('p')(({ theme }) => ({
  position: 'absolute',
  transform: 'translate(0%,100%)',
  color: theme.palette.primary.dark,
  fontFamily: 'Inter',
  fontSize: 12
}))
