import { useEffect } from 'react'
import { sendEventToGoogleAnalytics } from '/src/utils/react-ga'
import { useUser } from '/src/hooks/use-user'

const findClosestWithLimit = (element, selector, limit) => {
  let currentElement = element
  let level = 0

  while (currentElement && level < limit) {
    if (currentElement.matches(selector)) {
      return currentElement
    }
    currentElement = currentElement.parentElement
    level++
  }

  return null
}

export const useButtonClickTracker = () => {
  const { user } = useUser()
  useEffect(() => {
    const handleClickEvent = (event) => {
      const element = event?.target
      const elementGAId = findClosestWithLimit(element, '[data-gaid]', 5)
        ?.dataset?.gaid
      const elementText = element?.innerText || 'no-text'

      if (elementGAId) {
        const eventData = {
          action: 'click',
          category: 'button_click',
          element_id: elementGAId,
          element_text: elementText,
          user_uid: user?.uid,
          company_name: user?.companyName
        }

        sendEventToGoogleAnalytics('click', eventData)
      }
    }

    document.addEventListener('click', handleClickEvent)

    return () => {
      document.removeEventListener('click', handleClickEvent)
    }
  }, [user?.uid, user?.companyName])
}
