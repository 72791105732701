import dayjs from 'dayjs'
import { REVIEW_STATES, EVALUATION_STATES } from '/src/constants/statuses'
import { createObjectFromArrayOfObjects } from '/src/utils/create-object-from-array'

class ReviewModal {
  #generateMarks = () => {
    return Array.from({ length: 41 }, (_, i) => ({
      value: i * 2.5
    }))
  }

  prepareForQuestions = (result) => {
    const { questions, responses, people, ...rest } = result

    // Round previous scores to the nearest multiple of 2.5, which is the step size in sliders
    people.forEach((person, i, arr) => {
      Object.entries(person.previousData).forEach(([key, data]) => {
        if (data.score < 0 || data.score > 100) {
          throw new Error(
            `Invalid previous score for ${person.fullName} in question ${key}. Score: ${data.score}`
          )
        }
        data.score = Math.round(data.score / 2.5) * 2.5
      })
    })

    // Create an object of people for easy access
    const peopleObject = createObjectFromArrayOfObjects({
      array: people,
      fieldToKey: 'uid'
    })

    // get the questions they have already answered and exist in the responses array and add the their feedback in responses array to his object
    // if the question does not exist in the responses array, add the default values to the feedback array
    const filteredQuestions = questions.map((question) => {
      // get the questionId from the question object
      const questionId = question.questionId
      // get current question from responses array
      const questionDataFromResponses = responses.find(
        (response) => response.questionId === questionId
      )
      // get response data from the question object
      const { feedbacks, selfScore, selfComment } = questionDataFromResponses
      // Get default slider marks
      const defaultMarks = this.#generateMarks()

      // // Update feedback data with previous scores if applicable
      // feedbacks.forEach((feedback) => {
      //   const lastRating =
      //     peopleObject[feedback.userId].previousData[questionId]?.score
      //   // If there is a "last rating" from last evaluation and the current score is 0, set the current score to last rating
      //   feedback.score =
      //     lastRating && feedback.score <= 0 && !feedback.isIgnored
      //       ? lastRating
      //       : feedback.score
      // })

      // Track the nonzero picked values from the feedbacks array, which will be disabled in the slider
      const scoresToDisable = feedbacks
        .map((feedback) => feedback.score)
        .filter((score) => score > 0)

      // Filters the default marks array to exclude any marks that have been picked, based on a set of picked values.
      const availableScoreMarks = defaultMarks.filter(
        (scoreMark) => !scoresToDisable.includes(scoreMark.value)
      )

      // Maps each feedback to a new structure with additional details and calculations.
      const mappedFeedbacks = feedbacks.map((feedback) => {
        const reviewedUser = peopleObject[feedback.userId]

        return {
          fullName: feedback.fullName,
          comment: feedback.comment,
          value: feedback.score,
          isApplicable: !feedback.isIgnored,
          uid: feedback.userId,
          marks: feedback.isIgnored
            ? [{ value: feedback.score }]
            : [...availableScoreMarks, { value: feedback.score }],
          seniority: reviewedUser.seniority,
          previousData: reviewedUser.previousData[questionId] || null,
          isDirectManager: reviewedUser.isDirectManager
        }
      })

      // Sort the feedbacks by fullName or seniority
      if (result?.dataConfig?.displaySeniorityInReview) {
        mappedFeedbacks.sort((a, b) =>
          a.seniority.toLowerCase().localeCompare(b.seniority.toLowerCase())
        )
      } else {
        mappedFeedbacks.sort((a, b) =>
          a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase())
        )
      }

      // Create a selfScoreObject object to add to the question object
      const selfScoreObject = {
        fullName: 'Yourself',
        value: selfScore,
        comment: selfComment,
        uid: 'self',
        isApplicable: true,
        marks: [...defaultMarks, { value: selfScore }]
      }

      // Create an updated question object with the new properties
      const updatedQuestion = {
        ...question,
        defaultMarks: defaultMarks,
        feedbacks: mappedFeedbacks,
        selfScore: selfScoreObject
      }

      return updatedQuestion
    })

    // return only the necessary properties
    return {
      reviewId: result.reviewId,
      evaluation: result.evaluation,
      questionIndex: result.questionIndex,
      questions: filteredQuestions,
      ...rest
    }
  }
}

class ReviewsModal {
  // constructor(reviews) {
  //   this.reviews = reviews
  // }

  #mapStatuses = (reviews) => {
    reviews.forEach((review) => {
      review.status = EVALUATION_STATES[review.status]
      review.review.status = REVIEW_STATES[review.review.status]
    })
    return reviews
  }

  #sortReviews = (reviews) => {
    // sort by status
    const sortOrder = {
      [REVIEW_STATES.AWAITING_SELECTION]: 1,
      [REVIEW_STATES.INCOMPLETE]: 2,
      [REVIEW_STATES.SUBMITTED]: 3,
      [REVIEW_STATES.AWAITING_RESULTS]: 4,
      [REVIEW_STATES.COMPLETED]: 5,
      [REVIEW_STATES.MISSED]: 6
    }

    reviews.sort((a, b) => {
      try {
        if (a.review.status === b.review.status) {
          let d1
          let d2

          if (a.expectedEndDate) {
            d1 = dayjs.unix(a.expectedEndDate._seconds)
            d2 = dayjs.unix(b.expectedEndDate._seconds)
          } else if (a.endDate) {
            d1 = dayjs.unix(a.endDate._seconds)
            d2 = dayjs.unix(b.endDate._seconds)
          }

          // Compare the dates
          if (d1.isBefore(d2)) {
            return 1
          } else if (d1.isAfter(d2)) {
            return -1
          } else {
            return 0
          }
        }

        return sortOrder[a.review.status] - sortOrder[b.review.status]
      } catch (error) {
        console.log(error)
        return 0
      }
    })

    return reviews
  }

  prepareForTable = (reviews) => {
    // 1. map statuses
    const statusesMappedReviews = this.#mapStatuses(reviews)

    // 2. sort by status, ongoing first then by date startDate
    const sortedReviews = this.#sortReviews(statusesMappedReviews)

    // 3. group by status (ongoing and others)
    const ongoingReviews = sortedReviews.filter(
      (review) => review.status === EVALUATION_STATES.ONGOING
    )
    const completedReviews = sortedReviews.filter(
      (review) => review.status !== EVALUATION_STATES.ONGOING
    )

    return {
      ongoingReviews,
      completedReviews
    }
  }
}

export const reviewModal = new ReviewModal()

export const reviewsModal = new ReviewsModal()
