// Components
import {
  Stack,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Box,
  Typography,
  Checkbox,
  Grid,
  IconButton,
  Divider,
  Switch,
  Chip,
  ListItemText,
  OutlinedInput,
  FormHelperText
} from '@mui/material'
import Center from '/src/components/center'
import LabelIcon from '@mui/icons-material/Label'
import ImageIcon from '@mui/icons-material/Image'
import ScatterGridChart from '/src/components/charts/scatter-grid'
import CustomDialog from '/src/components/custom-dialog'
import DebounceInput from '/src/components/debounced-input'
import { LightTooltip } from '/src/components/light-tooltip'

// Hooks
import { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'
import { useDialog } from '/src/hooks/use-dialog'

// Utils
import { localStorageService } from '/src/services/local-storage-service'
import { getRandomColor } from '/src/utils/get-random-color'
import html2canvas from 'html2canvas'

// Constants
import { USER_KEYS } from '/src/constants/user'
import GreyOutlinedButton from '/src/components/buttons/grey-outlined-button'
import { SCORES } from '/src/constants/evaluation-config'

// --- Constants just for this file ---

// Fields for color dots by field
const { DIVISION, LOCATION, SENIORITY, MANAGER, TEAM, JOB_LEVEL } = USER_KEYS

// Create a set of color by options for easy lookup
const allowedColoringOptions = new Set([
  DIVISION,
  LOCATION,
  SENIORITY,
  MANAGER,
  TEAM,
  JOB_LEVEL
])

// Score Type
const SCORE = 'SCORE'
const PERCENTILE = 'PERCENTILE'

// Score Option Name Transformer
const scoreOptionNameTransformer = {
  peerfulScore: 'Algorithm Score',
  selfScore: 'Self Score',
  managerScore: 'Manager Score',
  rawAverageScore: 'Raw Average Score'
}

const useDashboardGrid = ({ results, xConfig, yConfig }) => {
  // Iterate through the array and calculate the the users x and y position based on xConfig and yConfig
  // configs = { scoreOption: SCORES.PEERFUL_SCORE, scoreType: SCORE, selectedBehaviors: ["Grit", "Collab"...] }
  // Take average of the selected behaviors for the x and y axis for 2 situations: SCORE and PERCENTILE
  for (const obj of results) {
    let cumulativeXAxisValue = 0
    let cumulativeYAxisValue = 0
    let totalApplicableXAxisWeights = 0
    let totalApplicableYAxisWeights = 0

    for (const identifier of [PERCENTILE, SCORE]) {
      for (const [behaviorName, scoreObject] of Object.entries(
        obj[identifier.toLocaleLowerCase() + 's']
      )) {
        if (
          xConfig.selectedBehaviors.includes(behaviorName) &&
          xConfig.scoreType === identifier
        ) {
          if (scoreObject[xConfig.scoreOption]) {
            cumulativeXAxisValue += scoreObject[xConfig.scoreOption]
            totalApplicableXAxisWeights++
          }
        }
        if (
          yConfig.selectedBehaviors.includes(behaviorName) &&
          yConfig.scoreType === identifier
        ) {
          if (scoreObject[xConfig.scoreOption]) {
            cumulativeYAxisValue += scoreObject[yConfig.scoreOption]
            totalApplicableYAxisWeights++
          }
        }
      }
    }

    obj.xAxisValue = (
      cumulativeXAxisValue / totalApplicableXAxisWeights
    ).toFixed(0)

    obj.yAxisValue = (
      cumulativeYAxisValue / totalApplicableYAxisWeights
    ).toFixed(0)
  }

  // [
  //     {name: 'Alice', xAxisValue: 5, yAxisValue: 8},
  //     {name: 'Bob', xAxisValue: 7, yAxisValue: 5}, ...
  // ];
  // Create an employee data set with the format above for each employee in the results
  let employees = results.map((obj) => {
    const employee = {
      name: obj.fullName,
      uid: obj.uid,
      xAxisScoreName: scoreOptionNameTransformer[xConfig.scoreOption],
      xAxisScoreType: xConfig.scoreType === PERCENTILE ? 'Percentile' : '',
      yAxisScoreName: scoreOptionNameTransformer[yConfig.scoreOption],
      yAxisScoreType: yConfig.scoreType === PERCENTILE ? 'Percentile' : '',
      xAxis: obj.xAxisValue,
      yAxis: obj.yAxisValue
    }

    // Iterate over the colorByOptions set
    for (let option of allowedColoringOptions) {
      // Check if the obj object has the property
      if (obj[option]) {
        // Add the property to the employee object
        employee[option] = obj[option]
      }
    }

    return employee
  })

  return { employees }
}

// Texts for color by options for display
const coloringOptionsLabels = {
  [DIVISION]: 'Division',
  [LOCATION]: 'Location',
  [SENIORITY]: 'Seniority',
  [MANAGER]: 'Manager',
  [TEAM]: 'Team',
  [JOB_LEVEL]: 'Job Level'
}

const hexColors = [
  '#ff4a4a',
  '#F79009',
  '#10B981',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#06AED4',
  '#6C737F',
  '#CFC807'
]

const initialGridCustomizations = {
  selectedFieldWithColors: {
    field: '',
    colorsMap: new Map()
  },
  userPropsOption: 'Average Percentile',
  levelTagsOn: true,
  gridBackgroundLabels: [
    "Everyone's Friend",
    'Solid Contributor',
    'Cultural Ambassador and Star Performer',
    'Invisible Colleague',
    'Average Teammate',
    'Results Over Form',
    'Mismatch',
    'Inconsistent Performer',
    'Toxic High Performer'
  ],
  xAxisConfig: {
    scoreOption: SCORES.PEERFUL_SCORE,
    scoreType: SCORE
  },
  yAxisConfig: {
    scoreOption: SCORES.PEERFUL_SCORE,
    scoreType: PERCENTILE
  }
}

const DashboardGrid = () => {
  // Get required functionality and datas from userTable context to work properly with the grid
  const {
    filtered: results,
    drawerFilters: filters,
    handleClick
  } = useUsersTableContext()

  // --- Data preparation for the grid ---

  // Get the filtered behaviors from the filters
  const filteredBehaviors = useMemo(() => {
    if (filters.behaviors.values.length) {
      return filters.behaviors.values
    }
    return filters.behaviors.defaultValues
  }, [filters.behaviors])

  // Get the all avaliable scores from filtered results
  const uniqueScoreOptions = useMemo(() => {
    const uniqueScoreOptions = []
    for (const result of results) {
      for (const scoreBasedPercentile of Object.values(result.percentiles)) {
        for (const scoreName of Object.keys(scoreBasedPercentile)) {
          if (!uniqueScoreOptions.includes(scoreName)) {
            uniqueScoreOptions.push(scoreName)
          }
        }
      }
    }
    return uniqueScoreOptions
  }, [results])

  // Get reference to the chart for capturing the grid photo
  const chartRef = useRef(null)

  // Hold all the grid customization states
  const [gridCustomizationState, setGridCustomizationState] = useState({
    selectedFieldWithColors: initialGridCustomizations.selectedFieldWithColors, // Not dependent on local storage
    userPropsOption:
      localStorageService.getItem('grid-dashboard-userPropsOption') ||
      initialGridCustomizations.userPropsOption,
    levelTagsOn: localStorageService.getItem('grid-dashboard-showLevelTag'),
    gridBackgroundLabels:
      localStorageService.getItem('grid-dashboard-backgroundLabels') ||
      initialGridCustomizations.gridBackgroundLabels,
    xAxisConfig: {
      scoreOption:
        localStorageService.getItem('xAxis-scoreOption') ||
        initialGridCustomizations.xAxisConfig.scoreOption,
      scoreType:
        localStorageService.getItem('xAxis-scoreType') ||
        initialGridCustomizations.xAxisConfig.scoreType,
      selectedBehaviors:
        localStorageService
          .getItem('xAxis-selectedBehaviors')
          ?.filter((behavior) => filteredBehaviors.includes(behavior)) ||
        filteredBehaviors
    },
    yAxisConfig: {
      scoreOption:
        localStorageService.getItem('yAxis-scoreOption') ||
        initialGridCustomizations.yAxisConfig.scoreOption,
      scoreType:
        localStorageService.getItem('yAxis-scoreType') ||
        initialGridCustomizations.yAxisConfig.scoreType,
      selectedBehaviors:
        localStorageService
          .getItem('yAxis-selectedBehaviors')
          ?.filter((behavior) => filteredBehaviors.includes(behavior)) ||
        filteredBehaviors
    }
  })

  const xAxisLabelName = useMemo(
    () =>
      `${
        scoreOptionNameTransformer[
          gridCustomizationState.xAxisConfig.scoreOption
        ]
      } ${
        gridCustomizationState.xAxisConfig.scoreType === PERCENTILE
          ? 'Percentile'
          : ''
      }`,
    [
      gridCustomizationState.xAxisConfig.scoreType,
      gridCustomizationState.xAxisConfig.scoreOption
    ]
  )
  const yAxisLabelName = useMemo(
    () =>
      `${
        scoreOptionNameTransformer[
          gridCustomizationState.yAxisConfig.scoreOption
        ]
      } ${
        gridCustomizationState.yAxisConfig.scoreType === PERCENTILE
          ? 'Percentile'
          : ''
      }`,
    [
      gridCustomizationState.yAxisConfig.scoreType,
      gridCustomizationState.yAxisConfig.scoreOption
    ]
  )

  // Call hook to calculate the employees data for the grid
  const { employees } = useDashboardGrid({
    results,
    xConfig: gridCustomizationState.xAxisConfig,
    yConfig: gridCustomizationState.yAxisConfig
  })

  const handleAxisScoreOptionChange = useCallback((event) => {
    const axis = event.target.name.split('-')[0]
    setGridCustomizationState((prevState) => ({
      ...prevState,
      [`${axis}AxisConfig`]: {
        ...prevState[`${axis}AxisConfig`],
        scoreOption: event.target.value
      }
    }))
    localStorageService.setItem(`${axis}Axis-scoreOption`, event.target.value)
  }, [])

  const handleAxisScoreTypeChange = useCallback((event) => {
    const axis = event.target.name.split('-')[0]
    const newValue = event.target.checked ? PERCENTILE : SCORE
    setGridCustomizationState((prevState) => ({
      ...prevState,
      [`${axis}AxisConfig`]: {
        ...prevState[`${axis}AxisConfig`],
        scoreType: newValue
      }
    }))
    localStorageService.setItem(`${axis}Axis-scoreType`, newValue)
  }, [])

  const handleAxisBehaviorsChange = useCallback((event) => {
    const axis = event.target.name.split('-')[0]
    setGridCustomizationState((prevState) => ({
      ...prevState,
      [`${axis}AxisConfig`]: {
        ...prevState[`${axis}AxisConfig`],
        selectedBehaviors: event.target.value
      }
    }))
    localStorageService.setItem(
      `${axis}Axis-selectedBehaviors`,
      event.target.value
    )
  }, [])

  const handleCaptureGridPhoto = useCallback(() => {
    const chart = chartRef.current
    if (chart) {
      html2canvas(chart, {
        x: -50,
        y: -30,
        width: 800,
        height: 800
      }).then((canvas) => {
        const link = document.createElement('a')
        link.href = canvas.toDataURL('image/png')
        link.download = 'incompass-grid-image.png'
        link.click()
      })
    }
  }, [chartRef])

  const handleBackgroundLabelsChange = useCallback(
    (index) => (label) => {
      const newState = [...gridCustomizationState.gridBackgroundLabels]
      newState[index] = label
      setGridCustomizationState((prevState) => ({
        ...prevState,
        gridBackgroundLabels: newState
      }))

      localStorageService.setItem('grid-dashboard-backgroundLabels', newState)
    },
    [gridCustomizationState.gridBackgroundLabels]
  )

  const handleLevelTagCheckBoxChange = useCallback(
    (event) => {
      setGridCustomizationState((prevState) => ({
        ...prevState,
        levelTagsOn: event?.target?.checked
      }))

      localStorageService.setItem(
        'grid-dashboard-showLevelTag',
        event?.target?.checked
      )
    },
    [setGridCustomizationState]
  )

  const handleColorInputChange = useCallback(
    (event) => {
      setGridCustomizationState((prevState) => ({
        ...prevState,
        userPropsOption: event?.target?.value
      }))

      localStorageService.setItem(
        'grid-dashboard-userPropsOption',
        event?.target?.value
      )
    },
    [setGridCustomizationState]
  )

  // Get all available options for color by from the results and create a map of them and store key values in a set
  const validColorByOptionsMap = useMemo(() => {
    const map = new Map()

    for (const curr of results) {
      for (const key in curr) {
        // Check if the key is allowed to be colored by
        if (allowedColoringOptions.has(key)) {
          // Check if the value is not empty and add it to the set
          let value = curr[key]
          if (!map.has(key) && value) {
            map.set(key, new Set())
          }
          if (value) {
            map.get(key).add(value)
          }
        }
      }
    }

    return map
  }, [results])

  useEffect(() => {
    // Merge selectedBehaviors with the values from the filters
    const newXAxisSelectedBehaviors =
      gridCustomizationState.xAxisConfig.selectedBehaviors.filter((behavior) =>
        filteredBehaviors.includes(behavior)
      )
    const newYAxisSelectedBehaviors =
      gridCustomizationState.yAxisConfig.selectedBehaviors.filter((behavior) =>
        filteredBehaviors.includes(behavior)
      )
    setGridCustomizationState((prevState) => ({
      ...prevState,
      xAxisConfig: {
        ...prevState.xAxisConfig,
        selectedBehaviors: newXAxisSelectedBehaviors
      },
      yAxisConfig: {
        ...prevState.yAxisConfig,
        selectedBehaviors: newYAxisSelectedBehaviors
      }
    }))

    // Set the selected behaviors to the local storage
    localStorageService.setItem(
      'xAxis-selectedBehaviors',
      newXAxisSelectedBehaviors
    )
    localStorageService.setItem(
      'yAxis-selectedBehaviors',
      newYAxisSelectedBehaviors
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.behaviors])

  useEffect(() => {
    // Get the selected key
    const values = validColorByOptionsMap.get(
      gridCustomizationState.userPropsOption
    )
    // Generate colors for the selected color by option
    if (gridCustomizationState.userPropsOption && values) {
      const generatedColors = new Map()
      // Get or generate colors for the values
      let counter = 0
      values.forEach((value) => {
        generatedColors.set(value, hexColors[counter] || getRandomColor())
        counter++
      })

      // Set the generated colors and the selected color by option
      setGridCustomizationState((prevState) => ({
        ...prevState,
        selectedFieldWithColors: {
          field: gridCustomizationState.userPropsOption,
          colorsMap: generatedColors
        }
      }))
    } else {
      // If none is selected, clear the selected color by option and colors
      setGridCustomizationState((prevState) => ({
        ...prevState,
        selectedFieldWithColors:
          initialGridCustomizations.selectedFieldWithColors
      }))
    }
  }, [gridCustomizationState.userPropsOption, validColorByOptionsMap])

  const bgLabelsDialog = useDialog()

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          position: 'relative'
        }}
      >
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          spacing={{ xs: 4, lg: 0 }}
          sx={{
            flex: 1,
            justifyContent: 'space-evenly',
            alignItems: 'center',
            flexWrap: {
              xs: 'wrap',
              lg: 'nowrap'
            },
            pb: 3
          }}
        >
          <Stack>
            {/* Grid Chart */}
            <Stack
              ref={chartRef}
              sx={{
                pl: 4
              }}
            >
              <ScatterGridChart
                employees={employees}
                axisTags={[xAxisLabelName, yAxisLabelName]}
                axisBehaviors={[
                  gridCustomizationState.xAxisConfig.selectedBehaviors,
                  gridCustomizationState.yAxisConfig.selectedBehaviors
                ]}
                handleClick={handleClick}
                levelTags={
                  gridCustomizationState.levelTagsOn &&
                  filters.evaluations.defaultValues[
                    filters.evaluations.defaultValues.length - 1
                  ].evaluationConfig.dataConfig.grades
                }
                backgroundLabels={gridCustomizationState.gridBackgroundLabels}
                selectedFieldWithColors={
                  gridCustomizationState.selectedFieldWithColors
                }
                coloringOptionsLabels={coloringOptionsLabels}
              />
            </Stack>
          </Stack>
          {/* Grid customizations */}
          <Stack
            flexDirection="column"
            flexGrow={1}
            sx={{
              pt: {
                xs: 0,
                md: 5
              },
              alignItems: 'center',
              justifyContent: 'center',
              pr: { xs: 0, md: 2 }
            }}
          >
            <Stack spacing={2} width={'90%'}>
              <Box>
                <Divider
                  textAlign="center"
                  sx={{
                    color: 'text.secondary'
                  }}
                >
                  <Typography variant="body2">Chart Configurations</Typography>
                </Divider>

                {validColorByOptionsMap.size ? (
                  <Stack
                    alignItems="center"
                    spacing={2}
                    sx={{
                      mb: 2,
                      width: '100%',
                      px: 2,
                      pt: 2,
                      flexWrap: 'wrap'
                    }}
                    direction="row"
                  >
                    <FormControl sx={{ m: 1, width: '35%' }} size="small">
                      <InputLabel id="color-by-label">Color by</InputLabel>
                      <Select
                        labelId="color-by-label"
                        id="color-by"
                        value={
                          gridCustomizationState.selectedFieldWithColors
                            .field || initialGridCustomizations.userPropsOption
                        }
                        label="Color by"
                        onChange={handleColorInputChange}
                      >
                        <MenuItem
                          value={initialGridCustomizations.userPropsOption}
                        >
                          Average Percentile
                        </MenuItem>
                        {Array.from(validColorByOptionsMap.keys()).map(
                          (key) => (
                            <MenuItem key={key} value={key}>
                              {coloringOptionsLabels[key]}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>

                    <Stack
                      direction="row"
                      rowGap={1}
                      columnGap={1}
                      flexWrap="wrap"
                      sx={{
                        width: '60%'
                      }}
                    >
                      {/* Render the values of selected option */}
                      {validColorByOptionsMap.get(
                        gridCustomizationState.selectedFieldWithColors.field
                      )?.size ? (
                        Array.from(
                          validColorByOptionsMap.get(
                            gridCustomizationState.selectedFieldWithColors.field
                          )
                        )
                          .sort((a, b) =>
                            typeof a === 'string' ? a.localeCompare(b) : a - b
                          )
                          // If more than 10, show only first 10 and a ""...""
                          .slice(0, 10)
                          .concat(
                            validColorByOptionsMap.get(
                              gridCustomizationState.selectedFieldWithColors
                                .field
                            ).size > 10
                              ? ['...']
                              : []
                          )
                          .map((value) => (
                            <Center direction="row" spacing={1} key={value}>
                              <Box
                                component="span"
                                sx={{
                                  width: 20,
                                  height: 10,
                                  backgroundColor:
                                    gridCustomizationState.selectedFieldWithColors.colorsMap.get(
                                      value
                                    ),
                                  borderRadius: 1
                                }}
                              />

                              <Typography key={value} variant="caption">
                                {value}
                              </Typography>
                            </Center>
                          ))
                      ) : (
                        <Typography variant="caption">
                          Colored by average percentile. You can select a
                          different parameter for coloring.
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                ) : null}
                <Stack direction="row" alignItems="center" spacing={3} px={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-evenly"
                    maxWidth={300}
                    sx={{
                      border: '1px solid #e3e3e3',
                      borderRadius: 1,
                      px: 2,
                      flexGrow: 1
                    }}
                  >
                    <Switch
                      aria-label="level-tags-switch"
                      checked={!!gridCustomizationState.levelTagsOn}
                      onChange={handleLevelTagCheckBoxChange}
                    />
                    <Typography variant="subtitle2" py={1}>
                      Show Performance Labels
                    </Typography>
                  </Stack>
                  <CustomDialog
                    controller={bgLabelsDialog}
                    title="Set 9-Box Labels"
                    dividers
                    maxWidth="md"
                    openButton={
                      <GreyOutlinedButton
                        onClick={bgLabelsDialog.handleOpen}
                        startIcon={<LabelIcon />}
                      >
                        Set 9-Box Labels
                      </GreyOutlinedButton>
                    }
                  >
                    <Grid container spacing={2}>
                      {gridCustomizationState.gridBackgroundLabels.map(
                        (value, index) => (
                          <Grid item xs={4} key={index}>
                            <DebounceInput
                              fullWidth
                              variant="outlined"
                              value={value}
                              handleDebounce={handleBackgroundLabelsChange(
                                index
                              )}
                              debounceTimeout={300}
                              multiline
                              maxRows={3}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </CustomDialog>
                  <LightTooltip title="Download Grid Image">
                    <IconButton
                      data-gaid="download-grid-image"
                      onClick={handleCaptureGridPhoto}
                      color="primary"
                    >
                      <ImageIcon />
                    </IconButton>
                  </LightTooltip>
                </Stack>
              </Box>
              {['x', 'y'].map((axis) => {
                const noBehaviorsSelected =
                  !gridCustomizationState[`${axis}AxisConfig`].selectedBehaviors
                    .length
                return (
                  <Box key={axis}>
                    <Divider
                      textAlign="center"
                      sx={{
                        color: 'text.secondary',
                        p: 1
                      }}
                    >
                      <Typography variant="body2">
                        {axis.toLocaleUpperCase()} Axis Configurations
                      </Typography>
                    </Divider>
                    <Stack direction="row" rowGap={1} spacing={2} px={2}>
                      <Stack direction="column" columnGap={2} spacing={2}>
                        <Stack spacing={1} width="100%">
                          <InputLabel id={`${axis}-axis-score-type-selection`}>
                            Score Type
                          </InputLabel>
                          <Select
                            labelId={`${axis}-axis-score-option-selection-label`}
                            id={`${axis}-axis-score-option-selection-label`}
                            value={
                              gridCustomizationState[`${axis}AxisConfig`]
                                .scoreOption
                            }
                            name={`${axis}-axis-score-option-selectInput`}
                            onChange={handleAxisScoreOptionChange}
                          >
                            {uniqueScoreOptions.map((option) => (
                              <MenuItem key={option} value={option}>
                                {scoreOptionNameTransformer[option]}
                              </MenuItem>
                            ))}
                          </Select>
                        </Stack>
                        <Stack rowGap={1}>
                          <Box>
                            <InputLabel>Score Mode</InputLabel>
                          </Box>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-evenly"
                            sx={{
                              border: '1px solid #e3e3e3',
                              borderRadius: 1,
                              px: 2,
                              flexGrow: 1
                            }}
                          >
                            <Typography
                              variant="body2"
                              color={
                                gridCustomizationState[`${axis}AxisConfig`]
                                  .scoreType !== SCORE
                                  ? 'text.disabled'
                                  : 'success.main'
                              }
                            >
                              Score
                            </Typography>
                            <Switch
                              checked={
                                gridCustomizationState[`${axis}AxisConfig`]
                                  .scoreType === PERCENTILE
                              }
                              color="default"
                              onChange={handleAxisScoreTypeChange}
                              name={`${axis}-axis-toggle`}
                            />
                            <Typography
                              variant="body2"
                              color={
                                gridCustomizationState[`${axis}AxisConfig`]
                                  .scoreType !== PERCENTILE
                                  ? 'text.disabled'
                                  : 'success.main'
                              }
                            >
                              Percentile
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>

                      <Stack
                        sx={{
                          width: '100%',
                          height: '100%'
                        }}
                        flexGrow={1}
                        spacing={1}
                      >
                        <InputLabel id={`${axis}-axis-behaviors-selection`}>
                          Behaviors
                        </InputLabel>
                        <FormControl error={noBehaviorsSelected}>
                          <Select
                            labelId={`${axis}-axis-behaviors-selection-label`}
                            id={`${axis}-axis-behaviors-selection`}
                            multiple
                            value={
                              gridCustomizationState[`${axis}AxisConfig`]
                                .selectedBehaviors
                            }
                            name={`${axis}-axis-behaviors-selection-selectInput`}
                            label="Behaviors"
                            onChange={handleAxisBehaviorsChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => (
                              <Stack
                                flexWrap="wrap"
                                gap={0.5}
                                flexDirection="row"
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Stack>
                            )}
                            MenuProps={MenuProps}
                          >
                            {filteredBehaviors.map((option) => (
                              <MenuItem key={option} value={option}>
                                <Checkbox
                                  checked={
                                    gridCustomizationState[
                                      `${axis}AxisConfig`
                                    ].selectedBehaviors.indexOf(option) > -1
                                  }
                                />
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))}
                          </Select>
                          {noBehaviorsSelected ? (
                            <FormHelperText>
                              Please select behaviors for the axis.
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Stack>
                    </Stack>
                  </Box>
                )
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export default DashboardGrid
