import { useSettings } from '/src/hooks/use-settings'
import Center from '/src/components/center'
import { motion } from 'framer-motion'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const LoadingOverlay = ({ children, height = 50, sx = {}, ...otherProps }) => {
  const settings = useSettings()

  return (
    <Center
      data-testid="loading-overlay"
      sx={{
        position: 'relative',
        height: height + 'vh',
        ...sx
      }}
      {...otherProps}
    >
      <Box
        sx={{
          borderRadius: 2,
          position: 'absolute',
          zIndex: 0,
          width: '100%',
          height: '100%'
        }}
      >
        <motion.div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: 'inherit'
          }}
          // create pulse animation effect on background color
          animate={{
            backgroundColor: [
              settings?.paletteMode === 'dark' ? '#111927' : '#F8F9FA',
              settings?.paletteMode === 'dark' ? '#1C2536' : '#F3F4F6'
            ]
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: 'reverse'
          }}
        />
      </Box>
      {children}
    </Center>
  )
}

LoadingOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  sx: PropTypes.object
}

export default LoadingOverlay
