import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { LOGO_MODES, Logo } from '/src/components/logo'
import { RouterLink } from '/src/components/router-link'
import { Scrollbar } from '/src/components/scrollbar'
import { usePathname } from '/src/hooks/use-pathname'
import { paths } from '/src/constants/paths'
import { TenantSwitch } from '/src/layouts/dashboard/tenant-switch'
import { MobileNavSection } from './mobile-nav-section'
import { useSettings } from '/src/hooks/use-settings'

const MOBILE_NAV_WIDTH = 240

const useCssVars = () => {
  const theme = useTheme()

  return useMemo(() => {
    if (theme.palette.mode === 'dark') {
      return {
        '--nav-bg': theme.palette.background.default,
        '--nav-color': theme.palette.neutral[100],
        '--nav-logo-border': theme.palette.neutral[700],
        '--nav-section-title-color': theme.palette.neutral[400],
        '--nav-item-color': theme.palette.neutral[400],
        '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
        '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
        '--nav-item-active-color': theme.palette.text.primary,
        '--nav-item-disabled-color': theme.palette.neutral[600],
        '--nav-item-icon-color': theme.palette.neutral[500],
        '--nav-item-icon-active-color': theme.palette.primary.main,
        '--nav-item-icon-disabled-color': theme.palette.neutral[700],
        '--nav-item-chevron-color': theme.palette.neutral[700],
        '--nav-scrollbar-color': theme.palette.neutral[400]
      }
    } else {
      return {
        '--nav-bg': theme.palette.customColors[900],
        '--nav-color': theme.palette.customColors.textColor,
        '--nav-logo-border': theme.palette.customColors[200],
        '--nav-section-title-color': theme.palette.customColors.textColor,
        '--nav-item-color': theme.palette.customColors.textColor,
        '--nav-item-hover-bg': theme.palette.customColors.hover,
        '--nav-item-active-bg': theme.palette.action.selected,
        '--nav-item-active-color': theme.palette.text.primary[900],
        '--nav-item-disabled-color': theme.palette.customColors[400],
        '--nav-item-icon-color': theme.palette.customColors.textColor,
        '--nav-item-icon-active-color': theme.palette.textColor,
        '--nav-item-icon-disabled-color': theme.palette.customColors[400],
        '--nav-item-chevron-color': theme.palette.customColors.textColor,
        '--nav-scrollbar-color': theme.palette.customColors[900]
      }
    }
  }, [theme])
}

export const MobileNav = (props) => {
  const { open, onClose, sections = [] } = props
  const pathname = usePathname()
  const theme = useTheme()
  const cssVars = useCssVars()
  const settingsContext = useSettings()

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          color: 'var(--nav-color)',
          width: MOBILE_NAV_WIDTH
        }
      }}
      variant="temporary"
    >
      <Scrollbar
        sx={{
          'height': '100%',
          '& .simplebar-content': {
            height: '100%'
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)'
          }
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <Stack alignItems="center" py={2}>
            <Stack
              sx={{
                columnGap: 1,
                width: '80%',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                direction: 'row',
                flexDirection: 'row',
                p: 2,
                my: 1,
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.neutral[400]
                    : theme.palette.background.default,
                borderRadius: 1,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center',
                boxShadow: theme.shadows[24]
              }}
            >
              <Stack
                component={RouterLink}
                href={paths.index}
                sx={{
                  height: 40,
                  width: 40
                }}
              >
                <Logo
                  mode={
                    theme.palette.mode === 'dark'
                      ? LOGO_MODES.BLACK
                      : LOGO_MODES.DEFAULT
                  }
                  companyLogo={
                    settingsContext.customizationConfig.organizationLogo
                  }
                />
              </Stack>
              <TenantSwitch
                name={
                  settingsContext.customizationConfig.organizationLogo &&
                  settingsContext.customizationConfig.organizationName
                }
              />
            </Stack>
          </Stack>
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2
            }}
          >
            {sections.map((section, index) => (
              <MobileNavSection
                items={section?.items || undefined}
                key={index}
                pathname={pathname}
                subheader={section?.subheader || undefined}
              />
            ))}
          </Stack>
          {settingsContext.customizationConfig.organizationLogo && (
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              columnGap={2}
              marginBottom={3}
              spacing={2}
            >
              <Stack flexDirection="row" alignItems="center" columnGap={1}>
                <Logo
                  width={30}
                  mode={
                    cssVars['--nav-color'] === '#ffffff'
                      ? LOGO_MODES.WHITE
                      : LOGO_MODES.BLACK
                  }
                />
                <Typography variant="subtitle1">Incompass</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Scrollbar>
    </Drawer>
  )
}

MobileNav.propTypes = {
  color: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  sections: PropTypes.array
}
