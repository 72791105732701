import _refiner from 'refiner-js'

export const identifyUserForSurvey = ({
  id,
  company,
  userRoles,
  ...rest
}: {
  id: string
  company: string
  userRoles: string
  [key: string]: any
}) =>
  _refiner('identifyUser', {
    environment: import.meta.env.VITE_NODE_ENV,
    id,
    company,
    userRoles,
    ...rest
  })

export const showSurvey = (surveyAlias: string) =>
  _refiner('showForm', surveyAlias)
