import { Paper, SvgIcon } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import PrimaryButton from '/src/components/buttons/primary-button'

export const FloatSaveButton = ({ paperProps, ...otherProps }) => {
  const { sx, ...otherPaperProps } = paperProps || {}

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 110,
        zIndex: 1000,
        ...sx
      }}
      elevation={24}
      {...otherPaperProps}
    >
      <PrimaryButton
        data-gaid="float-save-evaluation-config-button"
        sx={{
          p: 2
        }}
        startIcon={
          <SvgIcon>
            <SaveIcon />
          </SvgIcon>
        }
        {...otherProps}
      >
        Save
      </PrimaryButton>
    </Paper>
  )
}
