import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import Center from './center'
import { NOT_APPLICABLE } from '../constants/common'

function DateBox({ dates }) {
  const { monthDate, dayDate, yearDate } = dates
  return (
    <Box>
      <Center
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100',
          borderRadius: 2,
          width: 75,
          pt: 1,
          pb: 1
        }}
      >
        <Typography
          align="center"
          variant="subtitle2"
          fontSize={14}
          fontWeight={'bold'}
        >
          {monthDate ? monthDate : NOT_APPLICABLE}{' '}
          {dayDate ? dayDate : NOT_APPLICABLE}
        </Typography>
        <Typography align="center" variant="subtitle2" fontSize={12}>
          {yearDate ? yearDate : NOT_APPLICABLE}
        </Typography>
      </Center>
    </Box>
  )
}

DateBox.propTypes = {
  dates: PropTypes.shape({
    monthDate: PropTypes.string.isRequired,
    dayDate: PropTypes.string.isRequired,
    yearDate: PropTypes.string.isRequired
  }).isRequired
}

export default DateBox
