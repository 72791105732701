import PropTypes from 'prop-types'

export const LOGO_MODES = {
  BLACK: 'black',
  WHITE: 'white',
  SINGLE_COLOR: 'single-color',
  DEFAULT: 'default'
}

export const Logo = ({
  hide,
  mode = LOGO_MODES.DEFAULT,
  width,
  companyLogo
}) => {
  if (!hide) {
    if (companyLogo) {
      return <img src={companyLogo} width={width} />
    } else {
      return <img src={`/assets/logo-${mode}.png`} width={width} />
    }
  }

  return null
}

Logo.propTypes = {
  hide: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(LOGO_MODES)),
  children: PropTypes.bool,
  width: PropTypes.number,
  companyLogo: PropTypes.string
}
