import { Stack } from '@mui/material'
import React from 'react'
import SummaryComment from './summary-comment'

const SummaryComments = ({ feedbacks }) => {
  return (
    <Stack justifyContent="space-around" alignItems="center" ml={2}>
      {feedbacks.map((person, i) => {
        return <SummaryComment comment={person.comment || ''} key={i} />
      })}
    </Stack>
  )
}

export default SummaryComments
