import { Stack, Typography } from '@mui/material'
import { Logo } from './logo'

export const MobileWarning = () => {
  return (
    <Stack
      sx={{
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        p: 4,
        textAlign: 'center'
      }}
      spacing={8}
    >
      <Logo width={100} />
      <Stack spacing={1}>
        <Typography variant="h4" component="h1" gutterBottom>
          For the best experience, please use a larger screen.
        </Typography>
      </Stack>
    </Stack>
  )
}
