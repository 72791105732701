// Components
import { Box, Button, Stack, Typography, alpha } from '@mui/material'
import { Scrollbar } from '/src/components/scrollbar'
import { ResultCards } from '/src/components/result-card/result-card'
import AdminAndManagerEmployeeResultsPdfButton from '/src/components/pdf-previewer/components/admin-and-manager-employee-results-pdf-button'
import { resultsModal } from '/src/data/modals/workforce-results-modal'
import ResultNotesModal from '/src/sections/dashboard/reviews/results/result-notes-modal'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

// Hooks
import React, { useEffect, useRef, useState } from 'react'
import useEmployeeResultsPdf from '/src/components/pdf-previewer/hooks/use-employee-results-pdf'

// Utils
import consoleLog from '/src/utils/console-log'

// Constants
import { ROLES } from '/functions/shared/constants/roles'
import { CONFIG_FIELDS } from '/src/constants/evaluation-config'

const UserAnalysisReport = ({ modalData, onClose, user, userRole }) => {
  // Modal ref to get width and height for inner modal
  const modalRef = useRef()
  const [modalSize, setModalSize] = useState(null)

  // Converting the modalData (result table) format to the original result format
  const parsedResult = resultsModal.parseOriginalResultFromModalData(
    modalData,
    user
  )
  consoleLog('modalData in UserAnalysisReportParsedResult', parsedResult)

  const {
    generateEmployeeResultsPDF,
    isLoading: pdfLoading,
    dataCardsRef
  } = useEmployeeResultsPdf({
    result: parsedResult,
    isEmployee: false,
    fullName: parsedResult.fullName
  })

  useEffect(() => {
    if (modalRef.current) {
      setModalSize({
        width: modalRef.current.contentWrapperEl.offsetWidth,
        height: modalRef.current.contentWrapperEl.offsetHeight
      })
    }
  }, [modalRef])

  return (
    <>
      <Box
        onClick={onClose}
        sx={{
          'position': 'absolute',
          'top': 0,
          'left': 0,
          'width': '100%',
          'height': '100%',
          'backdropFilter': 'blur(4px)',
          'zIndex': -1,
          '&:focus': {
            outline: 'none'
          }
        }}
      />
      <div
        style={{
          position: 'relative'
        }}
      >
        <Scrollbar
          ref={modalRef}
          component={Stack}
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '90vh',
            mx: { lg: 10 },
            bgcolor: 'background.paper',
            borderRadius: 2,
            pb: 5,
            position: 'relative'
          }}
        >
          <Stack
            sx={{
              p: 5,
              flexDirection: 'row',
              justifyContent: 'space-between',
              backdropFilter: 'blur(4px)',
              backgroundColor: (theme) =>
                alpha(theme.palette.background.default, 0.6),
              position: 'sticky',
              top: 0,
              width: '100%',
              zIndex: 10
            }}
          >
            <Button
              variant={'text'}
              onClick={onClose}
              startIcon={<ArrowBackIcon />}
              sx={{
                minWidth: 'max-content'
              }}
            >
              Back
            </Button>
            <Stack
              sx={{
                flexGrow: 1,
                justifyContent: 'center'
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  // remove blur effect from the text
                  zIndex: 2,
                  pl: {
                    lg: 20,
                    md: 0
                  },
                  opacity: 1,
                  filter: 'blur(0px)',
                  textAlign: 'center'
                }}
              >
                {parsedResult.fullName}
              </Typography>
            </Stack>
            {parsedResult.resultsByQuestion.length ? (
              <Stack direction="row" alignItems="center" spacing={2}>
                <ResultNotesModal
                  modalSize={modalSize}
                  data={parsedResult}
                  userRole={userRole}
                />
                <AdminAndManagerEmployeeResultsPdfButton
                  {...{
                    generateEmployeeResultsPDF,
                    disabled: pdfLoading,
                    downloadWithComments:
                      (userRole === ROLES.MANAGER &&
                        modalData.evaluationConfig.reportConfig.find(
                          (config) =>
                            config.name ===
                            CONFIG_FIELDS.ALLOW_MANAGER_TO_DOWNLOAD_PDF_WITH_COMMENTS
                        )?.display) ||
                      userRole === ROLES.ADMIN
                  }}
                />
              </Stack>
            ) : null}
          </Stack>
          <Stack
            spacing={1}
            sx={{
              px: {
                lg: 5,
                md: 1
              },
              filter: 'none',
              WebkitFilter: 'none',
              pointerEvents: 'auto'
            }}
          >
            {parsedResult.resultsByQuestion.length ? (
              <ResultCards results={parsedResult} dataCardsRef={dataCardsRef} />
            ) : (
              <Typography
                variant="body1"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
                width="100%"
                textAlign="center"
              >
                {' This employee didn’t receive any reviews.'}
              </Typography>
            )}
          </Stack>
        </Scrollbar>
      </div>
    </>
  )
}

export default UserAnalysisReport
