import { Stack, SvgIcon, Typography } from '@mui/material'
import React from 'react'
import SportsScoreIcon from '@mui/icons-material/SportsScore'

export const ScoreGrade = ({ grade }) => {
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignContent="center" spacing={1}>
        <Typography variant="h4">{grade}</Typography>
        <SvgIcon color="primary" fontSize="large">
          <SportsScoreIcon />
        </SvgIcon>
      </Stack>
      <Typography variant="caption" color="text.secondary">
        Your Grade
      </Typography>
    </Stack>
  )
}
