import PropTypes from 'prop-types'
import { Divider, Stack } from '@mui/material'
import { MobileNavItem } from './mobile-nav-item'

const renderItems = ({ depth = 0, items, pathname }) =>
  items.reduce(
    (acc, item) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname
      }),
    []
  )

const reduceChildRoutes = ({ acc, depth, item, pathname }) => {
  const checkPath = !!(item.path && pathname)
  const partialMatch = checkPath ? pathname.includes(item.path) : false
  const exactMatch = checkPath ? pathname === item.path : false

  if (item.items) {
    acc.push(
      <MobileNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        open={partialMatch}
        title={item.title}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: 'none',
            m: 0,
            p: 0
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname
          })}
        </Stack>
      </MobileNavItem>
    )
  } else {
    acc.push(
      <MobileNavItem
        active={exactMatch}
        depth={depth}
        disabled={item.disabled}
        external={item.external}
        icon={item.icon}
        key={item.title}
        label={item.label}
        path={item.path}
        title={item.title}
      />
    )
  }

  return acc
}

export const MobileNavSection = (props) => {
  const { items = [], pathname, subheader = '', ...other } = props

  return (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: 'none',
        m: 0,
        p: 0
      }}
      {...other}
    >
      {subheader && (
        <Divider
          flexItem
          textAlign="left"
          sx={{
            'fontSize': 14,
            'fontWeight': 600,
            'py': 1,
            ':before, :after': {
              content: '""',
              opacity: 0.5,
              borderColor: 'var(--nav-section-title-color)'
            }
          }}
        >
          {subheader}
        </Divider>
      )}
      {renderItems({ items, pathname })}
    </Stack>
  )
}

MobileNavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  subheader: PropTypes.string
}
