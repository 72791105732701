import { object, string } from 'yup'

const companyCustomizationConfigSchema = object()
  .shape({
    name: string().required('Name field is required.'),
    colorCode: string().required('Color code field is required.'),
    font: string().required('Font field is required.'),
    logo: string().nullable().defined('Logo field is required.')
  })
  .noUnknown()

export const updateOrganization = companyCustomizationConfigSchema

export const getOrganization = companyCustomizationConfigSchema
