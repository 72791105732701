import React from 'react'
import styled from '@emotion/styled'
import { SvgIcon, Tooltip, tooltipClasses } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import TextButton from '/src/components/buttons/text-button'
import ReactMarkdown from 'react-markdown'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    maxWidth: 400
  }
}))

const SummaryComment = ({ comment }) => {
  return (
    <LightTooltip
      title={
        comment.length > 0 ? <ReactMarkdown>{comment}</ReactMarkdown> : null
      }
      placement="top"
      sx={{
        cursor: comment.length > 0 ? 'pointer' : 'default'
      }}
    >
      <span>
        <TextButton
          startIcon={
            comment.length ? (
              <SvgIcon>
                <VisibilityIcon />
              </SvgIcon>
            ) : null
          }
          disabled={comment.length === 0}
        >
          {comment.length > 0 ? 'Commented' : 'No Comment'}
        </TextButton>
      </span>
    </LightTooltip>
  )
}

export default SummaryComment
