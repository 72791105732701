import React from 'react'
import { Button, ButtonProps } from '@mui/material'

const TextButton: React.FC<ButtonProps> = ({ children, ...otherProps }) => (
  <Button variant="text" {...otherProps}>
    {children}
  </Button>
)

export default TextButton
