import PropTypes from 'prop-types'
import { withAuthGuard } from '/src/hocs/with-auth-guard'
import { useSettings } from '/src/hooks/use-settings'
import { useSections } from './config'
import { VerticalLayout } from './vertical-layout'
import { HelpDrawer } from '/src/components/faq/faq-drawer'
import useAppConfig from '/src/hooks/use-app-config'
import UnderMaintenance from '/src/components/under-maintenance'
import { SplashScreen } from '/src/components/splash-screen'

export const Layout = withAuthGuard((props) => {
  const settings = useSettings()
  const sections = useSections()
  const { isLoading, isMaintenance } = useAppConfig()

  return (
    <>
      {isLoading ? (
        <SplashScreen />
      ) : !isLoading && isMaintenance ? (
        <UnderMaintenance />
      ) : (
        <>
          <VerticalLayout sections={sections} {...props} />
          <HelpDrawer
            handleClose={settings.handleSettingsPreviewUpdate}
            open={settings.faqDrawerOpen}
          />
        </>
      )}
    </>
  )
})

Layout.propTypes = {
  children: PropTypes.node
}
