// MUI Components
import {
  Card,
  CardContent,
  List,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'

// Others
import PropTypes from 'prop-types'

// Icons
import ArrowRightIcon from '@untitled-ui/icons-react/build/cjs/ArrowRight'
import BehaviorItems from './behaviors/behavior-items'
import BehaviorForm from './behaviors/behavior-form'
import SecondaryButton from '/src/components/buttons/secondary-button'
import PrimaryButton from '/src/components/buttons/primary-button'

export const EvaluationBehaviorsStep = (props) => {
  const {
    onBack,
    onNext,
    formik,
    behaviors: prevBehaviors,
    evaluationStatus,
    ...other
  } = props

  const handleAddBehavior = (newBehavior) => {
    formik.setFieldValue('behaviors', [...formik.values.behaviors, newBehavior])
  }

  const handleUpdateBehavior = (updatedBehavior, index) => {
    formik.setFieldValue('behaviors', [
      ...formik.values.behaviors.map((behavior, i) =>
        i === index ? updatedBehavior : behavior
      )
    ])
  }

  const handleDeleteBehavior = (index) => {
    formik.setFieldValue(
      'behaviors',
      formik.values.behaviors.filter((_, i) => i !== index)
    )
  }

  return (
    <Stack spacing={3} {...other}>
      <Stack spacing={1}>
        <Typography variant="h6">
          What would you like to review users on?
        </Typography>
      </Stack>

      <List component="ul">
        <BehaviorItems
          behaviors={formik.values.behaviors}
          evaluationStatus={evaluationStatus}
          handleAddBehavior={handleAddBehavior}
          handleUpdateBehavior={handleUpdateBehavior}
          handleDeleteBehavior={handleDeleteBehavior}
        />
      </List>
      {!(evaluationStatus && evaluationStatus !== 'TEMPLATE') && (
        <Card>
          <CardContent>
            <BehaviorForm
              method="CREATE"
              handleAddBehavior={handleAddBehavior}
              handleUpdateBehavior={handleUpdateBehavior}
              handleDeleteBehavior={handleDeleteBehavior}
            />
          </CardContent>
        </Card>
      )}

      <Stack direction="row" spacing={1} mt={3}>
        <SecondaryButton onClick={onBack}>Back</SecondaryButton>
        <PrimaryButton
          endIcon={
            <SvgIcon>
              <ArrowRightIcon />
            </SvgIcon>
          }
          onClick={onNext}
        >
          Continue
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}

EvaluationBehaviorsStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  formik: PropTypes.object
}
