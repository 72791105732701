import makeRequest from './index'

const organizationsProvider = {
  updateOrganization: async (data) => {
    const result = await makeRequest({ data, endpoint: 'updateOrganization' })

    return result.data
  },
  getOrganization: async () => {
    const result = await makeRequest({ endpoint: 'getOrganization' })

    return result.data
  }
}

export default organizationsProvider
