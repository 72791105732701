// Create a singleton class to handle local storage operations
import { localStorageItemsToClearOnAuthChange } from '/src/constants/local-storage'

class LocalStorageService {
  private static instance: LocalStorageService

  private constructor() {}

  public static getInstance(): LocalStorageService {
    if (!LocalStorageService.instance) {
      LocalStorageService.instance = new LocalStorageService()
    }
    return LocalStorageService.instance
  }

  private getParsedValue<T>(value: string): T | string {
    try {
      return JSON.parse(value)
    } catch (error) {
      return value
    }
  }

  // Get local storage data
  public getItem<T>(key: string): T | string | null {
    const data = localStorage.getItem(key)
    return data ? this.getParsedValue(data) : null
  }

  // Create local storage data
  public setItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value))
  }

  // Reset local storage
  public reset(): void {
    localStorage.clear()
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key)
  }

  // Handle auth state change
  public handleAuthStateChange(email: string): void {
    const storedEmail = this.getItem<string>('email')

    // There is no email (account) stored in local storage
    if (!storedEmail) {
      this.setItem('email', email)
    } else if (storedEmail !== email) {
      // Email in local storage is different from the current email
      localStorageItemsToClearOnAuthChange.forEach((key) => {
        this.removeItem(key)
      })

      // Update the email in local storage
      this.setItem('email', email)
    }
  }
}

// Export the singleton instance
export const localStorageService = LocalStorageService.getInstance()
