import { useCallback, useEffect, useState } from 'react'
import consoleLog from '/src/utils/console-log'
import { useUser } from '/src/hooks/use-user'

export const checkAccessControl = ({ user, accessControl }) => {
  if (!user) {
    return false
  }
  // Check if the user has access to the settings
  return accessControl.some((settings) => {
    // Check if the user userRoles includes any of the roles
    const isRolesAllowed =
      !settings.roles || // Default to true if not provided
      user.userRoles.some((role) => settings.roles.includes(role))

    // Check if the user accountStatus is allowed
    const isStatusAllowed =
      !settings.statuses || settings.statuses.includes(user.status)

    // Check if the user permissions are allowed
    const isPermissionsAllowed =
      !settings.permissions ||
      (user.permissions &&
        Object.keys(user.permissions).some((permission) =>
          settings.permissions.includes(permission)
        ))

    // Check if the app permissions are allowed
    const isAppPermissionsAllowed =
      !settings.requiredAppPermission ||
      user?.permissions[settings.requiredAppPermission]

    return (
      isRolesAllowed &&
      isStatusAllowed &&
      isPermissionsAllowed &&
      isAppPermissionsAllowed
    )
  })
}

export function useAccessControl(accessControl) {
  const { user } = useUser()

  const checkAccess = useCallback(
    () => checkAccessControl({ user, accessControl }),
    [user, accessControl]
  )

  const [isFeatureAllowed, setIsFeatureAllowed] = useState(checkAccess())

  useEffect(() => {
    setIsFeatureAllowed(checkAccess())
  }, [checkAccess])

  consoleLog('state in useAccessControl ', isFeatureAllowed)

  return isFeatureAllowed
}
