import React from 'react'
import { Button, ButtonProps } from '@mui/material'

const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  ...otherProps
}) => (
  <Button variant="outlined" {...otherProps}>
    {children}
  </Button>
)

export default SecondaryButton
