export const getObjectValuesAsString = (obj) => {
  // get all values of n and return as string
  const values = Object.values(obj)

  // pass the values to the join method and ignore the values that are not strings
  const filteredValues = values.filter(
    (value) => typeof value === 'string' || typeof value === 'number'
  )

  // join the values and return the string
  const string = filteredValues.join('_')

  return string
}
