import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { useUser } from '/src/hooks/use-user'
import LoadingOverlay from '/src/components/loading-overlay'
import { AnimatedLogo } from '/src/components/animated-logo'
import requestsRepo from '/src/data/repos/requests-repo'
import { toast } from 'react-toastify'
import { useState, Fragment } from 'react'
import { useCallback } from 'react'
import { SeverityPill } from '/src/components/severity-pill'
import { removePrefixes } from '/src/utils/format-errors'
import { LoadingError } from '/src/components/loading-error'
import { Scrollbar } from '/src/components/scrollbar'
import { PARSED_REQUEST_STATUSES } from '/src/constants/employee-request-statuses'
import Proptypes from 'prop-types'
import TextButton from '/src/components/buttons/text-button'

/**
 * Renders a card component that displays a list of requests in a card format.
 * Handles loading and error states, as well as approving or declining requests based on the user's role.
 *
 * @param {Object} props - The input props.
 * @param {boolean} props.isLoading - Indicates whether the requests are currently being loaded.
 * @param {Object} props.error - An error object containing information about any errors that occurred while loading the requests.
 * @param {Function} props.refetch - A function to refetch the requests data.
 * @param {Array} props.requests - An array of request objects to be displayed.
 * @param {string} props.userRole - The role of the user (either "Admin" or "Manager").
 * @returns {JSX.Element} - Rendered JSX representing the requests inbox card component.
 */
export const RequestsInbox = ({
  isLoading,
  error,
  refetch,
  requests,
  userRole
}) => {
  const { stringAvatar } = useUser()

  // to manage loading state of approve/reject buttons
  const [disabled, setDisabled] = useState(false)

  const handler = useCallback(
    ({ requestId, isApproved }) =>
      requestsRepo.handleEmployeeRequest({
        userRole,
        requestId,
        isApproved
      }),
    [userRole]
  )

  const handleClick = useCallback(
    ({ requestId, isApproved }) =>
      async () => {
        const response = await toast.promise(
          handler({
            requestId,
            isApproved
          }),
          {
            pending: {
              render() {
                setDisabled(true)
                return 'Loading...'
              }
            },
            success: {
              render() {
                setDisabled(false)
                return `You have successfully ${
                  isApproved ? 'approved' : 'declined'
                } the request`
              }
            },
            error: {
              render({ data: err }) {
                setDisabled(false)
                return removePrefixes(err.message) || 'Something went wrong!'
              }
            }
          }
        )

        if (response) {
          refetch()
        }

        return response
      },
    [refetch, handler]
  )

  return (
    <Card>
      <CardHeader title="Requests" sx={{ ml: 2, mb: 1 }} />
      <Divider />
      <Box>
        <List
          component={Scrollbar}
          disablePadding
          sx={{ maxHeight: 400, overflow: 'auto' }}
        >
          {isLoading ? (
            <LoadingOverlay mx={3} my={1} height={40}>
              <AnimatedLogo width={60} />
            </LoadingOverlay>
          ) : error ? (
            <Box p={3}>
              <LoadingError
                message={error.message || 'Something went wrong!'}
              />
            </Box>
          ) : requests?.length > 0 ? (
            createListItem({
              requests,
              helpers: { stringAvatar, handleClick, disabled }
            })
          ) : (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ p: 2, m: 2, textAlign: 'center' }}
            >
              No requests found
            </Typography>
          )}
        </List>
      </Box>
      <Divider />
      {!isLoading && !error && requests?.length > 0 ? (
        <CardActions sx={{ justifyContent: 'flex-end', padding: 0 }}>
          <Typography variant="body2" sx={{ p: 2, m: 2, textAlign: 'center' }}>
            {requests.length} Total
          </Typography>
        </CardActions>
      ) : null}
    </Card>
  )
}

RequestsInbox.propTypes = {
  isLoading: Proptypes.bool,
  error: Proptypes.object,
  refetch: Proptypes.func,
  requests: Proptypes.array,
  userRole: Proptypes.string
}

const createListItem = ({ requests, helpers }) =>
  requests.map((request, i) => {
    return (
      <Fragment key={i}>
        <ListItem
          data-testid={request.uid}
          sx={{
            '&:hover': {
              backgroundColor: 'action.hover',
              cursor: 'pointer'
            }
          }}
        >
          <ListItemAvatar>
            <Avatar {...helpers.stringAvatar(request.fullName, 40, 40)} />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
                variant="subtitle2"
              >
                {request.fullName}
              </Typography>
            }
            secondary={
              <Typography
                color="text.secondary"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
                variant="body2"
              >
                {request.isApproved === null
                  ? `This employee requested to lower the minimum selection number from ${request.selectMin} to ${request.requestMin}.`
                  : "This employee's request has been handled."}
              </Typography>
            }
            sx={{ pr: 2 }}
          />
          <Stack direction="row" pr={1} spacing={1}>
            {request.status === PARSED_REQUEST_STATUSES.AWAITING_RESPONSE ? (
              <>
                <TextButton
                  data-gaid="decline-request"
                  data-testid={`decline-${request.uid}`}
                  children="Decline"
                  startIcon={
                    <SvgIcon>
                      <CancelOutlinedIcon color="error" />
                    </SvgIcon>
                  }
                  onClick={helpers.handleClick({
                    requestId: request.uid,
                    isApproved: false
                  })}
                  color="error"
                  size="small"
                  disabled={helpers.disabled}
                />
                <TextButton
                  data-gaid="approve-request"
                  data-testid={`approve-${request.uid}`}
                  children="Approve"
                  startIcon={
                    <SvgIcon color="success">
                      <CheckCircleOutlinedIcon />
                    </SvgIcon>
                  }
                  onClick={helpers.handleClick({
                    requestId: request.uid,
                    isApproved: true
                  })}
                  color="success"
                  size="small"
                  disabled={helpers.disabled}
                />
              </>
            ) : null}
            {request.status === PARSED_REQUEST_STATUSES.NOT_HANDLED ? (
              <SeverityPill color="warning">Not Handled</SeverityPill>
            ) : null}
            {request.status === PARSED_REQUEST_STATUSES.APPROVED ? (
              <SeverityPill color="success">Approved</SeverityPill>
            ) : null}
            {request.status === PARSED_REQUEST_STATUSES.REJECTED ? (
              <SeverityPill color="error">Rejected</SeverityPill>
            ) : null}
          </Stack>
        </ListItem>
        <Divider sx={{ mx: 2 }} />
      </Fragment>
    )
  })
