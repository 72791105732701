import React from 'react'
import { Unstable_Grid2 as Grid, Stack } from '@mui/material'
import { CompletionPercentages } from '/src/sections/dashboard/admin/reviews/stats/completion-percentages'
import { tableTypes } from '/src/components/users-table/constants/table-types'
import { UsersTable } from '/src/components/users-table/users-table'
import PropTypes from 'prop-types'
import { renderReviewStatus } from '/src/utils/render-review-status'
import { USER_KEYS } from '/src/constants/user'
import { COMPLETION_STATES } from '/src/constants/statuses'
const { FULL_NAME, TEAM, MANAGER } = USER_KEYS

const columns = [
  {
    key: FULL_NAME,
    label: 'Name',
    minWidth: 200,
    sort: true
  },
  {
    key: 'reviewCompletionStatus',
    label: 'Status',
    parser: ({ row, value, column }) => renderReviewStatus(value),
    minWidth: 200,
    sort: true,
    filter: true
  },
  {
    key: MANAGER,
    label: 'Manager',
    minWidth: 150,
    filter: true,
    sort: true
  },
  {
    key: TEAM,
    label: 'Team',
    minWidth: 150,
    filter: true,
    sort: true
  }
]

const StatTables = ({ stats, ...otherProps }) => {
  const completionPercentages =
    stats.usersCompletedResponses &&
    stats.usersPartiallyCompletedResponses &&
    stats.usersNotStartedResponses

  // Check if there are at least 5 completed users
  const sufficientCompletedUsers =
    stats.usersCompleteStatus.filter(
      (user) => user.reviewCompletionStatus === COMPLETION_STATES.COMPLETED
    ).length >= 5

  return (
    <Grid container rowGap={4} {...otherProps}>
      <Grid xs={12}>
        <Stack
          spacing={{
            xs: 3,
            lg: 4
          }}
        >
          {/* Completion Percentages */}
          {completionPercentages ? (
            <CompletionPercentages
              excellent={stats.usersCompletedResponses.length}
              good={stats.usersPartiallyCompletedResponses.length}
              bad={stats.usersNotStartedResponses.length}
            />
          ) : (
            // sentry will catch this error and show it in the issues section
            console.warn(
              'Error: No data was passed to CompletionPercentages component in the manager stat-tables.js file. '
            )
          )}
        </Stack>
      </Grid>
      <Grid xs={12}>
        {sufficientCompletedUsers ? (
          <UsersTable
            type={tableTypes.PROGRESS}
            data={stats.usersCompleteStatus}
            columns={columns}
            title="Track Individual Progress"
          />
        ) : (
          // sentry will catch this error and show it in the issues section
          console.warn(
            'Error: No data was passed to TeamStatsTable component in the manager stat-tables.js file. '
          )
        )}
      </Grid>
    </Grid>
  )
}

StatTables.propTypes = {
  stats: PropTypes.object.isRequired
}

export default StatTables
