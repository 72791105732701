import React from 'react'
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
  ListItemButtonProps
} from '@mui/material'

interface PopoverMenuButtonProps {
  item: {
    icon: JSX.Element
    text: string
    props: ListItemButtonProps
  }
}

const PopoverMenuButton: React.FC<PopoverMenuButtonProps> = ({ item }) => (
  <ListItemButton
    sx={{
      borderRadius: 1,
      px: 1,
      py: 0.5,
      pb: 1
    }}
    {...item.props}
  >
    <ListItemIcon
      sx={{
        mr: 0.5
      }}
    >
      <SvgIcon fontSize="small">{item.icon}</SvgIcon>
    </ListItemIcon>
    <ListItemText
      primary={
        <Typography variant="body1" fontSize="small">
          {item.text}
        </Typography>
      }
    />
  </ListItemButton>
)

export default PopoverMenuButton
