export const paths = {
  index: '/',
  checkout: '/checkout',
  contact: '/contact',
  pricing: '/pricing',
  login: '/login',
  signup: '/signup',
  demoAccount: '/demo-account',
  dashboard: {
    index: '/',
    reviews: {
      index: '/reviews',
      selection: (evaluationId) => `/reviews/${evaluationId}`,
      questions: (evaluationId) => `/reviews/${evaluationId}/questions`,
      submit: (evaluationId) => `/reviews/${evaluationId}/submit`,
      results: (evaluationId) => `/reviews/${evaluationId}/results`
    },
    admin: {
      index: '/admin',
      reviews: {
        index: '/admin/reviews',
        create: '/admin/create',
        details: (evaluationId) => `/admin/${evaluationId}/details`,
        update: (evaluationId) => `/admin/${evaluationId}/update`
      },
      management: '/admin/management'
    },
    manager: {
      index: '/manager',
      reviews: {
        index: '/manager/reviews',
        progress: (evaluationId) => `/manager/reviews/${evaluationId}/progress`
      }
    }
  },
  docs: '#',
  401: '/401',
  404: '/404',
  500: '/500'
}
