import { paths } from '/src/constants/paths'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router'
import { useAuth } from '/src/hooks/use-auth'

export const DemoGuard = ({ children }) => {
  // Getting the auth state
  const { user } = useAuth()

  if (user?.status !== 'DEMO_SETUP') {
    return <Navigate to={paths.dashboard.index} />
  }

  return <>{children}</>
}

DemoGuard.propTypes = {
  children: PropTypes.node.isRequired
}
