import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  exampleArr: []
}

const reducers = {
  getExmaples(state, action) {
    state.exampleArr = action.payload
  }
}

export const slice = createSlice({
  name: 'example',
  initialState,
  reducers
})

export const { reducer } = slice
