import React from 'react'
import { BehaviorItem } from './behavior-item'

const BehaviorItems = ({
  behaviors,
  evaluationStatus,
  handleAddBehavior,
  handleUpdateBehavior,
  handleDeleteBehavior
}) => {
  return behaviors?.map((behavior, index) => (
    <BehaviorItem
      key={index}
      index={index}
      behavior={behavior}
      evaluationStatus={evaluationStatus}
      handleAddBehavior={handleAddBehavior}
      handleUpdateBehavior={handleUpdateBehavior}
      handleDeleteBehavior={handleDeleteBehavior}
    />
  ))
}

export default BehaviorItems
