import { array, boolean, number, object, string } from 'yup'
import { EMPLOYEE_RELATION_TYPES } from '../constants/employees.js'

const getEmployeesUserSchema = object()
  .shape({
    fullName: string().required('Full name field is required.'),
    uid: string().required('User ID field is required.'),
    team: string().optional(),
    division: string().optional(),
    relation: string()
      .oneOf([...Object.values(EMPLOYEE_RELATION_TYPES)])
      .required('Relation field is required.')
  })
  .noUnknown()

export const getEmployees = object()
  .shape({
    employeeRequest: object()
      .shape({
        isApproved: boolean()
          .nullable()
          .defined('Is approved field is required.')
      })
      .noUnknown()
      .nullable()
      .defined('Employee request field is required.'),
    selectionConfig: object()
      .shape({
        requestMin: number()
          .integer()
          .nullable()
          .defined('Request min field is required.'),
        selectMin: number().integer().required('Select min field is required.'),
        selectMax: number().integer().required('Select max field is required.')
      })
      .noUnknown()
      .required('Selection config field is required.'),
    employees: array().of(getEmployeesUserSchema).ensure(),
    selectedUsers: array().of(getEmployeesUserSchema).ensure(),
    manager: string().notRequired()
  })
  .noUnknown() // No unknown fields allowed, strip all extra fields
