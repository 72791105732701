import PropTypes from 'prop-types'
import { Box, Container, Stack, Typography, useMediaQuery } from '@mui/material'
import { Seo } from './seo'
import { WarningSVG } from './common/SVGs/warning'

export const LoadingError = (props) => {
  const { message } = props
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <>
      <Seo title={message} />
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          py: '80px'
        }}
      >
        <Container maxWidth="lg">
          <Stack direction="row" spacing={6}>
            <WarningSVG />
            <Stack
              sx={{
                borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
                px: 2,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {import.meta.env.VITE_NODE_ENV === 'dev' ? (
                <Typography color="error.main" variant={mdUp ? 'h1' : 'h4'}>
                  {message}
                </Typography>
              ) : (
                <Stack spacing={2} ml={1}>
                  <Typography color="text.primary" variant={mdUp ? 'h1' : 'h4'}>
                    Oops! Something went wrong.
                  </Typography>
                  <Typography color="text.secondary" variant="body1">
                    Our team has been notified, and we're working to fix the
                    issue as soon as possible.
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  )
}

LoadingError.propTypes = {
  message: PropTypes.string.isRequired
}
