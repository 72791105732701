import React from 'react'
// mui imports
import styled from '@emotion/styled'
import { LinearProgress, Stack, linearProgressClasses } from '@mui/material'
import colorBetween from 'color-between'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5
  }
}))

const SummaryScoreBar = ({ score }) => {
  return (
    <Stack
      sx={{
        width: '100%'
      }}
    >
      <BorderLinearProgress
        variant="determinate"
        value={score}
        sx={{
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: `${colorBetween(
              '#f44336',
              '#4caf50',
              score / 100,
              'hex'
            )}`
          }
        }}
      />
    </Stack>
  )
}

export default SummaryScoreBar
