import { allFiltersMapToType } from '/src/components/filtering-drawer/constants/filter-drawer-types'

// get all unique values for each filter type
const getFilterOptions = ({ users, type }) => {
  if (!allFiltersMapToType[type])
    console.warn(`No filter type found for ${type}`)

  const filterKeys = allFiltersMapToType[type]
    .map((column) => column.key)
    .reduce((o, key) => ({ ...o, [key]: [] }), {})

  const filterTypesKeys = Object.keys(filterKeys)

  const uniqueValues = filterTypesKeys.reduce((acc, key) => {
    const values = users.map((user) => {
      // Apply parser if it exists
      const parser = allFiltersMapToType[type].find(
        (filter) => filter.key === key
      )?.parser

      if (parser) {
        return parser(user[key])
      }

      return user[key]
    })

    const unique = [...new Set(values)]

    if (!unique.length) return acc

    return { ...acc, [key]: { defaultValues: unique, values: [] } }
  }, {})

  // remove empty field
  Object.keys(uniqueValues).forEach((key) => {
    // filter out empty values and not applicable
    uniqueValues[key].defaultValues = uniqueValues[key].defaultValues.filter(
      (value) => value !== '' && value !== undefined && value !== null
    )

    const isEmpty = uniqueValues[key].defaultValues.length === 0

    return isEmpty && delete uniqueValues[key]
  })

  return uniqueValues
}

export { getFilterOptions }
