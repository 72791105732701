import { createContext, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import organizationRepo from '/src/data/repos/organizations-repo'
import { useUser } from '/src/hooks/use-user'
import { defaultLightColors } from '/src/theme/colors'
import { localStorageService } from '/src/services/local-storage-service'
import { STORAGE_KEY } from '/src/constants/local-storage'

export const initialSettings = {
  customizationConfig: {
    themeColor: defaultLightColors.main,
    organizationLogo: '',
    organizationName: 'Incompass Labs'
  },
  direction: 'ltr',
  layout: 'vertical',
  paletteMode: 'light',
  faqDrawerOpen: null,
  sideNavWidth: 0,
  responsiveFontSizes: true,
  stretch: false
}

const initialState = {
  ...initialSettings,
  isInitialized: false
}

export const SettingsContext = createContext({
  ...initialState,
  handleRevertDefault: async () => {},
  handleResetThemeColor: () => {},
  handleUpdate: () => {},
  handleSaveSideNavWidth: () => {},
  handleSettingsPreviewUpdate: () => {},
  handleResetOnSignOut: () => {},
  handleSettingsSave: async () => {},
  canRevertDefaultColor: false
})

export const SettingsProvider = (props) => {
  const { children } = props
  const { user } = useUser()
  const [state, setState] = useState(initialState)

  useEffect(() => {
    let restored = localStorageService.getItem(STORAGE_KEY)

    restored = restored || {}

    if (user) {
      const customizationConfig = {
        themeColor:
          user?.companyColorCode ||
          initialSettings.customizationConfig.themeColor,
        organizationLogo: user?.companyLogo,
        organizationName: user?.companyName
      }

      setState((prevState) => {
        localStorageService.setItem(STORAGE_KEY, {
          ...prevState,
          ...restored,
          ...{ customizationConfig },
          isInitialized: true
        })

        return {
          ...prevState,
          ...restored,
          ...{ customizationConfig },
          isInitialized: true
        }
      })
    }
  }, [user])

  const handleRevertDefault = useCallback(async () => {
    await organizationRepo.updateOrganization({
      colorCode: initialSettings.customizationConfig.themeColor
    })
    setState((prevState) => {
      const willSaved = {
        ...prevState,
        customizationConfig: {
          ...prevState.customizationConfig,
          themeColor: initialSettings.customizationConfig.themeColor
        }
      }
      localStorageService.setItem(STORAGE_KEY, willSaved)

      return willSaved
    })
  }, [])

  const handleResetThemeColor = useCallback(() => {
    const restored = localStorageService.getItem(STORAGE_KEY)
    setState((prevState) => ({
      ...prevState,
      ...{
        customizationConfig: {
          themeColor: restored.customizationConfig.themeColor,
          organizationLogo: prevState.customizationConfig.organizationLogo,
          organizationName: prevState.customizationConfig.organizationName
        }
      }
    }))
  }, [])

  const handleUpdate = useCallback(
    (settings) => {
      handleResetThemeColor()
      setState((prevState) => {
        localStorageService.setItem(STORAGE_KEY, {
          ...prevState,
          ...settings
        })

        return {
          ...prevState,
          ...settings
        }
      })
    },
    [handleResetThemeColor]
  )

  const handleResetOnSignOut = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      paletteMode: initialSettings.paletteMode,
      customizationConfig: {
        ...prevState.customizationConfig,
        themeColor: initialSettings.customizationConfig.themeColor
      }
    }))
  }, [])

  const handleSettingsPreviewUpdate = useCallback((settings) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...settings
      }
    })
  }, [])

  const handleSettingsSave = useCallback(async (settings) => {
    const newConfig = await organizationRepo.updateOrganization(
      Object.fromEntries(
        Object.entries({
          colorCode: settings.themeColor,
          logo: settings.organizationLogo,
          name: settings.organizationName
        }).filter(([_, value]) => value !== undefined)
      )
    )

    setState((prevState) => {
      const willSaved = {
        ...prevState,
        ...{
          customizationConfig: {
            themeColor: newConfig.colorCode,
            organizationLogo: newConfig.logo,
            organizationName: newConfig.name
          }
        }
      }
      localStorageService.setItem(STORAGE_KEY, willSaved)

      return willSaved
    })
  }, [])

  const handleSaveSideNavWidth = useCallback((width) => {
    setState((prevState) => ({
      ...prevState,
      sideNavWidth: width
    }))
  }, [])

  const canRevertDefaultColor =
    localStorageService.getItem(STORAGE_KEY)?.customizationConfig.themeColor !==
    initialSettings.customizationConfig.themeColor

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        handleSaveSideNavWidth,
        handleResetThemeColor,
        handleResetOnSignOut,
        handleRevertDefault,
        handleUpdate,
        handleSettingsPreviewUpdate,
        handleSettingsSave,
        canRevertDefaultColor
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const SettingsConsumer = SettingsContext.Consumer
