import { useEffect, useState } from 'react'

// Custom hook to check if a div has overflow and needs a tooltip
function useOverflowTooltip(id) {
  const [isTooltipActive, setIsTooltipActive] = useState(false)

  useEffect(() => {
    const cell = document.getElementById(id)?.children[0]

    if (cell) {
      if (cell.scrollWidth > cell.clientWidth) {
        setIsTooltipActive(true)
        return
      }
      setIsTooltipActive(false)
      return
    }
  }, [id])

  return isTooltipActive
}

export default useOverflowTooltip
