import { TableBody, TableCell, TableRow, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'
import { EVALUATION_STATES } from '/src/constants/statuses'
import CustomTableRow from './custom-table-row'

export const statusMap = {
  [EVALUATION_STATES.COMPLETED]: 'success',
  [EVALUATION_STATES.TEMPLATE]: 'info',
  [EVALUATION_STATES.ONGOING]: 'warning',
  [EVALUATION_STATES.AWAITING_RESULTS]: 'warning'
}

const Body = () => {
  const {
    columns,
    filtered,
    dialog,
    expandData,
    page,
    rowsPerPage,
    dataLoadMode,
    emptyRows,
    ExpandedRowComponent,
    isSelected
  } = useUsersTableContext()

  const rows = filtered.slice(
    dataLoadMode === 'pagination' ? page * rowsPerPage : 0,
    page * rowsPerPage + rowsPerPage
  )

  return (
    <TableBody>
      {
        // if no results, display no results message
        rows.length === 0 && (
          <TableRow width="100%">
            <TableCell colSpan={columns.length} align="left">
              <Typography px={2} py={1}>
                We couldn’t find any matches. Try adjusting the filters to see
                more results.
              </Typography>
            </TableCell>
          </TableRow>
        )
      }
      {rows.map((row, index) => {
        const isItemSelected =
          isSelected(row) || (dialog?.data?.uid === row.uid && dialog?.open)
        const labelId = `enhanced-table-checkbox-${index}`
        const isCurrentRowExpanded =
          row.uid === expandData.uid && expandData.index === index

        return (
          <Fragment key={index}>
            <CustomTableRow
              labelId={labelId}
              isItemSelected={isItemSelected}
              index={index}
              row={row}
            />
            {isCurrentRowExpanded && (
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{
                    'p': 0,
                    'position': 'relative',
                    '&:after': {
                      position: 'absolute',
                      content: '" "',
                      top: 0,
                      left: 0,
                      backgroundColor: 'primary.main',
                      width: 3,
                      height: 'calc(100% + 1px)'
                    }
                  }}
                >
                  <ExpandedRowComponent data={expandData} />
                </TableCell>
              </TableRow>
            )}
          </Fragment>
        )
      })}
      {emptyRows > 0 && dataLoadMode === 'pagination' ? (
        <TableRow
          style={{
            height: 30 * emptyRows
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      ) : null}
    </TableBody>
  )
}

export { Body }
