export const createObjectFromArrayOfObjects = ({ array, fieldToKey }) => {
  if (!Array.isArray(array) || typeof fieldToKey !== 'string') {
    console.warn('createObjectFromArrayOfObjects: Invalid parameters', {
      array,
      fieldToKey
    })
  }

  return array.reduce((acc, curr) => {
    acc[curr[fieldToKey]] = curr
    return acc
  }, {})
}
