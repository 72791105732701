import React from 'react'
import { Unstable_Grid2 as Grid, Typography, Popover } from '@mui/material'
import { CompletionPercentages } from './completion-percentages'
import { CompletionGraph } from './completion-graph'
import { CompletionStats } from './completion-stats'
import { tableTypes } from '/src/components/users-table/constants/table-types'
import { UsersTable } from '/src/components/users-table/users-table'
import { renderReviewStatus } from '/src/utils/render-review-status'
import { USER_KEYS } from '/src/constants/user'

const { FULL_NAME, TEAM, DIVISION, MANAGER, LOCATION } = USER_KEYS

const columns = [
  {
    key: FULL_NAME,
    label: 'Name',
    minWidth: 150,
    sort: true
  },
  {
    key: 'reviewCompletionStatus',
    label: 'Status',
    parser: ({ row, value, column }) => renderReviewStatus(value),
    minWidth: 170,
    filter: true,
    sort: true
  },
  {
    key: 'selectedPeople',
    label: 'Selected People',
    parser: ({ row, value, column }) =>
      renderPeopleArrayByFullname({
        peopleArray: value,
        selectedText: `${value.length} selected. Hover to view.`,
        noneSelectedText: 'No one selected.'
      }),
    minWidth: 210
  },
  {
    key: 'selectedBy',
    label: 'Selected By',
    parser: ({ row, value, column }) =>
      renderPeopleArrayByFullname({
        peopleArray: value,
        selectedText: `Selected by ${value.length}. Hover to view.`,
        noneSelectedText: 'Selected by no one.'
      }),
    minWidth: 230
  },
  {
    key: MANAGER,
    label: 'Manager',
    minWidth: 150,
    filter: true,
    sort: true
  },
  {
    key: TEAM,
    label: 'Team',
    minWidth: 150,
    filter: true,
    sort: true
  },
  {
    key: DIVISION,
    label: 'Division',
    minWidth: 150,
    filter: true,
    sort: true
  },
  {
    key: LOCATION,
    label: 'Location',
    minWidth: 150,
    filter: true,
    sort: true
  }
]

const SelectedPeoplePopover = ({ peopleArray, selectedText }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        variant="body2"
      >
        {selectedText}
      </Typography>
      <Popover
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={8}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              maxWidth: 400
            }
          }
        }}
      >
        <Typography variant="body2">
          {peopleArray.map((person, i, arr) => (
            <React.Fragment key={i}>
              {i !== arr.length - 1
                ? `${person[FULL_NAME]}, `
                : person[FULL_NAME]}
            </React.Fragment>
          ))}
        </Typography>
      </Popover>
    </div>
  )
}

const renderPeopleArrayByFullname = ({
  peopleArray,
  selectedText,
  noneSelectedText
}) =>
  peopleArray?.length > 0 ? (
    <SelectedPeoplePopover {...{ peopleArray, selectedText }} />
  ) : (
    <Typography variant="body">{noneSelectedText}</Typography>
  )

const StatTables = ({ stats, ...otherProps }) => {
  // Completion Percentages for circle shaped progress bars
  const completionPercentages =
    stats.usersCompletedResponses &&
    stats.usersPartiallyCompletedResponses &&
    stats.usersNotStartedResponses

  // Completion Graph Data for day by day completion chart
  const completionGraphStats = stats.completionGraphStats

  // Completion stats by properties
  const completionStats = stats.completionStats

  // Users with their completion status
  const usersCompleteStatus = stats.usersCompleteStatus

  // Data checks
  const isThereCompletionGraphData =
    completionGraphStats?.completionGraphData.every(
      (data) => data.data.length > 0
    ) && completionGraphStats?.dataCategories?.length > 0

  return (
    <Grid container rowGap={4} mb={3} {...otherProps}>
      <Grid xs={12}>
        {/* Completion Percentages */}
        {completionPercentages ? (
          <CompletionPercentages
            excellent={stats.usersCompletedResponses.length}
            good={stats.usersPartiallyCompletedResponses.length}
            bad={stats.usersNotStartedResponses.length}
          />
        ) : (
          // sentry will catch this error and show it in the issues section
          console.warn(
            'Error: No data was passed to the CompletionPercentages component in the admin stat-tables.js file.'
          )
        )}
      </Grid>
      <Grid xs={12} container spacing={3}>
        {isThereCompletionGraphData ? (
          <Grid xs={7}>
            <CompletionGraph
              chartSeries={completionGraphStats.completionGraphData}
              categories={completionGraphStats.dataCategories}
            />
          </Grid>
        ) : (
          // sentry will catch this error and show it in the issues section
          console.warn(
            'Error: No data was passed to the CompletionGraph component in the admin stat-tables.js file.'
          )
        )}
        <Grid
          xs={5}
          sx={{
            flexGrow: 1
          }}
        >
          {completionStats ? (
            <CompletionStats
              completionStats={completionStats}
              totalUsers={stats.totalUsers}
            />
          ) : (
            console.warn(
              'Error: No data was passed to the CompletionStats component in the admin stat-tables.js file.'
            )
          )}
        </Grid>
      </Grid>

      {/* Employees Table */}
      <Grid xs={12}>
        {usersCompleteStatus?.length > 0 ? (
          <UsersTable
            type={tableTypes.PROGRESS}
            data={usersCompleteStatus}
            columns={columns}
            title="Track Individual Progress"
          />
        ) : (
          // sentry will catch this error and show it in the issues section
          console.warn(
            'Error: No data was passed to the CompanyStatsTable component in the admin stat-tables.js file.'
          )
        )}
      </Grid>
    </Grid>
  )
}

export default StatTables
