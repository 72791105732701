import { userSchema } from './common/user-object.js'
import { evaluationSchema } from './common/evaluation.js'
import { reviewSchema } from './common/review.js'
import { array, object } from 'yup'

export const getReviewStats = object()
  .shape({
    users: array().of(userSchema).required('Users field is required.'),
    evaluation: evaluationSchema,
    reviews: array().of(reviewSchema)
  })
  .noUnknown()
