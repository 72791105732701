// React imports
import { useState } from 'react'

// MUI Components
import {
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  Stack,
  Typography,
  Box,
  Link
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

// Custom Components
import Title from '/src/components/common/Title/Title'
import Comment from '/src/components/result-card/comment'
import { Android12Switch } from '/src/components/android-12-switch'
import {
  EditableCard,
  addGreyFilterOnCondition
} from '/src/components/edittable-card'
import TextButton from '/src/components/buttons/text-button'

// Constants
import { CONFIG_FIELDS } from '/src/constants/evaluation-config'

const ResultDisplay = ({ onChange, reportConfig, result, formSettings }) => {
  // Getting theme
  const theme = useTheme()

  // Comments state and handlers
  const [openComments, setOpenComments] = useState(false)
  const handleCommentsClick = () => setOpenComments(!openComments)

  // Get height of the show all comments button to use it in blur bottom
  const blurBottomHeight =
    document.getElementById('show-all-comments')?.offsetHeight

  const controlledSwitchProps = { inputProps: { 'aria-label': 'controlled' } }

  return (
    <Card
      sx={{
        height: '100%'
      }}
    >
      <CardContent
        sx={{
          px: 5
        }}
      >
        {/* RESULT CARDS */}
        <Grid
          container
          rowGap={{
            xs: 2,
            md: 0
          }}
        >
          {/* CARD TITLE */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: {
                xs: 'center',
                md: 'flex-start'
              }
            }}
          >
            <Box>
              <Title textSize={26} text={'Own it'} mr={{ md: 6, xs: 0 }} />
              <Typography variant="caption">
                <Link component="div">Click to learn more.</Link>
              </Typography>
            </Box>
          </Grid>

          {/* SCORE CARDS */}
          <Grid
            component="div"
            sx={{
              justifyContent: 'center',
              pl: {
                xs: 0,
                md: 2
              },
              borderLeft: { xs: 'unset', md: '1px solid #e0e0e0' }
            }}
            item
            xs={12}
            md={9}
            container
            rowGap={2}
            columnGap={2}
          >
            {result.scores.map((score, index) => (
              <EditableCard
                key={index}
                data={score}
                config={reportConfig[score.name]}
                onChange={onChange}
                formSettings={formSettings}
              />
            ))}
          </Grid>
        </Grid>
        {/* COMMENTS */}
        <Stack spacing={4} marginTop={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mt={5}
          >
            <Card
              elevation={20}
              component={Stack}
              direction="row"
              alignItems="center"
              px={2}
              py={1}
            >
              <Title
                textSize={22}
                text={'Comments'}
                sx={{
                  ...addGreyFilterOnCondition(
                    reportConfig[CONFIG_FIELDS.COMMENTS].display
                  )
                }}
              />
              <Divider
                sx={{
                  ml: 2
                }}
                orientation="vertical"
                flexItem
              />
              <Android12Switch
                name={CONFIG_FIELDS.COMMENTS}
                checked={reportConfig[CONFIG_FIELDS.COMMENTS].display}
                onChange={onChange}
                {...controlledSwitchProps}
              />
            </Card>
          </Stack>
          <Stack
            sx={{
              position: 'relative',
              ...addGreyFilterOnCondition(
                reportConfig[CONFIG_FIELDS.COMMENTS].display
              )
            }}
          >
            {/* render all comments */}
            <Box>
              <Collapse
                in={openComments}
                timeout="auto"
                sx={{
                  '.MuiCollapse-hidden': {
                    position: 'relative'
                  }
                }}
                collapsedSize={result.comments.length > 0 ? 100 : 40}
              >
                {result.comments.map((comment, index) => {
                  return <Comment key={index} comment={comment} index={index} />
                })}
                {result.comments.length === 0 && (
                  <Typography
                    sx={{
                      textAlign: 'center'
                    }}
                  >
                    You didn't receive any written feedback for this behavior.
                  </Typography>
                )}
              </Collapse>
              {result.comments.length > 1 && (
                <Box
                  sx={{
                    display: !openComments ? 'block' : 'none',
                    position: 'absolute',
                    bottom: blurBottomHeight ? blurBottomHeight : 43,
                    left: 0,
                    right: 0,
                    height: 90,
                    background:
                      theme.palette.mode === 'dark'
                        ? `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #111927 100%)`
                        : `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)`,
                    transition: 'all 0.5s ease-in-out'
                  }}
                />
              )}
            </Box>
            {/* Show all comments button */}
            {result.comments.length > 1 && (
              <Stack
                id="show-all-comments"
                sx={{
                  alignItems: 'center',
                  mt: 2
                }}
              >
                <TextButton
                  onClick={handleCommentsClick}
                  endIcon={!openComments && <ExpandMore />}
                >
                  {openComments ? <ExpandLess fontSize="small" /> : 'Show'}
                </TextButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default ResultDisplay
