// userFields
export const USER_KEYS = {
  UID: 'uid',
  EMAIL: 'email',
  COMPANY: 'company',
  DIVISION: 'division',
  FULL_NAME: 'fullName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  MANAGER: 'manager',
  TEAM: 'team',
  USER_ROLES: 'userRoles',
  GENDER: 'gender',
  JOIN_DATE: 'joinDate',
  JOB_LEVEL: 'jobLevel',
  SENIORITY: 'seniority',
  STATUS: 'status',
  RACE: 'race',
  JOB_TITLE: 'jobTitle',
  SALARY: 'salary',
  LOCATION: 'location',
  TENURE: 'tenure',
  COHORT: 'cohort',
  PERMISSIONS: 'permissions'
}
