import { Avatar, Box, ButtonBase } from '@mui/material'
import { useUser } from '/src/hooks/use-user'
import { usePopover } from '/src/hooks/use-popover'
import { AccountPopover } from './account-popover'

export const AccountButton = () => {
  const { user, stringAvatar } = useUser()
  const popover = usePopover()

  return (
    <>
      <Box
        data-gaid="account-button"
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar {...stringAvatar(user?.fullName, 35, 35)} />
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  )
}
