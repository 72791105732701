// mui imports
import styled from '@emotion/styled'
import { LinearProgress, linearProgressClasses, Stack } from '@mui/material'

// color between imports
import colorBetween from 'color-between'

// component imports
import LimitBar from './limit-bar'

// helper imports
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'

const ScoreBar = (props) => {
  const { score, errorBar } = props
  const theme = useTheme()

  const { values, labels, helperText } = errorBar
  return (
    <Stack
      sx={{
        flexGrow: 1
      }}
    >
      <Stack
        sx={{
          width: '100%'
        }}
      >
        <LimitBar values={values} labels={labels} helperText={helperText} />
      </Stack>
      <BorderLinearProgress
        variant="determinate"
        theme={theme}
        value={score}
        sx={{
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: `${colorBetween(
              '#f44336',
              '#4caf50',
              score / 100,
              'hex'
            )}`
          }
        }}
      />
    </Stack>
  )
}

export default ScoreBar

ScoreBar.propTypes = {
  score: PropTypes.number.isRequired,
  errorBar: PropTypes.shape({
    values: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    helperText: PropTypes.string.isRequired
  })
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5
  }
}))
