import { Skeleton, Stack } from '@mui/material'

export const Skeletons = ({
  height,
  count = 1,
  width,
  variant = 'rectangular',
  ...otherProps
}) => {
  // create an array of length number
  const arr = Array.from({ length: count }, (_, i) => i)

  return (
    <Stack {...otherProps}>
      {arr.map((i) => (
        <Skeleton key={i} height={height} width={width} variant={variant} />
      ))}
    </Stack>
  )
}
