import React, { Suspense, useMemo, useState } from 'react'
import TabContainer from '/src/components/tab-container'
import { Stack } from '@mui/material'
import { ExecutiveOverview } from './dashboard-overview'
import {
  resultsModal,
  insightsModal
} from '/src/data/modals/workforce-results-modal'
import { getFilterOptions } from '/src/components/filtering-drawer/utils/get-filter-options'
import { drawerTypes } from '/src/components/filtering-drawer/constants/filter-drawer-filters'
import { ROLES } from '/functions/shared/constants/roles'
import { useLocation } from 'react-router'
import LoadingOverlay from '/src/components/loading-overlay'
import { AnimatedLogo } from '/src/components/animated-logo'
import DashboardAnalysis from './dashboard-analysis-tab'
import { ACCESS_CONTROLS } from '/src/constants/access-control-settings'
import { checkAccessControl } from '/src/hooks/use-access-control'
import { useUser } from '/src/hooks/use-user'

const DashboardInsights = React.lazy(() => import('./dashboard-insights'))
const DashboardCalibrationTab = React.lazy(() =>
  import('./dashboard-calibration-tab')
)

export const tabOrdersForAdmin = {
  EXECUTIVE_OVERVIEW: 0,
  CALIBRATION_ANALYSES: 1,
  WORKFORCE_INSIGHTS: 2,
  REWARD_ALLOCATION: 3
}
export const tabOrdersForManager = {
  CALIBRATION_ANALYSES: 0,
  TEAM_INSIGHTS: 1
}

const DashboardTabs = (props) => {
  const { workforceResults, evaluations, userRole } = props
  const { state } = useLocation()
  const { evaluationId, tab } = state || {}
  const [tabValue, setTabValue] = useState(tab ? tab : 0)
  const [defaultInsight, setDefaultInsight] = useState('reviewStatistics')
  const { user } = useUser()

  const handleTabChange = (newValue) => {
    setTabValue(newValue)
  }

  const changeDefaultInsight = (insight) => {
    setDefaultInsight(insight)
    setTabValue(userRole === ROLES.ADMIN ? 2 : 1)
    // Set insights filter to last evaluation uid
    const lastEvaluation = evaluations[evaluations.length - 1]
    setFilters((prevFilters) => ({
      ...prevFilters,
      insights: {
        ...prevFilters.insights,
        evaluations: {
          ...prevFilters.insights.evaluations,
          ...(lastEvaluation?.uid && {
            values: [lastEvaluation.uid]
          })
        }
      }
    }))
  }

  // Get all used behaviors
  const behaviors = [
    ...new Set(
      evaluations
        .map((evaluation) =>
          evaluation.questions.map((question) => question.title)
        )
        .flat()
    )
  ]

  /* DEFAULT VALUES FOR FILTERS */
  const defaultBehaviorValuesForFilters = {
    behaviors: { defaultValues: behaviors, values: [] }
  }

  const defaultEvaluationValuesForFilters = {
    evaluations: {
      defaultValues: evaluations,
      values: evaluationId ? [evaluationId] : []
    }
  }

  const defaultUserPropertiesForFilters = useMemo(
    () =>
      getFilterOptions({
        users: workforceResults,
        type: drawerTypes.DASHBOARD_DEFAULT
      }),
    [workforceResults]
  )

  const defaultCommonValuesForFilters = {
    ...defaultBehaviorValuesForFilters,
    ...defaultEvaluationValuesForFilters,
    ...defaultUserPropertiesForFilters
  }

  /* ANALYSIS SPECIFIC DEFAULT VALUES FOR FILTERS */
  const analysisDataWithoutFiltering = useMemo(
    () =>
      resultsModal
        .generateAnalyticsData({
          workforceResults: workforceResults
        })
        .filter((r) => r.behavior !== 'Average'),
    [workforceResults]
  )

  const analysisDefaultValuesForFilters = useMemo(
    () =>
      getFilterOptions({
        users: analysisDataWithoutFiltering,
        type: drawerTypes.DASHBOARD_ANALYSES
      }),
    [analysisDataWithoutFiltering]
  )

  const rewardDefaultValuesForFilters = useMemo(
    () =>
      getFilterOptions({
        users: analysisDataWithoutFiltering,
        type: drawerTypes.DASHBOARD_REWARDS
      }),
    [analysisDataWithoutFiltering]
  )

  const [filters, setFilters] = useState({
    analysis: {
      ...defaultCommonValuesForFilters,
      ...analysisDefaultValuesForFilters
    },
    rewards: {
      ...defaultCommonValuesForFilters,
      ...rewardDefaultValuesForFilters
    },
    // INSIGHTS DO NOT NEED ANY SPECIFIC FILTERS OTHER THAN DEFAULT BECAUSE IT DOES NOT ADD ANY CALCULATED VALUES INTO THE USER PROPERTIES
    insights: {
      ...defaultCommonValuesForFilters
    }
  })

  const analysisTabData = useMemo(
    () =>
      resultsModal.generateAnalyticsData({
        workforceResults: workforceResults,
        filters: filters.analysis
      }),
    [workforceResults, filters.analysis]
  )

  const rewardTabData = useMemo(
    () =>
      resultsModal
        .generateAnalyticsData({
          workforceResults: workforceResults,
          filters: filters.rewards
        })
        .filter((r) => r.behavior === 'Average'),
    [workforceResults, filters.rewards]
  )

  const insightsTabData = useMemo(
    () =>
      insightsModal.generateInsights({
        workforceResults: workforceResults,
        filters: filters.insights
      }),
    [workforceResults, filters.insights]
  )

  const getUserRoleFilters = (filters, userRole) => {
    const filtersByRole = {
      [ROLES.ADMIN]: filters,
      [ROLES.MANAGER]: (({
        percentile,
        gender,
        race,
        location,
        cohort,
        ...rest
      }) => rest)(filters)
    }
    return filtersByRole[userRole]
  }

  const tabComponents = {
    [ROLES.ADMIN]: [
      {
        label: 'Executive Overview',
        permission: ACCESS_CONTROLS.ADMIN_EXECUTIVE_OVERVIEW_TAB,
        component: (
          <ExecutiveOverview
            evaluations={evaluations}
            behaviors={behaviors}
            workforceResults={workforceResults}
            gridData={analysisDataWithoutFiltering}
            handleTabChange={handleTabChange}
            changeDefaultInsight={changeDefaultInsight}
          />
        )
      },
      {
        label: 'Calibration Analyses',
        permission: ACCESS_CONTROLS.ADMIN_DASHBOARD_ANALYSIS_TAB,
        component: (
          <DashboardAnalysis
            analysisTabData={analysisTabData}
            filters={getUserRoleFilters(filters.analysis, userRole)}
            setFilters={setFilters}
            userRole={userRole}
          />
        )
      },
      {
        label: 'Workforce Insights',
        permission: ACCESS_CONTROLS.ADMIN_DASHBOARD_INSIGHTS_TAB,
        component: (
          <DashboardInsights
            insights={insightsTabData}
            defaultInsight={defaultInsight}
            filters={getUserRoleFilters(filters.insights, userRole)}
            setFilters={setFilters}
            userRole={userRole}
          />
        )
      },
      {
        label: 'Reward Allocation',
        permission: ACCESS_CONTROLS.ADMIN_DASHBOARD_CALIBRATION_TAB,
        component: (
          <DashboardCalibrationTab
            setFilters={setFilters}
            rewardTabData={rewardTabData}
            filters={getUserRoleFilters(filters.rewards, userRole)}
          />
        )
      }
    ],
    [ROLES.MANAGER]: [
      {
        label: 'Team Analyses',
        permission: ACCESS_CONTROLS.MANAGER_DASHBOARD_ANALYSIS_TAB,
        component: (
          <DashboardAnalysis
            analysisTabData={analysisTabData}
            filters={getUserRoleFilters(filters.analysis, userRole)}
            setFilters={setFilters}
            userRole={userRole}
          />
        )
      },
      {
        label: 'Team Insights',
        permission: ACCESS_CONTROLS.MANAGER_DASHBOARD_INSIGHTS_TAB,
        component: (
          <DashboardInsights
            insights={insightsTabData}
            defaultInsight={defaultInsight}
            filters={getUserRoleFilters(filters.insights, userRole)}
            setFilters={setFilters}
            userRole={userRole}
          />
        )
      }
    ]
  }

  const finalTabComponents = tabComponents[userRole].filter((tabComponent) =>
    checkAccessControl({ user, accessControl: tabComponent.permission })
  )

  return (
    <TabContainer
      orientation="horizontal"
      tabValue={tabValue}
      handleTabChange={handleTabChange}
      tabLabels={finalTabComponents.map((tabComponent) => tabComponent.label)}
    >
      {finalTabComponents.map((tabComponent, index) => (
        <Stack key={index}>
          <Suspense
            fallback={
              <LoadingOverlay my={1} height={50}>
                <AnimatedLogo width={60} />
              </LoadingOverlay>
            }
          >
            {tabComponent.component}
          </Suspense>
        </Stack>
      ))}
    </TabContainer>
  )
}
export default DashboardTabs
