import * as React from 'react'
import { getObjectValuesAsString } from '/src/components/users-table/utils/get-object-values-as-string'
import {
  addFilter,
  addThreshold,
  applyFilters,
  applyInitialFilters,
  removeFilter
} from '/src/components/users-table/utils/filter-functions'
import { getComparator } from '../utils/get-comparator'
import { stableSort } from '../utils/stable-sort'

/**
 * Custom React hook that manages the state and logic for a table component.
 * Handles sorting, pagination, filtering, and selection of rows in the table.
 *
 * @param {Object} options - The options object.
 * @param {Array} options.selected - An array of initially selected rows in the table.
 * @param {Function} options.onSelect - A callback function to handle row selection.
 * @param {Array} options.defaultData - An array of user data for the table.
 * @param {string} options.dataLoadMode - A string indicating the data loading mode ('scroll' or 'pagination').
 * @param {Function} options.onSelectAll - A callback function to handle selecting all rows.
 * @param {boolean} options.checkbox - A boolean indicating whether the table has a checkbox column.
 * @param {string} options.defaultOrderBy - The default column to sort by.
 * @param {string} options.defaultOrder - The default sort order ('asc' or 'desc').
 * @param {Array} options.columns - An array of column configurations for the table.
 * @param {Function} options.setExtractData - A callback function to extract data from the table.
 * @param {boolean} options.updateOnDataChange - A boolean indicating whether to update the table on data change.
 * @returns {Object} - An object with the state variables and callback functions for the table component.
 */
const useTable = ({
  selected,
  onSelect,
  dialog,
  defaultData,
  dataLoadMode,
  gridView,
  onSelectAll,
  checkbox,
  reportExpand,
  defaultOrderBy,
  defaultOrder,
  columns,
  setExtractData,
  updateOnDataChange,
  gridData
}) => {
  const [order, setOrder] = React.useState(defaultOrder)
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy)
  const [page, setPage] = React.useState(0)
  const [scrollLoading, setScrollLoading] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    dataLoadMode === 'scroll' ? 100 : 25
  )
  const [showGridView, setShowGridView] = React.useState(gridView)
  const isGridView = React.useMemo(
    () => gridView && gridData && showGridView,
    [gridView, gridData, showGridView]
  )
  const tableData = React.useMemo(
    () =>
      isGridView
        ? gridData
        : // Filter data on first render
          applyInitialFilters({
            data: defaultData,
            columns,
            defaultOrder,
            defaultOrderBy
          }),
    [isGridView, gridData, defaultData, columns, defaultOrder, defaultOrderBy]
  )

  const [filtered, setFiltered] = React.useState(tableData)
  // Set filtered data and extract data
  const setFilteredData = React.useCallback(
    (data) => {
      setFiltered(data)
      if (setExtractData) {
        setExtractData(data)
      }
    },
    [setExtractData]
  )
  const [filterChips, setFilterChips] = React.useState([])
  const [activeFilters, setActiveFilters] = React.useState([])
  const [activeThresholds, setActiveThresholds] = React.useState([])
  const [search, setSearch] = React.useState({
    text: '',
    searchBy: []
  })
  const [localSelected, setLocalSelected] = React.useState(
    selected ? selected.map((n) => getObjectValuesAsString(n)) : []
  )

  // ReportExpand states
  const [expandData, setExpandData] = React.useState({
    uid: '',
    index: -1
  })

  // Ask anything states
  const [displayAskAnything, setDisplayAskAnything] = React.useState(false)

  const handleRequestSort = React.useCallback(
    (event, newOrderBy) => {
      const newOrder = order === 'asc' ? 'desc' : 'asc'
      setOrder((prev) => newOrder)
      setOrderBy((prev) => newOrderBy)

      // sort filtered
      setFilteredData((prev) =>
        stableSort(filtered, getComparator(newOrder, newOrderBy, undefined))
      )
    },
    [order, filtered, setFilteredData]
  )

  const handleSelectAllClick = React.useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelected = filtered.map((n) => {
          const string = getObjectValuesAsString(n)

          return string
        })

        onSelectAll(event, filtered)
        setLocalSelected(newSelected)
        return
      }
      onSelectAll(event, [])
      setLocalSelected([])
    },
    [onSelectAll, filtered]
  )

  const handleClick = React.useCallback(
    (event, row, index) => {
      if (checkbox) {
        const key = getObjectValuesAsString(row)

        const selectedIndex = localSelected.indexOf(key)
        let newSelected = []

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(localSelected, key)
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(localSelected.slice(1))
        } else if (selectedIndex === localSelected.length - 1) {
          newSelected = newSelected.concat(localSelected.slice(0, -1))
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            localSelected.slice(0, selectedIndex),
            localSelected.slice(selectedIndex + 1)
          )
        }

        onSelect(event, row)
        setLocalSelected(newSelected)
      } else if (reportExpand) {
        // find user results by id and set modal data
        const result = filtered.find(
          (user, key) => user.uid === row.uid && key === index
        )

        if (expandData.uid === row.uid && expandData.index === index) {
          setExpandData({ uid: '', index: -1 })
        } else {
          setExpandData({ ...result, index })
        }

        if (!result) return console.error('No data found for this user.')

        return result
      } else if (dialog) {
        const result = filtered.find((user) => user.uid === row.uid)

        if (dialog.open && dialog.data?.uid === row.uid) {
          dialog.handleClose()
          return
        }

        dialog.handleOpen(result)
      }
    },
    [
      filtered,
      dialog,
      localSelected,
      onSelect,
      checkbox,
      reportExpand,
      expandData
    ]
  )

  const handleChangePage = React.useCallback((event, newPage) => {
    setPage(newPage)
  }, [])

  const handleChangeRowsPerPage = React.useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }, [])

  const isSelected = React.useCallback(
    (row) => {
      const key = getObjectValuesAsString(row)

      return localSelected.indexOf(key) !== -1
    },
    [localSelected]
  )

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filtered.length) : 0

  const handleUsersFiltering = React.useCallback(
    ({ type, action, payload }) => {
      // Initialize filtered with raw data based on view mode
      let filtered = isGridView ? gridData : defaultData
      let newFilters = []
      if (action === 'search') {
        // apply filters: threshold, active filters, search text
        filtered = applyFilters(filtered, {
          activeThresholds,
          activeFilters,
          search: {
            text: payload,
            searchBy: type
          }
        })

        // set search text and search by
        setSearch((prev) => ({
          ...prev,
          text: payload,
          searchBy: type
        }))
        setFilteredData(filtered)

        return filtered
      }

      if (action === 'add') {
        // add filter to active filters
        newFilters = addFilter(activeFilters, type, payload)

        // apply filters: threshold, active filters, search text
        filtered = applyFilters(filtered, {
          activeThresholds,
          activeFilters: newFilters,
          search
        })

        setActiveFilters(newFilters)
        setFilteredData(filtered)

        return filtered
      }
      if (action === 'remove') {
        newFilters = removeFilter(activeFilters, type, payload)

        // apply filters: threshold, active filters, search text
        filtered = applyFilters(filtered, {
          activeThresholds,
          activeFilters: newFilters,
          search
        })

        setActiveFilters(newFilters)
        setFilteredData(filtered)

        return filtered
      }
      if (action === 'threshold') {
        // add new threshold
        const newThresholds = addThreshold(activeThresholds, type, payload)

        // apply filters: threshold, active filters, search text
        filtered = applyFilters(filtered, {
          activeThresholds: newThresholds,
          activeFilters,
          search
        })

        setActiveThresholds(newThresholds)
        setFilteredData(filtered)

        return filtered
      }

      return console.error('handleUsersFiltering: no action provided')
    },
    [
      activeFilters,
      search,
      activeThresholds,
      setFilteredData,
      gridData,
      defaultData,
      isGridView
    ]
  )

  const toggleGridView = React.useCallback(() => {
    setShowGridView((prev) => {
      // if true, set filtered to gridData
      // GRID VIEW
      if (!prev) {
        setActiveFilters([]) // Remove filters
        setFilterChips([]) // Remove chips

        // Only apply search filter if search text exists
        setFilteredData(
          applyFilters(gridData, {
            search
          })
        )
      } else {
        // TABLE VIEW
        // Apply filters and sort on table view if filters exist
        setFilteredData(
          stableSort(
            applyFilters(defaultData, {
              activeThresholds,
              activeFilters,
              search
            }),
            getComparator(order, orderBy, undefined)
          )
        )
      }

      return !prev
    })
  }, [
    gridData,
    defaultData,
    activeFilters,
    activeThresholds,
    search,
    order,
    orderBy,
    setFilteredData
  ])

  // Track initial render
  const isInitialRender = React.useRef(true)
  // Update filtered data on data change
  React.useEffect(() => {
    if (!tableData || !updateOnDataChange || isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    const applyFiltersAndSort = (data, applySort = false) => {
      const filteredData = applyFilters(data, {
        activeThresholds: isGridView ? [] : activeThresholds,
        activeFilters: isGridView ? [] : activeFilters,
        search
      })
      return applySort
        ? stableSort(filteredData, getComparator(order, orderBy, undefined))
        : filteredData
    }

    const newFilteredAndSortedData = applyFiltersAndSort(
      isGridView ? gridData : defaultData,
      !isGridView
    )

    setFiltered(newFilteredAndSortedData)
    setExtractData?.(newFilteredAndSortedData)
  }, [tableData]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    dialog,
    handleRequestSort,
    handleSelectAllClick,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    isSelected,
    setPage,
    toggleGridView,
    showGridView,
    gridView,
    scrollLoading,
    setScrollLoading,
    emptyRows,
    filtered,
    selected: localSelected,
    expandData,
    handleUsersFiltering,
    filterChips,
    setFilterChips,
    displayAskAnything,
    setDisplayAskAnything
  }
}

export { useTable }
