import { useTheme } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { ToastContainer, Zoom } from 'react-toastify'

export const Toaster = () => {
  const theme = useTheme()

  return (
    <>
      <ToastContainer
        toastStyle={{
          backdropFilter: 'blur(6px)',
          backgroundColor: alpha(theme.palette.neutral[900], 0.7),
          color: theme.palette.common.white,
          boxShadow: theme.shadows[16]
        }}
        position="top-center"
        autoClose={3000}
        newestOnTop
        closeOnClick
        pauseOnHover
        hideProgressBar
        closeButton={false}
        transition={Zoom}
      />
    </>
  )
}
