import { DefaultCellWrapper } from './default-cell-wrapper'
import Proptypes from 'prop-types'
import { NOT_APPLICABLE } from '/src/constants/common'

export const CellRenderer = ({ column, row, tooltip }) => {
  // Get the value from the row
  const value = row[column.key]
  // row: the whole row data, value: the field value, column: the column name
  const parsedValue = column.parser
    ? column.parser({
        row,
        value,
        column
      })
    : value || (value === 0 ? 0 : NOT_APPLICABLE)

  if (column.wrapper) {
    return (
      <DefaultCellWrapper
        width={column.minWidth}
        tooltip={tooltip}
        value={parsedValue}
      >
        <column.wrapper value={value} column={column}>
          {parsedValue}
        </column.wrapper>
      </DefaultCellWrapper>
    )
  }

  return (
    <DefaultCellWrapper
      width={column.minWidth}
      tooltip={tooltip}
      value={parsedValue}
    >
      {parsedValue}
    </DefaultCellWrapper>
  )
}

CellRenderer.propTypes = {
  column: Proptypes.object,
  row: Proptypes.object,
  tooltip: Proptypes.bool
}
