import PropTypes from 'prop-types'
import { Box, Card, Typography, Unstable_Grid2 as Grid } from '@mui/material'
import { alpha, useTheme } from '@mui/material/styles'
import { Chart } from '/src/components/chart'

const categories = [
  {
    id: 'excellent',
    title: 'Completed'
  },
  {
    id: 'good',
    title: 'Partially Completed'
  },
  {
    id: 'bad',
    title: 'Not Started'
  }
]

const createChartOptions = (theme, color) => {
  return {
    chart: {
      background: 'transparent'
    },
    colors: [color],
    labels: [''],
    plotOptions: {
      radialBar: {
        dataLabels: {
          value: {
            fontSize: '20px',
            fontWeight: 600,
            offsetY: -10
          }
        },
        hollow: {
          size: '50%'
        },
        track: {
          background: alpha(color, 0.12)
        }
      }
    },
    states: {
      active: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    theme: {
      mode: theme.palette.mode
    }
  }
}

export const CompletionPercentages = (props) => {
  const { excellent, good, bad } = props
  const theme = useTheme()

  const total = excellent + good + bad

  const colorsMap = {
    excellent: theme.palette.success.main,
    good: theme.palette.warning.main,
    bad: theme.palette.error.main
  }

  return (
    <Card>
      <Box sx={{ p: 1 }}>
        <Grid container spacing={3}>
          {categories.map((category) => {
            const color = colorsMap[category.id]
            const chartOptions = createChartOptions(theme, color)
            const amount = props[category.id] || 0
            const progress = Math.round((amount / total) * 100)
            const chartSeries = [progress]

            return (
              <Grid key={category.title} xs={12} md={4}>
                <Box
                  sx={{
                    alignItems: 'center',
                    backgroundColor: alpha(color, 0.04),
                    borderRadius: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2
                  }}
                >
                  <Typography sx={{ color }} variant="h6">
                    {category.title}
                  </Typography>
                  <Chart
                    height={200}
                    options={chartOptions}
                    series={chartSeries}
                    type="radialBar"
                  />
                  <Typography color="text.secondary" variant="body2">
                    {amount} employees
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Card>
  )
}

CompletionPercentages.propTypes = {
  bad: PropTypes.number.isRequired,
  excellent: PropTypes.number.isRequired,
  good: PropTypes.number.isRequired
}
