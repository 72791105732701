import React from 'react'
import { Button, ButtonProps } from '@mui/material'

const PrimaryButton: React.FC<ButtonProps> = ({ children, ...otherProps }) => (
  <Button variant="contained" {...otherProps}>
    {children}
  </Button>
)

export default PrimaryButton
