import { Card, CardContent, ListItem } from '@mui/material'
import { useState } from 'react'
import BehaviorForm from './behavior-form'
import DisplayBehavior from './display-behavior'

export const BehaviorItem = ({
  index,
  behavior,
  evaluationStatus,
  handleAddBehavior,
  handleUpdateBehavior,
  handleDeleteBehavior
}) => {
  const [editMode, setEditMode] = useState(false)

  const toggleEditMode = () => setEditMode((prev) => !prev)

  return (
    <Card
      sx={{
        marginBottom: 3
      }}
    >
      <CardContent>
        <ListItem>
          {editMode ? (
            <BehaviorForm
              index={index}
              method="UPDATE"
              behavior={behavior}
              evaluationStatus={evaluationStatus}
              toggleEditMode={toggleEditMode}
              handleAddBehavior={handleAddBehavior}
              handleUpdateBehavior={handleUpdateBehavior}
            />
          ) : (
            <DisplayBehavior
              index={index}
              behavior={behavior}
              toggleEditMode={toggleEditMode}
              handleDeleteBehavior={handleDeleteBehavior}
            />
          )}
        </ListItem>
      </CardContent>
    </Card>
  )
}
