export function createObjectFromKeys({ object, keys }) {
  // Check if inputs are valid
  if (typeof object !== 'object' || !Array.isArray(keys)) {
    console.error('Invalid input types')
    return {}
  }

  return keys.reduce((filteredObject, key) => {
    if (key in object && object[key] !== undefined) {
      filteredObject[key] = object[key]
    }
    return filteredObject
  }, {})
}
