const EQUALS_OPERATION_LABEL = 'Equals'
const NOT_EQUALS_OPERATION_LABEL = 'Not Equals'
const GREATER_THAN_OPERATION_LABEL = 'Greater Than'
const LESS_THAN_OPERATION_LABEL = 'Less Than'
const GREATER_THAN_OR_EQUAL_TO_OPERATION_LABEL = 'Greater Than or Equal To'
const LESS_THAN_OR_EQUAL_TO_OPERATION_LABEL = 'Less Than or Equal To'

const filterModesForNumericFields = [
  {
    label: GREATER_THAN_OPERATION_LABEL,
    sign: '>',
    name: 'greater'
  },
  // {
  //   label: GREATER_THAN_OR_EQUAL_TO_OPERATION_LABEL,
  //   sign: '≥',
  //   name: 'greater-or-equal'
  // },
  {
    label: LESS_THAN_OPERATION_LABEL,
    sign: '<',
    name: 'less'
  },
  // {
  //   label: LESS_THAN_OR_EQUAL_TO_OPERATION_LABEL,
  //   sign: '≤',
  //   name: 'less-or-equal'
  // },
  {
    label: EQUALS_OPERATION_LABEL,
    sign: '=',
    name: 'equals'
  },
  {
    label: NOT_EQUALS_OPERATION_LABEL,
    sign: '!=',
    name: 'not-equals'
  }
]

export {
  filterModesForNumericFields,
  EQUALS_OPERATION_LABEL,
  NOT_EQUALS_OPERATION_LABEL,
  GREATER_THAN_OPERATION_LABEL,
  LESS_THAN_OPERATION_LABEL,
  GREATER_THAN_OR_EQUAL_TO_OPERATION_LABEL,
  LESS_THAN_OR_EQUAL_TO_OPERATION_LABEL
}
