import React, { useCallback } from 'react'
import { Stack, Typography, Chip, Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'

const MultiselectChips = (props) => {
  const { defaultValues, label, values, type, setFilters } = props

  const handleSelect = useCallback(
    (value) => {
      setFilters((prev) => {
        // if value is already selected, remove it
        if (prev[type].values.includes(value)) {
          return {
            ...prev,
            [type]: {
              ...prev[type],
              values: prev[type].values.filter((val) => val !== value)
            }
          }
        }

        // otherwise add it
        return {
          ...prev,
          [type]: {
            ...prev[type],
            values: [...prev[type].values, value]
          }
        }
      })
    },
    [type, setFilters]
  )

  return (
    <Grid item container xs={12}>
      <Stack spacing={2}>
        <Typography color="inherit" variant="subtitle2">
          {label}
        </Typography>
        <Stack
          direction="row"
          rowGap={2}
          columnGap={2}
          sx={{
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'start'
          }}
        >
          {defaultValues.map((value, index) => (
            <Chip
              key={type + index}
              variant={values.includes(value) ? 'filled' : 'outlined'}
              onDelete={() => handleSelect(value)}
              onClick={() => handleSelect(value)}
              deleteIcon={
                values.includes(value) ? (
                  <CloseIcon
                    sx={{
                      width: 18,
                      height: 18
                    }}
                  />
                ) : (
                  <AddIcon />
                )
              }
              color={values.includes(value) ? 'primary' : 'default'}
              label={
                <Typography fontSize={13} variant="body2">
                  {value}
                </Typography>
              }
              sx={{
                'py': 2.5,
                'px': 0.5,
                'borderRadius': '6px',
                '&:hover': {
                  backgroundColor: 'primary.alpha75'
                }
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Grid>
  )
}

export default MultiselectChips
