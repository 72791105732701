import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'

const Title = (props) => {
  const theme = useTheme()
  const { text, textSize, sx, ...otherProps } = props
  return (
    <Box sx={sx}>
      <Typography
        onChange={props.onChange}
        sx={{
          fontWeight: theme.typography.fontWeightBold,
          fontSize: textSize,
          color: theme.palette.text.primary,
          minWidth: 'min-content'
        }}
        {...otherProps}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default Title
