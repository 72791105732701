import { Navigate } from 'react-router'
import { useUser } from '/src/hooks/use-user'
import { ROLES } from '/functions/shared/constants/roles'
import { paths } from '/src/constants/paths'

const Page = () => {
  let to
  const { user } = useUser()

  // if user is admin or manager, redirect to admin/manager dashboard, else redirect to my reviews page
  if (user.userRoles.includes(ROLES.ADMIN)) {
    to = paths.dashboard.admin.index
  } else {
    to = paths.dashboard.reviews.index
  }

  return <Navigate to={to} />
}

export default Page
