import { Stack } from '@mui/material'
import React from 'react'

const ScoringTableBody = ({ children, ...otherProps }) => {
  return (
    <Stack direction="row" {...otherProps}>
      {children}
    </Stack>
  )
}

export default ScoringTableBody
