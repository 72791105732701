import {
  Box,
  Divider,
  IconButton,
  Link,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import React from 'react'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { paths } from '/src/constants/paths'
import { RouterLink } from '/src/components/router-link'
import { useParams } from 'react-router'
import { Save } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PropTypes from 'prop-types'
import { LightTooltip } from '/src/components/light-tooltip'
import TextButton from '/src/components/buttons/text-button'
import ReactMarkdown from 'react-markdown'
import CustomCollapse from '/src/components/collapse'

const CustomBox = ({ children, sx, ...otherProps }) => (
  <Box
    sx={{
      backgroundColor: (theme) => `${theme.palette.primary.main}10`,
      borderRadius: '8px',
      paddingY: 2,
      paddingLeft: 3,
      paddingRight: 5,
      width: 'fit-content',
      ...sx
    }}
    {...otherProps}
  >
    {children}
  </Box>
)

const QuestionCardHeader = ({
  commentMin,
  thereIsLastRating,
  disabled,
  allConditions,
  currentQuestion
}) => {
  const { evaluationId } = useParams()

  const memoisedTitle = React.useMemo(
    () => currentQuestion.title,
    [currentQuestion.title]
  )

  return (
    <Stack spacing={2} mb={3}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h4" data-testid="question-title">
            {`${currentQuestion.title}`}
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <TextButton
            data-gaid="save-and-leave-question"
            component={RouterLink}
            disabled={disabled}
            href={paths.dashboard.reviews.index}
            data-testid="leave-button"
            children="Save & Leave"
            startIcon={<Save />}
          />
          <Divider orientation="vertical" />
          <LightTooltip title="Change selected colleagues.">
            <IconButton
              data-gaid="reselect-colleagues"
              component={RouterLink}
              data-testid="reselect-colleagues"
              href={paths.dashboard.reviews.selection(evaluationId)}
              state={{
                isRoutingProper: true,
                isReSelection: true
              }}
              sx={{ borderRadius: 1 }}
              color="primary"
            >
              <GroupAddIcon />
            </IconButton>
          </LightTooltip>
        </Stack>
      </Stack>
      <Stack
        direction={currentQuestion.description ? 'row' : 'column'}
        spacing={2}
      >
        {/* Colored box with question description and helper link */}
        {currentQuestion.helperLink || currentQuestion.description ? (
          <CustomBox
            sx={{
              flexBasis: '60%',
              ...(currentQuestion.helperLink && // override the default color and padding
                !currentQuestion.description && {
                  p: 0,
                  backgroundColor: 'transparent'
                })
            }}
          >
            {currentQuestion.description ? (
              <CustomCollapse
                contentKey={memoisedTitle}
                collapsedSize={180}
                dataGaid="question-description-show-more"
                expandMoreLabel="Show more"
                expandLessLabel="Show less"
              >
                <ReactMarkdown>{currentQuestion.description}</ReactMarkdown>
              </CustomCollapse>
            ) : null}
            {currentQuestion.helperLink ? (
              <Stack>
                <Link
                  data-gaid="question-helper-link"
                  target="_blank"
                  href={
                    currentQuestion.helperLink.includes('http')
                      ? currentQuestion.helperLink
                      : 'https://' + currentQuestion.helperLink
                  }
                  fontWeight="bold"
                  fontSize={14}
                  data-testid="external-link"
                  marginTop={currentQuestion.description && 2}
                >
                  Click here to learn more about this behavior.
                </Link>
              </Stack>
            ) : null}
          </CustomBox>
        ) : null}
        <CustomBox
          sx={{
            flexBasis: '40%',
            width: '100%',
            backgroundColor: '#27283308' // override the default color
          }}
        >
          <Stack spacing={0.5} data-testid="requirements">
            {allConditions.map((condition, index) => {
              return (
                <Stack
                  direction="row"
                  key={index}
                  spacing={1}
                  data-testid="requirements-item"
                >
                  <SvgIcon
                    fontSize="small"
                    sx={{
                      color: condition.passed ? 'success.main' : '#D9D9D9'
                    }}
                  >
                    <CheckCircleIcon />
                  </SvgIcon>
                  <Typography
                    fontSize={14}
                    data-testid="comment-warning"
                    color={condition.passed ? 'success.main' : '#6B6C7E'}
                  >
                    {condition.message}
                  </Typography>
                </Stack>
              )
            })}
          </Stack>
        </CustomBox>
      </Stack>
      {/* Question directions */}
      <Stack paddingTop={2} paddingBottom={2}>
        <Typography
          variant="h6"
          fontWeight="bold"
          data-testid="question"
          marginBottom={2}
        >
          How well do you think these colleagues display this behavior?
        </Typography>
        <Typography variant="h7" fontWeight={400} data-testid="comment-warning">
          Use the sliders to indicate how well the behavior shows up for each
          colleague, anonymously.
          {commentMin !== null
            ? ' You can use the `Give Feedback` button to provide more specific feedback or examples.'
            : null}
          {thereIsLastRating
            ? ' If you reviewed a colleague the last time, you’ll see where you placed them on the slider below.'
            : null}
        </Typography>
      </Stack>
    </Stack>
  )
}

QuestionCardHeader.propTypes = {
  data: PropTypes.shape({
    currentQuestion: PropTypes.object.isRequired,
    currentQuestionIndex: PropTypes.number.isRequired,
    allConditions: PropTypes.array.isRequired,
    disabled: PropTypes.bool
  })
}

export default QuestionCardHeader
