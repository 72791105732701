import { Suspense, lazy } from 'react'
import { AuthGuard } from '/src/guards/auth-guard'
import { DemoGuard } from '/src/guards/demo-guard'
import { AnimatedLogo } from '/src/components/animated-logo'
import LoadingOverlay from '/src/components/loading-overlay'

// Firebase
const FirebaseDemoSetupPage = lazy(() => import('/src/pages/demo/DemoSetup'))

export const demoRoutes = [
  {
    path: '/demo-account',
    element: (
      <AuthGuard>
        <DemoGuard>
          <Suspense
            fallback={
              <LoadingOverlay mx={10} my={4} height={50}>
                <AnimatedLogo width={60} />
              </LoadingOverlay>
            }
          >
            <FirebaseDemoSetupPage />
          </Suspense>
        </DemoGuard>
      </AuthGuard>
    )
  }
]
