import React, { useCallback, useEffect, useMemo } from 'react'
import { Grid, Slider, Typography } from '@mui/material'

const RangeSlider = (props) => {
  const { defaultValues, label, values, type, setFilters, sign, minmax } = props

  // Extract min and max values from values array as array to pass to Slider
  const defaultMinMax = useMemo(
    () =>
      minmax
        ? [minmax[0], minmax[1]]
        : [Math.min(...defaultValues), Math.max(...defaultValues)],
    [defaultValues, minmax]
  )

  // Set slider value to default min and max
  const [sliderValue, setSliderValue] = React.useState(
    values.length ? values : defaultMinMax
  )

  const markLabel = useMemo(() => (sign ? `${sign}` : ''), [sign])

  // If minmax is passed, use it, otherwise use defaultMinMax
  let customMin, customMax
  customMin = useMemo(
    () => (minmax ? minmax[0] : defaultMinMax[0]),
    [minmax, defaultMinMax]
  )
  customMax = useMemo(
    () => (minmax ? minmax[1] : defaultMinMax[1]),
    [minmax, defaultMinMax]
  )

  // Divide equally to 3 marks
  const marks = useMemo(
    () => [
      {
        value: customMin,
        label: `${markLabel}${customMin}`
      },
      {
        value: Math.round((customMin + customMax) / 2),
        label: `${markLabel}${Math.round((customMin + customMax) / 2)}`
      },
      {
        value: customMax,
        label: `${markLabel}${customMax}`
      }
    ],
    [markLabel, customMin, customMax]
  )

  // This function will be used when user slides the slider (changes the insider value)
  const handleChange = useCallback(
    (event, newValue) => {
      setSliderValue(newValue)
    },
    [setSliderValue]
  )

  // This function will be used when user stops sliding or on mouse-up event
  const handleChangeCommitted = useCallback(
    (event, newValue) => {
      // If new value is same as default min and max, set it to empty array
      const sameWithInitial =
        JSON.stringify(newValue) ===
        JSON.stringify([defaultMinMax[0], defaultMinMax[1]])
      setFilters((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          values: sameWithInitial ? [] : newValue
        }
      }))
    },
    [type, setFilters, defaultMinMax]
  )

  // Format value label with sign (currency, percentage, etc.)
  const handleValueLabelFormat = useCallback(
    (value) => {
      return `${markLabel}${value}`
    },
    [markLabel]
  )

  // If we have no selected filter for this component and sliderValue is not defaultMinMax, set it to defaultMinMax to reset
  useEffect(() => {
    if (
      !values.length &&
      JSON.stringify(sliderValue) !== JSON.stringify(defaultMinMax)
    ) {
      setSliderValue(defaultMinMax)
    }

    // eslint-disable-next-line
  }, [values])

  return (
    <Grid item container xs={12} alignItems="start">
      <Grid item xs={3} pr={4}>
        <Typography color="inherit" variant="subtitle2">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Slider
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          min={customMin}
          max={customMax}
          valueLabelFormat={handleValueLabelFormat}
          marks={marks}
          value={sliderValue}
          valueLabelDisplay="auto"
        />
      </Grid>
    </Grid>
  )
}

export default RangeSlider
