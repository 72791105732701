import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useTheme } from '@mui/material'
import {
  MDXEditor,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  ListsToggle,
  Separator,
  toolbarPlugin,
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  thematicBreakPlugin,
  MDXEditorMethods
} from '@mdxeditor/editor'
import { GlobalStyles, Paper, styled, lighten, PaperProps } from '@mui/material'

const EditorWrapper = styled(Paper)(({ theme }) => ({
  border: `1px solid #E5E7EB`,
  borderRadius: theme.shape.borderRadius,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  overflow: 'auto',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? lighten(theme.palette.background.paper, 0.1)
      : theme.palette.background.paper
}))

const globalStyles = (
  <GlobalStyles
    styles={{
      '.mdxeditor-popup-container': {
        zIndex: 2000
      },
      '._toolbarRoot_yms4a_160': {
        top: '-1px'
      }
    }}
  />
)

interface EditorProps {
  defaultMarkdown?: string
  placeholder?: string
  onChange?: (markdown: string) => void
  editorRef?: React.Ref<MDXEditorMethods> | null
  wrapperProps?: PaperProps
  debounceTimeout?: number
  handleDebounce?: (value: string) => void
}

const Editor = forwardRef<MDXEditorMethods, EditorProps>(
  (
    {
      defaultMarkdown = '',
      placeholder = 'Type here...',
      onChange = () => {},
      wrapperProps,
      debounceTimeout,
      handleDebounce
    },
    ref
  ) => {
    const theme: any = useTheme()
    const [markdown, setMarkdown] = useState(defaultMarkdown)
    const timerRef = useRef<number | null>(null)

    useEffect(() => {
      setMarkdown(defaultMarkdown)
    }, [defaultMarkdown])

    const handleChange = (newMarkdown: string) => {
      setMarkdown(newMarkdown)

      if (debounceTimeout && handleDebounce) {
        clearTimeout(timerRef.current!)
        timerRef.current = window.setTimeout(() => {
          handleDebounce(newMarkdown)
        }, debounceTimeout)
      } else {
        onChange(newMarkdown)
      }
    }

    return (
      <EditorWrapper {...wrapperProps} data-testid="mdx-editor-wrapper">
        {globalStyles}
        <MDXEditor
          ref={ref}
          markdown={markdown}
          placeholder={placeholder}
          onChange={handleChange}
          plugins={[
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  <UndoRedo />
                  <Separator />
                  <BoldItalicUnderlineToggles options={['Bold', 'Italic']} />
                  <Separator />
                  <ListsToggle options={['bullet', 'number']} />
                  <Separator />
                  <BlockTypeSelect />
                </>
              )
            }),
            headingsPlugin({
              allowedHeadingLevels: [2, 3]
            }),
            listsPlugin(),
            markdownShortcutPlugin(),
            thematicBreakPlugin()
          ]}
          {...(theme?.palette?.mode === 'dark'
            ? { className: 'dark-theme' }
            : {})}
        />
      </EditorWrapper>
    )
  }
)

export default Editor
