import makeRequest from './index'

const requestsProvider = {
  sendRequestToManager: async (data) => {
    const result = await makeRequest({ data, endpoint: 'requestToManager' })

    return result.data
  },
  handleEmployeeRequest: async (data) => {
    const result = await makeRequest({
      data,
      endpoint: 'handleEmployeeRequest'
    })

    return result.data
  },
  getEmployeeRequests: async (data) => {
    const result = await makeRequest({
      data,
      endpoint: 'getEmployeeRequests'
    })

    return result.data
  }
}

export default requestsProvider
