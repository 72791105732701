import { EVALUATION_STATES, REVIEW_STATES } from '/src/constants/statuses'

export const actionTypes = {
  DELETE: 'delete',
  COMPLETE: 'complete',
  START: 'start',
  INITIAL_VALUES: 'initialValues'
}

export const dialogContents = {
  [actionTypes.INITIAL_VALUES]: {
    evaluationId: '',
    type: '',
    title: '',
    content: '',
    firstButton: '',
    secondButton: ''
  },
  [actionTypes.DELETE]: {
    type: actionTypes.DELETE,
    title: 'Delete Review',
    content:
      'Are you sure you want to delete this review? This action is irreversible.',
    firstButton: 'Cancel',
    secondButton: 'Delete'
  },
  [actionTypes.COMPLETE]: {
    type: actionTypes.COMPLETE,
    title: 'Complete Review',
    content:
      'Are you sure you want to complete this review? This action is irreversible and will end this review process for all participants.',
    firstButton: 'Cancel',
    secondButton: 'Complete'
  },
  [actionTypes.START]: {
    type: actionTypes.START,
    title: 'Launch Review',
    content:
      'Are you ready to launch this review? This action will initialize this review process for all participants and send them a notification.',
    firstButton: 'Cancel',
    secondButton: 'Launch'
  }
}

export const evaluationStatusesForUI = {
  [EVALUATION_STATES.COMPLETED]: 'COMPLETED',
  [EVALUATION_STATES.TEMPLATE]: 'TEMPLATE',
  [EVALUATION_STATES.ONGOING]: 'ONGOING',
  [EVALUATION_STATES.AWAITING_RESULTS]: 'AWAITING RESULTS'
}

export const evaluationStatusesBgColorMap = {
  [EVALUATION_STATES.COMPLETED]: 'success',
  [EVALUATION_STATES.TEMPLATE]: 'info',
  [EVALUATION_STATES.ONGOING]: 'warning',
  [EVALUATION_STATES.AWAITING_RESULTS]: 'warning'
}

export const reviewStatusesBgColorMap = {
  [REVIEW_STATES.COMPLETED]: 'success',
  [REVIEW_STATES.AWAITING_SELECTION]: 'warning',
  [REVIEW_STATES.INCOMPLETE]: 'warning',
  [REVIEW_STATES.SUBMITTED]: 'info',
  [REVIEW_STATES.AWAITING_RESULTS]: 'info',
  [REVIEW_STATES.MISSED]: 'error'
}

export const reviewStatusesForUI = {
  [REVIEW_STATES.AWAITING_SELECTION]: 'Ready',
  [REVIEW_STATES.INCOMPLETE]: 'Started',
  [REVIEW_STATES.AWAITING_RESULTS]: 'Awaiting Results',
  [REVIEW_STATES.SUBMITTED]: 'Submitted',
  [REVIEW_STATES.COMPLETED]: 'Completed',
  [REVIEW_STATES.MISSED]: 'Missed'
}
