import { Chart } from '/src/components/chart'
import { memo, useMemo } from 'react'
import { ChartTypes } from '/src/constants/chart-types'
import colorBetween from 'color-between/lib/color-between'
import { useTheme } from '@mui/material/styles'
import { Box, Grid, Stack, Typography, SxProps } from '@mui/material'
import { ApexOptions } from 'apexcharts'

const scatterGridBackgroundData = [
  {
    width: '30%',
    height: '30%',
    text: "Everyone's Friend",
    lightColor: '#ffe22719',
    darkColor: '#ffe22733'
  },
  {
    text: 'Solid Contributor',
    lightColor: '#96f19a19',
    darkColor: '#96f19a32',
    width: '40%',
    height: '30%'
  },
  {
    text: 'Cultural Ambassador and Star Performer',
    lightColor: '#27ff3216',
    darkColor: '#27ff322a',
    width: '30%',
    height: '30%'
  },
  {
    text: 'Invisible Colleague',
    lightColor: '#f794561b',
    darkColor: '#f794562d',
    width: '30%',
    height: '40%'
  },
  {
    text: 'Average Teammate',
    lightColor: '#d0d0c818',
    darkColor: '#d0d0c833',
    width: '40%',
    height: '40%'
  },
  {
    text: 'Results Over Form',
    lightColor: '#96f19a19',
    darkColor: '#96f19a32',
    width: '30%',
    height: '40%'
  },
  {
    text: 'Mismatch',
    lightColor: '#ff272713',
    darkColor: '#ff27272b',
    width: '30%',
    height: '30%'
  },
  {
    text: 'Inconsistent Performer',
    lightColor: '#f794561b',
    darkColor: '#f794562d',
    width: '40%',
    height: '30%'
  },
  {
    text: 'Toxic High Performer',
    lightColor: '#ffe22719',
    darkColor: '#ffe22733',
    width: '30%',
    height: '30%'
  }
]

function getColor(x: number, y: number) {
  // Compute the average normalized value
  let avgNorm = (x / 100 + y / 100) / 2
  return `${colorBetween('#f44336', '#4caf50', avgNorm, 'hex')}`
}

type Employee = {
  name: string
  competencyScore: number
  valueScore: number
  uid: string
  division: string
  jobLevel: number
  manager: string
  seniority: string
  team: string
  [key: string]: any // TODO: Fix this
}

interface ScatterGridChartProps extends ApexOptions {
  employees: Employee[]
  levelTags?: string[]
  axisTags?: string[]
  axisBehaviors?: string[][]
  backgroundLabels?: string[] | null
  handleClick?: (event: any, employee: any) => void
  selectedFieldWithColors?: {
    field: string
    colorsMap: Map<string, string>
  }
  coloringOptionsLabels?: Record<string, string>
  sx?: SxProps
  showNames?: boolean
  scatterGridBackground?: {
    top: number
    left: number
    width: number
    height: number
    right: number
    bottom: number
  }
}

const ScatterGridChart = ({
  employees,
  levelTags,
  axisTags = ['Value Percentile', 'Competency Percentile'],
  axisBehaviors,
  backgroundLabels = null,
  sx = {},
  handleClick,
  selectedFieldWithColors,
  coloringOptionsLabels,
  scatterGridBackground,
  ...otherOptions
}: ScatterGridChartProps) => {
  const theme = useTheme()
  // Create series, one for each employee
  const series = employees.map((e) => {
    return {
      name: e.name,
      uid: e.uid,
      type: ChartTypes.SCATTER,
      category: selectedFieldWithColors && selectedFieldWithColors.field,
      data: [[e.xAxis, e.yAxis]]
    }
  })

  const colors = employees.map((e) => {
    if (selectedFieldWithColors && e[selectedFieldWithColors?.field]) {
      return selectedFieldWithColors.colorsMap.get(
        e[selectedFieldWithColors.field]
      )
    }

    return getColor(e.xAxis, e.yAxis)
  })

  const options: ApexOptions = useMemo(() => {
    return {
      chart: {
        background: 'transparent',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        ...(handleClick
          ? {
              events: {
                dataPointMouseEnter: (event) => {
                  event.target.style.cursor = 'pointer'
                },
                dataPointSelection: (
                  event,
                  chartContext,
                  { seriesIndex, w }
                ) => {
                  // We need to get the index of the selected employee and the data of it to show the report
                  const employee = employees[seriesIndex]
                  handleClick(event, employee)
                }
              }
            }
          : {})
      },
      dataLabels: {
        enabled: otherOptions.showNames || false,
        formatter: function (_, opt) {
          return employees[opt.seriesIndex].name
        },
        offsetY: -7,
        style: {
          fontSize: '11px'
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        marker: {
          show: true
        },
        custom: function ({ seriesIndex }) {
          return (
            '<div class="arrow_box">' +
            '<span><b>' +
            employees[seriesIndex].name +
            '</b></span>' +
            '<br>' +
            `${employees[seriesIndex].yAxisScoreName} ${employees[seriesIndex].yAxisScoreType}: ` +
            employees[seriesIndex].yAxis +
            '<br>' +
            `${employees[seriesIndex].xAxisScoreName} ${employees[seriesIndex].xAxisScoreType}: ` +
            employees[seriesIndex].xAxis +
            (selectedFieldWithColors &&
            coloringOptionsLabels &&
            coloringOptionsLabels[selectedFieldWithColors.field]
              ? '<br>' +
                coloringOptionsLabels[selectedFieldWithColors.field] +
                ': ' +
                '<span>' +
                (employees[seriesIndex] as any)[selectedFieldWithColors.field] +
                '</span>'
              : '') +
            '</div>'
          )
        }
      },
      grid: {
        borderColor: '#C0C0C0',
        strokeDashArray: 1,
        position: 'back',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      markers: {
        size: 6,
        strokeColors: '#bdbdbd',
        strokeWidth: 1
      },
      colors,
      xaxis: {
        tickAmount: 10,
        min: 0,
        max: 100,
        // make sure labels are rounded
        labels: {
          style: {
            colors: theme.palette.mode === 'dark' ? '#fff' : '#000'
          },
          formatter: function (val) {
            return Math.round(Number(val)).toString()
          }
        }
      },
      yaxis: {
        tickAmount: 10,
        min: 0,
        max: 100,
        // make sure labels are rounded
        labels: {
          style: {
            colors: theme.palette.mode === 'dark' ? '#fff' : '#000'
          },
          formatter: function (val) {
            return Math.round(Number(val)).toString()
          }
        }
      }
    }
  }, [
    employees,
    selectedFieldWithColors,
    coloringOptionsLabels,
    colors,
    otherOptions.showNames,
    theme.palette.mode,
    handleClick
  ])

  // Insert the background labels
  const updatedScatterGridBackgroundData = useMemo(() => {
    if (backgroundLabels) {
      return scatterGridBackgroundData.map((area, index) => {
        return {
          ...area,
          text: backgroundLabels[index]
        }
      })
    } else {
      return scatterGridBackgroundData
    }
  }, [backgroundLabels])

  // Merge custom options with default options, This line is for preventing the initial animation disappearing
  const mergedOptions = { ...options, ...otherOptions }

  return (
    <Stack alignItems="center" sx={sx}>
      <Box>
        <Stack direction="row">
          <Stack direction="row" spacing={3} mb={4} mt={2}>
            {axisTags ? (
              <Stack justifyContent="center" alignItems="center">
                <Typography
                  variant="subtitle1"
                  width={300}
                  position="absolute"
                  columnGap={1}
                  textAlign="center"
                  sx={{
                    transform: 'rotate(-90deg)'
                  }}
                >
                  {axisTags[1]}
                </Typography>
              </Stack>
            ) : null}
            {axisBehaviors ? (
              <Stack
                flexDirection="column-reverse"
                justifyContent="space-evenly"
                alignItems="center"
                flexGrow={1}
                width={5}
              >
                <Typography
                  variant="caption"
                  textAlign="center"
                  width="max-content"
                  sx={{
                    transform: 'rotate(-90deg)',
                    fontSize:
                      14 - Math.round(axisBehaviors[1].join(' - ').length / 25)
                  }}
                >
                  {axisBehaviors[1]
                    .map((axisBehavior) => axisBehavior)
                    .join(' - ')}
                </Typography>
              </Stack>
            ) : null}
            {levelTags ? (
              <Stack
                justifyContent="space-evenly"
                alignItems="center"
                flexDirection="column-reverse"
              >
                {levelTags.map((levelTag, index) => (
                  <Stack
                    key={index}
                    alignItems="center"
                    justifyContent="center"
                    height={`${(100 - 10) / levelTags.length}%`}
                    width={35}
                    borderRadius={1}
                    sx={{
                      backgroundColor:
                        theme.palette.mode === 'light'
                          ? 'ghostwhite'
                          : theme.palette.grey[800]
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      textAlign="center"
                      width="max-content"
                      sx={{
                        transform: 'rotate(-90deg)'
                      }}
                    >
                      {levelTag}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            ) : null}
          </Stack>
          <div
            style={{
              position: 'relative'
            }}
          >
            <Chart
              height={550}
              width={550}
              options={mergedOptions}
              series={series}
              type={ChartTypes.SCATTER}
            />
            <Grid
              position="absolute"
              container
              width={485}
              height={482}
              left={45}
              top={30}
              textAlign="center"
              zIndex={-1}
              sx={{
                float: 'inline-end'
              }}
              {...scatterGridBackground}
            >
              {updatedScatterGridBackgroundData.map((area) => (
                <Grid
                  item
                  key={area.text}
                  width={area.width}
                  height={area.height}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor:
                      theme.palette.mode === 'light'
                        ? area.lightColor
                        : area.darkColor
                  }}
                >
                  <Typography
                    variant="body1"
                    color="black"
                    sx={{
                      color:
                        theme.palette.mode === 'light'
                          ? theme.palette.text.primary // Corrected this line
                          : theme.palette.info.contrastText,
                      opacity: 0.7
                    }}
                  >
                    {area.text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        </Stack>
        <Stack spacing={2} ml={15} mr={3}>
          {levelTags ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              {levelTags.map((levelTag, index) => (
                <Stack
                  key={index}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={1}
                  py={1}
                  width={`${(100 - 10) / levelTags.length}%`}
                  sx={{
                    backgroundColor:
                      theme.palette.mode === 'light'
                        ? 'ghostwhite'
                        : theme.palette.grey[800]
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    textAlign="center"
                    height={20}
                    width="max-content"
                  >
                    {levelTag}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          ) : null}
          {axisBehaviors ? (
            <Stack
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              flexGrow={1}
            >
              <Typography
                variant="caption"
                textAlign="center"
                width="max-content"
                sx={{
                  fontSize:
                    14 - Math.round(axisBehaviors[0].join(' - ').length / 25)
                }}
              >
                {axisBehaviors[0]
                  .map((axisBehavior) => axisBehavior)
                  .join(' - ')}
              </Typography>
            </Stack>
          ) : null}
          {axisTags ? (
            <Stack justifyContent="center" alignItems="center">
              <Typography
                variant="subtitle1"
                position="absolute"
                alignItems="center"
                columnGap={1}
                noWrap={true}
              >
                {axisTags[0]}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </Box>
    </Stack>
  )
}

export default memo(ScatterGridChart)
