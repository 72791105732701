import { array, boolean, number, object, string } from 'yup'

export const feedbacksSchema = array().of(
  object()
    .shape({
      comment: string().nullable().defined('Comment field is required.'),
      fullName: string().required('Full name field is required.'),
      isIgnored: boolean().required('Is ignored field is required.'),
      score: number().required('Score field is required.'),
      userId: string().required('User ID field is required.')
    })
    .noUnknown()
)

export const responsesSchema = array().of(
  object().shape({
    feedbacks: feedbacksSchema.required('Feedbacks field is required.'),
    questionId: string().required('Question ID field is required.'),
    title: string().required('Title field is required.'),
    selfScore: number().required('Self score field is required.'),
    selfComment: string().defined('Self comment field is required.')
  })
)
