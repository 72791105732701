export const FAQ_IDS = {
  EMPLOYEE_REVIEWS: 'EMPLOYEE_REVIEWS',
  EMPLOYEE_RESULTS: 'EMPLOYEE_RESULTS',
  EMPLOYEE_REQUEST: 'EMPLOYEE_REQUEST',
  SELECTION: 'SELECTION',
  QUESTIONS: 'QUESTIONS',
  SUBMIT: 'SUBMIT',
  ADMIN_OVERVIEW: 'ADMIN_OVERVIEW',
  ADMIN_STATS: 'ADMIN_STATS',
  MANAGE_ORGANIZATION: 'MANAGE_ORGANIZATION',
  CREATE_NEW_EVALUATION: 'CREATE_NEW_EVALUATION',
  UPDATE_EVALUATION: 'UPDATE_EVALUATION',
  MANAGER_OVERVIEW: 'MANAGER_OVERVIEW',
  MANAGER_REQUESTS: 'MANAGER_REQUESTS',
  MANAGER_STATS: 'MANAGER_STATS'
}
