import { tableTypes } from '/src/components/users-table/constants/table-types'

const props = {
  [tableTypes.ADMIN_DASHBOARD]: {
    checkbox: false,
    updateOnDataChange: true,
    dialog: {},
    csv: true,
    exportAllData: true,
    blur: false,
    handleOpenDrawerFilters: () => {},
    drawerFilters: {},
    blurText: 'Pending...',
    fixedBodyCell: true,
    gridView: true,
    blurComponent: null,
    search: true
  },
  [tableTypes.MANAGER_DASHBOARD]: {
    checkbox: false,
    updateOnDataChange: true,
    dialog: {},
    csv: true,
    exportAllData: true,
    blur: false,
    handleOpenDrawerFilters: () => {},
    drawerFilters: {},
    blurText: 'Pending...',
    fixedBodyCell: true,
    gridView: false,
    blurComponent: null,
    search: true
  },
  [tableTypes.TEST_TABLE]: {
    checkbox: false,
    csv: true,
    blur: false,
    blurText: 'Pending...',
    fixedBodyCell: true,
    blurComponent: null,
    search: true
  },
  [tableTypes.PROGRESS]: {
    checkbox: false,
    csv: false,
    search: true
  },
  [tableTypes.SELECTION]: {
    checkbox: true,
    csv: false,
    onSelect: () => {},
    onSelectAll: () => {},
    selected: [],
    search: true
  }
}

export { props }
