import ReactGA from 'react-ga4'

export const sendEventToGoogleAnalytics = (
  name: string,
  {
    action,
    category,
    ...otherArguments
  }: {
    action: string
    category: string
    label?: string
    value?: number
    nonInteraction?: boolean
    transport?: 'beacon' | 'xhr' | 'image'
    [key: string]: any
  }
) => {
  ReactGA.event(name, {
    action,
    category,
    ...otherArguments
  })
}
