import { Card, CardContent, CardHeader } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Chart } from '/src/components/chart'

const useChartOptions = (categories) => {
  const theme = useTheme()

  return {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: [theme.palette.warning.main, theme.palette.info.main],
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'solid',
      opacity: 0
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      strokeColors: theme.palette.background.paper,
      size: 6
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      categories: categories
    }
  }
}

export const CompletionGraph = (props) => {
  const { chartSeries, categories, otherProps } = props

  const chartOptions = useChartOptions(categories)

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
      {...otherProps}
    >
      <CardHeader title="Completion Progress" />
      <CardContent sx={{ pt: 0, flexGrow: 1 }}>
        <Chart
          height={360}
          options={chartOptions}
          series={chartSeries}
          type="area"
        />
      </CardContent>
    </Card>
  )
}
