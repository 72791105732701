import React from 'react'
import { ButtonProps } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import TextButton from '/src/components/buttons/text-button'

const NextButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <TextButton endIcon={<ArrowForwardIcon />} {...props}>
      Next
    </TextButton>
  )
}

export default NextButton
