import { Box, Container, Typography, useTheme } from '@mui/material'
import PrimaryButton from './buttons/primary-button'
import WinterMagic from '/src/components/winter-magic'
import { Stack } from '@mui/system'
import { useEffect } from 'react'
import { usePathname } from '/src/hooks/use-pathname'

export const Error = ({ resetError }) => {
  const theme = useTheme()
  const pathname = usePathname()

  // Reset the error state when the user navigates to a new page
  useEffect(() => {
    return () => {
      if (resetError) {
        resetError()
      }
    }
  }, [pathname, resetError])

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        py: '80px'
      }}
    >
      <Container maxWidth="xl">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            flexGrow: 1
          }}
          spacing={6}
          mx={10}
        >
          <Stack spacing={4} mt={10} alignItems="flex-start">
            <Stack spacing={5}>
              <Typography variant="h1">Oops!</Typography>
              <Typography variant="h5">
                Something went wrong. Our team has been notified, and we're
                working to fix the issue as soon as possible.
              </Typography>
            </Stack>
            <PrimaryButton
              onClick={() => window.location.reload()}
              size="large"
            >
              Try again
            </PrimaryButton>
          </Stack>
          <Stack
            sx={{
              maxHeight: 500
            }}
          >
            <WinterMagic color={theme?.palette?.primary?.main} />
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}
