// MUI components
import {
  Card,
  Stack,
  alpha,
  Box,
  Modal,
  Fade,
  Backdrop,
  Typography,
  Button
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

// Custom components
import { Scrollbar } from '/src/components/scrollbar'
import ScoringTableBody from '/src/sections/dashboard/reviews/questions/scoring-table/body'
import FullNameColumn from '/src/sections/dashboard/reviews/questions/scoring-table/fullname-column'
import SummaryScoreBars from './summary-score-bars'
import SummaryComments from './summary-comments'
import { ROLES } from '/functions/shared/constants/roles'
import { useAuth } from '/src/hooks/use-auth'

const FeedbacksSummaryModal = ({ review, open, onClose }) => {
  const questions = review?.questions
  const { user } = useAuth()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            outline: 'none'
          }}
        >
          <Scrollbar
            component={Stack}
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: '85vh',
              width: {
                xs: '99vw',
                md: '85vw'
              },
              bgcolor: 'background.paper',
              borderRadius: 2,
              pb: 5,
              position: 'relative'
            }}
          >
            <Stack
              sx={{
                p: 5,
                flexDirection: 'row',
                justifyContent: 'space-between',
                backdropFilter: 'blur(4px)',
                backgroundColor: (theme) =>
                  alpha(theme.palette.background.default, 0.6),
                position: 'sticky',
                top: 0,
                width: '100%',
                zIndex: 10
              }}
            >
              <Button
                variant={'text'}
                onClick={onClose}
                startIcon={<ArrowBackIcon />}
                sx={{
                  minWidth: 'max-content'
                }}
              >
                Back
              </Button>
              <Stack
                sx={{
                  flexGrow: 1,
                  justifyContent: 'center'
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    // remove blur effect from the text
                    zIndex: 2,
                    pr: 6,
                    opacity: 1,
                    filter: 'blur(0px)',
                    textAlign: 'center'
                  }}
                >
                  Your Feedback to Your Colleagues
                </Typography>
              </Stack>
            </Stack>

            <Stack
              px={{
                xs: 1,
                md: 4,
                lg: 10
              }}
              spacing={8}
              mt={2}
            >
              {questions?.map((question, index) => {
                const isSelfScoreEnabled =
                  review.dataConfig.enableSelfScoring &&
                  (!question.leadershipBehavior ||
                    (question.leadershipBehavior &&
                      user.userRoles.includes(ROLES.MANAGER)))

                const feedbacks = [
                  ...question.feedbacks,
                  ...(isSelfScoreEnabled ? [question.selfScore] : [])
                ]

                return (
                  <Card
                    sx={{
                      px: '5%',
                      py: '2%',
                      boxShadow: 5
                    }}
                    key={index}
                  >
                    <Typography mb={4} textAlign="center" variant="h4">
                      {question.title}
                    </Typography>
                    <ScoringTableBody
                      mt={{
                        xs: 13,
                        md: 11,
                        lg: 9
                      }}
                    >
                      <FullNameColumn
                        feedbacks={feedbacks}
                        displaySeniorityInReview={
                          review.dataConfig.displaySeniorityInReview
                        }
                      />
                      <Stack
                        flexGrow={1}
                        px={{
                          xs: 0,
                          md: 3
                        }}
                      >
                        <SummaryScoreBars
                          feedbacks={feedbacks}
                          grades={review.dataConfig.grades}
                        />
                      </Stack>
                      <SummaryComments feedbacks={feedbacks} />
                    </ScoringTableBody>
                  </Card>
                )
              })}
            </Stack>
          </Scrollbar>
        </Box>
      </Fade>
    </Modal>
  )
}

export default FeedbacksSummaryModal
