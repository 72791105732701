// PENDING_BATCHES // AWAITING PEER SELECTION
// INCOMPLETE // STARTED BUT NOT FINISHED
// SUBMITTED // FINISHED & AWAITING OTHERS
// AWAITING_RESULTS // COMPLETED & AWAITING RESULTS
// COMPLETED // COMPLETED & RESULTS READY
// MISSED // EVALUATION COMPLETED BUT REVIEW NOT COMPLETED

export const REVIEW_STATES = {
  AWAITING_SELECTION: 'AWAITING_SELECTION',
  INCOMPLETE: 'INCOMPLETE',
  SUBMITTED: 'SUBMITTED',
  AWAITING_RESULTS: 'AWAITING_RESULTS',
  COMPLETED: 'COMPLETED',
  MISSED: 'MISSED'
}

export const EVALUATION_STATES = {
  TEMPLATE: 'TEMPLATE',
  ONGOING: 'ONGOING',
  AWAITING_RESULTS: 'AWAITING_RESULTS',
  COMPLETED: 'COMPLETED',
  DELETED: 'DELETED'
}

export const COMPLETION_STATES = {
  COMPLETED: 'Completed',
  NOT_STARTED: 'Not Started',
  PARTIALLY_COMPLETED: 'Partially Completed'
}
