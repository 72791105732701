// MUI imports
import { Theme, styled } from '@mui/material/styles'
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const LightTooltip = styled(
  ({ className, ...props }: TooltipProps & { className?: string }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }: { theme: Theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white || 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow:
      (theme?.shadows && theme.shadows[1]) ||
      'rgba(0, 0, 0, 0.1) 0px 10px 50px',
    fontSize: 13,
    maxWidth: 400
  }
})) as typeof Tooltip
