import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import { Seo } from '/src/components/seo'
import LoadingOverlay from '/src/components/loading-overlay'
import { AnimatedLogo } from '/src/components/animated-logo'
import { useUser } from '/src/hooks/use-user'
import TutorialModal from '/src/components/tutorial-modal'
import { TUTORIAL_IDS } from '/src/constants/tutorials'
import { ReviewsTable } from '/src/components/reviews-table/reviews-table'
import { ROLES } from '/functions/shared/constants/roles'
import { LoadingError } from '/src/components/loading-error'
import useGetRequest, { Endpoints } from '/src/hooks/requests/use-get-request'

const Page = () => {
  const { data, isLoading, error } = useGetRequest({
    endpoint: Endpoints.GET_REVIEWS
  })

  // Check if the user has completed the tutorial
  const { user } = useUser()
  const displayTutorial = !user?.tutorials?.find(
    (tutorial) => tutorial.name === TUTORIAL_IDS.REVIEW_PROCESS
  )?.completed

  return (
    <>
      <Seo title="My Reviews" />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            mb: 5
          }}
        >
          {displayTutorial ? (
            <TutorialModal
              {...{
                tutorialId: TUTORIAL_IDS.REVIEW_PROCESS,
                user,
                isRequired: true
              }}
            />
          ) : null}
          <Box sx={{ p: 3, pt: 0 }}>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="flex-start"
              spacing={1}
            >
              <div>
                <Typography variant="h3">My Reviews</Typography>
              </div>
            </Stack>
          </Box>
          <Divider />
          <Grid px={3} container rowGap={2}>
            {error && <LoadingError message={error.message} />}
            {isLoading ? (
              <Grid item xs={12} mt={3}>
                <LoadingOverlay>
                  <AnimatedLogo width={60} />
                </LoadingOverlay>
              </Grid>
            ) : null}
            {data?.ongoingReviews?.length > 0 && (
              <Grid item xs={12} mt={3}>
                <ReviewsTable
                  title="Ongoing Reviews"
                  reviews={data?.ongoingReviews}
                  role={ROLES.EMPLOYEE}
                />
              </Grid>
            )}
            {data?.completedReviews?.length > 0 && (
              <Grid item xs={12} mt={3}>
                <ReviewsTable
                  title="Completed Reviews"
                  reviews={data?.completedReviews}
                  role={ROLES.EMPLOYEE}
                />
              </Grid>
            )}
            {!error &&
              data?.ongoingReviews?.length === 0 &&
              data?.completedReviews?.length === 0 && (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  flexGrow={1}
                  mt={18}
                >
                  <Typography variant="h5">
                    Looks like there aren’t any reviews yet. Please check later.
                  </Typography>
                </Stack>
              )}
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default Page
