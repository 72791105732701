import { Box, Typography, Stack, Divider, Container } from '@mui/material'
import { Seo } from '/src/components/seo'
import { useParams } from 'react-router'
import StatTables from '../../../sections/dashboard/manager/stats/stat-tables'
import LoadingOverlay from '/src/components/loading-overlay'
import { AnimatedLogo } from '/src/components/animated-logo'
import { withReviewGuard } from '/src/hocs/with-review-guard'
import { LoadingError } from '/src/components/loading-error'
import { SeverityPill } from '/src/components/severity-pill'
import { ROLES } from '/functions/shared/constants/roles'
import { useUser } from '/src/hooks/use-user'
import { Skeletons } from '/src/components/skeletons'
import { getStatus } from '/src/components/reviews-table/helpers'
import useGetRequest, { Endpoints } from '/src/hooks/requests/use-get-request'

const Page = () => {
  const { evaluationId } = useParams()

  const {
    isLoading: isLoadingTeamStats,
    error: errorTeamStats,
    data: teamStats
  } = useGetRequest({
    endpoint: Endpoints.GET_REVIEW_STATS,
    data: {
      evaluationId,
      userRole: ROLES.MANAGER
    }
  })

  const userRoles = useUser().user.userRoles
  const {
    isLoading: isLoadingEvaluation,
    error: errorEvaluation,
    data: evaluation
  } = useGetRequest({
    endpoint: Endpoints.GET_EVALUATION,
    data: { evaluationId },
    repoData: { userRoles }
  })

  const isLoading = isLoadingTeamStats || isLoadingEvaluation

  const isOngoing = evaluation?.status === 'ONGOING'

  const errors = [errorTeamStats, errorEvaluation]

  const error = errors.find((error) => error)

  if (error && !isLoading) {
    return <LoadingError message={error?.message || 'Something went wrong'} />
  }

  const status = !isLoading
    ? getStatus({
        evaluation: evaluation,
        role: ROLES.MANAGER
      })
    : {}

  return (
    <>
      <Seo title="Results & Stats" />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          flexGrow: 1
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ p: 3, pt: 0 }}>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="flex-start"
              spacing={1}
            >
              {isLoading ? (
                <Skeletons width={300} height={50} variant="rounded" />
              ) : (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="flex-start"
                >
                  <Typography variant="h3" mr={2}>
                    My Team's Progress - {evaluation.name}
                  </Typography>
                  <SeverityPill color={status.statusColor}>
                    {status.status}
                  </SeverityPill>
                </Stack>
              )}
            </Stack>
          </Box>
          <Divider />
          <Container
            maxWidth="lg"
            sx={{
              my: 3
            }}
          >
            <Stack>
              {isLoading ? (
                <LoadingOverlay my={3}>
                  <AnimatedLogo width={60} />
                </LoadingOverlay>
              ) : null}
              {isOngoing && !isLoading ? (
                <Stack>
                  {!isLoadingTeamStats && !errorTeamStats ? (
                    <StatTables my={3} stats={teamStats} />
                  ) : null}
                  {!isLoadingTeamStats && errorTeamStats ? (
                    <LoadingError
                      message={errorTeamStats.message || 'Something went wrong'}
                    />
                  ) : null}
                </Stack>
              ) : null}
              {!isOngoing && !isLoading ? (
                <>
                  {/* completion stats components */}
                  <Stack>
                    {!isLoadingTeamStats && !errorTeamStats ? (
                      <StatTables stats={teamStats} />
                    ) : null}
                    {!isLoadingTeamStats && errorTeamStats ? (
                      <LoadingError
                        message={
                          errorTeamStats.message || 'Something went wrong'
                        }
                      />
                    ) : null}
                  </Stack>
                </>
              ) : null}
            </Stack>
          </Container>
        </Container>
      </Box>
    </>
  )
}

export default withReviewGuard(Page)
