// Hooks
import { useCallback, useState, useRef, useEffect } from 'react'
import { Bounce, toast } from 'react-toastify'
import { useRouter } from '/src/hooks/use-router'
import { useLocation } from 'react-router'

// Repo functions
import reviewsRepo from '/src/data/repos/reviews-repo'

// Utils and constants
import consoleLog from '/src/utils/console-log'
import { paths } from '/src/constants/paths'
import { removePrefixes } from '/src/utils/format-errors'
import { useUser } from '/src/hooks/use-user'
import { showSurvey } from '/src/refiner/functions'
import { surveyAliasMappings } from '/src/refiner/constants'
import { ROLES } from '/functions/shared/constants/roles'

// Supportive texts
const supportiveTexts = [
  'Your constructive feedback is making a positive impact.',
  "Your feedback is a great contribution to your colleague's success.",
  'Your thoughtful feedback is fostering a culture of improvement.',
  'Your honest feedback is guiding your colleague towards excellence.',
  'Your insightful comments are driving progress. Keep sharing!',
  'Your feedback is sparking positive change. Keep it up!',
  'Your feedback is sparking positive change. Keep it up!',
  'Thank you! Your observations are helping your team grow stronger.',
  'Keep it coming! Your feedback is a catalyst for improvement.',
  'Well done! Your input helps your colleague improve.',
  'Thanks for your insight!',
  'Keep those ideas flowing!',
  'Thanks for your constructive feedback!'
]

const supportiveIcons = [
  '🙏',
  '🏆',
  '🏅',
  '🌟',
  '👏',
  '🙌',
  '💪',
  '🚀',
  '💯',
  '✅'
]

// Destructuring the repo functions
const { updateReview } = reviewsRepo

// Helper function to get the number of skippable people
const getSkippablePersonCount = ({
  currentQuestion,
  requestMin,
  isLeadershipOnlyEvaluation
}) => {
  // Getting the number of people who are skipped
  const skippedPersonCount = currentQuestion.feedbacks.filter(
    (person) => !person.isApplicable
  ).length

  let maxNumberOfSkippablePeople
  // LEADERSHIP CONDITIONS CHECK
  if (isLeadershipOnlyEvaluation) {
    // At least one person should be reviews, others can be skipped
    maxNumberOfSkippablePeople = currentQuestion.feedbacks.length - 1
  } else if (currentQuestion.leadershipBehavior) {
    // Everyone can be skipped
    maxNumberOfSkippablePeople = currentQuestion.feedbacks.length
  } else {
    // Set skippable person count to at most 20%, or requestMin
    maxNumberOfSkippablePeople = Math.min(
      currentQuestion.feedbacks.length - requestMin,
      Math.floor(currentQuestion.feedbacks.length * 0.2)
    )
  }

  // Returning the remaining number of skippable people
  return maxNumberOfSkippablePeople - skippedPersonCount
}

// Helper function to get the new marks for handleChange function to use in slider
const getNewMarks = (newPeopleArray, currentQuestion) => {
  // Get all values with its counts as a map
  const valuesToCounts = newPeopleArray
    .filter((person) => person.isApplicable)
    .reduce((acc, person) => {
      acc[person.value] = (acc[person.value] || 0) + 1
      return acc
    }, {})

  // Getting the values that should be disabled
  const disabledValues = Object.keys(valuesToCounts)
    .filter((value) => valuesToCounts[value] > 1)
    .map((value) => Number(value))

  // Remove disabled values from defaultMarks and keep 0
  const newMarks = currentQuestion.defaultMarks.filter(
    (mark) => !disabledValues.includes(mark.value) || mark.value === 0
  )

  return newMarks
}

export const useQuestion = (initialReviewState) => {
  // Scroll to the top
  const topElement = useRef(null)
  const scrollToTop = () => {
    if (!topElement?.current) return console.warn('Top element is not found')

    return topElement.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }

  const router = useRouter()

  const {
    questions: initialQuestions,
    reviewId,
    evaluation: evaluationId
  } = initialReviewState

  // If user comes by clicking on review progress from submit page, get the clicked question index from the state and set it as the initial question index
  const { state: locationState } = useLocation()
  const navigatedQuestionIndex = initialQuestions.findIndex(
    (question) => question.questionId === locationState?.navigatedQuestionId
  )

  const isLeadershipOnlyEvaluation = initialQuestions.every(
    (q) => q.leadershipBehavior
  )

  // Set the initial question index
  const initialQuestionIndex =
    navigatedQuestionIndex >= 0
      ? navigatedQuestionIndex
      : initialReviewState.questionIndex

  // Getting the user
  const { user } = useUser()

  // Set the remainingAllowedRemovablePersonCount 2 or less
  const initialSkippablePersonCount = getSkippablePersonCount({
    currentQuestion: initialQuestions[initialQuestionIndex || 0],
    requestMin: initialReviewState.dataConfig.requestMin,
    isLeadershipOnlyEvaluation
  })

  // Questions data
  const [questionsData, setQuestionsData] = useState({
    questions: initialQuestions,
    currentQuestionIndex: initialQuestionIndex || 0,
    skippablePersonCount: initialSkippablePersonCount
  })

  // this state is used to store all conditions to display on the page
  const [allConditions, setAllConditions] = useState(
    checkAllConditions({
      currentQuestion: initialQuestions[initialQuestionIndex || 0],
      review: initialReviewState,
      user
    })
  )

  // this state is used to disable checkboxes if remainingRemovablePersonCount is zero
  const [disableCheckboxes, setDisableCheckboxes] = useState(
    initialSkippablePersonCount === 0
  )

  // this state is used to disable buttons if responses are getting updated
  const [isDisabled, setIsDisabled] = useState(false)

  // this state is used to track if there are any changes in the responses
  const [isChanged, setIsChanged] = useState(false)

  // this state is used to display the check icon between question transitions
  const [displayCheckIcon, setDisplayCheckIcon] = useState(false)

  // Update the responses and get the new updated review object
  const updateResponses = useCallback(async () => {
    setIsDisabled(true)
    const currentQuestion =
      questionsData.questions[questionsData.currentQuestionIndex]

    consoleLog('currentQuestion: ', currentQuestion)

    const updatedCurrentQuestionResponse = currentQuestion.feedbacks.map(
      (person) => {
        return {
          comment: person.comment,
          isIgnored: !person.isApplicable,
          userId: person.uid,
          score: person.value,
          fullName: person.fullName
        }
      }
    )

    const newResponse = {
      title: currentQuestion.title,
      questionId: currentQuestion.questionId,
      feedbacks: updatedCurrentQuestionResponse,
      selfScore: currentQuestion.selfScore.value,
      selfComment: currentQuestion.selfScore.comment
    }

    // update the review's responses
    const updatedReview = await toast.promise(
      updateReview({
        reviewId,
        responses: {
          [currentQuestion.questionId]: newResponse
        }
      }),
      {
        pending: 'Saving your progress...',
        success: {
          render() {
            setIsChanged(false)
            setIsDisabled(false)
            return 'Progress saved! 🎉'
          }
        },
        error: {
          render({ data: err }) {
            console.error('Error in update review: ', err)
            setIsChanged(false)
            setIsDisabled(false)
            return (
              removePrefixes(err.message) ||
              'Oops. Something went wrong! Your answers could not be saved.'
            )
          }
        }
      }
    )

    consoleLog('updatedReview: ', updatedReview)
    return updatedReview
  }, [questionsData, reviewId])

  // Handle the question change (update the responses and get the new questions)
  const handleQuestionChange = useCallback(
    async (questionId) => {
      // Initialize questions data
      // If there are changes, update the responses and get the updated questions
      let questions = questionsData.questions
      if (isChanged) {
        const updatedReview = await updateResponses()
        questions = updatedReview.questions
      }

      // Getting the new question index
      const newQuestionIndex = questions.findIndex(
        (question) => question.questionId === questionId
      )

      setQuestionsData((prevData) => {
        // Getting the new skippable person count for the new question
        const newSkippablePersonCount = getSkippablePersonCount({
          currentQuestion: questions[newQuestionIndex],
          requestMin: initialReviewState.dataConfig.requestMin,
          isLeadershipOnlyEvaluation
        })

        // Updating the disableCheckboxes state
        setDisableCheckboxes(newSkippablePersonCount === 0)

        return {
          questions: questions,
          currentQuestionIndex: newQuestionIndex,
          skippablePersonCount: newSkippablePersonCount
        }
      })

      // Set new conditions states to display on the page
      setAllConditions(() =>
        checkAllConditions({
          currentQuestion: questions[newQuestionIndex],
          review: initialReviewState,
          user
        })
      )

      return
    },
    [
      updateResponses,
      initialReviewState,
      isLeadershipOnlyEvaluation,
      user,
      isChanged,
      questionsData.questions
    ]
  )

  // Handle the slider change
  const handleChange = useCallback(
    (userId) => (newValue, event) => {
      setQuestionsData((prevValues) => {
        if (userId === 'self') {
          const currentQuestionIndex = prevValues.currentQuestionIndex
          const currentQuestion = prevValues.questions[currentQuestionIndex]

          currentQuestion.selfScore.value = newValue
          // Set new conditions states to display on the page
          setAllConditions(() =>
            checkAllConditions({
              currentQuestion,
              review: initialReviewState,
              user
            })
          )

          return {
            ...prevValues,
            questions: [
              ...prevValues.questions.slice(0, currentQuestionIndex),
              currentQuestion,
              ...prevValues.questions.slice(currentQuestionIndex + 1)
            ]
          }
        }

        const currentQuestionIndex = prevValues.currentQuestionIndex
        const currentQuestion = prevValues.questions[currentQuestionIndex]
        const newPeopleArray = [...currentQuestion.feedbacks]
        const index = newPeopleArray.findIndex((value) => value.uid === userId)

        // if people is not applicable, do not change the value and show a toast message
        if (!newPeopleArray[index].isApplicable) {
          toast.error('This person is not applicable')
          return prevValues
        }

        newPeopleArray[index].value = newValue

        // Getting the new marks
        const newMarks = getNewMarks(newPeopleArray, currentQuestion)

        // add new marks to only applicable people's marks array
        newPeopleArray.forEach((person) => {
          if (person.isApplicable) {
            person.marks = [...newMarks, { value: person.value }]
          }
        })

        const updatedQuestions = [
          ...prevValues.questions.slice(0, currentQuestionIndex),
          {
            ...currentQuestion,
            feedbacks: newPeopleArray
          },
          ...prevValues.questions.slice(currentQuestionIndex + 1)
        ]

        // Set new conditions states to display on the page
        setAllConditions(() =>
          checkAllConditions({
            currentQuestion: {
              ...currentQuestion,
              feedbacks: newPeopleArray
            },
            review: initialReviewState,
            user
          })
        )

        return {
          ...prevValues,
          questions: updatedQuestions
        }
      })

      setIsChanged(true)
    },
    [initialReviewState, user]
  )

  // Handle Feedback Changes (Save the comment)
  const handleCommentSave = useCallback(
    (userId) => (comment) => {
      setQuestionsData((prevValues) => {
        const currentQuestionIndex = prevValues.currentQuestionIndex
        const currentQuestion = prevValues.questions[currentQuestionIndex]

        if (userId === 'self') {
          currentQuestion.selfScore.comment = comment

          return {
            ...prevValues,
            questions: [
              ...prevValues.questions.slice(0, currentQuestionIndex),
              currentQuestion,
              ...prevValues.questions.slice(currentQuestionIndex + 1)
            ]
          }
        }

        const newPeopleArray = [...currentQuestion.feedbacks]
        const index = newPeopleArray.findIndex((value) => value.uid === userId)

        newPeopleArray[index].comment = comment

        const updatedQuestions = [
          ...prevValues.questions.slice(0, currentQuestionIndex),
          {
            ...currentQuestion,
            feedbacks: newPeopleArray
          },
          ...prevValues.questions.slice(currentQuestionIndex + 1)
        ]

        // Set new conditions states to display on the page
        setAllConditions(() =>
          checkAllConditions({
            currentQuestion: {
              ...currentQuestion,
              feedbacks: newPeopleArray
            },
            review: initialReviewState,
            user
          })
        )

        // Show a toast
        if (comment.length) {
          const randomText =
            supportiveTexts[Math.floor(Math.random() * supportiveTexts.length)]
          const randomIcon =
            supportiveIcons[Math.floor(Math.random() * supportiveIcons.length)]
          toast(randomText, {
            icon: (
              <div>
                <span key={index}>{randomIcon}</span>
              </div>
            ),
            transition: Bounce
          })
        }

        return {
          ...prevValues,
          questions: updatedQuestions
        }
      })

      setIsChanged(true)
    },
    [initialReviewState, user]
  )

  // Handle checkbox click
  const handleApplicable = useCallback(
    (person) => () => {
      setQuestionsData((prevValues) => {
        const currentQuestionIndex = prevValues.currentQuestionIndex
        const currentQuestion = prevValues.questions[currentQuestionIndex]
        let skippablePersonCount = prevValues.skippablePersonCount

        // get the clicked person
        const newPeopleArray = [...currentQuestion.feedbacks]
        const index = newPeopleArray.findIndex(
          (value) => value.uid === person.uid
        )

        // Get the new checkbox value
        const newCheckboxValue = !newPeopleArray[index].isApplicable

        // UNCHECKED THE CHECKBOX CASE
        if (!newCheckboxValue) {
          // If the person has a comment and the user wants to skip the person, show a confirmation dialog
          if (
            newPeopleArray[index].comment.length > 0 &&
            !window.confirm(
              "You've already provided feedback for this person. Are you sure you want to skip this person?"
            )
          ) {
            return prevValues
          }

          // Reset value(score), marks, isApplicable and comment
          newPeopleArray[index].isApplicable = newCheckboxValue
          newPeopleArray[index].value = 0
          newPeopleArray[index].marks = [{ value: 0 }]
          newPeopleArray[index].comment = ''

          // if the person is not applicable, decrease the remainingAllowedRemovablePersonCount
          if (skippablePersonCount > 0) {
            skippablePersonCount--

            // if remainingAllowedRemovablePersonCount is zero, disable the checkboxes
            if (skippablePersonCount === 0) {
              setDisableCheckboxes(true)
            }
          }
        } else {
          // CHECKED THE CHECKBOX CASE

          // Set new checkbox value
          newPeopleArray[index].isApplicable = newCheckboxValue

          // Getting the new marks
          const newMarks = getNewMarks(newPeopleArray, currentQuestion)

          // add new marks to only applicable people's marks array
          newPeopleArray.forEach((person) => {
            if (person.isApplicable) {
              person.marks = [...newMarks, { value: person.value }]
            }
          })

          // if the person is applicable, increase the remainingAllowedRemovablePersonCount
          skippablePersonCount++
          // if remainingAllowedRemovablePersonCount is not zero and disableCheckboxes is true, enable the checkboxes
          if (skippablePersonCount > 0 && disableCheckboxes) {
            setDisableCheckboxes(false)
          }
        }

        // Set new conditions states to display on the page
        setAllConditions(() =>
          checkAllConditions({
            currentQuestion: {
              ...currentQuestion,
              feedbacks: newPeopleArray
            },
            review: initialReviewState,
            user
          })
        )

        return {
          ...prevValues,
          questions: [
            ...prevValues.questions.slice(0, currentQuestionIndex),
            {
              ...currentQuestion,
              feedbacks: newPeopleArray
            },
            ...prevValues.questions.slice(currentQuestionIndex + 1)
          ],
          skippablePersonCount: skippablePersonCount
        }
      })

      setIsChanged(true)
    },
    [initialReviewState, disableCheckboxes, user]
  )

  // Handle the next question
  const handleNext = useCallback(async () => {
    // Disable next and previous buttons
    setIsDisabled(true)

    // Handle next if the current question is the last question
    if (
      questionsData.currentQuestionIndex ===
      questionsData.questions.length - 1
    ) {
      // Check if all questions are answered
      const allQuestionsMetConditions = questionsData.questions.every(
        (question) =>
          checkAllConditions({
            currentQuestion: question,
            review: initialReviewState,
            user
          }).every((condition) => condition.passed)
      )

      // If all questions are not passed, show a toast message and return
      if (!allQuestionsMetConditions) {
        setIsDisabled(false)
        scrollToTop()
        return toast('😅 Oops, looks like you missed some questions.')
      }

      // Otherwise, update the responses and navigate to the submit page
      await updateResponses()

      router.push(paths.dashboard.reviews.submit(evaluationId), {
        state: {
          isRoutingProper: true
        },
        replace: true
      })
      return setIsDisabled(false)
    }

    await handleQuestionChange(
      questionsData.questions[questionsData.currentQuestionIndex + 1].questionId
    )

    if (allConditions.every((condition) => condition.passed)) {
      setDisplayCheckIcon(true)
      setTimeout(() => {
        setDisplayCheckIcon(false)
      }, 1500)
    } else {
      setTimeout(() => {
        scrollToTop()
      }, 1000)
    }

    // Enable next and previous buttons
    return setIsDisabled(false)
  }, [
    questionsData,
    handleQuestionChange,
    updateResponses,
    evaluationId,
    router,
    initialReviewState,
    user,
    allConditions
  ])

  // this function is used to go to the previous question
  const handlePrevious = useCallback(async () => {
    // Disable next and previous buttons
    setIsDisabled(true)

    // Update the current question index
    await handleQuestionChange(
      questionsData.questions[questionsData.currentQuestionIndex - 1].questionId
    )

    // Scroll to top of the page after card transition (it takes 1 second, slide animation)
    setTimeout(() => {
      scrollToTop()
    }, 1000)

    // Enable next and previous buttons
    return setIsDisabled(false)
  }, [questionsData, handleQuestionChange])

  const handleReorder = useCallback(() => {
    return setQuestionsData((prevData) => {
      const currentQuestion = prevData.questions[prevData.currentQuestionIndex]
      const reorderedPeople = [...currentQuestion.feedbacks].sort(
        (a, b) => a.value - b.value
      )

      const updatedQuestions = [
        ...prevData.questions.slice(0, prevData.currentQuestionIndex),
        {
          ...currentQuestion,
          feedbacks: reorderedPeople
        },
        ...prevData.questions.slice(prevData.currentQuestionIndex + 1)
      ]

      return {
        ...prevData,
        questions: updatedQuestions
      }
    })
  }, [])

  // get current question and feedbacks to display on the page
  const { currentQuestionIndex, questions, skippablePersonCount } =
    questionsData
  const currentQuestion = questions[currentQuestionIndex]
  const { feedbacks } = currentQuestion

  // Show questions page survey when the user reaches the third question
  useEffect(() => {
    if (currentQuestionIndex === 2 && showSurvey) {
      showSurvey(surveyAliasMappings.QUESTIONS_SURVEY)
    }
  }, [currentQuestionIndex, questions.length])

  return {
    handleChange,
    handleApplicable,
    handleNext,
    handlePrevious,
    handleCommentSave,
    updateResponses,
    handleReorder,
    handleQuestionChange,
    isDisabled,
    questions,
    isChanged,
    currentQuestion,
    currentQuestionIndex,
    feedbacks,
    topElement,
    allConditions,
    disableCheckboxes,
    skippablePersonCount,
    displayCheckIcon
  }
}

export const checkAllConditions = ({ currentQuestion, review, user }) => {
  const conditions = []

  const isLeadershipOnlyEvaluation = review.questions.every(
    (q) => q.leadershipBehavior
  )

  // CHECKING IF EVERY APPLICABLE PERSON HAS SCORE
  const isEveryApplicablePersonHasScore = currentQuestion.feedbacks.every(
    (person) => (person.isApplicable ? person.value > 0 : true)
  )

  // push isEveryApplicablePersonHasFeedback to conditions array
  conditions.push({
    passed: isEveryApplicablePersonHasScore,
    message: 'Slide the scale for every person you checked.'
  })

  // LEADERSHIP BEHAVIOR CONDITION CHECK
  if (currentQuestion.leadershipBehavior) {
    // Getting the manager
    const manager = currentQuestion.feedbacks.find(
      (person) => person.isDirectManager
    )

    // Adding the condition if the manager exists
    if (manager) {
      // check if the manager has a score & a comment
      const isManagerReviewed = manager.value > 0 && manager.comment.length > 0

      // push isManagerReviewed to conditions array
      conditions.push({
        passed: isManagerReviewed,
        message: `Make sure to provide a score and feedback to your manager, ${manager?.fullName}.`
      })
    }

    // SELF SCORING CONDITION CHECK
    if (
      review.dataConfig.enableSelfScoring &&
      user.userRoles.includes(ROLES.MANAGER)
    ) {
      // check if the person scored themselves
      const scoredThemselves = currentQuestion?.selfScore?.value > 0

      // push scoredThemselves to conditions array
      conditions.push({
        passed: scoredThemselves,
        message: 'Slide the scale for yourself.'
      })
    }

    // COMMENT MIN CHECK FOR LEADERSHIP ONLY BEHAVIORS
    if (isLeadershipOnlyEvaluation && review.dataConfig.commentMin > 0) {
      // Getting the minimum comment number
      const minCommentNumber = Math.min(
        currentQuestion.feedbacks.length,
        review.dataConfig.commentMin
      )

      // check if there are at least two person has a comment to go to the next question
      const isCommentCountTrue =
        currentQuestion.feedbacks.filter(
          (person) => person.isApplicable && person.comment.length > 0
        ).length >= minCommentNumber

      // push isCommentCountTrue to conditions array
      conditions.push({
        passed: isCommentCountTrue,
        message: `Provide feedback for at least ${minCommentNumber} people who would benefit the most from further commentary.`
      })
    }

    // Return if leadership behavior is applied
    return conditions
  }

  // SELF SCORING CONDITION CHECK
  if (review.dataConfig.enableSelfScoring) {
    // check if the person scored themselves
    const scoredThemselves = currentQuestion?.selfScore?.value > 0

    // push scoredThemselves to conditions array
    conditions.push({
      passed: scoredThemselves,
      message: 'Slide the scale for yourself.'
    })
  }

  // MIN COMMENT COUNT CONDITION CHECK
  if (review.dataConfig.commentMin > 0) {
    // Getting the minimum comment number
    const minCommentNumber = Math.min(
      currentQuestion.feedbacks.length,
      review.dataConfig.commentMin
    )

    // check if there are at least two person has a comment to go to the next question
    const isCommentCountTrue =
      currentQuestion.feedbacks.filter(
        (person) => person.isApplicable && person.comment.length > 0
      ).length >= minCommentNumber

    // push isCommentCountTrue to conditions array
    conditions.push({
      passed: isCommentCountTrue,
      message: `Provide feedback for at least ${minCommentNumber} people who would benefit the most from further commentary.`
    })
  }

  // FEEDBACK FOR LOWEST AND HIGHEST RATED PERSON CONDITION CHECK
  const applicablePeople = currentQuestion.feedbacks.filter(
    (person) => person.isApplicable
  )
  // Check if the lowest and highest rated people have comments if the feature is enabled
  if (
    review.dataConfig.enableMandatoryCommentsForLowestAndHighest &&
    applicablePeople.length >= 2
  ) {
    if (!isEveryApplicablePersonHasScore) {
      conditions.push({
        passed: false,
        message: `Provide feedback for people whom you are marking as the highest and the lowest.`
      })
    } else {
      // Getting all scores from applicable people
      const scores = applicablePeople.map((person) => person.value)

      // Getting the lowest and highest scores
      const minScore = Math.min(...scores)
      const maxScore = Math.max(...scores)

      // Getting the lowest and highest rated people (it can be more than one person)
      const lowestRatedPeople = applicablePeople.filter(
        (person) => person.value === minScore
      )
      const highestRatedPeople = applicablePeople.filter(
        (person) => person.value === maxScore
      )

      // Check if the at least some of the lowest and highest rated people have comments
      const commentedForLowestAndHighest =
        lowestRatedPeople.some((person) => person.comment.length > 0) &&
        highestRatedPeople.some((person) => person.comment.length > 0)

      // Getting the names of the lowest and highest rated people to display in the message
      const lowestRatedPeopleNames = lowestRatedPeople
        .map((person) => person.fullName)
        .join(' or ')
      const highestRatedPeopleNames = highestRatedPeople
        .map((person) => person.fullName)
        .join(' or ')

      // Add the condition to the conditions array
      conditions.push({
        passed: commentedForLowestAndHighest,
        message: `Provide feedback for ${lowestRatedPeopleNames} and ${highestRatedPeopleNames}.`
      })
    }
  }

  return conditions
}
