import { useContext, useEffect } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import consoleLog from '/src/utils/console-log'
import { useUser } from '/src/hooks/use-user'
import { usePathname } from '/src/hooks/use-pathname'

export function useSaveOnLeave(when = true, update = async () => {}) {
  const { signingOut } = useUser()
  const pathName = usePathname()
  const { navigator } = useContext(NavigationContext)

  const isOnQuestionsPageSignOut = pathName.includes('questions') && signingOut

  useEffect(() => {
    if (!when) {
      return
    }

    const push = navigator.push

    navigator.push = async (...args) => {
      if (!isOnQuestionsPageSignOut) {
        const result = await update()

        if (result !== false) {
          push(...args)
        }
      }
    }

    const handleQuestionsPageUpdate = async () => {
      if (isOnQuestionsPageSignOut) {
        consoleLog(
          'Sign out detected on questions, updating review before leaving...'
        )
        await update()
      }
    }

    handleQuestionsPageUpdate()

    const listener = async ({ location, action }) => {
      consoleLog('listener in use-save-on-leave.js', { location, action })
      if (action === 'POP') {
        await update()
      }
    }

    const unlisten = navigator.listen(listener)

    return () => {
      navigator.push = push
      unlisten()
    }
  }, [navigator, update, when, isOnQuestionsPageSignOut])
}
