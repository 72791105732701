import statsModal from '/src/data/modals/stats-modal'
import statsProvider from '/src/data/providers/stats'
import schemas from '/functions/shared/schemas'
import consoleLog from '/src/utils/console-log'

const statsRepo = {
  getReviewStats: async (data) => {
    try {
      const result = await statsProvider.getReviewStats(data)

      consoleLog(
        'result in getReviewStats before validation and parsing',
        result
      )

      // validate the result
      const validatedResult = schemas.getReviewStats.validateSync(result)

      // parse the result
      const parsedResult = statsModal.prepareForCharts(validatedResult)

      consoleLog(
        'result in getReviewStats after parsing and validation',
        parsedResult
      )

      return parsedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  }
}

export default statsRepo
