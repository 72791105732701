import {
  Avatar,
  Card,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  SvgIcon,
  Typography
} from '@mui/material'
import CheckIcon from '@untitled-ui/icons-react/build/cjs/Check'
import { useStepper } from './use-stepper'
import { EVALUATION_FORM_METHODS } from '/src/constants/evaluation-form-methods'
import PropTypes from 'prop-types'
import { FloatSaveButton } from './float-save-button'
import TextButton from '/src/components/buttons/text-button'

const StepIcon = (props) => {
  const { active, completed, icon } = props

  const highlight = active || completed

  return (
    <Avatar
      sx={{
        height: 40,
        width: 40,
        ...(highlight && {
          backgroundColor: 'primary.main',
          color: 'primary.contrastText'
        })
      }}
      variant="rounded"
    >
      {completed ? (
        <SvgIcon>
          <CheckIcon />
        </SvgIcon>
      ) : (
        icon
      )}
    </Avatar>
  )
}

const EvaluationForm = ({
  evaluation,
  method,
  behaviors = [],
  companyEmployees
}) => {
  // get necessary data from useStepper hook
  const { activeStep, steps, handleSetActiveStep, formik } = useStepper({
    evaluation,
    method,
    behaviors,
    companyEmployees
  })

  return (
    <Card
      component={Stack}
      sx={{
        p: {
          xs: 1,
          md: 5
        },
        borderRadius: {
          xs: 0,
          md: 3
        },
        boxShadow: {
          xs: 0,
          md: 5
        }
      }}
      data-testid="evaluation-form"
    >
      {formik.dirty && method === EVALUATION_FORM_METHODS.UPDATE ? (
        <FloatSaveButton
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}
        />
      ) : null}

      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{
          '& .MuiStepConnector-line': {
            borderLeftColor: 'divider',
            borderLeftWidth: 2,
            ml: 1
          }
        }}
      >
        {steps.map((step, index) => {
          const isCurrentStep = activeStep === index

          return (
            <Step key={index}>
              <TextButton
                disableRipple
                sx={{
                  color: isCurrentStep ? 'primary.main' : 'text.primary'
                }}
                onClick={handleSetActiveStep(index)}
              >
                <StepLabel StepIconComponent={StepIcon}>
                  <Typography sx={{ ml: 2 }} variant="overline">
                    {step.label}
                  </Typography>
                </StepLabel>
              </TextButton>
              <StepContent
                sx={{
                  borderLeftColor: 'divider',
                  borderLeftWidth: 2,
                  ml: '20px',
                  ...(isCurrentStep && {
                    py: 4
                  })
                }}
              >
                {step.content}
              </StepContent>
            </Step>
          )
        })}
      </Stepper>
    </Card>
  )
}

EvaluationForm.propTypes = {
  evaluation: PropTypes.object,
  method: PropTypes.oneOf([...Object.values(EVALUATION_FORM_METHODS)])
    .isRequired,
  companyEmployees: PropTypes.array.isRequired
}

export default EvaluationForm
