import { Box, Container, Stack, Typography } from '@mui/material'
import { Seo } from '/src/components/seo'
import { ROLES } from '/functions/shared/constants/roles'
import LoadingOverlay from '/src/components/loading-overlay'
import { AnimatedLogo } from '/src/components/animated-logo'
import { LoadingError } from '/src/components/loading-error'
import DashboardTabs from '/src/sections/dashboard/admin/reviews/overview/dashboard-tabs'
import { TUTORIAL_IDS } from '/src/constants/tutorials'
import { useUser } from '/src/hooks/use-user'
import TutorialModal from '/src/components/tutorial-modal'
import useGetRequest, { Endpoints } from '/src/hooks/requests/use-get-request'

const Page = () => {
  const {
    data: workforceResults,
    isLoading: isLoadingWorkforceResults,
    error: errorWorkforceResults
  } = useGetRequest({
    endpoint: Endpoints.GET_WORKFORCE_RESULTS,
    data: {
      userRole: ROLES.MANAGER
    }
  })

  const {
    data: evaluations,
    isLoading: isLoadingEvaluations,
    error: errorEvaluations
  } = useGetRequest({
    endpoint: Endpoints.GET_EVALUATIONS,
    data: { userRole: ROLES.MANAGER }
  })

  const isLoading = isLoadingEvaluations || isLoadingWorkforceResults
  const error = errorEvaluations || errorWorkforceResults
  // Filtering out the evaluations and sorting
  const completedEvaluations = evaluations
    ?.filter((e) => e.status === 'COMPLETED')
    .reverse()

  const { user } = useUser()
  const displayTutorial = !user?.tutorials?.find(
    (tutorial) => tutorial.name === TUTORIAL_IDS.MANAGER_ANALYTICS
  )?.completed

  return (
    <>
      <Seo title="Overview" />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            mb: 5
          }}
        >
          {displayTutorial ? (
            <TutorialModal
              {...{
                tutorialId: TUTORIAL_IDS.MANAGER_ANALYTICS,
                user,
                isRequired: true
              }}
            />
          ) : null}
          {isLoading ? (
            <LoadingOverlay my={3}>
              <AnimatedLogo width={60} />
            </LoadingOverlay>
          ) : null}
          {error ? (
            <LoadingError message={error.message || 'Something went wrong'} />
          ) : null}
          {!isLoading && !error ? (
            workforceResults.some(
              (workforceResult) => workforceResult.userResults.length > 0
            ) ? (
              <DashboardTabs
                workforceResults={workforceResults}
                evaluations={completedEvaluations}
                userRole={ROLES.MANAGER}
              />
            ) : (
              <Stack height="70vh" alignItems="center" justifyContent="center">
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  There are no completed reviews.
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  You can come back to see the analytics after the results are
                  published.
                </Typography>
              </Stack>
            )
          ) : null}
        </Container>
      </Box>
    </>
  )
}

export default Page
