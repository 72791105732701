import * as Sentry from '@sentry/react'
import { Error } from './error'

const PageHasBeenForceRefreshed = 'page-has-been-force-refreshed'

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.sessionStorage.getItem(PageHasBeenForceRefreshed)
  )

  // If this is the first time the page has been force refreshed, create the session storage item
  if (!pageHasAlreadyBeenForceRefreshed) {
    window.sessionStorage.setItem(PageHasBeenForceRefreshed, 1)
    return window.location.reload()
  }

  // If session storage item exists, increment it and reload the page
  window.sessionStorage.setItem(
    PageHasBeenForceRefreshed,
    pageHasAlreadyBeenForceRefreshed + 1
  )
  return window.location.reload()
}

const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => {
        const isChunkLoadError =
          error?.message?.includes('ChunkLoadError') ||
          error?.message?.includes('Loading chunk') ||
          error?.name?.includes('ChunkLoadError') ||
          error?.name?.includes('Loading chunk')

        const pageHasBeenForceRefreshed = JSON.parse(
          window.sessionStorage.getItem(PageHasBeenForceRefreshed)
        )

        const isUnexpectedToken = error?.message?.includes(
          "Unexpected token '<'"
        )

        const isUnexpectedExpression = error?.message?.includes(
          "expected expression, got '<'"
        )

        // If the error is a chunk load or unexpected token error and the page has been force refreshed less than 5 times, reload the page
        if (
          (isChunkLoadError || isUnexpectedToken || isUnexpectedExpression) &&
          pageHasBeenForceRefreshed < 5
        ) {
          if (isChunkLoadError) {
            console.warn(
              'ChunkLoadError catched and page has been force refreshed. Reloading page...'
            )
          }
          if (isUnexpectedToken) {
            console.warn(
              'UnexpectedTokenError catched and page has been force refreshed. Reloading page...'
            )
          }
          if (isUnexpectedExpression) {
            console.warn(
              'UnexpectedExpressionError catched and page has been force refreshed. Reloading page...'
            )
          }

          retryPageLoading()
          return
        }

        // If the error is a chunk load or unexpected token error and the page has been force refreshed more than 5 times or different than chunkloaderror, show the error page
        return <Error resetError={resetError} />
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
