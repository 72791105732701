// Components
import { Box, Container } from '@mui/material'
import { Seo } from '/src/components/seo'
import { withReviewGuard } from '/src/hocs/with-review-guard'
import { toast } from 'react-toastify'
import QuestionCard from '/src/sections/dashboard/reviews/questions/question-card'
import DashboardLoading from '/src/components/dashboard-loading'

// Hooks
import { useEffect } from 'react'
import { useParams } from 'react-router'
import useGetRequest, { Endpoints } from '/src/hooks/requests/use-get-request'

// You can adjust time in minutes before showing the break notification
const BREAK_MINUTES = 20

const Page = () => {
  const { evaluationId } = useParams()
  const { data: review, isLoading } = useGetRequest({
    endpoint: Endpoints.GET_REVIEW,
    data: { evaluationId }
  })

  // Show a message after BREAK_MINUTES minutes of continuous work
  // Clear the timeout when the user switches tabs or windows and set it again when they return
  useEffect(() => {
    let timeoutId

    const setTimer = () => {
      timeoutId = setTimeout(() => {
        toast(
          `Awesome effort! You've been working for ${BREAK_MINUTES} minutes straight. How about a short break?`,
          {
            icon: <span>☕️</span>,
            autoClose: 5000
          }
        )
      }, BREAK_MINUTES * 60 * 1000) // Delay in milliseconds
    }

    const clearTimer = () => {
      clearTimeout(timeoutId)
    }

    setTimer()

    window.addEventListener('blur', clearTimer)
    window.addEventListener('focus', setTimer)

    return () => {
      clearTimeout(timeoutId) // Clear the timeout on component unmount
      window.removeEventListener('blur', clearTimer)
      window.removeEventListener('focus', setTimer)
    }
  }, [])

  return (
    <>
      <Seo title="Questions" />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          flexGrow: 1,
          mt: 0.5
        }}
      >
        <Container maxWidth="lg">
          {isLoading ? <DashboardLoading /> : null}
          {!isLoading ? <QuestionCard data={review} /> : null}
        </Container>
      </Box>
    </>
  )
}

export default withReviewGuard(Page)
