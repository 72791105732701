import {
  Drawer,
  Stack,
  SvgIcon,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
  Card,
  Box,
  MenuItem,
  InputLabel,
  Select,
  Divider,
  Alert
} from '@mui/material'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import XIcon from '@untitled-ui/icons-react/build/cjs/X'
import consoleLog from '/src/utils/console-log'
import Chart from 'react-apexcharts'
import { Scrollbar } from '/src/components/scrollbar'
import { UserAnalysisReportModal } from './user-analysis-report-modal'
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined'
import HearingOutlinedIcon from '@mui/icons-material/HearingOutlined'
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined'
import { ChartTypes } from '/src/constants/chart-types'
import { checkIfReportConfigHasComponentsToShow } from '/src/utils/evaluation-config'
import { LightTooltip } from '/src/components/light-tooltip'
import InfoIcon from '@mui/icons-material/Info'
import { ROLES } from '/functions/shared/constants/roles'
import { capitalizeCamelCase } from '/src/utils/capitalize-camelCaseString'
import { alpha } from '@mui/system'
import { useDialog } from '/src/hooks/use-dialog'
import { toast } from 'react-toastify'
import { SCORES } from '/src/constants/evaluation-config'
import PrimaryButton from '/src/components/buttons/primary-button'
import { NOT_APPLICABLE } from '/src/constants/common'

// We use this constant to show a info icon when the user has less than 4 reviews
export const MIN_REVIEWS_RECEIVED = 4
export const RECEIVED_FEW_REVIEWS_INFO_TEXT =
  'This employee received a low number of reviews, which may impact the quality of results.'

const UserProgressBarChart = (props) => {
  const { data: userData } = props
  const theme = useTheme()

  // Create series from userResults where x axis will be evaluationId and y axis will be peerfulScore
  const series = []
  const uniqueBehaviors = [
    ...new Set(
      userData
        .map((evaluation) =>
          evaluation.resultsByQuestion
            .filter((result) => result.reviewsReceived)
            .map((result) => result.behavior)
        )
        .flat()
    )
  ].sort()

  for (const evaluation of userData) {
    const evaluationData = []
    // We need to add null for absent behaviors in the evaluation for proper chart rendering
    for (const behavior of uniqueBehaviors) {
      const result = evaluation.resultsByQuestion.find(
        (result) => result.behavior === behavior
      )

      evaluationData.push({
        x: behavior,
        y: result?.peerfulScore?.toFixed(0) || null,
        goals:
          result?.cohortMedian >= 0
            ? [
                {
                  name: 'Cohort Median',
                  value: result.cohortMedian.toFixed(0),
                  strokeWidth: 2,
                  strokeColor: 'black'
                }
              ]
            : []
      })
    }
    if (evaluationData.length > 0) {
      series.push({
        name: evaluation.evaluationName,
        data: evaluationData
      })
    }
  }

  const options = {
    chart: {
      zoom: {
        enabled: false
      },
      animations: {
        speed: 500
      },
      toolbar: {
        show: false
      }
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.warning.main,
      '#E91E63',
      '#9C27B0',
      '#673AB7',
      '#3F51B5'
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    // fill: {
    //   opacity: [0.24, 0.24, 1, 1]
    // },
    grid: {
      borderColor: theme.palette.mode === 'dark' ? '#80898e' : '#e0e0e0',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      // Make sure labels are between 0 and 100
      min: 0,
      max: 100,
      // make sure labels are rounded
      labels: {
        style: {
          colors: theme.palette.mode === 'dark' ? '#fff' : '#000'
        }
      }
    },
    yaxis: {
      // make sure labels are rounded
      labels: {
        style: {
          colors: theme.palette.mode === 'dark' ? '#fff' : '#000'
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: theme.palette.mode,
      markers: {
        show: true
      },
      y: {
        formatter: function (value) {
          if (value === null) {
            return NOT_APPLICABLE
          }
          return value
        }
      }
    },
    legend: {
      show: true,
      height: 50,
      showForSingleSeries: true,
      showForNullSeries: false,
      showForZeroSeries: true,
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: theme.palette.mode === 'dark' ? '#fff' : '#000'
      },
      itemMargin: {
        horizontal: 15,
        vertical: 5
      }
    }
  }

  return <Chart options={options} series={series} type={ChartTypes.BAR} />
}

const UserProgressDumbbellChart = (props) => {
  const { data: userData, mdDown, xlDown } = props
  const [selectedReviews, setSelectedReviews] = React.useState(
    userData.length > 1
      ? [
          { name: userData[0].evaluationName, value: userData[0].evaluationId },
          {
            name: userData[userData.length - 1].evaluationName,
            value: userData[userData.length - 1].evaluationId
          }
        ]
      : [{ name: userData[0].evaluationName, value: userData[0].evaluationId }]
  )
  const theme = useTheme()

  // Create series from userResults where x axis will be evaluationId and y axis will be peerfulScore
  const series = []
  const uniqueBehaviors = [
    ...new Set(
      userData
        .map((evaluation) =>
          evaluation.resultsByQuestion
            .filter((result) => result.reviewsReceived)
            .map((result) => result.behavior)
        )
        .flat()
    )
  ].sort()

  // Score Types to display in the bar chart
  const scoreTypesToDisplay = [
    { type: SCORES.MANAGER_SCORE, display: false },
    { type: SCORES.SELF_SCORE, display: false },
    { type: SCORES.RAW_AVERAGE_SCORE, display: false }
  ]

  const selectedUserData = [
    ...selectedReviews.map((review) =>
      userData.find((data) => data.evaluationId === review.value)
    )
  ]

  for (const evaluation of selectedUserData) {
    const evaluationData = []
    // We need to add null for absent behaviors in the evaluation for proper chart rendering
    for (const behavior of uniqueBehaviors) {
      const result = evaluation.resultsByQuestion.find(
        (result) => result.behavior === behavior
      )

      // Find the max score to set the x axis max value (managerScore, selfScore, rawAverageScore)
      const scoreFields = {
        ...(result?.managerScore && {
          managerScore: { value: result.managerScore, color: '#E91E63' }
        }),
        ...(result?.selfScore && {
          selfScore: { value: result.selfScore, color: '#228B22' }
        }),
        ...(result?.rawAverageScore && {
          rawAverageScore: { value: result.rawAverageScore, color: '#0000FF' }
        })
      }

      scoreTypesToDisplay.forEach((item) => {
        if (item.type in scoreFields) {
          item.display = true
          item.color = scoreFields[item.type].color
        }
      })

      // Get the valid scores to find the max score
      const validScoresList = Object.values(scoreFields)
        .filter((score) => score.value !== undefined)
        .map((score) => score.value)

      let maxScore = null
      const goals = []

      if (validScoresList.length) {
        // Add the remaining score types to the goals
        maxScore = Math.max(...validScoresList).toFixed(0)
        for (const [name, { value, color }] of Object.entries(scoreFields)) {
          if (value !== undefined) {
            goals.push({
              name: name,
              value: value.toFixed(0),
              strokeWidth: mdDown ? 6 : 8,
              strokeHeight: 0,
              strokeLineCap: 'round',
              strokeColor: color
            })
          }
        }
      }

      evaluationData.push({
        x: behavior,
        y: maxScore,
        goals: goals
      })
    }
    if (evaluationData.length > 0) {
      series.push({
        name: evaluation.evaluationName,
        data: evaluationData
      })
    }
  }

  const options = {
    chart: {
      zoom: {
        enabled: false
      },
      animations: {
        speed: 500
      },
      toolbar: {
        show: false
      }
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.warning.main,
      '#E91E63',
      '#9C27B0',
      '#673AB7',
      '#3F51B5'
    ],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: userData.length > 1 ? (mdDown ? 90 : 60) : 2,
        rangeBarGroupRows: false,
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    ...(userData.length > 1 && {
      stroke: {
        colors: ['transparent'],
        width: mdDown ? 15 : 20
      }
    }),
    grid: {
      borderColor: theme.palette.mode === 'dark' ? '#80898e' : '#e0e0e0',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    xaxis: {
      // Make sure labels are between 0 and 100
      min: 0,
      max: 100,
      // make sure labels are rounded
      labels: {
        style: {
          colors: theme.palette.mode === 'dark' ? '#fff' : '#000'
        }
      }
    },
    yaxis: {
      // make sure labels are rounded
      labels: {
        offsetY: userData.length > 1 ? -10 : 0,
        style: {
          colors: theme.palette.mode === 'dark' ? '#fff' : '#000'
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ dataPointIndex, w }) {
        const tooltipData = w.config.series.map((s) => ({
          review: s.name,
          behavior: s.data[dataPointIndex].x,
          goals: s.data[dataPointIndex].goals
        }))

        const behavior = tooltipData[0].behavior

        // Create the tooltip content like the above example
        return (
          '<div>' +
          '<div class="apexcharts-tooltip-title" style="font-size: 0.75rem;">' +
          behavior +
          '</div>' +
          '<div ' +
          'style="padding: 0 10px;">' +
          tooltipData
            .map(
              (d) =>
                '<div' +
                ' style="display: flex; justify-content: space-between; align-items: center;column-gap: 10px;padding: 5px 0;font-size: 0.75rem;">' +
                '<span><b>' +
                d.review +
                '</b></span>' +
                // Create the goals content line line
                '<div>' +
                (d.goals?.length
                  ? d.goals
                      .map(
                        (goal) =>
                          '<span' +
                          ' style="color: ' +
                          goal.strokeColor +
                          ';">' +
                          '<span style="border: 2px solid ' +
                          goal.strokeColor +
                          ';' +
                          'background-color: ' +
                          goal.strokeColor +
                          ';border-radius: 50%; width: 10px; height: 10px; display: inline-block; margin-right: 5px;"></span>' +
                          '</span>' +
                          '</span>' +
                          '<span>' +
                          capitalizeCamelCase(goal.name) +
                          ': ' +
                          goal.value +
                          '</span>'
                      )
                      .join('<br>')
                  : NOT_APPLICABLE) +
                '</div>' +
                '</div>'
            )
            .join('<hr>') +
          '</div>' +
          '</div>'
        )
      },
      theme: theme.palette.mode,
      markers: {
        show: true
      }
    },
    legend: {
      show: false
    }
  }

  const handleReviewSelect = useCallback((event, orderNo) => {
    const { name, value } = event.target
    setSelectedReviews((prev) => {
      const newSelectedReviews = [...prev]
      newSelectedReviews[orderNo] = { name, value }

      // If other has the same value then swap the values
      if (newSelectedReviews[0].value === newSelectedReviews[1].value) {
        newSelectedReviews[0] = prev[1]
        newSelectedReviews[1] = prev[0]
      }

      return newSelectedReviews
    })
  }, [])

  return (
    <Stack direction="row">
      {userData.length > 1 ? (
        <style>
          {`
         #dumbbell-chart g .apexcharts-bar-goals-markers g {
          transform: translateY(${mdDown ? -7 : -10}px) !important;
        }
      `}
        </style>
      ) : null}
      <Chart
        id="dumbbell-chart"
        options={options}
        series={series}
        width={mdDown ? 400 : xlDown ? 600 : 700}
        type={ChartTypes.BAR}
      />
      <Stack paddingY={3} spacing={'15%'}>
        <Stack>
          {scoreTypesToDisplay.map((scoreType) => {
            return scoreType.display ? (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                key={scoreType.type}
              >
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: scoreType.color
                  }}
                />
                <Typography variant="caption">
                  {capitalizeCamelCase(scoreType.type)}
                </Typography>
              </Stack>
            ) : null
          })}
        </Stack>
        <Stack spacing={userData.length > 1 ? 2 : 0}>
          <Box>
            <InputLabel>
              <Stack spacing={1} direction="row" alignItems="center">
                <Box
                  sx={{
                    width: 10,
                    height: 2.5,
                    backgroundColor: theme.palette.primary.main
                  }}
                />
                <Typography variant="caption">
                  {userData.length > 1 ? 'Review 1' : selectedReviews[0].name}
                </Typography>
              </Stack>
            </InputLabel>
            {userData.length > 1 ? (
              <Select
                fullWidth
                value={selectedReviews[0].value}
                size="small"
                variant="standard"
                onChange={(e) => handleReviewSelect(e, 0)}
              >
                {userData.map((evaluation) => (
                  <MenuItem
                    disabled={
                      evaluation.evaluationId === selectedReviews[0].value
                    }
                    key={evaluation.evaluationId}
                    value={evaluation.evaluationId}
                  >
                    {evaluation.evaluationName}
                  </MenuItem>
                ))}
              </Select>
            ) : null}
          </Box>
          {userData.length > 1 ? (
            <Box>
              <InputLabel>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Box
                    sx={{
                      width: 10,
                      height: 2.5,
                      backgroundColor: theme.palette.warning.main
                    }}
                  />
                  <Typography variant="caption">Review 2</Typography>
                </Stack>
              </InputLabel>
              <Select
                fullWidth
                value={selectedReviews[1].value}
                size="small"
                variant="standard"
                onChange={(e) => handleReviewSelect(e, 1)}
              >
                {userData.map((evaluation) => (
                  <MenuItem
                    disabled={
                      evaluation.evaluationId === selectedReviews[1].value
                    }
                    key={evaluation.evaluationId}
                    value={evaluation.evaluationId}
                  >
                    {evaluation.evaluationName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  )
}

function DashboardUserAnalysis(props) {
  const { data: userData, filters, onClose, open, userRole } = props
  const theme = useTheme()
  const xlDown = useMediaQuery((theme) => theme.breakpoints.down('xl'))
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const reportModal = useDialog()

  // Generate appliedFilters as string like: 'Behaviors: 'Grit', 'Execution', 'Innovation' | Managers: 'John Doe', 'Jane Doe' | Divisions: 'Sales',
  // Only display filters that are not empty
  const appliedFilters = Object.entries(filters)
    .map(([key, filters]) => {
      const { values, defaultValues } = filters

      if (Array.isArray(values) && values.length > 0) {
        if (key === 'evaluations') {
          // Evaluation has special case because they are based on uid
          return `Reviews: ${defaultValues
            .filter((e) => values.includes(e.uid))
            .map((e) => e.name)
            .join(', ')}`
        }

        if (key !== 'jobLevel' && typeof values[0] === 'number') {
          // Range has special case it is based on number but jobLevel excluded
          return `${capitalizeCamelCase(key)}: ${values.join(' - ')}`
        }

        return `${capitalizeCamelCase(key)}: ${values.join(', ')}`
      }
      return null
    })
    .filter((filter) => filter)
    .join(' | ')

  const onClickReport = useCallback(
    (evaluationId) => {
      const selectedEvaluation = userData.userResults.find(
        (evaluation) => evaluation.evaluationId === evaluationId
      )

      if (!selectedEvaluation) {
        console.warn(
          'DashboardUserAnalysis: Evaluation not found in user data to open report modal.',
          {
            userData,
            evaluationId: evaluationId
          }
        )

        return toast.error(
          'Oops! Something went wrong, our team has been notified.'
        )
      }

      reportModal.handleOpen(selectedEvaluation)
    },
    [reportModal, userData]
  )

  let content = null

  consoleLog('USER DATA: ', userData)

  const evaluationResults = userData?.userResults

  const noReportComponentsToShowInAnyEvaluation = evaluationResults?.every(
    (evaluation) => {
      return checkIfReportConfigHasComponentsToShow(
        evaluation.evaluationConfig.reportConfig
      )
    }
  )

  const noReviewsReceivedInAnyEvaluation = evaluationResults?.every(
    (evaluation) =>
      evaluation.resultsByQuestion.every((result) => !result.reviewsReceived)
  )

  if (userData) {
    const hasManagerScore = Boolean(userData[SCORES.MANAGER_SCORE])
    const hasCohortScore = Boolean(userData[SCORES.COMPANY_MEDIAN])

    const userAnalysesCardsData = [
      {
        name: 'Average Score',
        value: userData.peerfulScore,
        icon: <DataUsageOutlinedIcon />,
        description:
          'The average score of the employee for the selected reviews and behaviors.'
      },
      {
        name: 'Average Manager Score',
        value: userData.managerScore,
        icon: <SupervisorAccountOutlinedIcon />,
        description:
          'The average score the employee received from their manager for the selected review cycles and behaviors.'
      },
      {
        name: 'Reviews Received',
        value: userData.reviewsReceived,
        icon: <HearingOutlinedIcon />,
        description:
          'The average number of reviews received by the employee for the selected review cycles and behaviors'
      }
    ]

    // Add more granular data for admins
    if (userRole === ROLES.ADMIN) {
      userAnalysesCardsData.push(
        ...[
          // {
          //   name: 'Confidence',
          //   value: renderConfidenceScore(userData.confidence),
          //   icon: <AdminPanelSettingsOutlinedIcon />,
          //   description: 'The confidence of the predicted scores, based on the number of reviews received, and the reliability of the reviewers.'
          // },
          // {
          //   name: 'Deviation Score',
          //   value: userData.standardDeviation,
          //   icon: <ShowChartOutlinedIcon />,
          //   appendix: renderConfidenceScore(deviationLevel)
          // },
          {
            name: 'Reviews Given',
            value: userData.reviewsGiven,
            icon: <RecordVoiceOverOutlinedIcon />,
            description:
              'The average number of reviews given to the employee for the selected review cycles and behaviors'
          },
          {
            name: 'Change in Reviewers',
            value: userData.averageReviewerPeopleChange,
            sign: '%',
            icon: <PublishedWithChangesOutlinedIcon />,
            description:
              'The average percentage change in reviewers across different review cycles. For example, if the change is 50%, this would mean that the half of the people who reviewed were new in each review cycle.'
          },
          {
            name: 'Change in Selected Colleagues',
            value: userData.averageSelectedPeopleChange,
            sign: '%',
            icon: <PublishedWithChangesOutlinedIcon />,
            description:
              'The average percentage change in colleagues selected by the employee, across different review cycles. For example, if the change is 50%, this would mean that the half of the reviewed colleagues were new in each review cycle.'
          }
        ]
      )
    }

    content = (
      <Stack rowGap={2} p={6} pt={3}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Typography color="inherit" variant="h5">
            {userData.fullName}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            flexGrow={1}
            justifyContent="flex-end"
          >
            {appliedFilters ? (
              <Scrollbar
                sx={{
                  overflowX: 'auto',
                  overflowY: 'auto',
                  maxHeight: 75,
                  width: '80%'
                }}
              >
                <Stack
                  direction="row"
                  alignItems="start"
                  spacing={2}
                  sx={{
                    px: 2,
                    py: 1,
                    borderRadius: '4px',
                    backgroundColor: alpha(theme.palette.warning.light, 0.5)
                  }}
                >
                  <Typography color="warning.main" variant="caption">
                    <strong>Applied Filters:</strong> {appliedFilters}
                  </Typography>
                </Stack>
              </Scrollbar>
            ) : null}
            <IconButton
              color="inherit"
              onClick={onClose}
              sx={{
                borderRadius: '4px',
                border: '1px solid',
                borderColor: 'action.focus'
              }}
            >
              <SvgIcon>
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Stack>
        </Stack>
        <Typography variant="caption" color="text.secondary">
          {userData.jobTitle ? (
            <span>
              <strong>Job Title:</strong> {userData.jobTitle} {'\t-\t'}
            </span>
          ) : null}
          {userData.team ? (
            <span>
              <strong>Team:</strong> {userData.team} {'\t-\t'}
            </span>
          ) : null}
          {userData.seniority ? (
            <span>
              <strong>Seniority:</strong> {userData.seniority} {'\t-\t'}
            </span>
          ) : null}
          {userData.manager ? (
            <span>
              <strong>Manager:</strong> {userData.manager}
            </span>
          ) : null}
        </Typography>
        {userData.reviewsReceived < MIN_REVIEWS_RECEIVED ? (
          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={1}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? 'neutral.800' : 'white',
              borderRadius: 1,
              mt: 1,
              width: 'fit-content',
              boxShadow: 2,
              padding: 1
            }}
          >
            <SvgIcon color="primary" fontSize="small">
              <InfoIcon />
            </SvgIcon>
            <Typography variant="caption" color="text.secondary">
              {RECEIVED_FEW_REVIEWS_INFO_TEXT}
            </Typography>
          </Stack>
        ) : null}
        <Divider />
        {noReportComponentsToShowInAnyEvaluation ||
        noReviewsReceivedInAnyEvaluation ? null : (
          <Box>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              fontWeight={'bold'}
              sx={{ marginBottom: 2 }}
            >
              {`Click to access ${userData.fullName}'s reports and development plans:`}
            </Typography>
            <Stack
              direction="row"
              rowGap={2}
              columnGap={2}
              sx={{
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'start'
              }}
            >
              {evaluationResults.map((result) => {
                // Check if at least one score display is enabled
                const noReportComponentsToShow =
                  checkIfReportConfigHasComponentsToShow(
                    result.evaluationConfig.reportConfig
                  )
                const noReviewsReceived = !result.resultsByQuestion.some(
                  (result) => result.reviewsReceived
                )

                if (noReportComponentsToShow || noReviewsReceived) return null

                return (
                  <PrimaryButton
                    data-gaid={`see-user-report-of-${result.evaluationName}-review`}
                    startIcon={<VisibilityOutlinedIcon />}
                    onClick={() => onClickReport(result.evaluationId)}
                    key={result.evaluationId}
                    sx={{
                      minWidth: '220px'
                    }}
                  >
                    {result.evaluationName}
                  </PrimaryButton>
                )
              })}
            </Stack>
          </Box>
        )}
        {reportModal.data ? (
          <UserAnalysisReportModal
            open={reportModal.open}
            modalData={reportModal.data}
            userRole={userRole}
            user={{
              fullName: userData.fullName,
              userId: userData.userId,
              manager: userData.manager
            }}
            onClose={reportModal.handleClose}
          />
        ) : null}
        <Divider />
        <Typography variant="subtitle2" color="text.secondary">
          Use the cards below to see a high level summary of the user, including
          average scores and total reviews received.
        </Typography>
        <Grid container spacing={1}>
          {userAnalysesCardsData.map((point) =>
            !Number.isNaN(point?.value) && point.value >= 0 ? (
              <Grid item xs={mdDown ? 4 : 3} key={point.name}>
                <Stack
                  height={75}
                  direction="row"
                  flex={1}
                  alignItems="center"
                  justifyContent="space-evenly"
                  spacing={0.5}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark' ? 'neutral.800' : 'white',
                    borderRadius: 2,
                    boxShadow: 2
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    flex={xlDown ? 0.3 : 0.4}
                  >
                    {point.icon}
                  </Stack>
                  <Stack spacing={0.4} flex={xlDown ? 0.7 : 0.6}>
                    <Typography
                      color="text.secondary"
                      variant="caption"
                      fontSize={12}
                      lineHeight={1.2}
                    >
                      {point.name}
                    </Typography>
                    <Stack direction="row" alignItems="center">
                      <Typography variant="h6">
                        {point.value}
                        {point.sign || ''}
                      </Typography>
                      <Stack
                        sx={{
                          scale: '0.9'
                        }}
                      >
                        {point.appendix}
                      </Stack>
                    </Stack>
                  </Stack>
                  <LightTooltip
                    title={
                      <Typography variant="body2" sx={{ maxWidth: 300 }}>
                        {point.description}
                      </Typography>
                    }
                  >
                    <IconButton
                      sx={{
                        position: 'relative',
                        top: -20
                      }}
                      color="primary"
                    >
                      <SvgIcon
                        sx={{
                          fontSize: 20 // reduce icon size
                        }}
                      >
                        <InfoIcon />
                      </SvgIcon>
                    </IconButton>
                  </LightTooltip>
                </Stack>
              </Grid>
            ) : null
          )}
        </Grid>
        {/* Bar Chart. If no review data is available, skip */}
        {evaluationResults?.length && !noReviewsReceivedInAnyEvaluation ? (
          <>
            <Divider />
            <Typography variant="subtitle2" color="text.secondary">
              Use the bar chart below to understand the performance of the
              employee over time
              {hasCohortScore
                ? ', and how they compare to colleagues in their cohort'
                : ''}
              .
            </Typography>
            <Card
              sx={{
                p: 0,
                flexGrow: 1
              }}
            >
              <UserProgressBarChart data={evaluationResults} />
            </Card>
            <Divider />
            <Typography variant="subtitle2" color="text.secondary">
              Use the bar chart below to understand the performance of the
              employee over time, and how their colleagues
              {hasManagerScore ? ', self, and manager ' : ' and self '}
              scores differ.
            </Typography>
            <Card sx={{ p: 0, flexGrow: 1 }}>
              <UserProgressDumbbellChart
                data={evaluationResults}
                mdDown={mdDown}
                xlDown={xlDown}
              />
            </Card>
          </>
        ) : (
          <Alert severity="info">
            {appliedFilters
              ? 'This employee does not have any results based on the applied filters.'
              : 'This employee has not received any reviews.'}
          </Alert>
        )}
      </Stack>
    )
  }

  return (
    <Drawer
      anchor="right"
      disableScrollLock
      open={open}
      ModalProps={{
        onClose: onClose
      }}
      PaperProps={{
        elevation: 24,
        sx: {
          backgroundColor: theme.palette.mode === 'light' && 'ghostwhite',
          width: mdDown ? '600px' : xlDown ? '800px' : '1000px'
        }
      }}
      variant="temporary"
    >
      <Scrollbar
        sx={{
          'height': '100%',
          '& .simplebar-content': {
            height: '100%'
          }
        }}
      >
        {content}
      </Scrollbar>
    </Drawer>
  )
}

export default DashboardUserAnalysis

DashboardUserAnalysis.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.object
}
