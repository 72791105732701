import { SCORES } from '/src/constants/evaluation-config'

export const resultDataOrderValues = {
  [SCORES.PEERFUL_SCORE]: 1,
  [SCORES.SCORE_BAR]: 2,
  [SCORES.SELF_SCORE]: 3,
  [SCORES.MANAGER_SCORE]: 4,
  [SCORES.REVIEWS_RECEIVED]: 5,
  [SCORES.COMMENTS_RECEIVED]: 6
}

export const resultDataTitles = {
  [SCORES.PEERFUL_SCORE]: 'Your Score',
  [SCORES.SCORE_BAR]: null,
  [SCORES.SELF_SCORE]: 'Self Score',
  [SCORES.MANAGER_SCORE]: 'Manager Score',
  [SCORES.REVIEWS_RECEIVED]: 'Reviews Received',
  [SCORES.COMMENTS_RECEIVED]: 'Comments Received'
}
