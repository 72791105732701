import { useState, useEffect, useRef, useCallback } from 'react'

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const targetRef = useRef(null)

  // This function updates the dimensions
  const updateDimensions = useCallback(() => {
    const target = targetRef.current
    if (target) {
      const { width, height } = target.getBoundingClientRect()
      setDimensions({ width, height })
    }
  }, [])

  // Update dimensions on mount
  useEffect(() => {
    const targetElement = targetRef.current
    if (!targetElement) return

    const observer = new ResizeObserver(() => {
      updateDimensions()
    })
    observer.observe(targetElement)

    return () => observer.unobserve(targetElement)
  }, [updateDimensions])

  return [targetRef, dimensions, updateDimensions]
}
