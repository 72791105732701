import { useMemo } from 'react'
import FilterTick from '/src/icons/filter-tick'
import FilterGeneral01 from '/src/icons/filter-general-01'
import GreyOutlinedButton from '../buttons/grey-outlined-button'

const DrawerFiltersButton = ({ filters, handleOpenDrawerFilters }) => {
  // Get the number of applied filters
  const appliedFilters = useMemo(
    () =>
      Object.values(filters).reduce((acc, curr) => {
        return acc + curr.values.length
      }, 0),
    [filters]
  )

  return (
    <GreyOutlinedButton
      data-gaid="drawer-filters-button"
      startIcon={
        appliedFilters ? (
          <FilterTick color="primary" />
        ) : (
          <FilterGeneral01 color="grey.700" />
        )
      }
      onClick={handleOpenDrawerFilters}
      isActive={appliedFilters}
      sx={{
        minWidth: 180,
        minHeight: 50
      }}
    >
      {appliedFilters ? `Active Filters (${appliedFilters})` : 'Filters'}
    </GreyOutlinedButton>
  )
}

export default DrawerFiltersButton
