// Components
import { Box, ButtonBase, SvgIcon, Tooltip } from '@mui/material'
import { Popover } from '@mui/material'

// Icons
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

// Hooks
import { usePopover } from '/src/hooks/use-popover'

const MoreMenu = (props) => {
  const { children, horizontal = false } = props

  const popover = usePopover()
  const anchorEl = popover.anchorRef.current

  return (
    <>
      <Tooltip title="More">
        <Box>
          <ButtonBase
            sx={{
              p: '6px'
            }}
            onClick={popover.handleOpen}
            ref={popover.anchorRef}
            size="small"
          >
            <SvgIcon>
              {horizontal ? (
                <MoreHorizIcon size="small" />
              ) : (
                <MoreVertIcon size="small" />
              )}
            </SvgIcon>
          </ButtonBase>
        </Box>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        disableScrollLock
        onClose={popover.handleClose}
        open={popover.open}
      >
        <Box>{children}</Box>
      </Popover>
    </>
  )
}

export default MoreMenu
