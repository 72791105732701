import { array, boolean, number, object, string } from 'yup'

export const getEmployeeRequests = array()
  .of(
    object()
      .shape({
        fullName: string().required('Full name field is required.'),
        isApproved: boolean()
          .nullable()
          .defined('Is approved field is required.'),
        selectMin: number().integer().required('Select min field is required.'),
        requestMin: number()
          .integer()
          .required('Request min field is required.'),
        status: string().required('Status field is required.'),
        uid: string().required('UID field is required.')
      })
      .noUnknown()
  )
  .ensure()

const requestDocumentSchema = object()
  .shape({
    evaluationId: string().required('Evaluation ID field is required.'),
    requestedTo: string().defined('Requested to field is required.').nullable(),
    requestedBy: string().required('Requested by field is required.'),
    fullName: string().required('Full name field is required.'),
    uid: string().required('UID field is required.'),
    status: string().required('Status field is required.'),
    type: string().required('Type field is required.'),
    isApproved: boolean().nullable().defined('isApproved field is required.')
  })
  .noUnknown()

export const requestToManager = requestDocumentSchema

export const handleEmployeeRequest = requestDocumentSchema
