import reviewsProvider from '/src/data/providers/reviews'
import consoleLog from '/src/utils/console-log'
import { reviewModal, reviewsModal } from '/src/data/modals/reviews-modal'
import schemas from '/functions/shared/schemas'
import { unloadService } from '/src/services/before-unload-service'

const reviewsRepo = {
  getReview: async (data) => {
    try {
      const result = await reviewsProvider.getReview(data)

      consoleLog('review result in getReview before parsing', result)

      // validating the result
      const validatedResult = schemas.getReview.validateSync(result)

      // parse your result here
      const parsedResult = reviewModal.prepareForQuestions(validatedResult)

      consoleLog('review result in getReview after parsing', parsedResult)

      return parsedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  getReviews: async () => {
    try {
      const result = await reviewsProvider.getReviews()

      consoleLog('result in getReviews before parsing and validation', result)

      // validate data here
      const validatedResult = schemas.getReviews.validateSync(result)

      consoleLog(
        'result in getReviews after validation before parsing',
        validatedResult
      )

      // parse data here
      const parsedResult = reviewsModal.prepareForTable(validatedResult)

      consoleLog(
        'result in getReviews after parsing and validation',
        parsedResult
      )

      return parsedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  updateReview: async (data) => {
    try {
      const result = await reviewsProvider.updateReview(data)

      consoleLog('result in updateReview before parsing and validation', result)

      // validate your result here ( update review simply returns the what getReview returns)
      const validatedResult = schemas.updateReview.validateSync(result)

      consoleLog(
        'result in updateReview after validation before parsing',
        validatedResult
      )

      // parse data here
      const parsedResult = reviewModal.prepareForQuestions(validatedResult)

      consoleLog(
        'result in updateReview after parsing and validation',
        parsedResult
      )

      return parsedResult
    } catch (error) {
      if (error.message === 'Error: Refresh required.') {
        // Disable the page reload prevention
        unloadService.disablePrevention()
        // Refresh the page
        window.location.reload()
        return console.warn('Review people changed. Refresh required.')
      }
      console.warn(error)
      throw new Error(error)
    }
  }
}

export default reviewsRepo
