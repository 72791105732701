import { TablePagination } from '@mui/material'
import React from 'react'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'

const Pagination = (props) => {
  // const { rowsPerPageOptions = [5, 10, 25, 50, 100] } = props

  const {
    filtered,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPageOptions
  } = useUsersTableContext()

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={filtered.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

export { Pagination }
