import { InputAdornment, Stack, TextField, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useMemo } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'
import consoleLog from '/src/utils/console-log'

export const Thresholds = () => {
  const { columns, handleUsersFiltering, thresHoldProps } =
    useUsersTableContext()
  const wrapperProps = thresHoldProps?.wrapperProps

  const wrapperSx = wrapperProps?.sx
  // the rest values of wrapperProps
  const otherWrapperProps = Object.keys(wrapperProps || {}).reduce(
    (obj, key) => {
      if (key !== 'sx') {
        obj[key] = wrapperProps[key]
      }
      return obj
    },
    {}
  )

  const thresholdColumns = useMemo(() => {
    return columns.filter((column) => column.thresHold)
  }, [columns])

  const handleChange = useCallback(
    ({ fieldKey, thresHoldType }) =>
      (event) => {
        const value = parseInt(event.target.value)

        consoleLog({
          type: fieldKey,
          action: 'threshold',
          payload: {
            value,
            type: thresHoldType
          }
        })

        handleUsersFiltering({
          type: fieldKey,
          action: 'threshold',
          payload: {
            value,
            type: thresHoldType
          }
        })
      },
    [handleUsersFiltering]
  )

  if (thresholdColumns.length === 0) {
    return null
  }

  return (
    <Stack
      mx={2}
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        '& .MuiTextField-root': {
          mx: 1,
          minWidth: '20ch',
          ...wrapperSx
        }
      }}
      {...otherWrapperProps}
    >
      {thresholdColumns.map((column) => {
        // set default value for threshold

        return (
          <TextField
            type="number"
            defaultValue={column.thresHold.value}
            sx={{
              // to hide arrows from input
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                {
                  WebkitAppearance: 'none',
                  margin: 0
                },
              '& input[type=number]': {
                MozAppearance: 'textfield'
              }
            }}
            onChange={handleChange({
              fieldKey: column.key,
              thresHoldType: column.thresHold.type
            })}
            key={column.key}
            label={column.label + ' Threshold'}
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'text.secondary',
                      textAlign: 'center',
                      mt: 0.3
                    }}
                  >
                    {column.thresHold.type === 'up' ? 'min' : 'max'}
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {column.thresHold.type === 'up' ? <ArrowUpwardIcon /> : null}
                  {column.thresHold.type === 'down' ? (
                    <ArrowDownwardIcon />
                  ) : null}
                </InputAdornment>
              )
            }}
            inputProps={{
              min: 0,
              max: 100
            }}
          />
        )
      })}
    </Stack>
  )
}
