import makeRequest from './index'

const aiProvider = {
  queryGemini: async (data) => {
    try {
      const result = await makeRequest({ data, endpoint: 'queryGemini' })

      return result.data
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default aiProvider
