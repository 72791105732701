import { PARSED_REQUEST_STATUSES } from '/src/constants/employee-request-statuses'
import { EVALUATION_STATES } from '/src/constants/statuses'

class RequestsModal {
  #sortEmployeeRequests(employeeRequests) {
    const sortedEmployeeRequests = employeeRequests.sort((a, b) => {
      // Sort by status
      // AWAITING_RESULTS/NOT_HANDLED < APPROVED < REJECTED
      const statusOrder = {
        [PARSED_REQUEST_STATUSES.AWAITING_RESPONSE]: 0,
        [PARSED_REQUEST_STATUSES.NOT_HANDLED]: 0,
        [PARSED_REQUEST_STATUSES.APPROVED]: 1,
        [PARSED_REQUEST_STATUSES.REJECTED]: 2
      }

      const aStatus = statusOrder[a.status]
      const bStatus = statusOrder[b.status]

      if (aStatus < bStatus) {
        return -1
      }
      if (aStatus > bStatus) {
        return 1
      }

      return 0
    })

    return sortedEmployeeRequests
  }

  #calculateStatuses(employeeRequests) {
    const mappedRequests = employeeRequests.map((request) => {
      const { isApproved } = request

      // If isApproved is null, set status to AWAITING_RESPONSE (awaiting response from manager)
      if (isApproved === null) {
        return {
          ...request,
          status: PARSED_REQUEST_STATUSES.AWAITING_RESPONSE
        }
      } else if (isApproved) {
        // If request is approved, set status to APPROVED
        return {
          ...request,
          status: PARSED_REQUEST_STATUSES.APPROVED
        }
      } else {
        // If isApproved is false, set status to REJECTED
        return {
          ...request,
          status: PARSED_REQUEST_STATUSES.REJECTED
        }
      }
    })

    return mappedRequests
  }

  crossCheckStatuses({ evaluation, mappedRequests }) {
    const { status } = evaluation

    // If evaluation is not ongoing, set all AWAITING_RESPONSE statuses to NOT_HANDLED
    if (status !== EVALUATION_STATES.ONGOING) {
      return mappedRequests.map((request) => {
        if (request.status === PARSED_REQUEST_STATUSES.AWAITING_RESPONSE) {
          return {
            ...request,
            status: PARSED_REQUEST_STATUSES.NOT_HANDLED
          }
        }
        return request
      })
    }

    return mappedRequests
  }

  prepareData(employeeRequests) {
    // Calculate statuses for employee requests
    const mappedRequests = this.#calculateStatuses(employeeRequests)
    // Sort employee requests by status
    const sortedEmployeeRequests = this.#sortEmployeeRequests(mappedRequests)

    return sortedEmployeeRequests
  }
}

const requestsModal = new RequestsModal()

export default requestsModal
