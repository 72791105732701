import { alpha } from '@mui/material/styles'
import {
  error,
  info,
  neutral,
  success,
  warning,
  withAlphas,
  defaultDarkColors
} from '/src/theme/colors'

export const createPalette = (config) => {
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[100], 0.38),
      tableHead: neutral[800],
      focus: alpha(neutral[100], 0.16),
      hover: alpha(neutral[100], 0.02),
      selected: alpha(neutral[100], 0.12)
    },
    background: {
      default: '#0B0F19',
      paper: neutral[900]
    },
    divider: '#2D3748',
    error,
    info,
    mode: 'dark',
    neutral,
    primary: withAlphas(defaultDarkColors),
    success,
    text: {
      primary: '#EDF2F7',
      secondary: '#A0AEC0',
      disabled: 'rgba(255, 255, 255, 0.48)'
    },
    warning
  }
}
