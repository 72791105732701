import { Typography, alpha, Stack } from '@mui/material'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'
import { Thresholds } from './thresholds'
import { useMemo } from 'react'
import { InfoSign } from '/src/components/info-sign'

export function EnhancedTableToolbar({ ...other }) {
  const { selected, title, columns, infoSignProps } = useUsersTableContext()

  const numSelected = selected.length

  const hasThreshold = useMemo(() => {
    return columns.some((column) => column.thresHold)
  }, [columns])

  if (!title && numSelected === 0) {
    return null
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: '32px 24px 16px ',
        marginBottom: 1,
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            )
        })
      }}
      {...other}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="h6"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title ? title : null}
        </Typography>
      )}
      {hasThreshold ? <Thresholds /> : null}
      {infoSignProps ? <InfoSign {...infoSignProps} /> : null}
    </Stack>
  )
}
