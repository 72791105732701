// components
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  SvgIcon,
  useMediaQuery
} from '@mui/material'
import { RouterLink } from '/src/components/router-link'
import RouterButton from '/src/components/buttons/router-button'
import FeedbacksSummaryModal from '/src/sections/dashboard/reviews/submit/feedbacks-summary-modal'
import MoreMenu from '/src/components/more-menu'
import PopoverMenuButton from '/src/components/buttons/pop-over-menu-button'
import { motion } from 'framer-motion'

// constants
import { paths } from '/src/constants/paths'
import { EVALUATION_STATES, REVIEW_STATES } from '/src/constants/statuses'
import {
  actionTypes,
  dialogContents,
  evaluationStatusesBgColorMap,
  reviewStatusesForUI,
  reviewStatusesBgColorMap,
  evaluationStatusesForUI
} from './constants'

// Hooks
import { useState } from 'react'
import { useCallback } from 'react'
import { useDialog } from '/src/hooks/use-dialog'
import useGetRequest, { Endpoints } from '/src/hooks/requests/use-get-request'

// Icons
import TimelineIcon from '@mui/icons-material/Timeline'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import ReviewsIcon from '@mui/icons-material/Reviews'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import DeleteIcon from '@mui/icons-material/Delete'

// Utils
import consoleLog from '/src/utils/console-log'
import dayjs from 'dayjs'
import { checkIfReportConfigHasComponentsToShow } from '/src/utils/evaluation-config'
import { removePrefixes } from '/src/utils/format-errors'
import { toast } from 'react-toastify'

// Others
import evaluationsRepo from '/src/data/repos/evaluations-repo'
import { ROLES } from '/functions/shared/constants/roles'
import PropTypes from 'prop-types'
import { CONFIG_FIELDS } from '/src/constants/evaluation-config'
import {
  tabOrdersForAdmin,
  tabOrdersForManager
} from '/src/sections/dashboard/admin/reviews/overview/dashboard-tabs'
import { LightTooltip } from '/src/components/light-tooltip'

/**
 * Dynamically generates buttons based on user roles and evaluation statuses.
 * Utilizes React components for rendering UI elements conditionally.
 *
 * @param {Object} params - The parameters object.
 * @param {Object} params.evaluation - The evaluation object containing status and other data.
 * @param {Object} params.helpers - An object containing helper functions for event handling.
 * @param {string} params.role - The current user's role.
 * @returns React elements corresponding to the specific role and evaluation status.
 */

export const getButtonsBasedOnRole = ({
  evaluation,
  helpers,
  role,
  isOpenCloseCycleAllowed
}) => {
  if (!evaluation || !role) {
    console.warn('Evaluation or role is missing', { evaluation, role })
    return null
  }

  switch (role) {
    case ROLES.ADMIN:
      return getAdminButtons({ evaluation, helpers, isOpenCloseCycleAllowed })
    case ROLES.MANAGER:
      return getManagerButtons(evaluation)
    case ROLES.EMPLOYEE:
      return getEmployeeButtons(evaluation)
    default:
      console.warn('Invalid role in getButtonsBasedOnRole', { role })
      return null
  }
}

const ButtonsWrapper = ({ children }) => (
  <Stack
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'right',
      gap: '8px'
    }}
  >
    {children}
  </Stack>
)

const AdminAndManagerActionButton = (props) => {
  const notOnSmallScreen = useMediaQuery('(min-width:800px)')
  return (
    <Button
      sx={{
        flexGrow: 1,
        width: notOnSmallScreen ? '160px' : '110px'
      }}
      size="small"
      color="primary"
      // Remove startIcon on small screens
      {...(notOnSmallScreen ? props : { ...props, startIcon: null })}
    />
  )
}

const ManagerCycleDetailsButton = ({ href }) => (
  <AdminAndManagerActionButton
    data-gaid="manager-see-progress"
    variant="outlined"
    component={RouterLink}
    aria-label="see-progress-button"
    children={useMediaQuery('(min-width:800px)') ? 'See Progress' : 'Progress'}
    startIcon={<TimelineIcon />}
    state={{
      isRoutingProper: true
    }}
    href={href}
  />
)

ManagerCycleDetailsButton.propTypes = {
  href: PropTypes.string.isRequired
}

const AdminCycleDetailsButton = (props) => (
  <AdminAndManagerActionButton
    data-gaid="manage-cycle"
    variant="outlined"
    component={RouterLink}
    aria-label="see-details-button"
    children={useMediaQuery('(min-width:800px)') ? 'Manage Cycle' : 'Manage'}
    startIcon={<ReadMoreIcon />}
    state={{
      isRoutingProper: true
    }}
    {...props}
  />
)

AdminCycleDetailsButton.propTypes = {
  href: PropTypes.string.isRequired
}

const SeeResults = ({ href, evaluationId, tab, ...otherProps }) => (
  <AdminAndManagerActionButton
    variant="outlined"
    component={RouterLink}
    aria-label="see-results-button"
    children="See Results"
    startIcon={<QueryStatsIcon />}
    state={{
      isRoutingProper: true,
      evaluationId,
      tab
    }}
    href={href}
    {...otherProps}
  />
)

SeeResults.propTypes = {
  href: PropTypes.string.isRequired
}

const AdminMoreButton = ({ onDelete }) => (
  <MoreMenu>
    <PopoverMenuButton
      item={{
        icon: <DeleteIcon />,
        text: 'Delete',
        props: {
          'onClick': onDelete,
          'data-gaid': 'delete-cycle'
        }
      }}
    />
  </MoreMenu>
)

AdminMoreButton.propTypes = {
  onDelete: PropTypes.func.isRequired
}

const getAdminButtons = ({ evaluation, helpers, isOpenCloseCycleAllowed }) => {
  const seeDetailsHref = paths.dashboard.admin.reviews.details(evaluation.uid)

  switch (evaluation.status) {
    case EVALUATION_STATES.ONGOING:
      return (
        <ButtonsWrapper>
          <AdminCycleDetailsButton href={seeDetailsHref} />
          <LightTooltip
            title={
              isOpenCloseCycleAllowed
                ? ''
                : 'This feature is not available on demo accounts.'
            }
          >
            <span>
              <AdminAndManagerActionButton
                data-gaid="close-cycle"
                data-testid="complete-button"
                variant="contained"
                aria-label="complete-button"
                disabled={!isOpenCloseCycleAllowed}
                children="Close Cycle"
                startIcon={<CheckIcon />}
                onClick={helpers.handleClick({
                  evaluationId: evaluation.uid,
                  type: actionTypes.COMPLETE
                })}
              />
            </span>
          </LightTooltip>
          <AdminMoreButton
            onDelete={helpers.handleClick({
              evaluationId: evaluation.uid,
              type: actionTypes.DELETE
            })}
          />
        </ButtonsWrapper>
      )
    case EVALUATION_STATES.AWAITING_RESULTS:
      return (
        <ButtonsWrapper>
          <AdminCycleDetailsButton href={seeDetailsHref} />
          <AdminMoreButton
            onDelete={helpers.handleClick({
              evaluationId: evaluation.uid,
              type: actionTypes.DELETE
            })}
          />
        </ButtonsWrapper>
      )
    case EVALUATION_STATES.COMPLETED:
      return (
        <ButtonsWrapper>
          <AdminCycleDetailsButton href={seeDetailsHref} />
          <SeeResults
            data-gaid="admin-see-results"
            href={paths.dashboard.admin.index}
            evaluationId={evaluation.uid}
            tab={tabOrdersForAdmin.CALIBRATION_ANALYSES}
          />
          <AdminMoreButton
            onDelete={helpers.handleClick({
              evaluationId: evaluation.uid,
              type: actionTypes.DELETE
            })}
          />
        </ButtonsWrapper>
      )
    case EVALUATION_STATES.TEMPLATE:
      return (
        <ButtonsWrapper>
          <AdminAndManagerActionButton
            data-gaid="edit-cycle"
            children="Edit"
            variant="outlined"
            component={RouterLink}
            aria-label="edit-button"
            href={paths.dashboard.admin.reviews.update(evaluation.uid)}
            startIcon={<EditIcon />}
            state={{ isRoutingProper: true }}
          />
          <LightTooltip
            title={
              isOpenCloseCycleAllowed
                ? ''
                : 'This feature is not available on demo accounts.'
            }
          >
            <span>
              <AdminAndManagerActionButton
                children="Launch"
                startIcon={<RocketLaunchIcon />}
                variant="contained"
                aria-label="launch-button"
                disabled={!isOpenCloseCycleAllowed}
                onClick={helpers.handleClick({
                  evaluationId: evaluation.uid,
                  type: actionTypes.START
                })}
              />
            </span>
          </LightTooltip>

          <AdminMoreButton
            onDelete={helpers.handleClick({
              evaluationId: evaluation.uid,
              type: actionTypes.DELETE
            })}
          />
        </ButtonsWrapper>
      )
    default:
      return null
  }
}

const getManagerButtons = (evaluation) => {
  const enableManagerResults = evaluation?.evaluationConfig.reportConfig.find(
    (config) => config.name === CONFIG_FIELDS.ENABLE_MANAGER_RESULTS
  ).display

  const seeCompletionHref = paths.dashboard.manager.reviews.progress(
    evaluation.uid
  )

  switch (evaluation.status) {
    case EVALUATION_STATES.ONGOING:
    case EVALUATION_STATES.AWAITING_RESULTS:
      return (
        <ButtonsWrapper>
          <ManagerCycleDetailsButton href={seeCompletionHref} />
        </ButtonsWrapper>
      )

    case EVALUATION_STATES.COMPLETED:
      return (
        <ButtonsWrapper>
          <ManagerCycleDetailsButton href={seeCompletionHref} />
          {enableManagerResults ? (
            <SeeResults
              data-gaid="manager-see-results"
              href={paths.dashboard.manager.index}
              evaluationId={evaluation.uid}
              tab={tabOrdersForManager.CALIBRATION_ANALYSES}
            />
          ) : null}
        </ButtonsWrapper>
      )
    default:
      return null
  }
}

const EmployeeActionButton = ({ sx, hidden, ...props }) => {
  const notOnSmallScreen = useMediaQuery('(min-width:800px)')
  const isRouterNeeded = props.to
  const ProperButton = isRouterNeeded ? RouterButton : Button
  return (
    <ProperButton
      variant="contained"
      size="small"
      sx={{
        width: notOnSmallScreen ? '170px' : '120px',
        minWidth: 'max-content',
        flexGrow: 1,
        display: hidden ? 'none' : 'flex',
        ...sx
      }}
      {...(!isRouterNeeded && {
        children: props.text
      })}
      {...(notOnSmallScreen ? props : { ...props, startIcon: null })}
    />
  )
}

const SeeFeedbackSummaryButton = ({ evaluationId }) => {
  const { isLoading, refetch } = useGetRequest({
    endpoint: Endpoints.GET_REVIEW,
    id: evaluationId,
    data: { evaluationId },
    config: {
      // Disable automatic fetching, fetch only when the refetch function is called
      refetchOnWindowFocus: false,
      enabled: false // disable this query from automatically running
    }
  })

  // ** We use useDialog hook to manage the state of the modal.
  const feedbacksSummaryModal = useDialog()

  const handleClick = async () => {
    // Fetch feedback data
    const { error, data } = await refetch()

    // Handle error
    if (error) {
      return toast.error('Failed to fetch feedback')
    }

    // Open feedbacks summary modal
    return feedbacksSummaryModal.handleOpen(data)
  }

  return (
    <Box>
      <EmployeeActionButton
        data-gaid="your-inputs"
        size="small"
        variant="outlined"
        startIcon={
          isLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <ReviewsIcon />
          )
        }
        onClick={handleClick}
        text={'My Inputs'}
      />
      <FeedbacksSummaryModal
        review={feedbacksSummaryModal.data}
        open={feedbacksSummaryModal.open}
        onClose={feedbacksSummaryModal.handleClose}
      />
    </Box>
  )
}

const getEmployeeButtons = (evaluation) => {
  const { review } = evaluation
  const { status } = review
  const evaluationId = evaluation.uid

  const enableEmployeeResults = evaluation.evaluationConfig.reportConfig.find(
    (config) => config.name === CONFIG_FIELDS.ENABLE_EMPLOYEE_RESULTS
  ).display

  // Check if at least one score display is enabled
  const noReportComponents = checkIfReportConfigHasComponentsToShow(
    evaluation.evaluationConfig.reportConfig
  )

  // PENDING_BATCHES // AWAITING PEER SELECTION
  // INCOMPLETE // STARTED BUT NOT FINISHED
  // SUBMITTED // FINISHED & AWAITING OTHERS
  // AWAITING_RESULTS // COMPLETED & AWAITING RESULTS
  // COMPLETED // COMPLETED & RESULTS READY
  // MISSED // EVALUATION COMPLETED BUT REVIEW NOT COMPLETED

  switch (status) {
    case REVIEW_STATES.AWAITING_SELECTION:
      return (
        <ButtonsWrapper>
          <motion.div
            // pulse animation on logo
            animate={{
              opacity: [1, 1],
              scale: [1.1, 1]
            }}
            transition={{
              duration: 0.5,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatType: 'reverse',
              type: 'spring'
            }}
          >
            <EmployeeActionButton
              data-gaid="review-start-button"
              startIcon={
                <SvgIcon>
                  <PlayArrowIcon />
                </SvgIcon>
              }
              to={paths.dashboard.reviews.selection(evaluationId)}
              text="Start"
              state={{ isRoutingProper: true }}
            />
          </motion.div>
        </ButtonsWrapper>
      )
    case REVIEW_STATES.INCOMPLETE:
      return (
        <ButtonsWrapper>
          <motion.div
            // pulse animation on logo
            animate={{
              opacity: [1, 1],
              scale: [1.1, 1]
            }}
            transition={{
              duration: 0.5,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatType: 'reverse',
              type: 'spring'
            }}
          >
            <EmployeeActionButton
              data-gaid="review-continue-button"
              startIcon={
                <SvgIcon>
                  <PlayArrowIcon />
                </SvgIcon>
              }
              to={paths.dashboard.reviews.questions(evaluationId)}
              text="Continue"
              state={{ isRoutingProper: true }}
            />
          </motion.div>
        </ButtonsWrapper>
      )
    case REVIEW_STATES.COMPLETED:
      return (
        <ButtonsWrapper>
          <SeeFeedbackSummaryButton evaluationId={evaluation.uid} />
          <EmployeeActionButton
            data-gaid="employee-see-results"
            hidden={!enableEmployeeResults || noReportComponents}
            startIcon={
              <SvgIcon>
                <QueryStatsIcon />
              </SvgIcon>
            }
            to={paths.dashboard.reviews.results(evaluationId)}
            text={'My Feedback'}
            state={{ isRoutingProper: true }}
          />
        </ButtonsWrapper>
      )
    case REVIEW_STATES.SUBMITTED:
      return (
        <ButtonsWrapper>
          <SeeFeedbackSummaryButton evaluationId={evaluation.uid} />
        </ButtonsWrapper>
      )
    case REVIEW_STATES.AWAITING_RESULTS:
      return (
        <ButtonsWrapper>
          <SeeFeedbackSummaryButton evaluationId={evaluation.uid} />
        </ButtonsWrapper>
      )

    default:
      return null
  }
}

export const getStatus = ({ evaluation, role }) => {
  // If the role is EMPLOYEE, the status is taken from the review
  if (ROLES.EMPLOYEE === role) {
    const reviewStatus = evaluation.review.status

    return {
      status: reviewStatusesForUI[reviewStatus],
      statusColor: reviewStatusesBgColorMap[reviewStatus] || 'warning'
    }
  } else if (ROLES.ADMIN === role || ROLES.MANAGER === role) {
    // If the role is ADMIN or MANAGER, the status is taken from the evaluation itself
    const evaluationStatus = evaluation.status

    return {
      status: evaluationStatusesForUI[evaluationStatus],
      statusColor: evaluationStatusesBgColorMap[evaluationStatus] || 'warning'
    }
  } else {
    console.warn('Invalid role in getStatus', { role })
    return null
  }
}

export const getDates = ({ evaluation, role }) => {
  if (ROLES.ADMIN === role || ROLES.MANAGER === role) {
    return getDatesForManagerAndAdmin(evaluation)
  } else if (ROLES.EMPLOYEE === role) {
    return getDatesForEmployee(evaluation)
  } else {
    console.warn('Invalid role in getDates', { role })
  }
}

const getDatesForManagerAndAdmin = (evaluation) => {
  // Dynamic startDate and endDates
  const dynamicStartDate =
    evaluation.status === EVALUATION_STATES.TEMPLATE
      ? evaluation.expectedStartDate
      : evaluation.startDate
  const dynamicEndDate =
    evaluation.status === EVALUATION_STATES.COMPLETED ||
    evaluation.status === EVALUATION_STATES.AWAITING_RESULTS
      ? evaluation.endDate
      : evaluation.expectedEndDate

  // Start Dates
  const startDateAtDay = dayjs.unix(dynamicStartDate?._seconds).format('D')
  const startDateAtMonth = dayjs
    .unix(dynamicStartDate?._seconds)
    .format('MMM')
    .toUpperCase()
  const startDateAtYear = dayjs.unix(dynamicStartDate?._seconds).format('YYYY')
  // End Dates
  const endDateAtDay = dayjs.unix(dynamicEndDate?._seconds).format('D')
  const endDateAtMonth = dayjs
    .unix(dynamicEndDate?._seconds)
    .format('MMM')
    .toUpperCase()
  const endDateAtYear = dayjs.unix(dynamicEndDate?._seconds).format('YYYY')

  return {
    startDateAtDay,
    startDateAtMonth,
    startDateAtYear,
    endDateAtDay,
    endDateAtMonth,
    endDateAtYear
  }
}

const getDatesForEmployee = (evaluation) => {
  // Dynamic endDate
  // AWAITING_RESULTS - COMPLETED - MISSED: endDate
  // AWAITING_SELECTION - INCOMPLETE - SUBMITTED: expectedEndDate
  const dynamicEndDate =
    evaluation.status === REVIEW_STATES.AWAITING_RESULTS ||
    evaluation.status === REVIEW_STATES.COMPLETED ||
    evaluation.status === REVIEW_STATES.MISSED
      ? evaluation.endDate
      : evaluation.expectedEndDate

  // Start Dates
  const startDateAtDay = dayjs.unix(evaluation.startDate?._seconds).format('D')
  const startDateAtMonth = dayjs
    .unix(evaluation.startDate?._seconds)
    .format('MMM')
    .toUpperCase()
  const startDateAtYear = dayjs
    .unix(evaluation.startDate?._seconds)
    .format('YYYY')
  // End Dates
  const endDateAtDay = dayjs.unix(dynamicEndDate?._seconds).format('D')
  const endDateAtMonth = dayjs
    .unix(dynamicEndDate?._seconds)
    .format('MMM')
    .toUpperCase()
  const endDateAtYear = dayjs.unix(dynamicEndDate?._seconds).format('YYYY')

  const status = evaluation.review.status
  const statusColor = reviewStatusesBgColorMap[status] || 'warning'

  const statusForUI = {
    [REVIEW_STATES.AWAITING_SELECTION]: 'Incomplete',
    [REVIEW_STATES.INCOMPLETE]: 'Incomplete',
    [REVIEW_STATES.AWAITING_RESULTS]: 'Awaiting Results',
    [REVIEW_STATES.SUBMITTED]: 'Submitted',
    [REVIEW_STATES.COMPLETED]: 'Completed',
    [REVIEW_STATES.MISSED]: 'Missed'
  }

  const dates = {
    startDateAtDay: startDateAtDay,
    startDateAtMonth: startDateAtMonth,
    startDateAtYear: startDateAtYear,
    endDateAtDay: endDateAtDay,
    endDateAtMonth: endDateAtMonth,
    endDateAtYear: endDateAtYear
  }

  return {
    ...dates,
    status: statusForUI[status],
    statusColor
  }
}

export const executeAction = async ({ evaluationId, type, methods }) => {
  const actionFunctions = {
    [actionTypes.DELETE]: (evaluationId) =>
      toast.promise(evaluationsRepo.deleteEvaluation({ evaluationId }), {
        pending: 'Deleting review...',
        success: {
          render() {
            methods.setDisabled(false)
            return 'Review deleted.'
          }
        },
        error: {
          render({ data: err }) {
            methods.setDisabled(false)
            console.warn(err)
            return removePrefixes(err.message) || 'Something went wrong.'
          }
        }
      }),
    [actionTypes.COMPLETE]: (evaluationId) =>
      toast.promise(evaluationsRepo.completeEvaluation({ evaluationId }), {
        pending: 'Completing review...',
        success: {
          render() {
            methods.setDisabled(false)
            return 'Review completed.'
          }
        },
        error: {
          render({ data: err }) {
            methods.setDisabled(false)
            console.warn(err)
            return removePrefixes(err.message) || 'Something went wrong.'
          }
        }
      }),
    [actionTypes.START]: (evaluationId) =>
      toast.promise(evaluationsRepo.startEvaluation({ evaluationId }), {
        pending: 'Starting review...',
        success: {
          render() {
            methods.setDisabled(false)
            return 'Review started.'
          }
        },
        error: {
          render({ data: err }) {
            methods.setDisabled(false)
            console.warn(err)
            return removePrefixes(err.message) || 'Something went wrong.'
          }
        }
      })
  }
  return await actionFunctions[type](evaluationId)
}

export const useActionsWithDialog = ({ refetch }) => {
  const dialog = useDialog()
  const [disabled, setDisabled] = useState(false)

  const handleClick = useCallback(
    ({ evaluationId, type }) =>
      async () => {
        dialog.handleOpen({
          evaluationId,
          ...dialogContents[type]
        })
      },
    [dialog]
  )

  const handleContinue = useCallback(async () => {
    const { evaluationId, type } = dialog.data

    setDisabled(true)
    const res = await executeAction({
      evaluationId,
      type,
      methods: {
        setDisabled
      }
    })

    consoleLog('response in handleContinue: ', res)

    await refetch()

    dialog.handleClose()
  }, [dialog, refetch])

  return {
    dialog,
    handleClick,
    handleContinue,
    disabled
  }
}
