import { ROLES } from '/functions/shared/constants/roles'

export const SCORE_BARS = {
  CHART_WITH_COHORT: 'chartWithCohort',
  CHART_WITH_ALL_PEOPLE: 'chart',
  BAR_WITH_ALL_PEOPLE: 'barWithRange',
  BAR_WITH_COHORT: 'barWithCohort',
  GRADE: 'grade'
}

export const DEFAULT_SCORE_LABELS = [
  'Needs Development',
  'Proficient',
  'Great Role Model'
]

export const SCORES = {
  PEERFUL_SCORE: 'peerfulScore',
  SELF_SCORE: 'selfScore',
  COMPANY_MIN: 'companyMin',
  COMPANY_MAX: 'companyMax',
  COMPANY_MEDIAN: 'companyMedian',
  COHORT_MIN: 'cohortMin',
  COHORT_MAX: 'cohortMax',
  COHORT_MEDIAN: 'cohortMedian',
  RAW_AVERAGE_SCORE: 'rawAverageScore',
  CONFIDENCE: 'confidence',
  PERCENTILE: 'percentile',
  STANDARD_DEVIATION: 'standardDeviation',
  REVIEWS_RECEIVED: 'reviewsReceived',
  REVIEWS_GIVEN: 'reviewsGiven',
  COMMENTS_RECEIVED: 'commentsReceived',
  SCORE_BAR: 'scoreBar',
  MANAGER_SCORE: 'managerScore',
  COHORT: 'cohort',
  COMPANY: 'company'
}

export const ALLOWED_EMPLOYEE_RESULT_FIELDS = [
  SCORES.SCORE_BAR,
  SCORES.SELF_SCORE,
  SCORES.PEERFUL_SCORE,
  SCORES.REVIEWS_RECEIVED,
  SCORES.COMMENTS_RECEIVED,
  SCORES.MANAGER_SCORE
]

export const CONFIG_FIELDS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  ENABLE_SELF_SCORING: 'enableSelfScoring',
  SELECT_MIN: 'selectMin',
  SELECT_MAX: 'selectMax',
  ALLOW_REQUEST_MIN: 'allowRequestMin',
  REQUEST_MIN: 'requestMin',
  ALLOW_COMMENT_MIN: 'allowCommentMin',
  COMMENT_MIN: 'commentMin',
  DIMENSIONS: 'dimensions',
  ENABLE_EMPLOYEE_RESULTS: 'enableEmployeeResults',
  ENABLE_MANAGER_RESULTS: 'enableManagerResults',
  PEERFUL_SCORE: 'peerfulScore',
  SCORE_BAR: 'scoreBar',
  SELF_SCORE: 'selfScore',
  MANAGER_SCORE: 'managerScore',
  REVIEWS_RECEIVED: 'reviewsReceived',
  COMMENTS_RECEIVED: 'commentsReceived',
  COMMENTS: 'comments',
  EMPLOYEE_SELECTION: 'employeeSelection',
  GRADES: 'grades',
  ENABLE_MANDATORY_COMMENTS_FOR_LOWEST_AND_HIGHEST:
    'enableMandatoryCommentsForLowestAndHighest',
  ENABLE_MANDATORY_INCLUSION_FOR_DIRECT_REPORTS:
    'enableMandatoryInclusionForDirectReports',
  ENABLE_MANDATORY_INCLUSION_FOR_DIRECT_MANAGER:
    'enableMandatoryInclusionForDirectManager',
  DISPLAY_SENIORITY_IN_REVIEW: 'displaySeniorityInReview',
  DISPLAY_MANAGER_SCORE_TO_MANAGER: 'displayManagerScoreToManager',
  ALLOW_MANAGER_TO_DOWNLOAD_PDF_WITH_COMMENTS:
    'allowManagerToDownloadPdfWithComments',
  GUIDELINES: 'guidelines'
}

export const defaultDataConfigOptions = {
  [CONFIG_FIELDS.SELECT_MIN]: 8,
  [CONFIG_FIELDS.SELECT_MAX]: 20,
  [CONFIG_FIELDS.REQUEST_MIN]: 5,
  [CONFIG_FIELDS.ENABLE_MANDATORY_INCLUSION_FOR_DIRECT_REPORTS]: false,
  [CONFIG_FIELDS.ENABLE_MANDATORY_INCLUSION_FOR_DIRECT_MANAGER]: false,
  [CONFIG_FIELDS.ENABLE_SELF_SCORING]: true,
  [CONFIG_FIELDS.ENABLE_MANDATORY_COMMENTS_FOR_LOWEST_AND_HIGHEST]: false,
  [CONFIG_FIELDS.COMMENT_MIN]: 3,
  [CONFIG_FIELDS.GRADES]: DEFAULT_SCORE_LABELS,
  [CONFIG_FIELDS.DISPLAY_SENIORITY_IN_REVIEW]: false,
  [CONFIG_FIELDS.GUIDELINES]: { [ROLES.EMPLOYEE]: '', [ROLES.MANAGER]: '' }
}

export const defaultReportConfigOptions = [
  {
    name: CONFIG_FIELDS.ENABLE_EMPLOYEE_RESULTS,
    display: false,
    description: null,
    type: null
  },
  {
    name: CONFIG_FIELDS.ENABLE_MANAGER_RESULTS,
    display: false,
    description: null,
    type: null
  },
  {
    name: CONFIG_FIELDS.SCORE_BAR,
    display: true,
    description: 'An overview of your performance for this behavior.',
    type: SCORE_BARS.CHART_WITH_COHORT
  },
  {
    name: CONFIG_FIELDS.SELF_SCORE,
    display: true,
    description: 'This scores shows how you rated yourself for this behavior.',
    type: null
  },
  {
    name: CONFIG_FIELDS.MANAGER_SCORE,
    display: true,
    description:
      'This scores shows how your manager rated you for this behavior.',
    type: null
  },
  {
    name: CONFIG_FIELDS.PEERFUL_SCORE,
    display: true,
    description: 'Your calibrated score for this behavior.',
    type: null
  },
  {
    name: CONFIG_FIELDS.REVIEWS_RECEIVED,
    display: true,
    description: "The number of reviews you've received.",
    type: null
  },
  {
    name: CONFIG_FIELDS.COMMENTS_RECEIVED,
    display: true,
    description: "The number of comments you've received.",
    type: null
  },
  {
    name: CONFIG_FIELDS.COMMENTS,
    display: true,
    description: null,
    type: null
  },
  {
    name: CONFIG_FIELDS.DISPLAY_MANAGER_SCORE_TO_MANAGER,
    display: true,
    description: null,
    type: null
  },
  {
    name: CONFIG_FIELDS.ALLOW_MANAGER_TO_DOWNLOAD_PDF_WITH_COMMENTS,
    display: true,
    description: null,
    type: null
  }
]
