import schemas from '/functions/shared/schemas'
import consoleLog from '/src/utils/console-log'
import resultsProvider from '/src/data/providers/results'
import userResultsModal from '/src/data/modals/user-results-modal'
import { convertFirestoreDateToTenure } from '/src/utils/convert-firestore-date-to-tenure'
import { USER_KEYS } from '/src/constants/user'
import { resultsModal } from '../modals/workforce-results-modal'

const resultsRepo = {
  getResultsForUser: async () => {
    try {
      const result = await resultsProvider.getResultsForUser()

      consoleLog('getResultsForUser --> beforeParsing', result)

      // Validating the results
      const validatedResult = schemas.getResultsForUser.validateSync(result)

      // Parsing the results
      const parsedResult = userResultsModal.prepareData(validatedResult)

      consoleLog('getResultsForUser --> afterParsing', parsedResult)

      return parsedResult
    } catch (error) {
      console.warn('getResultsForUser error: ', error)
      throw new Error(error)
    }
  },
  getWorkforceResults: async (data) => {
    try {
      const result = await resultsProvider.getWorkforceResults(data)

      consoleLog(
        'getWorkforceResults --> before parsing and validation',
        result
      )

      const validatedResult = schemas.getWorkforceResults.validateSync(result)

      // Add tenure field to companyResults
      validatedResult.forEach((r) => {
        if (USER_KEYS.JOIN_DATE in r) {
          r.tenure = convertFirestoreDateToTenure(r[USER_KEYS.JOIN_DATE])
        }
      })

      consoleLog(
        'getWorkforceResults --> after validation and before parsing',
        validatedResult
      )

      // Add percentiles
      const workforceResultsWithPercentiles =
        resultsModal.addPercentilesToWorkforceResults({
          workforceResults: validatedResult
        })

      return workforceResultsWithPercentiles
    } catch (error) {
      console.warn('getWorkforceResults error: ', error)
      throw new Error(error)
    }
  },
  updateResultNotes: async (data) => {
    try {
      const results = await resultsProvider.updateResultNotes(data)

      consoleLog('updateResultNotes --> beforeParsing', results)

      // Validating the result
      const validatedResult = schemas.updateResultNotes.validateSync(results)

      // Parsing the result

      consoleLog('updateResultNotes --> afterParsing', validatedResult)

      return validatedResult
    } catch (error) {
      console.warn('updateResultNotes error: ', error)
      throw new Error(error)
    }
  }
}

export default resultsRepo
