function descendingComparator(a, b, orderBy, exclude) {
  if (b[orderBy] === exclude) return -1 // exclude go to the end
  if (a[orderBy] === exclude) return 1 // exclude go to the end
  if (b[orderBy] < a[orderBy]) return -1
  if (b[orderBy] > a[orderBy]) return 1
  return 0
}

function getComparator(order, orderBy, exclude = undefined) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, exclude)
    : (a, b) => -descendingComparator(a, b, orderBy, exclude)
}

export { getComparator }
