import { Stack, Typography } from '@mui/material'
import React from 'react'

export const TitleWithHelper = ({ title, helperText, variant = 'h5' }) => (
  <Stack spacing={1}>
    <Typography variant={variant}>{title}</Typography>
    <Typography variant="caption" color="textSecondary">
      {helperText}
    </Typography>
  </Stack>
)
