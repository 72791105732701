import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Collapse, Stack, CollapseProps } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import TextButton from './buttons/text-button'

const BUTTONS_WRAPPER_ID = 'buttons-wrapper'

interface CustomCollapseProps extends CollapseProps {
  children: React.ReactNode
  blur?: boolean
  dataGaid?: string
  collapsedSize?: number
  expandMoreLabel?: string | null
  expandLessLabel?: string | null
  contentKey?: string
}

const CustomCollapse = ({
  children,
  blur = false,
  dataGaid = 'collapse-toggle',
  collapsedSize = 0,
  expandMoreLabel = 'Show',
  expandLessLabel = null,
  contentKey,
  ...otherProps
}: CustomCollapseProps) => {
  const [toggle, setToggle] = useState(false)
  const [showButtons, setShowButtons] = useState(true)
  const contentRef = useRef<HTMLDivElement>(null)
  const [dynamicCollapsedSize, setDynamicCollapsedSize] =
    useState(collapsedSize)

  const blurBottomHeight =
    document.getElementById(BUTTONS_WRAPPER_ID)?.offsetHeight

  const handleToggle = useCallback(() => {
    setToggle((prev) => !prev)
  }, [])

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight

      if (contentHeight <= collapsedSize) {
        setShowButtons(false)
        setDynamicCollapsedSize(contentHeight)
        setToggle(false)
      } else {
        setShowButtons(true)
        setDynamicCollapsedSize(collapsedSize)
        setToggle(false)
      }
    }
  }, [contentKey]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack
      sx={{
        position: 'relative'
      }}
    >
      {/* Hidden element to measure content height */}
      <Box
        ref={contentRef}
        sx={{
          visibility: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
          height: 'auto',
          overflow: 'hidden',
          pointerEvents: 'none'
        }}
      >
        {children}
      </Box>
      <Box>
        <Collapse
          key={contentKey}
          in={toggle}
          timeout="auto"
          sx={{
            '.MuiCollapse-hidden': {
              position: 'relative'
            },
            'pl': {
              xs: 1,
              md: 0
            },
            'pr': {
              xs: 3,
              md: 0
            }
          }}
          collapsedSize={dynamicCollapsedSize}
          {...otherProps}
        >
          {children}
        </Collapse>
        {blur && showButtons && (
          <Box
            sx={{
              display: !toggle ? 'block' : 'none',
              position: 'absolute',
              bottom: blurBottomHeight ? blurBottomHeight : 43,
              left: 0,
              right: 0,
              height: 90,
              background: (theme) =>
                theme.palette.mode === 'dark'
                  ? `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #111927 100%)`
                  : `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)`,
              transition: 'all 0.5s ease-in-out'
            }}
          />
        )}
      </Box>
      {showButtons && (
        <Stack
          id={BUTTONS_WRAPPER_ID}
          sx={{
            alignItems: 'center'
          }}
        >
          <TextButton
            onClick={handleToggle}
            endIcon={toggle ? <ExpandLess /> : <ExpandMore />}
            sx={{
              pr: toggle ? 3.5 : 2
            }}
            data-gaid={dataGaid}
          >
            {toggle ? expandLessLabel : expandMoreLabel}
          </TextButton>
        </Stack>
      )}
    </Stack>
  )
}

export default CustomCollapse
