import { Box, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined'
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined'

const CheckConnection = ({ children }) => {
  const [connectionStatus, setConnectionStatus] = useState('initial')
  const [toastToDismiss, setToastToDismiss] = useState(null)
  const theme = useTheme()

  useEffect(() => {
    const handleOnlineStatus = () => {
      setConnectionStatus('online')
    }

    const handleOfflineStatus = () => {
      setConnectionStatus('offline')
    }

    // Add event listeners for online and offline events
    window.addEventListener('online', handleOnlineStatus)
    window.addEventListener('offline', handleOfflineStatus)

    return () => {
      // Clean up the event listeners when the component is unmounted
      window.removeEventListener('online', handleOnlineStatus)
      window.removeEventListener('offline', handleOfflineStatus)
    }
  }, [])

  useEffect(() => {
    if (connectionStatus === 'online') {
      toast(
        <Box>
          <Typography variant="subtitle1">You're online now</Typography>
          <Typography variant="body2">
            Hurray! Internet is connected.
          </Typography>
        </Box>,
        {
          icon: <WifiOutlinedIcon sx={{ color: theme.palette.success.main }} />,
          autoClose: 3000
        }
      )
    } else if (connectionStatus === 'offline') {
      const offlineToast = toast(
        <Box>
          <Typography variant="subtitle1">You're offline</Typography>
          <Typography variant="body2">
            Opps! Internet is disconnected.
          </Typography>
        </Box>,
        {
          icon: <WifiOffOutlinedIcon color="action" />,
          autoClose: false,
          closeOnClick: false
        }
      )

      setToastToDismiss(offlineToast)
    }
  }, [connectionStatus, theme.palette.success.main])

  if (connectionStatus === 'online') {
    toast.dismiss(toastToDismiss)
  }

  return <>{children}</>
}

export default CheckConnection
