import { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import { format } from 'date-fns'
import { removeMd } from '/src/utils/remove-markdown'

const EmployeeResultsPdfDocument = (props) => {
  const { result, isEmployee, fullName, images, withComments } = props
  const results = result.resultsByQuestion

  const styles = useStyles()

  // Getting the current date
  const currentDate = new Date()
  const issueDate = format(currentDate, 'dd MMM yyyy')

  // Creating component variables
  const resultTitle = `${isEmployee ? 'My' : fullName + "'s"} Results`

  return (
    <Document title={`Results for ${fullName}`} author="Incompass Labs">
      <Page size="A4" style={styles.page}>
        <View style={styles.backgroundOverlay} fixed>
          <Image source="/assets/logo.png" />
        </View>
        <View style={styles.header}>
          <View style={styles.logo}>
            <Image
              source="/assets/logo.png"
              style={[styles.brand, { marginBottom: 4 }]}
            />
            <Text style={styles.h6}>Incompass Labs</Text>
          </View>
          <View style={styles.headerContent}>
            <Text style={[styles.h3, { marginBottom: 4 }]}>{resultTitle}</Text>
            <Text style={styles.subtitle2}>{result?.evaluationName}</Text>
          </View>
          <Text style={styles.h6}>{issueDate}</Text>
        </View>
        <View>
          {results?.map((resultByQuestion, index) => {
            return (
              <View key={resultByQuestion?.questionId} style={styles.card}>
                <View wrap={false}>
                  <View style={styles.cardHeader}>
                    <Text style={styles.h4}>{resultByQuestion?.behavior}</Text>
                    <Image src={images[index]} style={styles.resultHeader} />
                  </View>
                </View>
                {withComments ? (
                  <>
                    {resultByQuestion.selfComment ? (
                      <View
                        style={{
                          marginBottom: 8
                        }}
                      >
                        <Text style={styles.h6} pb={2}>
                          Self Reflection:{' '}
                        </Text>
                        <Text style={styles.body2}>
                          {resultByQuestion.selfComment}
                        </Text>
                      </View>
                    ) : null}
                    <View>
                      <Text style={styles.h6}>
                        {resultByQuestion?.comments.length
                          ? 'Comments: '
                          : 'No comments were received'}
                      </Text>
                      {resultByQuestion?.comments?.map((comment, key) => (
                        <Text
                          key={key}
                          style={[styles.body2, { marginBottom: 8 }]}
                          wrap={false}
                        >
                          {key + 1 + ' - ' + removeMd(comment.comment)}
                        </Text>
                      ))}
                    </View>
                  </>
                ) : null}
              </View>
            )
          })}
        </View>
        <View style={styles.footer} fixed>
          <Text
            style={styles.h6}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  )
}

EmployeeResultsPdfDocument.propTypes = {
  result: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  fullName: PropTypes.string.isRequired,
  withComments: PropTypes.bool.isRequired,
  isEmployee: PropTypes.bool.isRequired
}

export default EmployeeResultsPdfDocument

const useStyles = () => {
  return useMemo(() => {
    return StyleSheet.create({
      progressBar: {
        width: '40%',
        height: 10,
        backgroundColor: '#EEEEEE',
        borderRadius: 7.5
      },
      progress: {
        height: 10,
        borderRadius: 7.5
      },
      point: {
        position: 'absolute',
        width: 2,
        height: 10,
        backgroundColor: '#3063BA'
      },
      point2: {
        position: 'absolute',
        width: 2,
        height: 10,
        borderRadius: 3,
        backgroundColor: '#3063BA'
      },
      pointText: {
        color: '#3063BA',
        fontSize: 8,
        fontWeight: 300,
        lineHeight: 1.33,
        position: 'absolute',
        bottom: 10
      },
      line: {
        position: 'absolute',
        top: 5,
        height: 1,
        backgroundColor: '#3063BA'
      },
      decisiveInfo: {
        marginBottom: 12
      },
      page: {
        backgroundColor: '#FFFFFF',
        padding: 36
      },
      headerContent: {
        display: 'flex',
        flex: 1,
        maxWidth: 250,
        textAlign: 'center'
      },
      h3: {
        fontSize: 16,
        fontWeight: 800,
        lineHeight: 1.335
      },
      h4: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1.235,
        maxWidth: 150
      },
      h6: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.6
      },
      h7: {
        fontSize: 10,
        fontWeight: 900,
        lineHeight: 1.3
      },
      tinyLetters: {
        fontSize: 5,
        fontWeight: 400,
        lineHeight: 1.3
      },
      logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
      backgroundOverlay: {
        position: 'absolute',
        bottom: -100,
        left: -400,
        opacity: 0.05,
        height: 750,
        width: 750
      },
      subtitle2: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: 1.57
      },
      body2: {
        fontSize: 10,
        fontWeight: 400,
        lineHeight: 1.43
      },
      header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
        paddingBottom: 8,
        borderBottomWidth: 1,
        borderColor: '#EEEEEE',
        borderStyle: 'solid'
      },
      resultHeader: {
        maxHeight: 350,
        maxWidth: 400
      },
      brand: {
        height: 24,
        width: 24
      },
      cardHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: 16,
        marginBottom: 16
      },
      footer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0,
        paddingVertical: 8,
        alignSelf: 'center'
      },
      card: {
        paddingTop: 24,
        paddingBottom: 12,
        borderBottomWidth: 1,
        borderColor: '#EEEEEE',
        borderStyle: 'solid'
      }
    })
  }, [])
}
