import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material'
import { useState } from 'react'
import alasql from 'alasql'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import SendIcon from '@mui/icons-material/Send'
import consoleLog from '/src/utils/console-log'
import aiRepo from '/src/data/repos/ai-repo'
import { InfoSign } from '/src/components/info-sign'
import ReactMarkdown from 'react-markdown'
import { SCORES } from '/src/constants/evaluation-config'

const createTableDescription = (data) => {
  // columns is an array of objects. each object has a key and a label. get keys as a list:
  const keys = Object.keys(data[0])
  // Recreate data with only the keys we want. Check the type of field in each key and add that to the types list
  // delete the keys in the following list []
  const newData = data.map((row) => {
    const newRow = {}
    keys.forEach((key) => {
      if (
        ![
          'uid',
          'userId',
          'averageReviewerPeopleChange',
          'averageSelectedPeopleChange',
          'confidence',
          'competencyScore',
          'valueScore',
          'userResults'
        ].includes(key) &&
        typeof row[key] !== 'object'
      ) {
        if (key === SCORES.PEERFUL_SCORE) {
          newRow['calibrationScore'] = row[key]
        } else {
          newRow[key] = row[key]
        }
      }
    })
    return newRow
  })

  const finalKeys = Object.keys(newData[0])
  const tableColumnsWithTypes = `(${finalKeys.join(', ')})`

  try {
    // create alasql table using each keys and types if the type and skip if type is object.
    alasql.exec('DROP TABLE IF EXISTS employeeResults')
    alasql('CREATE TABLE employeeResults ' + tableColumnsWithTypes)
    alasql.tables.employeeResults.data = newData
  } catch (err) {
    consoleLog('alasql error:', err)
  }

  // (fullName string,behavior string,peerfulScore number,percentile number,confidence number,manager string,team string,division string,jobTitle string,seniority string,jobLevel number,tenure number,gender string,race string,reviewsReceived number,reviewsGiven number)
  let tableDescription = `The table provides the results of performance assessments for an organization. The scores were calculated using the "Incompass Algorithm", which intelligently aggregates colleague reviews. The table has the following columns: ${tableColumnsWithTypes}.\n`
  // Explain what each column means:
  tableDescription += `\nThe fullName column contains the name of the reviewed employee.`
  tableDescription += `\nThe calibrationScore column contains the average score that the reviewed person received. A low score means poor performance, and 
    a high score means good performance. The min score is ${Math.min(
      ...newData.map((row) => row.calibrationScore)
    )} and the max score is ${Math.max(
    ...newData.map((row) => row.calibrationScore)
  )}.`
  tableDescription += `\nThe percentile column contains the percentile (0 to 100) that 
      the reviewed person received based on the Incompass Score.`
  tableDescription += `\nThe confidence column contains the prediction confidence (0 to 100) 
      of the Incompass Algorithm in assigning this score to each employee. A high confidence means that we are 
      confident with the calibrationScore, a low score means we are not confident about the predicted calibrationScore.`
  tableDescription += `\nThe manager column contains the name of the manager of the reviewed employee.`
  // Describe the values that each column can take:
  finalKeys.forEach((key) => {
    if (['team', 'division', 'seniority', 'jobLevel', 'gender'].includes(key)) {
      // get all possible values for each key
      tableDescription += `\nThe ${key} column can take the following values: ${Array.from(
        new Set(newData.map((row) => row[key]).filter((val) => val))
      ).join(', ')}. `
    }
  })

  return { tableDescription, newData }
}

const askQuery = async (query, data) => {
  const { tableDescription, newData } = createTableDescription(data)

  // Try query trice. If it fails, return null.
  for (let i = 0; i < 3; i++) {
    consoleLog(`Trying query - attempt ${i + 1}:`, query)
    try {
      // AI Step #1
      // Query Vertex AI for SQL query.
      const questionToSqlQueryPrompt = `Analyze the SQL table that can be described as follows: "${tableDescription}"
          \n\n
          \nThe table name is employeeResults. Use ' as the quote character. Quote column aliases with ".
          Write a single-line SQL query to answer the following: ${query}
          \n\nMake sure to:
          \n1. If the question asking something unrelated to the table, say "I don't know".
          \n2. Limit the number of users returned to at most 10.
          \n3. Do not use quotes.
          \n4. Use "is" instead of "=" for equality check.
          \n5. Return only the SQL command, to be run directly on the table, without any additional text.`

      // Submit query to Vertex AI
      const sqlQueryResponse = await aiRepo.queryGemini({
        contents: [
          {
            role: 'user',
            parts: [
              {
                text: questionToSqlQueryPrompt
              }
            ]
          }
        ]
      })
      let sqlQuery = sqlQueryResponse[
        sqlQueryResponse.length - 1
      ]?.parts[0]?.text?.replaceAll('`', '')
      // Remove first incorrect text from the sqlQuery if the first three characters are "sql"
      if (sqlQuery.slice(0, 3) === 'sql') {
        sqlQuery = sqlQuery.slice(3).trim()
      }

      consoleLog('sqlQuery:', sqlQuery)
      // Use query to get result from alasql
      const alasqlResult = await alasql(sqlQuery, [newData])
      consoleLog('alasqlResult:', alasqlResult)

      // If no results were found, continue.
      if (alasqlResult?.length === 0) {
        consoleLog('No results found with alasql.')
        continue
      }

      // Post process to make sure all numerical values in the data have at most two decimal points
      alasqlResult.forEach((row) => {
        Object.keys(row).forEach((key) => {
          if (typeof row[key] === 'number') {
            row[key] = parseFloat(row[key].toFixed(1))
          }
        })
      })

      // AI Step #2
      // Query Vertex AI for table to text
      const tableToTextPrompt = `You used the following SQL command to answer the question "${query}": ${sqlQuery}. 
          The original table can be described as follows: "${tableDescription}"
          \n\n
          \nThe following table was given as a result of the SQL query: \n${JSON.stringify(
            alasqlResult
          )}.
          \n
          Convert this result table to natural language, such that your response will actually 
          be an answer to the original question ${query}. 
          \n\nMake sure to:
          \n1. Include numbers in your answer and justification when appropriate.
          \n2. Reply with a kind tone.
          \n3. Avoid using table column names directly.
          `

      // Get the final response text. If we found an answer and alasql isn't empty, break out of the loop.
      const finalTextResponse = await aiRepo.queryGemini({
        contents: [
          {
            role: 'user',
            parts: [
              {
                text: tableToTextPrompt
              }
            ]
          }
        ]
      })
      const finalText =
        finalTextResponse[finalTextResponse.length - 1]?.parts[0]?.text
      if (finalText) {
        consoleLog('finalText:', finalText)
        return finalText
      }
    } catch (err) {
      continue
    }
  }
  return "Couldn't find an answer... Please try another question."
}

export const QueryComponent = ({ data }) => {
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)

  const handleChange = (e) => {
    setQuery(e.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    setResponse(await askQuery(query, data))
    setLoading(false)
  }

  return (
    <Container style={{ maxWidth: '120vh' }}>
      <Stack
        spacing={3}
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: '20px', marginBottom: '35px' }}
      >
        <Stack direction="row" width="100%" spacing={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AutoAwesomeIcon color="primary" fontSize="large" />
          </Box>
          <Stack spacing={2} width="100%">
            <form onSubmit={handleSubmit}>
              <TextField
                placeholder="Ask a question..."
                variant="outlined"
                value={query}
                onChange={handleChange}
                disabled={loading}
                fullWidth
                data-gaid="ask-anything-input"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" disabled={!query || loading}>
                        {loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <SendIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </form>
          </Stack>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InfoSign
              info='You can use this field to ask questions about the data in natural language. For example, you can ask: "Who are the best managers, based on the success of their teams?" or "What is the average score for the Sales team?"'
              color="primary"
              sx={{
                marginTop: '-25px',
                marginLeft: '-15px'
              }}
              tooltipProps={{
                placement: 'top-start'
              }}
            />
          </Box>
        </Stack>
        {response && (
          <Box
            sx={{
              backgroundColor: (theme) => `${theme.palette.primary.main}10`, // 80 in HEX is 50% in opacity
              borderRadius: '16px', // Rounded edges
              p: 3,
              mt: 2,
              width: '100%'
            }}
          >
            {loading ? (
              <Stack sx={{ alignItems: 'center' }}>
                <CircularProgress
                  size={24}
                  style={{
                    backgroundColor: (theme) => `${theme.palette.primary.main}`
                  }}
                />
              </Stack>
            ) : (
              <ReactMarkdown>{response}</ReactMarkdown>
            )}
          </Box>
        )}
      </Stack>
    </Container>
  )
}
