import PropTypes from 'prop-types'
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined'
import { Box, ButtonBase, SvgIcon, Tooltip } from '@mui/material'
import { usePathname } from '/src/hooks/use-pathname'
import { useTheme } from '@mui/system'

export const FAQButton = (props) => {
  const { sectionParamType, settings, ...other } = props
  const theme = useTheme()
  const pathName = usePathname()
  const sectionPathType = pathName === '/' ? 'index' : pathName.split('/').pop() // Evaluate for index page

  const handleDrawerOpen = () =>
    settings.handleSettingsPreviewUpdate({
      faqDrawerOpen: sectionParamType ? sectionParamType : sectionPathType
    })

  return (
    <Tooltip title="Help">
      <Box {...other}>
        <ButtonBase
          data-gaid="help-button"
          sx={{
            backgroundColor: 'primary.main',
            borderRadius: '50%',
            color:
              theme.palette.mode === 'light'
                ? theme.palette.customColors.textColor
                : 'primary.contrastText',
            p: '4px'
          }}
          onClick={handleDrawerOpen}
          size="small"
        >
          <SvgIcon>
            <QuestionMarkOutlinedIcon size="small" />
          </SvgIcon>
        </ButtonBase>
      </Box>
    </Tooltip>
  )
}

FAQButton.propTypes = {
  settings: PropTypes.object.isRequired,
  sectionParamType: PropTypes.string
}
