import { useTheme } from '@mui/material'
import { useAuth } from './use-auth'

export const useUser = () => {
  const theme = useTheme()

  function stringAvatar(name, width, height, fontSize) {
    if (!name) {
      return {
        sx: {
          width,
          height,
          fontSize,
          color:
            theme.palette.mode === 'light' &&
            theme.palette.customColors.textColor,
          bgcolor:
            theme.palette.mode === 'light' && theme.palette.customColors[900]
        },
        children: '??'
      }
    }

    return {
      sx: {
        width,
        height,
        fontSize,
        color:
          theme.palette.mode === 'light' &&
          theme.palette.customColors.textColor,
        bgcolor:
          theme.palette.mode === 'light' && theme.palette.customColors[900]
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    }
  }

  // To get the user from the authContext
  const { user, signingOut, updateUser } = useAuth()

  return { user, signingOut, stringAvatar, updateUser }
}
