import { Box, Container, Stack, Typography, useMediaQuery } from '@mui/material'
import { RouterLink } from '/src/components/router-link'
import { Seo } from '/src/components/seo'
import { useRouter } from '/src/hooks/use-router'
import { paths } from '/src/constants/paths'
import TextButton from '/src/components/buttons/text-button'
import PrimaryButton from '/src/components/buttons/primary-button'

const Page = () => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const router = useRouter()

  return (
    <>
      <Seo title="Error: Authorization Required" />
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6
            }}
          >
            <Box
              alt="Not authorized"
              component="img"
              src="/assets/errors/error-401.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400
              }}
            />
          </Box>
          <Typography align="center" variant={mdUp ? 'h1' : 'h4'}>
            401: Authorization required
          </Typography>
          <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation.
          </Typography>
          <Stack
            sx={{
              justifyContent: 'center',
              mt: 6,
              mb: mdUp ? 3 : 0
            }}
            direction={mdUp ? 'column' : 'row'}
            spacing={2}
          >
            <TextButton component={RouterLink} onClick={() => router.back()}>
              Back to Previous Page
            </TextButton>
            <PrimaryButton component={RouterLink} href={paths.index}>
              Back to Home
            </PrimaryButton>
          </Stack>
        </Container>
      </Box>
    </>
  )
}

export default Page
