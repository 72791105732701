import {
  IconButton,
  Link,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import Proptypes from 'prop-types'
import { useCallback } from 'react'
import ReactMarkDown from 'react-markdown'

const DisplayBehavior = ({
  index,
  behavior,
  toggleEditMode,
  handleDeleteBehavior
}) => {
  const handleDeleteClick = useCallback(async () => {
    handleDeleteBehavior(index)
  }, [handleDeleteBehavior, index])

  return (
    <>
      <ListItemText
        sx={{
          maxWidth: '75%'
        }}
        primary={
          <Typography mb={1}>
            {behavior.title}{' '}
            {behavior.helperLink ? (
              <Link
                target="_blank"
                href={
                  behavior.helperLink.includes('http')
                    ? behavior.helperLink
                    : 'https://' + behavior.helperLink
                }
                variant="caption"
                fontWeight={500}
                marginLeft={1}
              >
                {behavior.helperLink}
              </Link>
            ) : null}
          </Typography>
        }
        secondaryTypographyProps={{
          component: 'span'
        }}
        secondary={<ReactMarkDown>{behavior.description}</ReactMarkDown>}
      />
      <ListItemSecondaryAction>
        <Stack flexDirection="row" columnGap={2}>
          <Tooltip title="Edit">
            <IconButton edge="end" onClick={toggleEditMode} name="Edit">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton edge="end" onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </ListItemSecondaryAction>
    </>
  )
}

DisplayBehavior.propTypes = {
  index: Proptypes.number.isRequired,
  behavior: Proptypes.object.isRequired,
  toggleEditMode: Proptypes.func.isRequired,
  handleDeleteBehavior: Proptypes.func.isRequired
}

export default DisplayBehavior
