// Components
import {
  Card,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
  StepButton
} from '@mui/material'

// Others
import PropTypes from 'prop-types'
import { Check } from '@mui/icons-material'
import { forwardRef, useCallback, useMemo } from 'react'
import { useMediaQuery } from '@mui/material'
import { useUser } from '/src/hooks/use-user'
import { checkAllConditions } from '/src/sections/dashboard/reviews/questions/hooks/use-question'
import { useLocation, useParams } from 'react-router'
import { useRouter } from '/src/hooks/use-router'
import { paths } from '/src/constants/paths'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
}))

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  'color': theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  'display': 'flex',
  'height': 22,
  'alignItems': 'center',
  ...(ownerState.active && {
    color: theme.palette.success.main
  }),
  '& .QontoStepIcon-completedIcon': {
    color: theme.palette.success.main,
    zIndex: 1,
    fontSize: 24
  },
  '& .QontoStepIcon-circle': {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    ...(ownerState.partiallyCompleted && {
      backgroundColor: theme.palette.warning.main
    })
  }
}))

const QontoStepIcon = (props) => {
  const { active, completed, className, partiallyCompleted } = props

  return (
    <QontoStepIconRoot
      ownerState={{ active, partiallyCompleted }}
      className={className}
    >
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool
}

const completionStatuses = {
  PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED',
  COMPLETED: 'COMPLETED',
  NOT_COMPLETED: 'NOT_COMPLETED'
}

export const constantStepIds = {
  START_REVIEW: 'start-review',
  SELECT_PEERS: 'select-peers',
  FEEDBACKS: 'feedbacks',
  SUBMIT: 'submit'
}

// Custom hook to handle the review progress operations
const useReviewProgress = ({ review, onQuestionStepClick }) => {
  const router = useRouter()
  // If user comes selection page first time, we disable Give Feedback step
  // because they haven't completed selection yet
  const { state } = useLocation()
  const isReSelection = state?.isReSelection
  const { user } = useUser()
  const { evaluationId } = useParams()

  const questions = review?.questions

  // Default and dynamic steps based on the review's questions
  const steps = useMemo(
    () => [
      {
        label: 'Start Review',
        id: constantStepIds.START_REVIEW,
        completionStatus: completionStatuses.COMPLETED
      },
      {
        label: 'Select Peers',
        id: constantStepIds.SELECT_PEERS,
        completionStatus: completionStatuses.COMPLETED
      },
      ...(questions?.length > 0
        ? questions.map((question) => {
            const conditions = checkAllConditions({
              currentQuestion: question,
              review,
              user
            })

            const completionStatus = conditions.every(
              (condition) => condition.passed
            )
              ? completionStatuses.COMPLETED
              : conditions.some((condition) => condition.passed)
              ? completionStatuses.PARTIALLY_COMPLETED
              : completionStatuses.NOT_COMPLETED

            return {
              label: question.title,
              id: question.questionId,
              completionStatus
            }
          })
        : [
            {
              label: 'Give Feedback',
              id: constantStepIds.FEEDBACKS,
              completionStatus: isReSelection
                ? completionStatuses.PARTIALLY_COMPLETED
                : completionStatuses.NOT_COMPLETED,
              disabled: !isReSelection
            }
          ]),
      { label: 'Submit', id: constantStepIds.SUBMIT, completed: false }
    ],
    [questions, review, user, isReSelection]
  )

  // Handle step click based on the stepId
  const handleStepClick = useCallback(
    (stepId) => async () => {
      // Find the index of the question in the questions array that matches the stepId
      const index = questions?.findIndex(
        (question) => question.questionId === stepId
      )

      // If such a question is found and an onClick function is provided, call the onClick function with the stepId
      if (index >= 0 && onQuestionStepClick)
        return await onQuestionStepClick(stepId)
      // If the stepId is 'select-peers', navigate to the peer selection page
      else if (stepId === constantStepIds.SELECT_PEERS) {
        return router.push(paths.dashboard.reviews.selection(evaluationId), {
          state: {
            isRoutingProper: true,
            isReSelection: true
          }
        })
      }
      // If the stepId is 'feedbacks', navigate to the questions page
      else if (stepId === constantStepIds.FEEDBACKS)
        return router.push(paths.dashboard.reviews.questions(evaluationId), {
          state: {
            isRoutingProper: true
          }
        })

      // If no matching question is found and the stepId is not 'select-peers', 'submit', or 'feedbacks', log a warning message
      return console.warn(
        'No condition matched for stepId in ReviewProgress: ',
        stepId
      )
    },
    [evaluationId, onQuestionStepClick, questions, router]
  )

  return {
    steps,
    handleStepClick
  }
}

export const ReviewProgress = forwardRef(
  ({ activeStepIndex, review, onQuestionStepClick }, ref) => {
    const down720px = useMediaQuery('(max-width:720px)')
    const { steps, handleStepClick } = useReviewProgress({
      review,
      onQuestionStepClick
    })

    return (
      <Stepper
        activeStep={activeStepIndex}
        connector={<QontoConnector />}
        component={Card}
        sx={{
          p: 2,
          boxShadow: 20,
          display: down720px ? 'none' : 'flex'
        }}
        ref={ref}
        nonLinear
      >
        {steps.map((step, i, arr) => (
          <Step
            key={step.label}
            // Disable click if step is the first, last, or active step
            disabled={
              i === 0 ||
              i === activeStepIndex ||
              i === arr.length - 1 ||
              step.disabled
            }
            // Add a completed=true prop to the Step component if the step's completionStatus is 'COMPLETED' to show the check icon
            {...(i !== activeStepIndex && {
              completed: step.completionStatus === completionStatuses.COMPLETED
            })}
          >
            <StepButton onClick={handleStepClick(step.id)}>
              <StepLabel
                StepIconComponent={QontoStepIcon}
                sx={{
                  'flexDirection': 'column-reverse',
                  // remove padding right on step label
                  '.MuiStepLabel-iconContainer': { pr: 0 },
                  '& .MuiStepLabel-label': {
                    fontSize: {
                      sm: 12,
                      md: 14
                    },
                    textAlign: 'center'
                  }
                }}
                StepIconProps={{
                  // Adjust step color by adding partiallyCompleted prop to the StepIconComponent (warning color for partially completed steps)
                  partiallyCompleted:
                    step.completionStatus ===
                      completionStatuses.PARTIALLY_COMPLETED &&
                    activeStepIndex !== i
                }}
                componentsProps={{
                  label: {
                    sx: {
                      // If step is not completed, default color is text.secondary, so we need to override it for partially completed steps
                      ...(step.completionStatus ===
                        completionStatuses.PARTIALLY_COMPLETED &&
                        activeStepIndex !== i && {
                          color: 'text.primary',
                          fontWeight: 500
                        })
                    }
                  }
                }}
              >
                {step.label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    )
  }
)

ReviewProgress.propTypes = {
  review: PropTypes.object,
  onQuestionStepClick: PropTypes.func
}
