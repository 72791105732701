import usersProvider from '/src/data/providers/user'
import schemas from '/functions/shared/schemas'
import consoleLog from '/src/utils/console-log'

const usersRepo = {
  getUser: async () => {
    try {
      const result = await usersProvider.getUser()

      consoleLog('result in getUser before parsing and validation', result)

      // validating the result
      const validatedResult = schemas.getUser.validateSync(result)

      consoleLog(
        'result in getUser after validation and before parsing',
        validatedResult
      )

      const { userRoles } = validatedResult

      return {
        ...validatedResult,
        userRoles: userRoles
      }
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  updateTutorials: async (data) => {
    try {
      const result = await usersProvider.updateTutorials(data)

      consoleLog(
        'result in updateTutorials before parsing and validation',
        result
      )

      // validating the result
      const validatedResult = schemas.updateTutorials.validateSync(result)

      // parse result here

      return validatedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  getCompanyPeople: async (data) => {
    try {
      const result = await usersProvider.getCompanyPeople(data)

      consoleLog(
        'result in getCompanyPeople before parsing and validation',
        result
      )

      // validating the result
      const validatedResult = schemas.batchGetUsers.validateSync(result)

      consoleLog('result in getCompanyPeople after parsing', validatedResult)

      return validatedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  setUserPermissions: async (data) => {
    try {
      const result = await usersProvider.setUserPermissions(data)

      // validating the result
      const validatedResult = schemas.setUserPermissions.validateSync(result)

      // parse result here

      return validatedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  },
  deleteUser: async (data) => {
    try {
      const result = await usersProvider.deleteUser(data)

      // validating the result
      const validatedResult = schemas.deleteUser.validateSync(result)

      // parse result here

      return validatedResult
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  }
}

export default usersRepo
