// Components
import { Stack, Typography } from '@mui/material'
import { UserScoreChart } from '/src/components/charts/user-score-chart'
import { ScoreGrade } from '/src/components/score-grade'
import BarWithRange from '/src/components/charts/bar-with-range'

// Constants
import { SCORE_BARS } from '/src/constants/evaluation-config'
import { resultDataTitles } from './constants'

// ** SCORE PARSERS **
export const generalScoreParser = (data) => (
  <Stack direction="column" spacing={1}>
    <Typography variant="h5">{data.value}</Typography>
    <Typography variant="caption" color="text.secondary">
      {resultDataTitles[data.name]}
    </Typography>
  </Stack>
)

export const peerfulScoreParser = (data) => (
  <Stack direction="column" spacing={1}>
    <Typography variant="h4">{data.value}</Typography>
    <Typography variant="caption" color="text.secondary">
      {resultDataTitles[data.name]}
    </Typography>
  </Stack>
)

// Score Bar Types
const scoreBarGetter = {
  [SCORE_BARS.CHART_WITH_COHORT]: (props) => <UserScoreChart {...props} />,
  [SCORE_BARS.CHART_WITH_ALL_PEOPLE]: (props) => <UserScoreChart {...props} />,
  [SCORE_BARS.BAR_WITH_ALL_PEOPLE]: (props) => <BarWithRange {...props} />,
  [SCORE_BARS.BAR_WITH_COHORT]: (props) => <BarWithRange {...props} />,
  [SCORE_BARS.GRADE]: (props) => <ScoreGrade {...props} />
}

// data.type = oneOf(scoreBarTypes)
// data.value[data.type] = the necessary props for the chart or barWithRange component
export const scoreBarParser = (data) =>
  scoreBarGetter[data.type](data.value[data.type]) // ==> (props) => <component {...props} />
