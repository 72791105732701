import {
  Typography,
  Card,
  CardContent,
  IconButton,
  SvgIcon
} from '@mui/material'
import { LightTooltip } from '/src/components/light-tooltip'
import InfoIcon from '@mui/icons-material/Info'

const DashboardInfoCard = ({
  title,
  value,
  appendix,
  description,
  ...others
}) => {
  return (
    <Card
      elevation={16}
      sx={{
        minWidth: 80,
        height: 200
      }}
      {...others}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <LightTooltip
          title={
            <Typography variant="body2" sx={{ maxWidth: 300 }}>
              {description}
            </Typography>
          }
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 2,
              right: 5
            }}
            color="primary"
          >
            <SvgIcon>
              <InfoIcon />
            </SvgIcon>
          </IconButton>
        </LightTooltip>
        <Typography variant="h5" gutterBottom>
          {value}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" maxWidth="80%">
          {title}
        </Typography>
        {appendix && (
          <Typography sx={{ fontSize: 14, mt: 0.5 }} color="textSecondary">
            {appendix}
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default DashboardInfoCard
