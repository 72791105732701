// Components
import React from 'react'
import RouterButton from '/src/components/buttons/router-button'
import AlertDialog from '/src/components/common/AlertDialog/AlertDialog'
import LoadingOverlay from '/src/components/loading-overlay'
import { AnimatedLogo } from '/src/components/animated-logo'
import { LoadingError } from '/src/components/loading-error'
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'

// Utils
import PropTypes from 'prop-types'

// Constants
import { paths } from '/src/constants/paths'

// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle'

// Helpers
import {
  getButtonsBasedOnRole,
  getDates,
  getStatus,
  useActionsWithDialog
} from './helpers'

// Constants
import { ROLES } from '/functions/shared/constants/roles'
import Center from '/src/components/center'
import { SeverityPill } from '/src/components/severity-pill'
import DateBox from '/src/components/date-box'
import { useAccessControl } from '/src/hooks/use-access-control'
import { ACCESS_CONTROLS } from '/src/constants/access-control-settings'

export const ReviewsTable = ({
  reviews,
  isLoading,
  error,
  refetch,
  role,
  title
}) => {
  const { dialog, handleClick, handleContinue, disabled } =
    useActionsWithDialog({
      refetch
    })

  const isOpenCloseCycleAllowed = useAccessControl(
    ACCESS_CONTROLS.REVIEWS_TABLE_BUTTONS
  )

  if (isLoading) {
    return (
      <LoadingOverlay>
        <AnimatedLogo width={60} />
      </LoadingOverlay>
    )
  } else if (error) {
    return (
      <LoadingError
        message={error.message || error.toString() || 'Something went wrong'}
      />
    )
  }

  const isAdminView = role === ROLES.ADMIN

  return (
    <Card>
      {isAdminView ? (
        <AlertDialog
          title={dialog.data?.title || ''}
          content={dialog.data?.content || ''}
          firstButton={dialog.data?.firstButton || ''}
          secondButton={dialog.data?.secondButton || ''}
          open={dialog.open}
          onClose={dialog.handleClose}
          onContinue={handleContinue}
          disabled={disabled}
          sx={{
            '& .MuiDialog-paper': {
              p: 1
            }
          }}
        />
      ) : null}
      <CardHeader
        title={title}
        sx={{ ml: 2, mb: 1 }}
        {...(isAdminView && {
          action: (
            <RouterButton
              data-gaid="create-new-cycle"
              startIcon={
                <SvgIcon>
                  <AddCircleIcon />
                </SvgIcon>
              }
              variant="contained"
              size="small"
              to={paths.dashboard.admin.reviews.create}
              text="Create a Cycle"
              sx={{
                mr: 5.4,
                minHeight: 40,
                minWidth: 160
              }}
            />
          )
        })}
      />

      <Divider />
      <Stack>
        {/* NO REVIEWS FOUND */}
        {reviews.length === 0 ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            my={18}
          >
            <Typography variant="h5" mb={3}>
              Looks like there aren’t any reviews yet.{' '}
              {isAdminView
                ? 'You can create one by clicking “Create New”.'
                : ''}
            </Typography>
            {isAdminView ? (
              <RouterButton
                data-gaid="reviews-table-create-new-button"
                startIcon={
                  <SvgIcon>
                    <AddCircleIcon />
                  </SvgIcon>
                }
                variant="text"
                to={paths.dashboard.admin.reviews.create}
                text="Create New"
              />
            ) : null}
          </Stack>
        ) : null}
        {reviews.length > 0
          ? reviews.map((review, i, arr) => {
              // Get the dates
              const {
                startDateAtMonth,
                startDateAtDay,
                startDateAtYear,
                endDateAtMonth,
                endDateAtDay,
                endDateAtYear
              } = getDates({ evaluation: review, role })

              // Get the status color and status
              const { statusColor, status } = getStatus({
                evaluation: review,
                role
              })

              const startDates = {
                monthDate: startDateAtMonth,
                dayDate: startDateAtDay,
                yearDate: startDateAtYear
              }

              const endDates = {
                monthDate: endDateAtMonth,
                dayDate: endDateAtDay,
                yearDate: endDateAtYear
              }

              return (
                <React.Fragment key={i}>
                  <Stack
                    data-testid="data-row"
                    key={review.uid}
                    flexDirection="row"
                    alignItems="center"
                    sx={{
                      minHeight: 100,
                      pr: 2,
                      py: 1
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        flexGrow: 1,
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <Stack direction="row">
                        <Center
                          sx={{
                            flexGrow: 0,
                            width: 140,
                            mx: 2
                          }}
                        >
                          <SeverityPill color={statusColor}>
                            {status}
                          </SeverityPill>
                        </Center>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{
                            display: {
                              sm: 'none',
                              md: 'flex'
                            }
                          }}
                        >
                          <DateBox dates={startDates} />
                          <Center>
                            <Typography variant="subtitle2">-</Typography>
                          </Center>
                          <DateBox dates={endDates} />
                        </Stack>
                      </Stack>
                      {/* Show the name and description */}
                      <Box
                        minWidth={'150px'}
                        maxWidth={'400px'}
                        sx={{
                          // flexGrow: 1,
                          px: 2,
                          py: 1
                        }}
                      >
                        <Typography variant="subtitle2">
                          {review.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          // hide if small
                          sx={{
                            display: {
                              sm: 'none',
                              md: 'block'
                            }
                          }}
                        >
                          {review.description}
                        </Typography>
                      </Box>
                    </Stack>
                    {/* Show start or continue based on the review's state */}
                    <Stack
                      direction="row"
                      sx={{
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {getButtonsBasedOnRole({
                        evaluation: review,
                        helpers: {
                          handleClick
                        },
                        role,
                        isOpenCloseCycleAllowed
                      })}
                    </Stack>
                  </Stack>
                  {i !== arr.length - 1 && <Divider />}
                </React.Fragment>
              )
            })
          : null}
      </Stack>
    </Card>
  )
}

ReviewsTable.propTypes = {
  reviews: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  refetch: PropTypes.func,
  role: PropTypes.string.isRequired
}
