import { array, number, object, string } from 'yup'

export const resultsByQuestionSchema = object()
  .shape({
    behavior: string().required('Behavior field is required'),
    comments: array()
      .of(
        object().shape({
          comment: string().required('Comment field is required'),
          uid: string().required('Uid field is required')
        })
      )
      .ensure(),
    selfComment: string().notRequired(), // equal to nullable().optional()
    questionId: string().required('Question id field is required'),
    reviewsGiven: number().required('Reviews given field is required'),
    reviewsReceived: number().required('Reviews received field is required'),
    questionDescription: string()
      .nullable()
      .defined('Question description field is required'),
    aiSummary: object()
      .shape({
        mainTakeAways: string().optional(),
        suggestions: string().optional()
      })
      .noUnknown()
      .optional(),
    scores: array()
      .of(
        object()
          .shape({
            name: string().required('Name field is required.'),
            value: number().required('Value field is required.')
          })
          .noUnknown()
      )
      .required('Scores field is required.')
  })
  .noUnknown()
