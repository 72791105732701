export function capitalizeCamelCase(word) {
  // Split the word at camel case boundaries using a regular expression
  if (word) {
    const words = word?.split(/(?=[A-Z])/)

    // Capitalize the first letter of each part and join them back together
    const capitalizedWord = words
      ?.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      ?.join(' ')

    return capitalizedWord
  }

  return word
}
