import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Checkbox from '@mui/material/Checkbox'
import { visuallyHidden } from '@mui/utils'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'
import { Stack, useTheme } from '@mui/material'
import { getFilterButtonsWithOptions } from '/src/components/users-table/utils/get-filter-button-with-options'
import { MultiSelect } from '/src/components/multi-select'
import { useCallback } from 'react'

export function EnhancedTableHead() {
  const theme = useTheme()
  const {
    handleSelectAllClick,
    order,
    orderBy,
    filters: types,
    fixedHeadCellComponent,
    fixedBodyCell,
    reportExpand,
    handleRequestSort,
    columns: headCells,
    handleUsersFiltering,
    checkbox,
    users,
    selected,
    filtered,
    filterChips,
    setFilterChips
  } = useUsersTableContext()

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property)
  }

  // selected users count
  const numSelected = selected.length
  const rowCount = filtered.length

  const filterButtonsWithOptions = getFilterButtonsWithOptions({
    filterTypes: types,
    users,
    columns: headCells
  })

  const handleChange = useCallback(
    (buttonName, key) => (selectedOption) => {
      // update chips
      setFilterChips((prev) => {
        // if selectedOption is an array of objects, it means that the user selected a non numeric filter
        if (typeof selectedOption[selectedOption.length - 1] !== 'object') {
          // check if chip already exists in array
          const chipExists = prev.find((chip) => {
            if (chip.type === buttonName) {
              return chip.value === selectedOption[selectedOption.length - 1]
            }

            return false
          })

          // if chip exists, remove it from filterChips array and update filtered array in context
          if (chipExists || selectedOption.length === 0) {
            // find the value filterChips includes but selectedOption doesn't
            const deletedChip = filterChips.find((chip) => {
              if (chip.type === buttonName) {
                return !selectedOption.includes(chip.value)
              }

              return false
            })

            // update filtered array in context
            handleUsersFiltering({
              // type should be lowercase to match keys in user objects
              // option values should be the same as in user objects
              type: key,
              action: 'remove',
              payload: deletedChip.value
            })

            // remove deleted chip from filterChips array
            const updatedChips = prev.filter((chip) => {
              if (chip.type === buttonName) {
                return chip.value !== deletedChip.value
              }

              return true
            })

            // return updated filterChips array
            return updatedChips
          }

          // update filtered array in context
          handleUsersFiltering({
            type: key,
            payload: selectedOption[selectedOption.length - 1],
            action: 'add'
          })

          return [
            ...prev,
            {
              type: buttonName,
              key,
              value: selectedOption[selectedOption.length - 1]
            }
          ]
        } else if (
          // if selectedOption is an array of objects, it means that the user selected a numeric filter
          typeof selectedOption[selectedOption.length - 1] === 'object'
        ) {
          // get the last selected option
          const { type, label, sign, value } =
            selectedOption[selectedOption.length - 1]
          // check if chip already exists in array

          // Check if the chip already exists in the filterChips array
          const chipExists = prev.find((chip) => {
            if (chip.type === buttonName) {
              return chip.label === label
            }

            return false
          })

          // if chip exists, and incoming value is blank remove the chip from filterChips array and update filtered array in context
          if (chipExists && !value) {
            const deletedChip = chipExists
            // update filtered array in context
            handleUsersFiltering({
              // type should be unique to match keys in mode and key
              type: type + ': ' + label,
              action: 'remove',
              payload: { value: value, operation: label, key }
            })

            // remove deleted chip from filterChips array
            const updatedChips = prev.filter((chip) => {
              if (chip.type === buttonName) {
                return chip.label !== deletedChip.label
              }

              return true
            })

            // return updated filterChips array
            return updatedChips
          }

          // update filtered array in context
          handleUsersFiltering({
            type: type + ': ' + label,
            action: 'add',
            payload: { value: value, operation: label, key }
          })

          // update chip from filterChips array
          const updatedChips = prev.filter((chip) => {
            if (chip.type === buttonName) {
              return chip.label !== label
            }

            return true
          })

          return [
            ...updatedChips,
            {
              key,
              type,
              label,
              sign,
              value
            }
          ]
        }
      })
    },
    [handleUsersFiltering, filterChips, setFilterChips]
  )

  return (
    <TableHead>
      <TableRow
        sx={{
          position: 'relative'
        }}
      >
        {checkbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={handleSelectAllClick}
              inputProps={{
                'aria-label': 'select all users'
              }}
            />
          </TableCell>
        )}
        {reportExpand && <TableCell />}
        {headCells.map((headCell) => {
          const filterButton = filterButtonsWithOptions.find(
            (filterButton) => filterButton.key === headCell.key
          )

          let values = []

          if (filterButton) {
            values = filterChips
              .map((chip) => {
                if (
                  chip.type === filterButton.name &&
                  filterButton.type === 'text'
                ) {
                  return chip.value
                }

                if (
                  chip.type === filterButton.name &&
                  filterButton.type === 'numeric'
                ) {
                  return chip
                }

                return null
              })
              .filter((value) => value !== null)
          }

          if (!headCell.sort) {
            return (
              <TableCell
                sx={{
                  minWidth: headCell.minWidth
                }}
                key={headCell.key}
              >
                <Stack direction="row" alignItems="center">
                  {headCell.label}
                  {filterButton ? (
                    <MultiSelect
                      key={filterButton.name}
                      label={filterButton.name}
                      type={filterButton.type}
                      onChange={handleChange(
                        filterButton.name,
                        filterButton.key
                      )}
                      options={filterButton.options}
                      value={values || []}
                      data-testid={`filter-${filterButton.name}`}
                    />
                  ) : null}
                </Stack>
              </TableCell>
            )
          }

          return (
            <TableCell
              key={headCell.key}
              align="left"
              // padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.key ? order : false}
              sx={{
                minWidth: headCell.minWidth
              }}
            >
              <Stack direction="row" alignItems="center">
                <TableSortLabel
                  active={orderBy === headCell.key}
                  direction={orderBy === headCell.key ? order : 'asc'}
                  onClick={createSortHandler(headCell.key)}
                  sx={{
                    '& .MuiTableSortLabel-icon': {
                      'opacity': 0.3,
                      '&.MuiTableSortLabel-hover': {
                        opacity: 0.7
                      },
                      '&.MuiTableSortLabel-active': {
                        opacity: 1
                      }
                    }
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.key ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
                {filterButton ? (
                  <MultiSelect
                    key={filterButton.name}
                    label={filterButton.name}
                    type={filterButton.type}
                    onChange={handleChange(filterButton.name, filterButton.key)}
                    options={filterButton.options}
                    value={values || []}
                    data-testid={`filter-${filterButton.name}`}
                  />
                ) : null}
              </Stack>
            </TableCell>
          )
        })}
        {fixedBodyCell ? (
          <TableCell
            padding="checkbox"
            sx={{
              zIndex: 5,
              top: -1,
              right: 0,
              position: 'sticky',
              textAlign: 'center',
              clipPath: 'inset(0.5px 0px 0px -10px)',
              boxShadow:
                theme.palette.mode === 'light'
                  ? '0px 0px 10px rgba(13, 38, 58, 0.12)'
                  : '0px 0px 10px rgba(44, 129, 198, 0.12)'
            }}
          >
            {fixedHeadCellComponent}
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  )
}
