import { ALLOWED_EMPLOYEE_RESULT_FIELDS } from '/src/constants/evaluation-config'

export const checkIfReportConfigHasComponentsToShow = (reportConfig) => {
  // Check if at least one reportConfig is enabled
  const noReportComponents = !reportConfig
    .filter((config) => ALLOWED_EMPLOYEE_RESULT_FIELDS.includes(config.name))
    .some((config) => config.display)

  return noReportComponents
}
