import { useState } from 'react'
import {
  Typography,
  List,
  ListItem,
  Chip,
  Pagination,
  Stack,
  Card,
  Grid,
  Box,
  SvgIcon
} from '@mui/material'
import DescriptionBox from '/src/components/description-box'
import ArrowRightIcon from '@untitled-ui/icons-react/build/cjs/ArrowRight'
import TextButton from '/src/components/buttons/text-button'
import { convertToSlug } from '/src/utils/convert-to-slug'

// Defining the max length of the list for pagination
const MAX_LENGTH = 5

const PaginatedDashboardCard = (props) => {
  // Getting the data from the props
  const {
    title,
    description,
    icon,
    nameField,
    performanceList,
    changeDefaultInsight,
    isPaginated,
    renderJobTitle,
    highlight
  } = props
  const [page, setPage] = useState(1)

  // Checking whether the performanceList is empty (If empty, don't display)
  if (!performanceList || performanceList.length === 0) {
    return null
  }

  // Setting the total number of pages
  const numberOfPages = Math.ceil(performanceList.length / MAX_LENGTH)

  // Getting the performers for the current page
  const performers = performanceList.slice(
    (page - 1) * MAX_LENGTH,
    page * MAX_LENGTH
  )

  const activatePagination = isPaginated && performanceList.length > MAX_LENGTH

  // Handling the page change
  const handlePageChange = (_, value) => {
    setPage(value)
  }

  // Dynamic function to get the chip component based on the highlight
  const getChipComponent = (performer, highlight) => {
    if (highlight === 'juniors' && performer.seniority === 'Junior') {
      return <Chip label={performer.seniority} color="success" />
    } else if (highlight === 'seniors' && performer.seniority === 'Senior') {
      return <Chip label={performer.seniority} color="warning" />
    } else return null
  }

  return (
    <Grid item xs={12} sm={12} md={isPaginated && renderJobTitle ? 6 : 4}>
      <Card
        elevation={16}
        component={Stack}
        sx={{
          padding: 2,
          height: '100%'
        }}
      >
        {/* Title */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          mx={2}
          mb={3}
          mt={1}
        >
          <SvgIcon fontSize="medium">{icon}</SvgIcon>
          <Typography variant="h5" component="h3" gutterBottom>
            {title}
          </Typography>
        </Stack>
        {/* Description Box */}
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: '8px',
            mb: 2
          }}
        >
          <DescriptionBox
            sx={{
              padding: 2,
              width: '100%',
              maxWidth: '100%',
              borderRadius: 'inherit'
            }}
            description={description}
          />
        </Box>
        {/* List of Performers */}
        <List
          sx={{
            flexGrow: 1
          }}
        >
          {performers.map((performer, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: '8px',
                mb: 1
              }}
            >
              <ListItem divider>
                {/* Name */}
                <Box
                  sx={{
                    flexBasis: '40%'
                  }}
                >
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flex: 1
                    }}
                    fontSize={16}
                    fontWeight={500}
                  >
                    {performer[nameField]}
                  </Typography>
                </Box>
                {/* Job Title */}
                <Box
                  sx={{
                    flexGrow: 1
                  }}
                >
                  {renderJobTitle && (
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        flex: 1
                      }}
                      fontSize={14}
                    >
                      {performer.jobTitle}
                    </Typography>
                  )}
                </Box>
                {/* Seniority */}
                <Box
                  sx={{
                    flexBasis: '15%'
                  }}
                >
                  {isPaginated && getChipComponent(performer, highlight)}
                </Box>
              </ListItem>
            </Box>
          ))}
        </List>
        {/* Pagination and Learn More Button */}
        <Stack
          spacing={1}
          direction="row"
          justifyContent={activatePagination ? 'space-between' : 'end'}
          alignItems="center"
        >
          {activatePagination && (
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Pagination
                page={page}
                count={numberOfPages}
                siblingCount={1}
                boundaryCount={1}
                color="primary"
                onChange={handlePageChange}
              />
            </Stack>
          )}
          <TextButton
            data-gaid={`learn-more-${convertToSlug(title)}`}
            sx={{
              maxWidth: '80%',
              minWidth: '120px',
              color: 'inherit'
            }}
            onClick={() => changeDefaultInsight()}
            endIcon={
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            }
            size="small"
          >
            Learn more
          </TextButton>
        </Stack>
      </Card>
    </Grid>
  )
}

export default PaginatedDashboardCard
