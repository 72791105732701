import tinycolor from 'tinycolor2'

/**
 * Generates color variants based on a base color.
 * @param {string} baseColor - The base color in hexadecimal format.
 * @returns {object} - An object containing the base color and its variants.
 */
export const getColorVariants = (
  baseColor: string
): {
  light: string
  lightest: string
  main: string
  dark: string
  darkest: string
  contrastText: string
} => {
  return {
    light: tinycolor(baseColor).lighten(15).toString(),
    lightest: tinycolor(baseColor).lighten(35).toString(),
    main: baseColor,
    dark: tinycolor(baseColor).darken(15).toString(),
    darkest: tinycolor(baseColor).darken(35).toString(),
    contrastText: isColorLight(baseColor) ? '#000000' : '#ffffff'
  }
}
/**
 * Converts a hexadecimal color value to an RGB color.
 * @param {string} hex - The hexadecimal color value.
 * @returns { {r: number, g: number, b: number} | null } - An object containing the red, green, and blue components of the color, or null if the input is not a valid hexadecimal color.
 */
const hexToRgb = function (
  hex: string
): { r: number; g: number; b: number } | null {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

/**
 * Determines if a color is light or dark.
 * @param {string} color - The hexadecimal color value.
 * @returns {boolean} - Returns true if the color is light, and false if the color is dark.
 */
export const isColorLight = (color: string): boolean => {
  const rgb = hexToRgb(color)
  if (!rgb) return false // return false if the color is not valid
  const { r, g, b } = rgb
  // Counting the perceptive luminance - human eye favors green color...
  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255
  return a < 0.35
}
