import PropTypes from 'prop-types'
import { Box, Divider, Stack } from '@mui/material'
import { SideNavItem } from './side-nav-item'

const renderItems = ({ depth = 0, items, pathname, isOpen, setDrawerIsOpen }) =>
  items.reduce(
    (acc, item) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname,
        isOpen,
        setDrawerIsOpen
      }),
    []
  )

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  pathname,
  isOpen,
  setDrawerIsOpen
}) => {
  const checkPath = !!(item.path && pathname)
  const partialMatch = checkPath ? pathname.includes(item.path) : false
  const exactMatch = checkPath ? pathname === item.path : false

  if (item.items) {
    acc.push(
      <SideNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title}
        label={item.label}
        open={partialMatch}
        title={item.title}
        path={item.path}
        isOpen={isOpen}
        setDrawerIsOpen={setDrawerIsOpen}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: 'none',
            m: 0,
            p: 0
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname,
            setDrawerIsOpen,
            isOpen
          })}
        </Stack>
      </SideNavItem>
    )
  } else {
    acc.push(
      <SideNavItem
        active={exactMatch}
        depth={depth}
        disabled={item.disabled}
        external={item.external}
        icon={item.icon}
        key={item.title}
        label={item.label}
        path={item.path}
        title={item.title}
        isOpen={isOpen}
        setDrawerIsOpen={setDrawerIsOpen}
      />
    )
  }

  return acc
}

export const SideNavSection = (props) => {
  const {
    setDrawerIsOpen,
    isOpen,
    items = [],
    pathname,
    subheader = '',
    ...other
  } = props

  return (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: 'none',
        m: 0,
        p: 0
      }}
      {...other}
    >
      {subheader &&
        (isOpen ? (
          <Divider
            flexItem
            textAlign="left"
            sx={{
              'fontSize': 14,
              'fontWeight': 600,
              'py': 1,
              ':before, :after': {
                content: '""',
                opacity: 0.5,
                borderColor: 'var(--nav-section-title-color)'
              }
            }}
          >
            {subheader}
          </Divider>
        ) : (
          <Box
            component="li"
            sx={{
              color: 'var(--nav-section-title-color)',
              fontSize: 13,
              fontWeight: 600,
              lineHeight: 1.66,
              mb: 1,
              py: 1,
              //  textTransform: 'uppercase',
              // horizontally center if not open
              textAlign: 'center'
            }}
          >
            {subheader}
          </Box>
        ))}
      {renderItems({ items, pathname, isOpen, setDrawerIsOpen })}
    </Stack>
  )
}

SideNavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  subheader: PropTypes.string,
  isOpen: PropTypes.bool,
  setDrawerIsOpen: PropTypes.func
}
