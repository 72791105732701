// React imports
import { useCallback } from 'react'

// MUI Components
import { Collapse, Stack } from '@mui/material'

// Custom Components
import CustomSwitch from '/src/components/custom-switch'
import ResultDisplay from '/src/sections/dashboard/admin/reviews/evaluation-form/results-display'
import {
  ResultCard,
  ReportsAreNotAvailable
} from '/src/components/result-card/result-card'
import { TitleWithHelper } from '/src/components/title-with-helper'
import { DevelopmentPlanGuidelines } from './development-plan-guidelines'

// Utils
import { createObjectFromArrayOfObjects } from '/src/utils/create-object-from-array'
import { checkIfReportConfigHasComponentsToShow } from '/src/utils/evaluation-config'
import {
  generalScoreParser,
  peerfulScoreParser,
  scoreBarParser
} from '/src/components/result-card/score-parsers'

// Others
import PropTypes from 'prop-types'
import { CONFIG_FIELDS } from '/src/constants/evaluation-config'
import { SCORE_BARS, SCORES } from '/src/constants/evaluation-config'

const sectionInnerSpacing = 2

export const AccessControlPanel = (props) => {
  const { formik, isCohortCompatible, ...other } = props

  const convertedReportConfig = createObjectFromArrayOfObjects({
    array: formik.values.evaluationConfig.reportConfig,
    fieldToKey: 'name'
  })

  const updateReportConfig = useCallback(({ arr, name, field, value }) => {
    const index = arr.findIndex((item) => item.name === name)
    if (index !== -1) {
      const updatedItem = { ...arr[index], [field]: value }
      return [...arr.slice(0, index), updatedItem, ...arr.slice(index + 1)]
    } else {
      console.warn(`Item with name '${name}' not found in array`)
      return arr // Return the original array if item not found
    }
  }, [])

  const handleReportConfigChanges = useCallback(
    (e) => {
      const { name, checked, value, type } = e.target
      const reportConfig = formik.values.evaluationConfig.reportConfig ?? []

      try {
        if (type === 'checkbox') {
          const newReportConfig = updateReportConfig({
            arr: reportConfig,
            name: name,
            field: 'display',
            value: checked
          })
          formik.setFieldValue('evaluationConfig.reportConfig', newReportConfig)
        } else if (type === 'text') {
          const newReportConfig = updateReportConfig({
            arr: reportConfig,
            name: name,
            field: 'description',
            value: value
          })
          formik.setFieldValue('evaluationConfig.reportConfig', newReportConfig)
        } else if (type === 'select') {
          const newReportConfig = updateReportConfig({
            arr: reportConfig,
            name: name,
            field: 'type',
            value: value
          })
          formik.setFieldValue('evaluationConfig.reportConfig', newReportConfig)
        }
      } catch (error) {
        console.error('Error updating Formik state:', error)
      }
    },
    [updateReportConfig, formik]
  )

  const handleDataConfigChanges = useCallback(
    (e) => {
      const { name, value, type, key } = e.target

      try {
        if (type === 'text') {
          formik.setFieldValue(
            `evaluationConfig.dataConfig.${name}${key ? `.${key}` : ''}`,
            value
          )
        }
      } catch (error) {
        console.error('Error updating Formik state:', error)
      }
    },
    [formik]
  )

  const formSettings = formik.values.formSettings

  // Adding grades in scoreBar object
  modifiedResultForResultDisplay.scores.forEach((score) => {
    if (score.name === SCORES.SCORE_BAR) {
      // Add grades to all scoreBar types except GRADE
      const { GRADE, ...otherTypes } = SCORE_BARS
      Object.values(otherTypes).forEach((type) => {
        // Remove cohort type charts if company doesn't have required cohort fields,
        // Cohort types are: CHART_WITH_COHORT, BAR_WITH_COHORT
        if (!isCohortCompatible) {
          score.types.forEach((scoreType) => {
            scoreType.options = scoreType.options.filter(
              (option) =>
                option.value !== SCORE_BARS.BAR_WITH_COHORT &&
                option.value !== SCORE_BARS.CHART_WITH_COHORT
            )
          })
        }

        // Add grades to prepare for rendering
        if (score.value[type]) {
          score.value[type].grades =
            formik.values.evaluationConfig.dataConfig.grades
        }
      })
    }
  })

  // Check if at least one score display is enabled
  const noReportComponents = checkIfReportConfigHasComponentsToShow(
    formik.values.evaluationConfig.reportConfig
  )

  return (
    <Stack spacing={5} marginBottom={20} {...other}>
      <Stack spacing={sectionInnerSpacing}>
        <TitleWithHelper
          title="Access Controls"
          helperText="Set who can see the results."
        />
        <Stack
          sx={{
            width: 600
          }}
        >
          <CustomSwitch>
            <CustomSwitch.Text>
              Allow participants to see their results.
            </CustomSwitch.Text>
            <CustomSwitch.FormControl
              name={CONFIG_FIELDS.ENABLE_EMPLOYEE_RESULTS}
              checked={
                convertedReportConfig[CONFIG_FIELDS.ENABLE_EMPLOYEE_RESULTS]
                  .display
              }
              onChange={handleReportConfigChanges}
              inputProps={{ 'aria-label': 'controlled' }}
              {...formSettings[CONFIG_FIELDS.ENABLE_EMPLOYEE_RESULTS]}
            />
          </CustomSwitch>
          <CustomSwitch>
            <CustomSwitch.Text>
              Allow managers to see their team results.
            </CustomSwitch.Text>
            <CustomSwitch.FormControl
              name={CONFIG_FIELDS.ENABLE_MANAGER_RESULTS}
              checked={
                convertedReportConfig[CONFIG_FIELDS.ENABLE_MANAGER_RESULTS]
                  .display
              }
              onChange={handleReportConfigChanges}
              inputProps={{ 'aria-label': 'controlled' }}
              {...formSettings[CONFIG_FIELDS.ENABLE_MANAGER_RESULTS]}
            />
          </CustomSwitch>
          <Collapse in={convertedReportConfig.enableManagerResults.display}>
            <CustomSwitch>
              <CustomSwitch.Text variant="body2">
                - Share manager scores with the managers.
              </CustomSwitch.Text>
              <CustomSwitch.FormControl
                name={CONFIG_FIELDS.DISPLAY_MANAGER_SCORE_TO_MANAGER}
                checked={
                  convertedReportConfig[
                    CONFIG_FIELDS.DISPLAY_MANAGER_SCORE_TO_MANAGER
                  ].display
                }
                onChange={handleReportConfigChanges}
                inputProps={{ 'aria-label': 'controlled' }}
                {...formSettings[
                  CONFIG_FIELDS.DISPLAY_MANAGER_SCORE_TO_MANAGER
                ]}
              />
            </CustomSwitch>
            <CustomSwitch>
              <CustomSwitch.Text variant="body2">
                - Allow managers to download employee reports with comments.
              </CustomSwitch.Text>
              <CustomSwitch.FormControl
                name={CONFIG_FIELDS.ALLOW_MANAGER_TO_DOWNLOAD_PDF_WITH_COMMENTS}
                checked={
                  convertedReportConfig[
                    CONFIG_FIELDS.ALLOW_MANAGER_TO_DOWNLOAD_PDF_WITH_COMMENTS
                  ].display
                }
                onChange={handleReportConfigChanges}
                inputProps={{ 'aria-label': 'controlled' }}
                {...formSettings[
                  CONFIG_FIELDS.ALLOW_MANAGER_TO_DOWNLOAD_PDF_WITH_COMMENTS
                ]}
              />
            </CustomSwitch>
          </Collapse>
        </Stack>
      </Stack>
      {/* RESULTS DISPLAY FOR EMPLOYEE */}
      <Stack spacing={sectionInnerSpacing}>
        <TitleWithHelper
          title="Configure Reports"
          helperText="Set how you would like the results to be displayed in the employee reports."
        />
        <ResultDisplay
          onChange={handleReportConfigChanges}
          reportConfig={convertedReportConfig}
          result={modifiedResultForResultDisplay}
          formSettings={formSettings}
        />
        <br />
        <TitleWithHelper
          title="Report Preview"
          helperText="You can see how the report will look like for the employee based on the configurations above."
        />
        {!noReportComponents ? (
          <ResultCard
            reportConfig={formik.values.evaluationConfig.reportConfig}
            result={rawResultForResultCard}
            dataConfig={formik.values.evaluationConfig.dataConfig}
            mode="preview"
          />
        ) : (
          <ReportsAreNotAvailable text="Looks like there is nothing to see here." />
        )}
      </Stack>
      <Stack spacing={sectionInnerSpacing}>
        <TitleWithHelper
          title="Development Plan Guidelines"
          helperText="Set the guidelines for the employee development plans. These guidelines will show on employees' and managers' Development Plan page."
        />
        <DevelopmentPlanGuidelines
          onChange={handleDataConfigChanges}
          data={formik.values.evaluationConfig.dataConfig.guidelines}
        />
      </Stack>
    </Stack>
  )
}

AccessControlPanel.propTypes = {
  formik: PropTypes.object.isRequired,
  isCohortCompatible: PropTypes.bool.isRequired
}

const modifiedResultForResultDisplay = {
  // {value: x, parser: y, grid: z, description: a, parser: funcX }
  scores: [
    {
      name: SCORES.PEERFUL_SCORE,
      value: 70,
      parser: peerfulScoreParser,
      description:
        'This is your calibrated score for this behavior based on your reviews.',
      grid: {
        xs: 3.7
      }
    },
    {
      name: SCORES.SCORE_BAR,
      value: {
        [SCORE_BARS.CHART_WITH_COHORT]: {
          score: 70,
          min: 35,
          max: 87,
          median: 72
        },
        [SCORE_BARS.CHART_WITH_ALL_PEOPLE]: {
          score: 70,
          min: 25,
          max: 90,
          median: 60
        },
        [SCORE_BARS.BAR_WITH_ALL_PEOPLE]: {
          score: 70,
          top: 75,
          bottom: 40,
          scoreType: SCORES.COMPANY
        },
        [SCORE_BARS.BAR_WITH_COHORT]: {
          score: 70,
          top: 78,
          bottom: 50,
          scoreType: SCORES.COHORT
        },
        [SCORE_BARS.GRADE]: {
          grade: 'Proficient'
        }
      },
      parser: scoreBarParser,
      types: [
        {
          title: 'Bell Curve Charts',
          options: [
            {
              value: SCORE_BARS.CHART_WITH_COHORT,
              label: 'Bell curve with cohort scores'
            },
            {
              value: SCORE_BARS.CHART_WITH_ALL_PEOPLE,
              label: 'Bell curve with company scores'
            }
          ]
        },
        {
          title: 'Bar with Range',
          options: [
            {
              value: SCORE_BARS.BAR_WITH_COHORT,
              label: 'Bar with cohort range'
            },
            {
              value: SCORE_BARS.BAR_WITH_ALL_PEOPLE,
              label: 'Bar with company range'
            }
          ]
        },
        {
          title: 'Other',
          options: [
            {
              value: SCORE_BARS.GRADE,
              label: 'Grade'
            }
          ]
        }
      ],
      description:
        'This shows how you compare to everyone in the organization.',
      grid: {
        xs: 7.7
      }
    },
    {
      name: SCORES.SELF_SCORE,
      value: 74,
      parser: generalScoreParser,
      grid: {
        xs: 5.7
      }
    },
    {
      name: SCORES.MANAGER_SCORE,
      value: 80,
      parser: generalScoreParser,
      grid: {
        xs: 5.7
      }
    },
    {
      name: SCORES.REVIEWS_RECEIVED,
      value: 20,
      parser: generalScoreParser,
      title: 'Reviews Received',
      grid: {
        xs: 5.7
      }
    },
    {
      name: SCORES.COMMENTS_RECEIVED,
      value: 10,
      parser: generalScoreParser,
      grid: {
        xs: 5.7
      }
    }
  ],
  questionDescription: 'High-quality impact, delivering results consistently.',
  reviewsReceived: 20,
  reviewsGiven: 10,
  comments: [
    {
      toUserId: 'ddc0576f-d92a-4f9b-9335-98f1f75eb2a4',
      comment:
        'During the app redesign phase in January, I noticed a few inconsistencies in execution. Periodic quality checks could be a potential solution.',
      uid: 'a7af8cfb-1ed1-4eaa-8c3f-cbb226540e34'
    },
    {
      toUserId: 'ddc0576f-d92a-4f9b-9335-98f1f75eb2a4',
      comment:
        'I recall the project hiccup in May. It seemed there was a gap in translating planning into execution. Regular check-ins could ensure better alignment.',
      uid: 'a960c0c3-193d-4bb0-afa4-e46bc8ad904a'
    },
    {
      toUserId: 'ddc0576f-d92a-4f9b-9335-98f1f75eb2a4',
      comment:
        'In the quarterly review for Q2, some deliverables missed the mark. Focusing more on the execution aspect will yield better results.',
      uid: '5095b728-43f0-469b-bf9f-25f7baa9f83b'
    },
    {
      toUserId: 'ddc0576f-d92a-4f9b-9335-98f1f75eb2a4',
      comment:
        "During our last marketing campaign, I observed that the execution of some strategies wasn't as efficient as planned. It would be beneficial to maintain a consistent follow-up.",
      uid: 'ab372754-a2de-4f6f-a68f-0403389cdeed'
    }
  ],
  behavior: 'Execution'
}

const rawResultForResultCard = {
  scores: [
    {
      name: SCORES.RAW_AVERAGE_SCORE,
      value: 75
    },
    {
      name: SCORES.PEERFUL_SCORE,
      value: 70
    },
    {
      name: SCORES.PERCENTILE,
      value: 72
    },
    {
      name: SCORES.COMPANY_MIN,
      value: 25
    },
    {
      name: SCORES.COMPANY_MAX,
      value: 90
    },
    {
      name: SCORES.COMPANY_MEDIAN,
      value: 60
    },
    {
      name: SCORES.COHORT_MIN,
      value: 30
    },
    {
      name: SCORES.COHORT_MAX,
      value: 85
    },
    {
      name: SCORES.COHORT_MEDIAN,
      value: 55
    },
    {
      name: SCORES.CONFIDENCE,
      value: 88
    },
    {
      name: SCORES.STANDARD_DEVIATION,
      value: 49
    },
    {
      name: SCORES.SELF_SCORE,
      value: 74
    },
    {
      name: SCORES.MANAGER_SCORE,
      value: 80
    }
  ],
  questionDescription:
    'An internal motivation and sense of ownership of their career & growth.',
  reviewsReceived: 21,
  reviewsGiven: 15,
  questionId: 'a87f6a0d-2e2e-4f0b-80d3-6e3b9c1bf5d8',
  comments: [
    {
      toUserId: 'dafe6a9b-827a-4a25-a0f0-ab6530b58ddd',
      comment:
        'Your commitment to self-improvement is evident. For instance, the additional training you pursued last March, even though it was tough, shows your dedication to personal growth.',
      uid: '9832fdb9-bc81-47e0-9913-6cc4b8a53109'
    },
    {
      toUserId: 'dafe6a9b-827a-4a25-a0f0-ab6530b58ddd',
      comment:
        'During the challenging product launch in January, your unwavering determination to meet deadlines, despite unexpected setbacks, exemplified true grit.',
      uid: '17e8fda5-0c31-4f22-94fe-bf7425c7b7fa'
    },
    {
      toUserId: 'dafe6a9b-827a-4a25-a0f0-ab6530b58ddd',
      comment:
        "During the team's brainstorming session in April, when faced with opposing viewpoints, it would be beneficial to show more resilience and openness to differing ideas.",
      uid: '8c7ad147-6db6-48bc-a6e7-7d9848aa22ae'
    },
    {
      toUserId: 'dafe6a9b-827a-4a25-a0f0-ab6530b58ddd',
      comment:
        'When our main client expressed dissatisfaction last October, your relentless effort to address their concerns and regain their trust showcased exceptional grit.',
      uid: '64cbcf59-9063-4020-af18-808b42fd7e55'
    },
    {
      toUserId: 'dafe6a9b-827a-4a25-a0f0-ab6530b58ddd',
      comment:
        'In our Q2 project, when initial strategies didn’t yield results, there was a tendency to switch directions frequently. Maintaining consistent effort and showing perseverance can lead to more lasting solutions.',
      uid: '8cfdd66f-4521-45ff-9bb3-fac536a27a61'
    },
    {
      toUserId: 'dafe6a9b-827a-4a25-a0f0-ab6530b58ddd',
      comment:
        'I remember the software glitch last November that kept us all up late. Your perseverance in troubleshooting and fixing the issue was truly commendable.',
      uid: 'fc36d906-b721-48b1-8c71-6a6b3fbfa2eb'
    }
  ],
  behavior: 'Grit',
  resultId: '6a39c990-1234-4cb1-9831-6801eb1a7ee7'
}
