import makeRequest from './index'

const resultsProvider = {
  getResultsForUser: async () => {
    const result = await makeRequest({ endpoint: 'getResultsForUser' })

    return result.data
  },
  getWorkforceResults: async (data) => {
    const result = await makeRequest({
      data,
      endpoint: 'getWorkforceResults'
    })

    return result.data
  },
  updateResultNotes: async (data) => {
    const result = await makeRequest({ data, endpoint: 'updateResultNotes' })

    return result.data
  }
}

export default resultsProvider
