import employeesModal from '/src/data/modals/employees-modal'
import employeesProvider from '/src/data/providers/employees'
import schemas from '/functions/shared/schemas'
import consoleLog from '/src/utils/console-log'

const employeesRepo = {
  getEmployees: async (data) => {
    try {
      const result = await employeesProvider.getEmployees(data)

      consoleLog('result in getEmployees before parsing', result)

      // validating the result
      const validatedResult = schemas.getEmployees.validateSync(result)

      // parse result here
      const parsedResult = employeesModal.prepareResult(validatedResult)

      consoleLog('result in getEmployees after parsing', parsedResult)

      return parsedResult
    } catch (error) {
      console.warn('error in getEmployees', error)
      throw new Error(error)
    }
  }
}

export default employeesRepo
