import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {
  Card,
  CircularProgress,
  IconButton,
  Stack,
  SvgIcon
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import aiRepo from '/src/data/repos/ai-repo'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Scrollbar } from '/src/components/scrollbar'
import { toast } from 'react-toastify'
import TextButton from '/src/components/buttons/text-button'
import { useMutation } from 'react-query'

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    width: '100%'
  },
  card: {
    p: 1,
    mx: {
      lg: 14
    }
  },
  scrollbar: {
    'py': 4,
    'px': {
      xs: 2,
      sm: 3,
      md: 6
    },
    'overflowY': 'auto',
    'overflowX': 'hidden',
    'maxHeight': '78vh',
    'bgcolor': 'background.paper',
    'borderRadius': 2,
    'position': 'relative',
    // remove on focus outline
    '&:focus': {
      outline: 'none'
    }
  },
  svgIcon: {
    justifySelf: 'flex-end',
    color: 'error.main'
  }
}

const formatSuggestionsPrompt = ({ user, result, evaluationQuestions }) => {
  let prompt =
    `I'm ${user.firstName} and I want to develop my career.` + user.jobTitle
      ? `My job title is ${user.jobTitle}. `
      : '' +
        `As a part of my company's 360 reviews, I received the following written feedback from my colleagues.
        Please provide a concise summary and actionable recommendations for me.
        Include specific examples to help me grow in my professional career.
        `
  for (const question of evaluationQuestions) {
    const relevantResult = result.resultsByQuestion.find(
      (r) => r.questionId === question.questionId
    )
    if (relevantResult?.comments) {
      const comments = relevantResult?.comments.map((c) => c.comment).join('\n')
      prompt += `\n\nValue: ${question.title}. Description: ${question.description}. Comments: ${comments}. `
    }
  }
  return prompt
}

const EmployeeSuggestionsModal = ({ user, result, evaluationQuestions }) => {
  const [open, setOpen] = React.useState(false)
  const [response, setResponse] = useState(null)

  const { isLoading, mutateAsync: generateSuggestion } = useMutation({
    mutationFn: async (data) => {
      const response = await aiRepo.queryGemini(data)
      return response[response.length - 1]?.parts[0]?.text
    },
    onSuccess: (data) => {
      setResponse(data)
      setOpen(true)
    },
    onError: (error) => {
      console.error('Error in generating suggestions: ', error)
    }
  })

  const handleOpen = async () => {
    await toast.promise(
      generateSuggestion({
        systemPrompt: `You are an AI and an expert career coach. You have a friendly tone, 
        you are kind, constructive, sympathetic, and succinct. When you make recommendations, 
        they are grounded. You provide examples when appropriate.`,
        contents: [
          {
            role: 'user',
            parts: [
              {
                text: formatSuggestionsPrompt({
                  user,
                  result,
                  evaluationQuestions
                })
              }
            ]
          }
        ]
      }),
      {
        error: {
          render() {
            return 'Oops. Something went wrong.'
          }
        }
      }
    )
  }
  const handleClose = () => setOpen(false)

  return (
    <Box>
      <TextButton
        onClick={handleOpen}
        size="large"
        startIcon={
          isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <SvgIcon>
              <AutoAwesomeIcon />
            </SvgIcon>
          )
        }
        data-gaid="generate-ai-growth-suggestions"
      >
        Suggestions
      </TextButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box sx={styles.box}>
            <Card sx={styles.card}>
              <Scrollbar sx={styles.scrollbar}>
                <Stack mb={4} alignItems="center" direction="row">
                  <IconButton onClick={handleClose}>
                    <SvgIcon sx={styles.svgIcon}>
                      <CloseIcon />
                    </SvgIcon>
                  </IconButton>
                  <Box
                    sx={{
                      flexGrow: 1
                    }}
                  >
                    <Typography
                      textAlign="center"
                      variant="h5"
                      marginBottom={1}
                    >
                      <SvgIcon sx={{ ...styles.svgIcon, ml: -4, mr: 1 }}>
                        <AutoAwesomeIcon />
                      </SvgIcon>
                      Development Suggestions
                    </Typography>
                  </Box>
                </Stack>
                <Box sx={{ p: 3 }}>
                  <ReactMarkdown>{response}</ReactMarkdown>
                </Box>
              </Scrollbar>
            </Card>
          </Box>
        </Fade>
      </Modal>
    </Box>
  )
}

export default EmployeeSuggestionsModal
