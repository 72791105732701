import { Box, Stack } from '@mui/material'
import { memo } from 'react'
import Slider from '/src/sections/dashboard/reviews/questions/slider'
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion'
import Proptypes from 'prop-types'
import ScoringTableHeader from './header'
import { useDimensions } from '/src/hooks/use-dimensions'

const SliderColumn = ({
  feedbacks,
  grades,
  handleChange,
  handleReorder = () => {}
}) => {
  const [headerRef, headerDimensions] = useDimensions()

  return (
    <Stack
      justifyContent="space-around"
      alignItems="center"
      flexGrow={1}
      sx={{
        position: 'relative'
      }}
    >
      <Box
        ref={headerRef}
        sx={{
          position: 'absolute',
          top: -(headerDimensions?.height + 15),
          width: '100%'
        }}
      >
        <ScoringTableHeader grades={grades} />
      </Box>
      <LayoutGroup>
        <AnimatePresence>
          {feedbacks.map((person, i) => {
            return (
              <motion.div
                style={{
                  width: '100%'
                }}
                key={person.uid}
                layout
                exit={{ opacity: 0 }}
              >
                <Slider
                  data-gaid="question-slider"
                  data-id={person.uid}
                  onChange={handleChange(person.uid)}
                  handleReorder={handleReorder}
                  person={person}
                  data-testid={person.uid}
                />
              </motion.div>
            )
          })}
        </AnimatePresence>
      </LayoutGroup>
    </Stack>
  )
}

SliderColumn.propTypes = {
  feedbacks: Proptypes.array.isRequired,
  handleChange: Proptypes.func.isRequired
}

export default memo(SliderColumn)
