import * as Yup from 'yup'
import { ROLES } from '/functions/shared/constants/roles'

const linkRegexPattern =
  /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ // eslint-disable-line

const behaviorSchema = Yup.object().shape({
  title: Yup.string().max(100).required('Dimension name is required'),
  description: Yup.string().optional(),
  helperLink: Yup.string()
    .matches(linkRegexPattern, {
      message: 'Link must be a valid URL'
    })
    .optional(),
  questionId: Yup.string().optional(),
  leadershipBehavior: Yup.boolean().required()
})

const step1ValidationSchema = Yup.object({
  evaluationId: Yup.string().optional(), // if method is update, we need evaluationId
  name: Yup.string()
    .required('Name is required')
    .max(50, 'Name must be less than 50 characters'),
  description: Yup.string()
    .required('Description is required')
    .max(200, 'Description must be less than 200 characters'),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), 'End date must be later than start date')
    .required('End date is required'),
  evaluationConfig: Yup.object({
    dataConfig: Yup.object({
      selectMax: Yup.number().required('Select max is required'),
      selectMin: Yup.number().required('Select min is required'),
      requestMin: Yup.number()
        .min(1, 'Request minimum must be greater than 0')
        .test(
          'less-than-selectMin',
          'Request minimum must be less than the minimum number of colleagues that employees can select to review',
          function (value) {
            const selectMin = this.resolve(Yup.ref('selectMin'))
            return value < selectMin
          }
        )
        .notRequired(),
      commentMin: Yup.number()
        .min(0, 'Minimum comments must be greater than or equal to 0')
        // CommentMin must be less than or equal to selectMin (use test function)
        .test(
          'less-than-or-equal-to-selectMin',
          'Minimum comments must be less than or equal to the minimum number of colleagues that employees can select to review',
          function (value) {
            const selectMin = this.resolve(Yup.ref('selectMin'))
            return value <= selectMin
          }
        )
        .test(
          'less-than-or-equal-to-requestMin',
          'Minimum comments must be less than or equal to the request minimum number of colleagues that employees can select to review',
          function (value) {
            const requestMin = this.resolve(Yup.ref('requestMin'))
            return !requestMin || value <= requestMin
          }
        )
        .notRequired(),
      grades: Yup.array()
        .of(
          Yup.string().required(
            'Grade name is required. Please enter a grade name.'
          )
        )
        .min(2, 'At least two grade is required')
        .max(4, 'Maximum 4 grades are allowed'),
      enableSelfScoring: Yup.boolean().required(
        'Enable self scoring is required'
      ),
      displaySeniorityInReview: Yup.boolean().required(
        'Display seniority in review is required'
      ),
      enableMandatoryCommentsForLowestAndHighest: Yup.boolean()
        .test(
          'check-min-2-comments-exist',
          'Minimum 2 comments must be mandatory to enable comments for lowest and highest',
          function (value) {
            const commentMin = this.resolve(Yup.ref('commentMin'))
            return value ? commentMin >= 2 : true
          }
        )
        .required(
          'Enable mandatory comments for lowest and highest is required'
        ),
      enableMandatoryInclusionForDirectManager: Yup.boolean().required(
        'Enable mandatory inclusion for direct manager is required'
      ),
      enableMandatoryInclusionForDirectReports: Yup.boolean().required(
        'Enable mandatory inclusion for direct reports is required'
      )
    })
  })
})

const step2ValidationSchema = Yup.object({
  evaluationConfig: Yup.object({
    reportConfig: Yup.array()
      .of(
        Yup.object({
          name: Yup.string().required('Name is required'),
          display: Yup.boolean().required('Display is required'),
          description: Yup.string().nullable().defined(),
          type: Yup.string().nullable().defined()
        })
      )
      .min(1, 'At least one report config is required')
  })
})

const step3ValidationSchema = Yup.object({
  behaviors: Yup.array()
    .of(behaviorSchema)
    .min(1, 'At least 1 behavior is required')
    .required('At least 1 behavior is required')
})

const step4ValidationSchema = Yup.object({
  companyEmployees: Yup.array(),
  users: Yup.array()
    .of(Yup.string().required('Employee ID is required'))
    .min(10, 'Please select at least 10 employees.')
    .test(
      'greater-than-or-equal-to-selectMin',
      'Number of participants must be greater than or equal to the minimum number of colleagues that employees can select to review',
      function (value) {
        const selectMin = this.resolve(
          Yup.ref('evaluationConfig.dataConfig.selectMin')
        )
        return value.length >= selectMin
      }
    )
    .test(
      'users-must-include-more-than-selectMin-managers-on-behaviors-only-leadership-condition',
      'Must include at least more than selectMin in the evaluation process when the evaluation consists only of leadership behaviors.',
      function (value) {
        const behaviors = this.resolve(Yup.ref('behaviors'))
        const selectMin = this.resolve(
          Yup.ref('evaluationConfig.dataConfig.selectMin')
        )
        const companyEmployees = this.resolve(Yup.ref('companyEmployees'))
        const onlyLeadershipBehaviors = behaviors.every(
          (b) => b.leadershipBehavior
        )
        const managers = companyEmployees.filter(
          (user) =>
            user.userRoles.includes(ROLES.MANAGER) && value.includes(user.uid)
        )
        return onlyLeadershipBehaviors ? managers.length > selectMin : true
      }
    )
})

export const validationSteps = [
  step1ValidationSchema,
  step3ValidationSchema,
  step4ValidationSchema
]

export const finalValidationSchema = Yup.object()
  .concat(step1ValidationSchema)
  .concat(step2ValidationSchema)
  .concat(step3ValidationSchema)
  .concat(step4ValidationSchema)
