import useGetRequest, { Endpoints } from '/src/hooks/requests/use-get-request'

// Constant for refetching the app config
const refetchInterval = 1000 * 60 * 60 * 24 // 24 hours

function useAppConfig() {
  const {
    data: appConfig,
    isLoading: isLoadingAppConfig,
    error: appConfigError
  } = useGetRequest({
    endpoint: Endpoints.GET_APP_CONFIG,
    config: {
      staleTime: refetchInterval,
      refetchInterval: refetchInterval
    }
  })

  if (!appConfigError) {
    return {
      isLoading: isLoadingAppConfig,
      ...appConfig
    }
  } else if (appConfigError) {
    return console.warn('App Config is not loaded', appConfigError)
  }
}

export default useAppConfig
