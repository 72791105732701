import appConfigProvider from '/src/data/providers/app-config'
import schemas from '/functions/shared/schemas'
import consoleLog from '/src/utils/console-log'

const appConfigRepo = {
  getAppConfig: async () => {
    try {
      const result = await appConfigProvider.getAppConfig()

      consoleLog('result in getAppConfig before validation', result)

      const validatedData = schemas.getAppConfig.validateSync(result)

      consoleLog('result in getAppConfig after validation', validatedData)

      return validatedData
    } catch (error) {
      console.warn('error @appConfig -> getAppConfig', error)
      throw error
    }
  }
}

export default appConfigRepo
