// Constants
import {
  ALLOWED_EMPLOYEE_RESULT_FIELDS,
  SCORE_BARS,
  SCORES
} from '/src/constants/evaluation-config'
import { resultDataOrderValues } from './constants'

// Score Parsers
import {
  generalScoreParser,
  peerfulScoreParser,
  scoreBarParser
} from './score-parsers'

// Utils
import { createObjectFromArrayOfObjects } from '/src/utils/create-object-from-array'
import consoleLog from '/src/utils/console-log'

/* MODAL CLASSES FOR PREPARING EMPLOYEE RESULTS DATA */
// This class includes all the functions that are used to prepare employee results data

class EmployeeResultsModal {
  // Constants
  CHART = 'chart'
  BAR = 'bar'

  scoreBarTypeMapping = {
    [SCORE_BARS.BAR_WITH_ALL_PEOPLE]: {
      cohortOrCompanyScore: SCORES.COMPANY,
      chartOrBar: this.BAR
    },
    [SCORE_BARS.BAR_WITH_COHORT]: {
      cohortOrCompanyScore: SCORES.COHORT,
      chartOrBar: this.BAR
    },
    [SCORE_BARS.CHART_WITH_COHORT]: {
      cohortOrCompanyScore: SCORES.COHORT,
      chartOrBar: this.CHART
    },
    [SCORE_BARS.CHART_WITH_ALL_PEOPLE]: {
      cohortOrCompanyScore: SCORES.COMPANY,
      chartOrBar: this.CHART
    }
  }

  // Score sets for cohort and company types
  scoreSets = {
    [SCORES.COHORT]: [
      SCORES.COHORT_MIN,
      SCORES.COHORT_MAX,
      SCORES.COHORT_MEDIAN
    ],
    [SCORES.COMPANY]: [
      SCORES.COMPANY_MIN,
      SCORES.COMPANY_MAX,
      SCORES.COMPANY_MEDIAN
    ]
  }

  // Utils
  #isScoreValueValid = (value) =>
    value !== null && value !== undefined && value >= 0

  #findScoreValue = (scores, scoreName) => {
    const scoreValue = scores.find((score) => score.name === scoreName)?.value
    return this.#isScoreValueValid(scoreValue) ? scoreValue : null
  }

  // Helpers
  #getScoresParser = (name) => {
    switch (name) {
      case SCORES.SCORE_BAR:
        return scoreBarParser
      case SCORES.PEERFUL_SCORE:
        return peerfulScoreParser
      default:
        return generalScoreParser
    }
  }

  #addScoreBarData = ({ scores, scoreBar, grades }) => {
    if (!scoreBar || !scores) {
      console.warn('addScoreBarData: scoreBar or scores are not provided.')
      return scores
    }

    let additionalScoreData
    let missingScores = []

    // Determine the score type
    const { cohortOrCompanyScore, chartOrBar } =
      this.scoreBarTypeMapping[scoreBar.type] || {}

    const scoreSet = this.scoreSets[cohortOrCompanyScore]

    if (cohortOrCompanyScore) {
      const score = this.#findScoreValue(scores, SCORES.PEERFUL_SCORE)
      const [min, max, median] = scoreSet.map((score) =>
        this.#findScoreValue(scores, score)
      )

      const top = (max + median) / 2
      const bottom = (min + median) / 2

      missingScores = [SCORES.PEERFUL_SCORE, ...scoreSet].filter(
        (name, i) => !this.#isScoreValueValid([score, min, max, median][i])
      )

      if (missingScores.length === 0) {
        additionalScoreData =
          chartOrBar === this.CHART
            ? { score, min, median, max, grades }
            : { score, top, bottom, grades, scoreType: cohortOrCompanyScore }
      }
    } else if (scoreBar.type === SCORE_BARS.GRADE) {
      const score = this.#findScoreValue(scores, SCORES.PEERFUL_SCORE)
      // Calculate grade based on score and grades by using the grade index
      const gradeIndex = Math.floor((score / 100) * grades.length)
      const grade = grades[gradeIndex]

      missingScores = [SCORES.PEERFUL_SCORE].filter(
        (name, i) => !this.#isScoreValueValid([score][i])
      )

      if (missingScores.length === 0) {
        additionalScoreData = { grade }
      }
    }

    if (additionalScoreData) {
      return [
        ...scores,
        {
          name: SCORES.SCORE_BAR,
          value: { [scoreBar.type]: additionalScoreData }
        }
      ]
    } else {
      console.warn(
        `addScoreBarData: Missing or invalid values for ${
          scoreBar.type
        }: ${missingScores.join(', ')}`
      )

      return scores
    }
  }

  #calculateGridForScores = (scores) => {
    const scoreBarIndex = scores.findIndex(
      (score) => score.name === SCORES.SCORE_BAR
    )

    // Determine if scoreBar is present and calculate restItemCount accordingly
    const hasScoreBar = scoreBarIndex !== -1
    const restItemCount = hasScoreBar ? scores.length - 2 : scores.length
    const isMultipleOfThree = restItemCount % 3 === 0

    // Calculate grid values based on the conditions
    const gridValues = scores.map((score, index) => {
      if (hasScoreBar) {
        if (index === scoreBarIndex) {
          // Assign a larger grid value for scoreBar
          return { md: 7.85, xs: 9 }
        } else if (index === (scoreBarIndex === 0 ? 1 : 0)) {
          // Assign xs: 3.7 to the first item (excluding scoreBar)
          return { md: 3.75, xs: 2.6 }
        } else if (isMultipleOfThree) {
          // Assign xs: 3.7 for the rest if the count is a multiple of three
          return { xs: 3.75 }
        } else {
          // Assign xs: 5.7 for the rest of the items
          return { xs: 5.81 }
        }
      } else {
        // Assign xs: 3.7 if scoreBar doesn't exist and scores length is a multiple of three, otherwise xs: 5.7
        return isMultipleOfThree ? { xs: 3.7 } : { xs: 5.7 }
      }
    })

    // Attach the calculated grid values to each score object
    return scores.map((score, index) => ({ ...score, grid: gridValues[index] }))
  }

  // Main function
  prepareEmployeeResultData = ({ result, reportConfig, dataConfig }) => {
    // Transform reportConfig into a map for efficient lookups
    const configMap = createObjectFromArrayOfObjects({
      array: reportConfig,
      fieldToKey: 'name'
    })

    // Add scoreBar data to scores
    // We look at the scoreBar config and prepare scoreBar data and component according to the type of scoreBar
    const scoreBarConfig = configMap[SCORES.SCORE_BAR]
    let scoresWithScoreBar = this.#addScoreBarData({
      scores: result.scores,
      scoreBar: scoreBarConfig,
      grades: dataConfig.grades
    })

    // Prepare additional scores which are not in the scores array
    const additionalScores = [
      { name: SCORES.REVIEWS_RECEIVED, value: result.reviewsReceived },
      { name: SCORES.COMMENTS_RECEIVED, value: result.comments.length }
    ]

    // Combine and process scores
    const processedScores = scoresWithScoreBar
      .concat(additionalScores)
      .reduce((accumulatedScores, currentScore) => {
        const scoreConfig = configMap[currentScore.name] || {}

        // Check if display is true and value is defined and >= 0
        if (scoreConfig.display) {
          if (
            currentScore.value === undefined ||
            currentScore.value === null ||
            currentScore.value < 0
          ) {
            consoleLog(
              `parseScores: Display is true but value is missing, null, or less than 0 for score '${currentScore.name}'.`
            )
          } else {
            accumulatedScores.push({
              ...currentScore,
              ...scoreConfig,
              parser: this.#getScoresParser(currentScore.name)
            })
          }
        }

        return accumulatedScores
      }, [])
      .sort(
        (a, b) =>
          (resultDataOrderValues[a.name] || Infinity) -
          (resultDataOrderValues[b.name] || Infinity)
      )

    // Set grid values for layout and filter according to the resultDataAllowedToBeShownToEmployees array
    return this.#calculateGridForScores(processedScores).filter((score) =>
      ALLOWED_EMPLOYEE_RESULT_FIELDS.includes(score.name)
    )
  }
}

const employeeResultsModal = new EmployeeResultsModal()

export default employeeResultsModal
