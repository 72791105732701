import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import PrimaryIconButton from '/src/components/buttons/primary-icon-button'

export const BasicPdfButton = ({
  isLoading = false,
  tooltipText = 'View as PDF',
  tooltipProps = {},
  disableTooltip = false,
  ...otherProps
}) => (
  <PrimaryIconButton
    disabled={isLoading}
    tooltipText={tooltipText}
    tooltipProps={tooltipProps}
    disableTooltip={disableTooltip}
    icon={<PictureAsPdfOutlinedIcon />}
    {...otherProps}
  />
)
