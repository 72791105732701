import { motion } from 'framer-motion'
import { LOGO_MODES, Logo } from '/src/components/logo'
import { useTheme } from '@mui/material'

export const AnimatedLogo = (props) => {
  const theme = useTheme()
  return (
    <motion.div
      // pulse animation effect on logo
      animate={{
        opacity: [0, 1],
        scale: [1.5, 1]
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatType: 'reverse',
        type: 'spring'
      }}
    >
      <Logo
        mode={
          theme.palette.mode === 'dark' ? LOGO_MODES.WHITE : LOGO_MODES.DEFAULT
        }
        {...props}
      />
    </motion.div>
  )
}
