import makeRequest from './index'

const employeesProvider = {
  getEmployees: async (data) => {
    const result = await makeRequest({ data, endpoint: 'getEmployees' })

    return result.data
  }
}

export default employeesProvider
