/**
 * Generates a random hexadecimal color.
 * @returns {string} The random color.
 */
export const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  const color = Array.from(
    { length: 6 },
    () => letters[Math.floor(Math.random() * letters.length)]
  )
  return `#${color.join('')}`
}
