import React, { useRef } from 'react'
import {
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  SvgIcon
} from '@mui/material'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import ChevronDownIcon from '@untitled-ui/icons-react/build/cjs/ChevronDown'
import ChevronRightIcon from '@untitled-ui/icons-react/build/cjs/ChevronRight'
import { EnhancedTableCell } from './enhanced-table-cell'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'
import { useTheme } from '@mui/material/styles'

const CustomTableRow = (props) => {
  const theme = useTheme()
  const { index, row, labelId, isItemSelected } = props

  const {
    columns,
    handleClick,
    fixedBodyCell,
    isCurrentRowExpanded,
    fixedBodyCellComponent,
    reportExpand,
    checkbox,
    dialog
  } = useUsersTableContext()

  const rippleRef = useRef(null)

  return (
    <TableRow
      onMouseEnter={() => rippleRef.current?.pulsate()}
      onMouseLeave={() => rippleRef.current?.stop()}
      hover
      role={checkbox ? 'checkbox' : 'contentinfo'}
      sx={{
        cursor: reportExpand || dialog ? 'pointer' : 'default'
      }}
      className="table-row"
      onClick={(event) => handleClick(event, row, index)}
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      {reportExpand && (
        <TableCell
          padding="checkbox"
          sx={{
            ...(isCurrentRowExpanded && {
              'position': 'relative',
              '&:after': {
                position: 'absolute',
                content: '" "',
                top: 0,
                left: 0,
                backgroundColor: 'primary.main',
                width: 3,
                height: 'calc(100% + 1px)'
              }
            })
          }}
        >
          <SvgIcon>
            {isCurrentRowExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </SvgIcon>
        </TableCell>
      )}
      {checkbox && (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId
            }}
          />
        </TableCell>
      )}
      {columns.map((column, index) => {
        return (
          <EnhancedTableCell
            isCurrent={isCurrentRowExpanded}
            key={column.key + row.uid + index}
            id={column.key + row.uid + index}
            column={column}
            index={index}
            row={row}
          />
        )
      })}
      {fixedBodyCell ? (
        <TableCell
          sx={{
            zIndex: 2,
            right: 0,
            pointerEvents: 'auto',
            position: 'sticky',
            clipPath: 'inset(0.5px 0px 0px -10px)',
            boxShadow:
              theme.palette.mode === 'light'
                ? '0px 0px 10px rgba(13, 38, 58, 0.12)'
                : '0px 0px 10px rgba(44, 129, 198, 0.12)',
            backgroundColor: 'background.paper'
          }}
        >
          {fixedBodyCellComponent || (
            <IconButton
              touchRippleRef={rippleRef}
              disableTouchRipple
              color="inherit"
              size="small"
              TouchRippleProps={{
                style: {
                  opacity: 0.2
                }
              }}
            >
              <ArrowForwardOutlinedIcon
                style={{
                  transform: 'scale(0.75)'
                }}
              />
            </IconButton>
          )}
        </TableCell>
      ) : null}
    </TableRow>
  )
}

export default CustomTableRow
