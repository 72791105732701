import React, { useState } from 'react'

// MUI Components
import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material'
import EditNoteIcon from '@mui/icons-material/EditNote'
import InfoIcon from '@mui/icons-material/Info'

// Custom Components
import { LightTooltip } from '/src/components/light-tooltip'
import { Android12Switch } from '/src/components/android-12-switch'
import TextButton from '/src/components/buttons/text-button'

// Utils
import { capitalizeCamelCase } from '/src/utils/capitalize-camelCaseString'

// PropTypes
import PropTypes from 'prop-types'

export const addGreyFilterOnCondition = (condition) => ({
  // add grey effect if display is false
  filter: condition ? 'none' : 'grayscale(1)',
  opacity: condition ? 1 : 0.5,
  transition: 'all 0.5s ease-in-out',
  cursor: 'pointer'
})

export const hideOnCondition = (condition) => ({
  visibility: condition ? 'visible' : 'hidden',
  opacity: condition ? 1 : 0,
  transition: 'all 0.5s ease-in-out'
})

export const EditableCard = ({
  config, // {name: y, display: x, description: x, type: x}
  data, // holds example content for the card {value: x, grid: z, description: a, parser: funcX, title: b, types }
  onChange,
  formSettings
}) => {
  const { description, display, type: activeItem } = config || {}

  const handleSettingChanges = (e) => {
    // Update parent state if onChange is provided
    if (onChange) onChange(e)

    return
  }

  // We only need these in local state
  const [dialog, setDialog] = useState(false)
  const handleOpen = () => setDialog(true)
  const handleClose = () => setDialog(false)

  // Preparing content
  // If the value is not applicable, we will display "Not available" instead of "N/A"
  data.value =
    data.value === undefined
      ? data?.parser
        ? 'Not available'
        : null
      : data.value

  const content = data?.parser
    ? data.parser({ ...data, ...(activeItem && { type: activeItem }) })
    : data.value

  if (content === null) return null

  return (
    <Card
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
      elevation={16}
      {...(data?.grid && {
        component: Grid,
        item: true,
        ...data?.grid // {xs: x, sm: y, md: z, ...}
      })}
    >
      {config ? (
        <EditableCardDialog
          open={dialog}
          onClose={handleClose}
          onSave={handleSettingChanges}
          config={config}
        />
      ) : null}
      <CardContent
        sx={{
          ...(config && { ...addGreyFilterOnCondition(display) })
        }}
      >
        {content}
        <LightTooltip
          title={
            <Typography variant="body2" sx={{ maxWidth: 300 }}>
              {config
                ? description
                : data?.description || 'No description provided'}
            </Typography>
          }
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 2,
              right: 5
            }}
            color="primary"
          >
            <SvgIcon>
              <InfoIcon />
            </SvgIcon>
          </IconButton>
        </LightTooltip>
      </CardContent>

      {config ? (
        <>
          <Divider />
          <CardActions disableSpacing>
            <Android12Switch
              // if setting and onChange is provided, make the switch controlled
              {...(config &&
                onChange && {
                  onChange: handleSettingChanges,
                  checked: display,
                  name: config.name,
                  inputProps: { 'aria-label': 'controlled' }
                })}
              // add disabled according to formSettings
              {...formSettings[config.name]}
            />
            {data?.types ? (
              <FormControl
                sx={{
                  width: '100%'
                }}
                // add disabled according to formSettings
                {...formSettings[config.name]}
              >
                <InputLabel htmlFor="grouped-select" id="grouped-select">
                  Select a chart type
                </InputLabel>
                <Select
                  labelId="grouped-select"
                  id="select"
                  label=" Select a chart type"
                  value={activeItem || data.types[0].options[0].value}
                  sx={{
                    height: 45,
                    ...(config && { ...hideOnCondition(display) })
                  }}
                  onChange={(e) =>
                    handleSettingChanges({
                      target: {
                        ...e.target,
                        type: 'select'
                      }
                    })
                  }
                  name={config.name}
                >
                  {data.types.map((group) => renderSelectGroup(group))}
                </Select>
              </FormControl>
            ) : null}
            <IconButton
              color="primary"
              variant="outlined"
              onClick={handleOpen}
              sx={{
                ml: 'auto',
                ...(config && { ...hideOnCondition(display) })
              }}
              // add disabled according to formSettings
              {...formSettings[config.name]}
            >
              <EditNoteIcon />
            </IconButton>
          </CardActions>
        </>
      ) : null}
    </Card>
  )
}

const renderSelectGroup = (group) => {
  const items = group.options.map((p) => {
    return (
      <MenuItem key={p.value} value={p.value}>
        {p.label}
      </MenuItem>
    )
  })

  return [<ListSubheader>{group.title}</ListSubheader>, items]
}

EditableCard.propTypes = {
  setting: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  types: PropTypes.array,
  parser: PropTypes.func
}

const EditableCardDialog = ({ open, onClose, onSave, config }) => {
  const [text, setText] = useState(config?.description || '')

  const handleSave = () => {
    try {
      onSave({
        target: {
          name: config.name,
          value: text,
          type: 'text'
        }
      })

      onClose()
    } catch (error) {
      console.log('inside of handleSave', { error })
    }
  }

  const configName = capitalizeCamelCase(config?.name)

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{configName}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Please enter a description for ${configName}`}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          multiline
          rows={4}
          label="Description"
          type="text"
          fullWidth
          variant="standard"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <TextButton onClick={onClose}>Cancel</TextButton>
        <TextButton onClick={handleSave}>Save</TextButton>
      </DialogActions>
    </Dialog>
  )
}

EditableCardDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string
}
