import evaluationsModal from '/src/data/modals/evaluations-modal'
import evaluationsProvider from '/src/data/providers/evaluations'
import consoleLog from '/src/utils/console-log'
import schemas from '/functions/shared/schemas'

const evaluationsRepo = {
  getEvaluation: async (data, repoData) => {
    try {
      const result = await evaluationsProvider.getEvaluation(data)

      consoleLog(
        'result in getEvaluation before parsing and validation: ',
        result
      )

      // YUP check validation
      const validatedData = schemas
        .getEvaluation(repoData?.userRoles)
        .validateSync(result)

      consoleLog(
        'result in getEvaluation after parsing and validation: ',
        validatedData
      )

      return result
    } catch (error) {
      console.warn('error in getEvaluation: ', error)
      throw new Error(error)
    }
  },
  getEvaluations: async (data) => {
    try {
      const result = await evaluationsProvider.getEvaluations(data)

      consoleLog(
        'result in getEvaluations before parsing and validation: ',
        result
      )

      // validating the result
      const validatedData = schemas.batchGetEvaluations.validateSync(result)

      consoleLog('result in getEvaluations after validation: ', validatedData)

      // parse result here
      const parsedResult = evaluationsModal.prepareForTable(validatedData)

      consoleLog(
        'result in getEvaluations after validation and parsing: ',
        parsedResult
      )

      return parsedResult
    } catch (error) {
      console.warn('error in getEvaluations: ', error)
      throw new Error(error)
    }
  },
  createEvaluation: async (data) => {
    try {
      const result = await evaluationsProvider.createEvaluation(data)

      // validating the result
      const validatedResult = schemas.createEvaluation.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error in createEvaluation: ', error)
      throw new Error(error)
    }
  },
  updateEvaluation: async (data) => {
    try {
      const result = await evaluationsProvider.updateEvaluation(data)

      // validating the result
      const validatedResult = schemas.updateEvaluation.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error in updateEvaluation: ', error)
      throw new Error(error)
    }
  },
  deleteEvaluation: async (data) => {
    try {
      const result = await evaluationsProvider.deleteEvaluation(data)

      // validating the result
      const validatedResult = schemas.deleteEvaluation.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error in deleteEvaluation: ', error)
      throw new Error(error)
    }
  },
  startEvaluation: async (data) => {
    try {
      const result = await evaluationsProvider.startEvaluation(data)

      // validating the result
      const validatedResult = schemas.startEvaluation.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error in startEvaluation: ', error)
      throw new Error(error)
    }
  },
  completeEvaluation: async (data) => {
    try {
      const result = await evaluationsProvider.completeEvaluation(data)

      // validating the result
      const validatedResult = schemas.completeEvaluation.validateSync(result)

      return validatedResult
    } catch (error) {
      console.warn('error in completeEvaluation: ', error)
      throw new Error(error)
    }
  }
}

export default evaluationsRepo
