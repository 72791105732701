import React, {
  useState,
  useCallback,
  useLayoutEffect,
  forwardRef,
  useImperativeHandle
} from 'react'

import mojs from '@mojs/core'

import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const useConfettiAnimation = ({
  duration: tlDuration,
  bounceEl,
  fadeEl,
  burstEl
}) => {
  const [animationTimeline, setAnimationTimeline] = useState(
    new mojs.Timeline()
  )

  useLayoutEffect(() => {
    if (!bounceEl || !fadeEl || !burstEl) {
      return
    }

    const triangleBurst = new mojs.Burst({
      parent: burstEl,
      radius: { 20: 60 },
      count: 5,
      angle: 30,
      children: {
        shape: 'polygon',
        radius: { 6: 0 },
        scale: 1,
        stroke: 'rgba(211,84,0 ,0.5)',
        strokeWidth: 2,
        angle: 210,
        delay: 30,
        speed: 0.2,
        easing: mojs.easing.bezier(0.1, 1, 0.3, 1),
        duration: tlDuration
      }
    })

    const circleBurst = new mojs.Burst({
      parent: burstEl,
      radius: { 20: 45 },
      angle: 25,
      duration: tlDuration,
      children: {
        shape: 'circle',
        fill: 'rgba(149,165,166 ,0.5)',
        delay: 30,
        speed: 0.2,
        radius: { 3: 0 },
        easing: mojs.easing.bezier(0.1, 1, 0.3, 1)
      }
    })

    if (typeof burstEl === 'string') {
      // clap.style.transform = 'scale(1, 1)'
      // const el = document.getElementById(id)
      // el.style.transform = 'scale(1, 1)'

      console.error('burstEl is a string')
    } else {
      burstEl.style.transform = 'scale(1, 1)'
    }

    const updatedAnimationTimeline = animationTimeline.add([
      circleBurst,
      triangleBurst
    ])

    setAnimationTimeline(updatedAnimationTimeline)
  }, [tlDuration, animationTimeline, bounceEl, fadeEl, burstEl])

  return animationTimeline
}
/** ====================================
   *      🔰 Confetti
  ==================================== **/

const Confetti = forwardRef(({ ...otherProps }, ref) => {
  const [{ clapRef, bounceEl, fadeEl }, setRefState] = useState({})

  const setRef = useCallback((node) => {
    if (node !== null) {
      setRefState((prevRefState) => ({
        ...prevRefState,
        [node.dataset.refkey]: node
      }))
    }
  }, [])

  const animationTimeline = useConfettiAnimation({
    duration: 300,
    bounceEl,
    fadeEl,
    burstEl: clapRef
  })

  // Expose clap animation function to parent component
  useImperativeHandle(
    ref,
    () => {
      return {
        clap: () => animationTimeline.replay()
      }
    },
    [animationTimeline]
  )

  return (
    <Box
      component="div"
      sx={{
        // default styles
        position: 'absolute',
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: 'yellowgreen'
      }}
      ref={setRef}
      data-refkey="clapRef"
      onClickCapture={animationTimeline.replay}
      {...otherProps}
    >
      <Box component="span" ref={setRef} data-refkey="bounceEl" />
      <Box component="span" ref={setRef} data-refkey="fadeEl" />
    </Box>
  )
})

Confetti.propTypes = {
  sx: PropTypes.object
}

export default Confetti
