import { Header, Body, Pagination, Filters } from './components'
import { UsersTableProvider } from './use-users-table-context'
import { Box, Card, Collapse, Stack, Typography } from '@mui/material'
import { useTable } from '/src/components/users-table/hooks/use-table'
import PropTypes from 'prop-types'
import React from 'react'
import { CustomTableContainer } from './components/table-container'
import { props as tableProps } from './constants/table-props'
import { EnhancedTableToolbar } from './components/filters/enhanced-table-toolbar'
import InfiniteScrollLoading from './components/infinite-scroll-loading'
import PageIndicator from './components/page-indicator'
import DashboardGrid from '/src/sections/dashboard/admin/reviews/overview/dashboard-grid'
import { QueryComponent } from './components/filters/ai-ask-anything/ask-anything'
import DescriptionBox from '../description-box'
import { tableTypes } from './constants/table-types'

/**
 * Represents a table component with various features such as filtering, pagination, and selection.
 *
 * @class UsersTable
 * @param {boolean} props.checkbox - Indicates whether to show a checkbox column for selection.
 * @param {Array} props.columns - An array of objects representing the table columns.
 * @param {Array} props.data - An array of objects representing the table data.
 * @param {Function} props.onSelect - A function to handle row selection.
 * @param {Array} props.selected - An array of selected row IDs.
 * @param {boolean} props.csv - Indicates whether to show a CSV export button.
 * @param {boolean} props.blur - Indicates whether to apply a blur effect to the table.
 * @param {string} props.blurText - The text to display when the table is blurred.
 * @param {Node} props.blurComponent - A custom component to display when the table is blurred.
 * @param {string} props.title - The title of the table.
 * @param {Function} props.setExtractData - A function to extract data from the table.
 * @param {boolean} props.updateOnDataChange - Indicates whether to update the table when data changes.
 * @param {Function} props.onSelectAll - A function to handle select all rows.
 * @param {Array} props.rowsPerPageOptions - An array of options for the rows per page dropdown.
 * @param {string} props.dataLoadMode - The data load mode ('scroll' or 'pagination').
 * @param {boolean} props.search - Indicates whether to show a search input.
 * @param {string} props.defaultOrderBy - The default column to order by.
 * @param {string} props.defaultOrder - The default order ('asc' or 'desc').
 * @param {Object} props.infoSignProps - Properties for an info sign component.
 * @param {string} props.infoSignProps.info - The info text for the info sign component.
 * @param {string} props.infoSignProps.size - The size of the info sign component ('small', 'medium', or 'large').
 * @param {Object} props.thresHoldProps - Properties for a threshold component.
 *
 * @returns {Node} The rendered UsersTable component.
 */

const UsersTable = (props) => {
  const {
    // required
    columns = [],
    data = [],
    type = null,
    // optional and can be overwritten
    dialog = tableProps[type]?.dialog || null,
    fixedBodyCell = tableProps[type]?.fixedBodyCell || false,
    gridView = tableProps[type]?.gridView || null,
    fixedHeadCellComponent = tableProps[type]?.fixedHeadCellComponent || null,
    handleOpenDrawerFilters = tableProps[type]?.handleOpenDrawerFilters || null,
    fixedBodyCellComponent = tableProps[type]?.fixedBodyCellComponent || null,
    drawerFilters = tableProps[type]?.drawerFilters || null,
    onSelect = tableProps[type]?.onSelect || (() => {}),
    onSelectAll = tableProps[type]?.onSelectAll || (() => {}),
    selected = tableProps[type]?.selected || [],
    checkbox = tableProps[type]?.checkbox || false,
    csv = tableProps[type]?.csv || false,
    exportAllData = tableProps[type]?.exportAllData || false,
    blur = tableProps[type]?.blur || false,
    blurText = tableProps[type]?.blurText || '',
    blurComponent = tableProps[type]?.blurComponent || null,
    rowsPerPageOptions = [5, 10, 25, 50, 100],
    dataLoadMode = 'scroll',
    title = null,
    reportExpand = tableProps[type]?.reportExpand || false,
    ExpandedRowComponent = null,
    search = tableProps[type]?.search || false,
    defaultOrderBy = columns[0]?.key || null,
    defaultOrder = 'asc',
    infoSignProps,
    thresHoldProps,
    setExtractData,
    updateOnDataChange = tableProps[type]?.updateOnDataChange || false,
    askAnything = false,
    gridData = null
  } = props

  // Dynamically render the columns based on the data that is passed in
  const dynamicColumns = React.useMemo(() => {
    /**
     * Given data and columns, return the columns that are present in the data.
     */
    // Create an array of objects with the key filter them based on columns array
    const commonKeys = new Set()
    // Iterate over every key inside every datum in the data and extract common keys.
    data.forEach((datum) => {
      Object.entries(datum).forEach(([datumKey, datumVal]) => {
        // Check if the in datum is present in the default columns array, otherwise skip it.
        if (
          columns.find(
            (column) => column.key === datumKey && datumVal !== undefined
          )
        ) {
          commonKeys.add(datumKey)
        }
      })
    })
    // Sorting the data based on the default order and order by
    data.sort((a, b) =>
      defaultOrder === 'desc'
        ? b[defaultOrderBy] - a[defaultOrderBy]
        : a[defaultOrderBy] - b[defaultOrderBy]
    )
    // Finally, remove columns that aren't present in the data, by checking the common keys.
    const commonColumns = columns.filter((column) => commonKeys.has(column.key))
    return commonColumns
  }, [columns, data, defaultOrderBy, defaultOrder])

  const table = useTable({
    dialog,
    dataLoadMode,
    selected,
    onSelect,
    onSelectAll,
    defaultData: data,
    checkbox,
    reportExpand,
    gridView,
    defaultOrderBy,
    defaultOrder,
    columns: dynamicColumns,
    updateOnDataChange,
    setExtractData,
    gridData
  })

  // create an object with the keys of the columns that have filter enabled
  const filters = React.useMemo(
    () =>
      columns
        .filter((column) => column.filter)
        .map((column) => column.key)
        .reduce((o, key) => ({ ...o, [key]: [] }), {}),
    [columns]
  )

  const isDataAnArrayOfObjectWhenNotEmpty = React.useMemo(() => {
    if (data.length === 0) {
      return true
    }

    return data.every((datum) => typeof datum === 'object')
  }, [data])

  // check required props and return null if not present, so the table doesn't break
  if (
    !data ||
    !isDataAnArrayOfObjectWhenNotEmpty ||
    !columns ||
    columns.length === 0
  ) {
    console.warn(
      'Some data is missing or not correct for the users table to render properly:',
      {
        data,
        isDataAnArrayOfObjectWhenNotEmpty,
        columns
      }
    )

    return null
  }

  let description = null
  if (gridView && table.showGridView) {
    description =
      'Use this chart to visualize workforce performance on a 9-box grid. You can configure the axes options to see different projections of performance. Click on the dots to learn more about each employee.'
  } else if (type === tableTypes.ADMIN_DASHBOARD) {
    description =
      "Use this table for an overview of your company's performance. Click on the rows to view detailed data, trends, and reports about each employee."
  } else if (type === tableTypes.MANAGER_DASHBOARD) {
    description =
      "Use this table for an overview of your team's performance. Click on the rows to view detailed data, trends, and reports about each person."
  }

  return (
    <UsersTableProvider
      value={{
        users: data,
        type,
        title,
        csv,
        checkbox,
        columns: dynamicColumns,
        handleOpenDrawerFilters,
        fixedHeadCellComponent,
        fixedBodyCellComponent,
        fixedBodyCell,
        drawerFilters,
        reportExpand,
        ExpandedRowComponent,
        dataLoadMode,
        rowsPerPageOptions,
        filters,
        search,
        infoSignProps,
        thresHoldProps,
        askAnything,
        gridData,
        exportAllData,
        ...table
      }}
    >
      <Box position="relative">
        {description ? (
          <DescriptionBox
            description={description}
            mb={2}
            sx={{
              minWidth: '100%'
            }}
          />
        ) : null}
        <Card
          sx={{
            borderTopRightRadius: dialog?.open && '0px',
            borderBottomRightRadius: dialog?.open && '0px',
            filter: blur ? 'blur(8px)' : 'none',
            WebkitFilter: blur ? 'blur(8px)' : 'none',
            pointerEvents: blur ? 'none' : 'auto',
            background: gridView && 'transparent'
          }}
        >
          <EnhancedTableToolbar />
          <Filters />
          {askAnything ? (
            <Collapse in={table.displayAskAnything} timeout="auto">
              <QueryComponent data={data} />
            </Collapse>
          ) : null}

          {gridView && table.showGridView ? <DashboardGrid /> : null}
          {!table.showGridView ? (
            <div>
              <CustomTableContainer>
                <Header />
                <Body />
              </CustomTableContainer>
              <InfiniteScrollLoading />
              {dataLoadMode === 'pagination' ? (
                <Pagination />
              ) : (
                <PageIndicator />
              )}
            </div>
          ) : null}
        </Card>
        {blur ? (
          <Stack
            position="absolute"
            top={40}
            alignItems="center"
            direction="row"
            spacing={1}
            left="50%"
            sx={{
              transform: 'translate(-50%, -50%)'
            }}
          >
            {blurComponent}
            <Typography variant="h6">{blurText}</Typography>
          </Stack>
        ) : null}
      </Box>
    </UsersTableProvider>
  )
}

UsersTable.propTypes = {
  children: PropTypes.node,
  checkbox: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.array,
  csv: PropTypes.bool,
  blur: PropTypes.bool,
  blurText: PropTypes.string,
  blurComponent: PropTypes.node,
  title: PropTypes.string,
  setExtractData: PropTypes.func,
  updateOnDataChange: PropTypes.bool,
  onSelectAll: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  dataLoadMode: PropTypes.oneOf(['scroll', 'pagination']),
  search: PropTypes.bool,
  defaultOrderBy: PropTypes.string,
  defaultOrder: PropTypes.string,
  infoSignProps: PropTypes.shape({
    info: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large'])
  }),
  thresHoldProps: PropTypes.object
}

export { UsersTable }
