import makeRequest from './index'

const evaluationsProvider = {
  getEvaluations: async (data) => {
    const result = await makeRequest({
      data,
      endpoint: 'batchGetEvaluations'
    })

    return result.data
  },
  createEvaluation: async (data) => {
    const result = await makeRequest({ data, endpoint: 'createEvaluation' })

    return result.data
  },
  updateEvaluation: async (data) => {
    const result = await makeRequest({ data, endpoint: 'updateEvaluation' })

    return result.data
  },
  deleteEvaluation: async (data) => {
    const result = await makeRequest({ data, endpoint: 'deleteEvaluation' })

    return result.data
  },
  getEvaluation: async (data) => {
    const result = await makeRequest({ data, endpoint: 'getEvaluation' })

    return result.data
  },
  startEvaluation: async (data) => {
    const result = await makeRequest({ data, endpoint: 'startEvaluation' })

    return result.data
  },
  completeEvaluation: async (data) => {
    const result = await makeRequest({ data, endpoint: 'completeEvaluation' })

    return result.data
  }
}

export default evaluationsProvider
