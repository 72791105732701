import { Stack } from '@mui/material'
import React, { memo } from 'react'
import CommentModal from '/src/sections/dashboard/reviews/questions/comment-modal'
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion'
import Proptypes from 'prop-types'

const CommentColumn = ({
  feedbacks,
  handleCommentSave,
  currentQuestion,
  commentMin
}) => (
  <>
    <Stack justifyContent="space-between" ml={2}>
      <LayoutGroup>
        <AnimatePresence>
          {feedbacks.map((person, i) => {
            return commentMin !== null ? (
              <motion.div key={person.uid} layout exit={{ opacity: 0 }}>
                <CommentModal
                  key={person.uid}
                  handleSave={handleCommentSave(person.uid)}
                  value={person.comment}
                  disabled={!person.isApplicable}
                  person={person}
                  data-testid={person.uid}
                  currentQuestion={currentQuestion}
                />
              </motion.div>
            ) : null
          })}
        </AnimatePresence>
      </LayoutGroup>
    </Stack>
  </>
)

CommentColumn.propTypes = {
  feedbacks: Proptypes.array.isRequired,
  handleCommentSave: Proptypes.func.isRequired,
  currentQuestion: Proptypes.object.isRequired,
  // commentMin number or null
  commentMin: Proptypes.number
}

export default memo(CommentColumn)
