import { Box, SvgIcon, Typography, useTheme } from '@mui/material'
import File04 from '@untitled-ui/icons-react/build/cjs/File04'
import SecondaryIconButton from '/src/components/buttons/secondary-icon-button'
import { Parser } from '@json2csv/plainjs'
import { toast } from 'react-toastify'
import consoleLog from '/src/utils/console-log'
import { USER_KEYS } from '/src/constants/user'
import proptypes from 'prop-types'
import { createObjectFromKeys } from '/src/utils/create-object-from-keys'
import {
  getAverageOfScores,
  isValidScore
} from '/src/data/modals/workforce-results-modal'
import { removeMd } from '/src/utils/remove-markdown'
import { NOT_APPLICABLE } from '/src/constants/common'

export const CsvDownloadButton = ({ rows, columns, exportAllData }) => {
  const theme = useTheme()
  const handleDownload = () => {
    try {
      const parser = new Parser()
      const csvJson = createCsv({
        rows: exportAllData ? prepareAllResultsForExportCSV(rows) : rows,
        columns: exportAllData ? createColumnsWithExtraFields(columns) : columns
      })
      consoleLog(`csvJson: `, csvJson)
      downloadCsv('incompass-data-export.csv', parser.parse(csvJson))
      toast.success('CSV downloaded.')
    } catch (err) {
      toast.error('Error downloading CSV.')
      console.warn(err)
    }
  }

  return (
    <SecondaryIconButton
      data-gaid="csv-download-button"
      icon={
        <Box position="relative">
          <SvgIcon color="action">
            <File04 />
          </SvgIcon>
          <Typography
            variant="caption"
            color={
              theme.palette.mode === 'light' ? 'neutral.600' : 'neutral.400'
            }
            fontWeight={500}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              backgroundColor:
                theme.palette.mode === 'light' ? 'white' : 'background.paper'
            }}
          >
            CSV
          </Typography>
        </Box>
      }
      onClick={handleDownload}
      tooltipText="Export Data"
    />
  )
}

CsvDownloadButton.propTypes = {
  rows: proptypes.array.isRequired,
  columns: proptypes.array.isRequired
}

// Define the keys of the user object
const {
  FULL_NAME,
  DIVISION,
  TEAM,
  SENIORITY,
  JOB_LEVEL,
  JOB_TITLE,
  MANAGER,
  TENURE,
  LOCATION,
  GENDER,
  COHORT,
  RACE
} = USER_KEYS

// Function to create columns with extra fields
const createColumnsWithExtraFields = (columns) => {
  const columnsWithExtraField = [...columns]

  // Insert review name after full name column
  columnsWithExtraField.splice(1, 0, {
    key: 'evaluationName',
    label: 'Review Cycle'
  })

  // Insert raw average score after behavior name column
  columnsWithExtraField.splice(5, 0, {
    key: 'rawAverageScore',
    label: 'Raw Average Score'
  })

  // Insert some fields at the end
  columnsWithExtraField.push({
    key: 'comments',
    label: 'Comments'
  })

  return columnsWithExtraField
}

// Function to prepare data for CSV export
const prepareAllResultsForExportCSV = (allResults) => {
  // Initialize an array to store the prepared results
  const preparedResults = []
  // Initialize a map to store the results by user ID
  const resultsMapByUserId = new Map()

  // Loop over all results
  for (const result of allResults) {
    // If the user ID is not already in the map, add it
    if (!resultsMapByUserId.has(result['userId'])) {
      resultsMapByUserId.set(result['userId'], {
        userResults: result.userResults,
        // Create an object from the keys defined above if keys exist
        ...createObjectFromKeys({
          object: result,
          keys: [
            FULL_NAME,
            DIVISION,
            TEAM,
            SENIORITY,
            JOB_LEVEL,
            JOB_TITLE,
            MANAGER,
            TENURE,
            LOCATION,
            GENDER,
            COHORT,
            RACE
          ]
        })
      })
    }
  }

  // Loop over the map values (i.e., the results by user ID)
  for (const result of resultsMapByUserId.values()) {
    // Destructure the result to separate userResults and the rest of the data
    const { userResults, ...userData } = result

    // Loop over the user results
    for (const evaluationResult of result.userResults) {
      const allScores = {}

      // Loop over the results by question
      for (const resultByQuestion of evaluationResult.resultsByQuestion) {
        // Push the prepared result to the array
        preparedResults.push({
          // Add the user data
          ...userData,
          // Overwrite the user data with the old user data at the time of the evaluation (e.g., job title, location, etc.)
          ...evaluationResult.user,
          // Add the evaluation name
          evaluationName: evaluationResult.evaluationName,
          // Add the result data (e.g., peerfulScore, rawScore, managerScore, etc.)
          ...resultByQuestion
        })

        // * WARNING: We skip comments for summary
        // * If you want to include comments in the summary, collect them here
        // Add the scores to the allScores object
        Object.entries(resultByQuestion).forEach(([key, value]) => {
          if (isValidScore(value)) {
            if (allScores[key] === undefined) {
              allScores[key] = []
            }
            allScores[key].push(value)
          }
        })
      }

      // Get average of scores
      const summary = getAverageOfScores(allScores)

      // Push the summary to the prepared results
      preparedResults.push({
        behavior: 'Average',
        ...userData,
        ...evaluationResult.user,
        evaluationName: evaluationResult.evaluationName,
        comments: [], // Passing empty array for comments to avoid errors
        ...summary
      })
    }
  }

  // Return the prepared results
  return preparedResults
}
// Downloading the CSV file
function downloadCsv(filename, csvData) {
  // Creating an empty anchor tag
  const element = document.createElement('a')
  // Setting its attributes
  element.setAttribute(
    'href',
    `data:text/csv;charset=utf-8,${encodeURIComponent(csvData)}`
  )
  element.setAttribute('download', filename)
  element.style.display = 'none'
  // Adding the element to the DOM, downloading and removing
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
// // Creating the CSV object ready to download
function createCsv({ rows, columns }) {
  // Empty csv data
  const csvData = []
  consoleLog({
    rows,
    columns
  })
  // Getting the column headers as (object keys)
  const columnData = []
  for (let i = 0; i < columns.length; i++) {
    columnData.push(columns[i].headerName)
  }
  csvData.push(columnData)
  // The whole object list that will be returned
  const rowData = []
  // Iterating through the rows
  for (let i = 0; i < rows.length; i++) {
    // Employee object (empty row)
    const employeeRow = {}
    // Adding employees attributes (key = titles & values = row elements)
    for (let j = 0; j < columns.length; j++) {
      if (columns[j].key === 'comments') {
        employeeRow[columns[j].label] = rows[i][columns[j].key].reduce(
          (acc, cur) => acc + removeMd(cur.comment) + '\n\n',
          ''
        )
      } else if (
        ['totalBonus', 'salary', 'raise', 'raiseCost'].includes(columns[j].key)
      ) {
        employeeRow[columns[j].label] = `${
          columns[j].parser({ value: rows[i][columns[j].key] }).props
            .children || NOT_APPLICABLE
        }`
      } else if (
        rows[i][columns[j].key] !== undefined &&
        rows[i][columns[j].key] !== null
      ) {
        employeeRow[columns[j].label] = `${
          rows[i][columns[j].key] || NOT_APPLICABLE
        }`
      } else {
        // If column exists but data doesn't exist in row, add "N/A"
        employeeRow[columns[j].label] = NOT_APPLICABLE
      }
    }
    rowData.push(employeeRow)
  }
  consoleLog('rowData', rowData)
  return rowData
}
