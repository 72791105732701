import { useCallback } from 'react'
import { Box, Chip, Stack } from '@mui/material'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'
import { capitalizeCamelCase } from '/src/utils/capitalize-camelCaseString'

const UserListFilter = ({ ...otherProps }) => {
  const { handleUsersFiltering, filterChips, setFilterChips } =
    useUsersTableContext()

  const handleChipDelete = useCallback(
    (deletedChip) => (e) => {
      if (deletedChip?.sign) {
        const { type, label, value, key } = deletedChip
        // update chips
        setFilterChips((prev) => {
          return prev.filter((chip) => {
            if (chip.type === type) {
              return chip.label !== label
            }

            return true
          })
        })

        // update filtered array in context
        handleUsersFiltering({
          type: type + ': ' + label,
          action: 'remove',
          payload: { value: value, operation: label, key }
        })
      } else {
        // update chips
        setFilterChips((prev) => {
          return prev.filter((chip) => chip.value !== deletedChip.value)
        })

        // update filtered array in context
        handleUsersFiltering({
          type: deletedChip.key,
          action: 'remove',
          payload: deletedChip.value
        })
      }
    },
    [handleUsersFiltering, setFilterChips]
  )

  const showChips = filterChips.length > 0

  return (
    <div {...otherProps}>
      {showChips ? (
        <>
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            mb={2}
            gap={1}
          >
            {filterChips.map((filter, index) => (
              <Chip
                key={index}
                label={
                  <Box
                    sx={{
                      'alignItems': 'center',
                      'display': 'flex',
                      '& span': {
                        fontWeight: 600
                      }
                    }}
                  >
                    {filter?.sign ? (
                      <>
                        <span>{filter.type}</span>: {filter.label} {filter.sign}{' '}
                        {filter.value}
                      </>
                    ) : (
                      <>
                        <span>{filter.type}</span>:{' '}
                        {filter.type === 'Status'
                          ? capitalizeCamelCase(filter.value)
                          : filter.value}
                      </>
                    )}
                  </Box>
                }
                onDelete={handleChipDelete(filter)}
                variant="outlined"
              />
            ))}
          </Stack>
        </>
      ) : null}
    </div>
  )
}

export { UserListFilter }
