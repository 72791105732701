import { Paper, Table, TableContainer } from '@mui/material'
import React from 'react'
import { Scrollbar } from '/src/components/scrollbar'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'

const CustomTableContainer = ({ children }) => {
  const {
    setPage,
    page,
    rowsPerPage,
    filtered,
    dataLoadMode,
    setScrollLoading,
    scrollLoading
  } = useUsersTableContext()

  const addMoreOnBottomReached = React.useCallback(
    (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      if (
        scrollHeight - scrollTop - clientHeight < 400 &&
        !scrollLoading &&
        page < Math.floor(filtered.length / rowsPerPage) &&
        dataLoadMode === 'scroll'
      ) {
        setScrollLoading(true)
        setTimeout(
          () => setPage((prevPage) => prevPage + 1, setScrollLoading(false)),
          500
        )
      }
    },
    [
      setPage,
      page,
      rowsPerPage,
      filtered,
      scrollLoading,
      dataLoadMode,
      setScrollLoading
    ]
  )

  return (
    <TableContainer
      component={Paper}
      onScrollCapture={addMoreOnBottomReached}
      sx={{
        borderRadius: 0
      }}
    >
      <Scrollbar
        sx={{
          minHeight: 400,
          maxHeight: '50vh'
        }}
      >
        <Table
          stickyHeader={true}
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'small'}
        >
          {children}
        </Table>
      </Scrollbar>
    </TableContainer>
  )
}

export { CustomTableContainer }
