import {
  Backdrop,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Divider,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import { Scrollbar } from '/src/components/scrollbar'
import { useSettings } from '/src/hooks/use-settings'
import RefreshCcw01Icon from '@untitled-ui/icons-react/build/cjs/RefreshCcw01'
import Check from '@untitled-ui/icons-react/build/cjs/Check'
import { groupFilterTypes } from './utils/get-filter-groups'
import MultiselectChips from './multiselect-chips'
import RangeSlider from './range-slider'
import MultiselectDropdown from './multiselect-dropdown'
import { componentTypes } from './constants/filter-drawer-filters'
import ReviewSelectCards from './review-select-cards'
import { useCallback, useState } from 'react'
import TextButton from '/src/components/buttons/text-button'

function FilteringDrawer(props) {
  const { onClose, open, filters, setFilters, drawerType } = props
  const sideNavLayoutWidth = useSettings().sideNavWidth

  const [localFilters, setLocalFilters] = useState(filters)

  const reviewSelect = groupFilterTypes({
    localFilters,
    drawerType,
    componentType: componentTypes.REVIEW_SELECT
  })

  const chipFilters = groupFilterTypes({
    localFilters,
    drawerType,
    componentType: componentTypes.CHIPS
  })

  const sliderFilters = groupFilterTypes({
    localFilters,
    drawerType,
    componentType: componentTypes.SLIDER
  })

  const selectFilters = groupFilterTypes({
    localFilters,
    drawerType,
    componentType: componentTypes.SELECT
  })

  const allFilters = [
    reviewSelect,
    chipFilters,
    sliderFilters,
    selectFilters
  ].filter((m) => Object.keys(m).length)

  const appliedFilters = Object.values(localFilters).reduce((acc, curr) => {
    return acc + curr.values.length
  }, 0)

  // Checks if apply button is disabled by comparing previous filters with current filters
  const checkIfApplyButtonDisabled = () => {
    for (const key in localFilters) {
      const localFilterValues = localFilters[key].values
      const filterValues = filters[key].values
      const localFilterValuesShallowCpy = [...localFilterValues]
      const filterValuesShallowCpy = [...filterValues]
      if (
        JSON.stringify(localFilterValuesShallowCpy.sort()) !==
        JSON.stringify(filterValuesShallowCpy.sort())
      ) {
        return false
      }
    }
    return true
  }

  const updateLocalFilters = () => {
    setFilters((prev) => ({
      ...prev,
      [drawerType]: {
        ...prev[drawerType],
        ...localFilters
      }
    }))
    onClose()
  }

  const resetFilters = useCallback(() => {
    setLocalFilters((prev) => {
      const newFilters = Object.keys(prev).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: {
            ...prev[curr],
            values: []
          }
        }
      }, {})

      return {
        ...prev,
        ...newFilters
      }
    })
  }, [setLocalFilters])

  const closeDrawer = useCallback(() => {
    setLocalFilters(filters)
    onClose()
  }, [onClose, setLocalFilters, filters])

  return (
    <>
      <Drawer
        open={open}
        PaperProps={{
          elevation: 24,
          sx: {
            zIndex: 10000,
            left: sideNavLayoutWidth,
            pointerEvents: 'auto',
            position: 'fixed',
            width: '500px'
          }
        }}
        variant="persistent"
      >
        <Scrollbar
          sx={{
            'height': '100%',
            '& .simplebar-content': {
              height: '100%'
            }
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            position="fixed"
            width={500}
            px={3}
            py={2}
            zIndex={4}
            sx={{
              boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
              backgroundColor: 'background.paper'
            }}
          >
            <Stack direction="row" spacing={2} alignItems="end">
              <Typography color="inherit" variant="h5">
                Filters
              </Typography>
              {appliedFilters ? (
                <Typography color="inherit" variant="body1">
                  ({appliedFilters} applied)
                </Typography>
              ) : null}
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <Tooltip
                title={appliedFilters ? 'Reset Filters' : ''}
                placement="left"
              >
                <IconButton
                  disabled={!appliedFilters}
                  color="inherit"
                  onClick={resetFilters}
                >
                  <SvgIcon fontSize="small">
                    <RefreshCcw01Icon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
              <TextButton
                disabled={checkIfApplyButtonDisabled()}
                endIcon={<Check />}
                onClick={updateLocalFilters}
              >
                Apply Filters
              </TextButton>
            </Stack>
          </Stack>
          <Stack
            spacing={2}
            sx={{
              px: 3,
              pb: 2,
              pt: 12
            }}
          >
            {allFilters.map((group, index) => (
              <Grid container rowGap={2} key={index}>
                {index ? (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                ) : null}
                {Object.values(group).map((filter) => {
                  const { componentType, key, ...other } = filter

                  if (componentType === componentTypes.REVIEW_SELECT) {
                    return (
                      <ReviewSelectCards
                        {...other}
                        setFilters={setLocalFilters}
                        type={key}
                        key={key}
                      />
                    )
                  } else if (componentType === componentTypes.CHIPS) {
                    return (
                      <MultiselectChips
                        {...other}
                        setFilters={setLocalFilters}
                        type={key}
                        key={key}
                      />
                    )
                  } else if (componentType === componentTypes.SLIDER) {
                    return (
                      <RangeSlider
                        {...other}
                        setFilters={setLocalFilters}
                        type={key}
                        key={key}
                      />
                    )
                  } else if (componentType === componentTypes.SELECT) {
                    return (
                      <MultiselectDropdown
                        {...other}
                        setFilters={setLocalFilters}
                        type={key}
                        key={key}
                      />
                    )
                  } else {
                    return null
                  }
                })}
              </Grid>
            ))}
          </Stack>
        </Scrollbar>
      </Drawer>
      <Backdrop
        style={{ zIndex: 1200 }} // Ensure the backdrop is above other content but below the drawer
        open={open}
        onClick={closeDrawer}
      />
    </>
  )
}

export default FilteringDrawer

FilteringDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  localFilters: PropTypes.object,
  updateLocalFilters: PropTypes.func,
  drawerType: PropTypes.string
}
