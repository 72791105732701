import React from 'react'
import { Chart } from '/src/components/chart'
import { ApexOptions } from 'apexcharts'
import { ChartTypes } from '/src/constants/chart-types'

type UserScoreChartProps = {
  score: number
  min: number
  max: number
  median: number
  grades: Array<string>
  height?: number | string
  width?: string | number
}

type StateTypes = {
  series: Array<{ type: string; data: Array<Array<number>> }>
  options: ApexOptions
}

export const UserScoreChart = (props: UserScoreChartProps) => {
  // Get chart fields from props
  const {
    score,
    min,
    max,
    median,
    width = '100%',
    height = 150,
    grades
  } = props

  // Calculate score offset, which is used to calculate the color of the chart and the area under the curve
  const scoreOffset = (score - min) / (max - min)
  // const scoreColor = `${colorBetween('#f44336', '#4caf50', scoreOffset, 'hex')}`
  const scoreColor = '#4caf50'
  const xAxisLabels = grades

  const state: StateTypes = {
    series: [
      {
        // Area chart for the area under the curve
        type: ChartTypes.AREA,
        data: [
          [min, 0],
          [median, 1],
          [max, 0]
        ]
      },
      {
        // Line chart for the line until the user's score
        type: ChartTypes.LINE,
        data: [
          [min, 0],
          [median, 1],
          [max, 0]
        ]
      }
    ],
    options: {
      chart: {
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      tooltip: {
        enabled: false
      },
      // Colors of lines for both series
      colors: ['#D3D3D3', scoreColor],
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth' as 'smooth' | 'straight' | 'stepline',
        // The area chart's line is dashed, then the line is solid until the user's score
        dashArray: [5, 0]
      },
      fill: {
        type: 'gradient',
        gradient: {
          type: 'horizontal',
          // Orange - green area under the curve
          colorStops: [
            [
              {
                offset: scoreOffset * 100,
                color: scoreColor,
                opacity: 0.7
              },
              {
                offset: 0,
                color: 'grey',
                opacity: 0.1
              }
            ],
            // Orange - green line until user score
            [
              {
                offset: scoreOffset * 100,
                color: scoreColor,
                opacity: 1.0
              },
              {
                offset: 0,
                color: 'grey',
                opacity: 0.0
              }
            ]
          ]
        }
      },
      xaxis: {
        type: 'numeric',
        min: 0,
        max: 100,
        tickAmount: 20,
        labels: {
          minHeight: xAxisLabels.length > 3 ? 50 : 0,
          formatter: function (val: string) {
            // convert to number to avoid type errors
            const convertedValue = Number(val)
            // Skip 0 and 100
            if (convertedValue === 0 || convertedValue === 100) {
              return ''
            }

            // Calculate label positions
            const totalLabels = xAxisLabels.length
            const interval = 100 / (totalLabels + 1)
            const labelPositions = Array.from(
              { length: totalLabels },
              (_, i) => Math.round((interval * (i + 1)) / 5) * 5
            )

            // Find and return the label for the current position
            const labelIndex = labelPositions.indexOf(
              Math.round(convertedValue)
            )
            const label =
              labelIndex !== -1
                ? totalLabels > 3
                  ? xAxisLabels[labelIndex].split(' ')
                  : xAxisLabels[labelIndex]
                : ''
            return label
          },
          style: {
            fontWeight: 'bold',
            fontSize: '10px', // Smaller font size
            cssClass: 'apexcharts-xaxis-label'
          }
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      grid: {
        padding: {
          top: 15,
          right: 10,
          left: 10
        },
        xaxis: { lines: { show: false } },
        yaxis: { lines: { show: false } }
      },
      legend: {
        show: false
      },
      annotations: {
        xaxis: [
          {
            x: score,
            borderColor: '#999',
            label: {
              text: 'Your Score',
              orientation: 'horizontal',
              offsetY: -15,
              style: {
                color: '#fff',
                background: scoreColor
              }
            }
          }
        ]
      }
    }
  }

  return (
    <Chart
      options={state.options}
      series={state.series}
      height={height}
      width={width}
    />
  )
}
