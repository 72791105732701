import aiProvider from '/src/data/providers/ai'
import schemas from '/functions/shared/schemas'
import consoleLog from '/src/utils/console-log'

// Constants for the query
const TEMPERATURE = 1
const MAX_OUTPUT_TOKENS = 8192
const TOP_P = 0.95

const aiRepo = {
  queryGemini: async ({
    systemPrompt,
    contents,
    temperature,
    maxOutputTokens,
    topP
  }) => {
    try {
      const result = await aiProvider.queryGemini({
        systemPrompt: systemPrompt ?? '',
        contents,
        temperature: temperature ?? TEMPERATURE,
        maxOutputTokens: maxOutputTokens ?? MAX_OUTPUT_TOKENS,
        topP: topP ?? TOP_P
      })

      consoleLog('result in queryGemini before validation', result)

      // validating the result
      const validatedData = schemas.queryGemini.validateSync(result)
      return validatedData
    } catch (error) {
      console.warn(error)
      throw new Error(error)
    }
  }
}

export default aiRepo
