import { toast } from 'react-toastify'
import evaluationsRepo from '/src/data/repos/evaluations-repo'
import { removePrefixes } from '/src/utils/format-errors'
import { EVALUATION_FORM_METHODS } from '/src/constants/evaluation-form-methods'
import consoleLog from '/src/utils/console-log'
import { EVALUATION_STATES } from '/src/constants/statuses'
import {
  defaultReportConfigOptions,
  defaultDataConfigOptions
} from '/src/constants/evaluation-config'
import { CONFIG_FIELDS } from '/src/constants/evaluation-config'

const commonDisabledFields = [
  CONFIG_FIELDS.START_DATE,
  CONFIG_FIELDS.EMPLOYEE_SELECTION,
  CONFIG_FIELDS.DIMENSIONS,
  CONFIG_FIELDS.ENABLE_SELF_SCORING,
  CONFIG_FIELDS.COMMENT_MIN,
  CONFIG_FIELDS.ALLOW_COMMENT_MIN,
  CONFIG_FIELDS.REQUEST_MIN,
  CONFIG_FIELDS.ALLOW_REQUEST_MIN,
  CONFIG_FIELDS.SELECT_MAX,
  CONFIG_FIELDS.SELECT_MIN,
  CONFIG_FIELDS.ENABLE_MANDATORY_COMMENTS_FOR_LOWEST_AND_HIGHEST,
  CONFIG_FIELDS.ENABLE_MANDATORY_INCLUSION_FOR_DIRECT_MANAGER,
  CONFIG_FIELDS.ENABLE_MANDATORY_INCLUSION_FOR_DIRECT_REPORTS
]

const disabledFieldsForOngoing = [
  ...commonDisabledFields
  // Addional fields for the ongoing status
]

const disabledFieldsForAwaitingResults = [
  ...commonDisabledFields,
  // Addional fields for the awaiting results status
  CONFIG_FIELDS.END_DATE
]

const disabledFieldsForCompleted = [
  ...commonDisabledFields,
  // Addional fields for the completed status
  CONFIG_FIELDS.END_DATE
]

const disabledFieldsForTemplate = []

const createFormSettings = (disabledFields) =>
  Object.values(CONFIG_FIELDS).reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]: {
        disabled: disabledFields.includes(fieldName)
      }
    }),
    {}
  )

export const evaluationFormSettings = {
  [EVALUATION_STATES.TEMPLATE]: createFormSettings(disabledFieldsForTemplate),
  [EVALUATION_STATES.ONGOING]: createFormSettings(disabledFieldsForOngoing),
  [EVALUATION_STATES.AWAITING_RESULTS]: createFormSettings(
    disabledFieldsForAwaitingResults
  ),
  [EVALUATION_STATES.COMPLETED]: createFormSettings(disabledFieldsForCompleted)
}

class EvaluationFormActions {
  #createEvaluation = async (values) => {
    try {
      const response = await evaluationsRepo.createEvaluation(values)

      return response
    } catch (error) {
      throw new Error(error)
    }
  }

  #updateEvaluation = async (values) => {
    try {
      const response = await evaluationsRepo.updateEvaluation(values)

      return response
    } catch (error) {
      throw new Error(error.message)
    }
  }

  parseValuesToSubmit = (values) => {
    // Remove companyEmployees and behaviors from values with destructuring
    // because we don't need them in the final values
    // we only need them in the initial values
    const { companyEmployees, behaviors, formSettings, status, ...rest } =
      values

    const valuesToSubmit = {
      ...rest,
      startDate: values.startDate.toDateString(),
      endDate: values.endDate.toDateString(),
      questions: behaviors
    }

    return valuesToSubmit
  }

  handleAction = async ({ values, method }) => {
    consoleLog('values in before submitting', values)
    try {
      if (method === EVALUATION_FORM_METHODS.UPDATE) {
        const response = await toast.promise(this.#updateEvaluation(values), {
          pending: 'Updating review...',
          success: 'Review updated.',
          error: {
            render({ data: err }) {
              return removePrefixes(err.message) || 'Something went wrong.'
            }
          }
        })

        return response
      } else if (method === EVALUATION_FORM_METHODS.CREATE) {
        const response = await toast.promise(this.#createEvaluation(values), {
          pending: 'Creating review...',
          success: 'Created review.',
          error: {
            render({ data: err }) {
              return removePrefixes(err.message) || 'Something went wrong.'
            }
          }
        })

        return response
      } else {
        throw new Error(
          'No method found, please specify method (create or update) by passing it as a prop to EvaluationForm'
        )
      }
    } catch (error) {
      console.warn(error)
    }
  }

  formatUnixDate = (unixDate) => {
    return unixDate ? new Date(unixDate?._seconds * 1000) : new Date()
  }

  mapQuestions = (questions) =>
    questions.map((question) => ({
      title: question.title,
      helperLink: question.helperLink,
      description: question.description,
      questionId: question.questionId,
      leadershipBehavior: question?.leadershipBehavior || false
    }))

  mapBehaviors = (behaviors) =>
    behaviors.map((b) => ({
      title: b.title,
      description: b.description,
      helperLink: b.helperLink,
      questionId: b.questionId,
      leadershipBehavior: b?.leadershipBehavior || false
    }))

  getInitialValues = ({ evaluation, behaviors, companyEmployees }) => ({
    ...(evaluation && { evaluationId: evaluation.uid }),
    name: evaluation?.name || '',
    description: evaluation?.description || '',
    startDate: this.formatUnixDate(evaluation?.expectedStartDate),
    endDate: this.formatUnixDate(evaluation?.expectedEndDate),
    questions: evaluation?.questions
      ? this.mapQuestions(evaluation.questions)
      : [],
    evaluationConfig: {
      // merge over default data config
      dataConfig: {
        ...defaultDataConfigOptions,
        ...evaluation?.evaluationConfig?.dataConfig
      },
      // merge over default report config by checking if the name exists the active evaluation config.
      // if it exists, use that, otherwise use the default.
      reportConfig: defaultReportConfigOptions.map((config) => ({
        ...config,
        ...evaluation?.evaluationConfig?.reportConfig?.find(
          (e) => e.name === config.name
        )
      }))
    },
    behaviors: behaviors ? this.mapBehaviors(behaviors) : [],
    users: evaluation?.users || [],
    companyEmployees: companyEmployees || [],
    formSettings: evaluationFormSettings[evaluation?.status] || {},
    status: evaluation?.status || EVALUATION_STATES.TEMPLATE
  })
}

const evaluationFormActions = new EvaluationFormActions()

export default evaluationFormActions
