// React imports
import { useState } from 'react'

// MUI components
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

// Drag and drop
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { LightTooltip } from '/src/components/light-tooltip'

const DROPPABLE_LABELS = 'droppable-labels'
const DROPPABLE_DELETE = 'droppable-delete'

export const LabelsWithDragAndDrop = ({
  width = 200,
  sx,
  labels = [],
  onChange = () => {},
  onDelete = () => {},
  disabled,
  ...otherProps
}) => {
  const [isDragging, setIsDragging] = useState(false)

  const onDragEnd = (result) => {
    const { source, destination } = result

    // Dropped outside the list
    if (!destination) {
      setIsDragging(false)
      return
    }

    if (destination.droppableId === DROPPABLE_DELETE) {
      // Handle deletion of labels
      onDelete(source.index)
    } else if (destination.droppableId === DROPPABLE_LABELS) {
      // Handle reordering of labels
      const reorderedItems = Array.from(labels)
      const [removed] = reorderedItems.splice(result.source.index, 1)
      reorderedItems.splice(result.destination.index, 0, removed)

      // set in formik values
      onChange(reorderedItems)
    }

    setIsDragging(false)
  }

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={() => setIsDragging(true)}
    >
      <Stack
        sx={{
          pt: 2,
          pb: 0,
          px: 3
        }}
        direction="row"
        justifyContent="flex-end"
      >
        <Droppable
          isDropDisabled={disabled}
          droppableId={DROPPABLE_LABELS}
          direction="horizontal"
        >
          {(droppableProvided, droppableSnapshot) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                // You can adjust the width of the container here
                width,
                mx: 'auto',
                ...sx
              }}
              {...otherProps}
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {labels.map((text, index) => (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                  {(draggableProvided) => (
                    <Paper
                      component={Stack}
                      elevation={2}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      ref={draggableProvided.innerRef}
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        // divide width by number of labels
                        maxWidth: width / labels.length,
                        p: 1
                      }}
                      spacing={1}
                      direction={'row'}
                    >
                      <DragIndicatorIcon fontSize="small" />
                      <Typography
                        sx={{
                          fontSize: labels.length < 4 ? 14 : 12,
                          fontWeight: 700
                        }}
                      >
                        {text}
                      </Typography>
                    </Paper>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </Stack>
          )}
        </Droppable>
        <Droppable droppableId={DROPPABLE_DELETE}>
          {(droppableProvided, droppableSnapshot) => (
            <LightTooltip title="Drag and drop here to delete a label.">
              <Box
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                marginTop={-1}
              >
                <IconButton
                  size="small"
                  sx={{
                    'transition': 'all 0.3s ease-in-out',
                    'color': droppableSnapshot.isDraggingOver
                      ? 'error.dark'
                      : 'error.main',
                    'transform': isDragging ? 'scale(1.5)' : 'scale(1)',
                    'position': 'relative',
                    ':before': {
                      // circle around icon
                      content: '""',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      border: (theme) =>
                        `2px solid ${theme.palette.error.main}`,
                      backgroundColor: droppableSnapshot.isDraggingOver
                        ? 'error.light'
                        : 'transparent',
                      zIndex: -1
                    }
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </LightTooltip>
          )}
        </Droppable>
      </Stack>
    </DragDropContext>
  )
}
