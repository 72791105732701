// React imports
import React, { forwardRef } from 'react'

// MUI Components
import {
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  Box,
  Link
} from '@mui/material'

// Custom Components
import Title from '/src/components/common/Title/Title'
import Comment from './comment'
import CustomCollapse from '/src/components/collapse'
import { EditableCard } from '/src/components/edittable-card'
import ReactMarkdown from 'react-markdown'

// Utils
import { createObjectFromArrayOfObjects } from '/src/utils/create-object-from-array'

// Others
import PropTypes from 'prop-types'
import Center from '/src/components/center'
import employeeResultsModal from './employee-results-modal'

export const ReportsAreNotAvailable = ({ text }) => (
  <Card
    component={Center}
    direction="row"
    spacing={1}
    sx={{
      height: 100
    }}
    elevation={20}
  >
    <Typography variant="h5">{text}</Typography>
  </Card>
)

export const ResultCards = ({ dataCardsRef, results }) => {
  // Removing results without reviews
  const resultsByQuestionWithReviews = results.resultsByQuestion

  return resultsByQuestionWithReviews.map((result, index) => {
    if (!dataCardsRef.current[index]) {
      dataCardsRef.current[index] = React.createRef()
    }

    return (
      <ResultCard
        ref={dataCardsRef.current[index]}
        key={index}
        result={result}
        reportConfig={results.evaluationConfig.reportConfig}
        dataConfig={results.evaluationConfig.dataConfig}
      />
    )
  })
}

export const ResultCard = forwardRef(
  ({ result, reportConfig, dataConfig, mode }, ref) => {
    const { convertedReportConfig, displayTrueScores } = useResultCard({
      result,
      reportConfig,
      dataConfig
    })

    const noComment = result.comments.length === 0

    return (
      <Card
        sx={{
          height: '100%'
        }}
      >
        <CardContent
          sx={{
            px: {
              md: 5,
              xs: 2
            }
          }}
        >
          {/* RESULT CARDS */}
          <Grid
            container
            rowGap={{
              xs: 2,
              md: 0
            }}
          >
            {/* CARD TITLE */}
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: {
                  xs: 'center',
                  md: 'flex-start'
                }
              }}
            >
              <Box>
                <Title
                  textSize={26}
                  text={result.behavior}
                  mr={{ md: 6, xs: 0 }}
                />
                {result.behaviorLink && (
                  <Typography variant="caption">
                    <Link href={result.behaviorLink} target="_blank">
                      Click to learn more.
                    </Link>
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* SCORE CARDS */}
            <Grid
              component="div"
              ref={ref}
              sx={{
                justifyContent: 'center',
                pl: {
                  xs: 0,
                  md: 2
                },
                borderLeft: { xs: 'unset', md: '1px solid #e0e0e0' }
              }}
              item
              xs={12}
              md={9}
              container
              rowGap={2}
              columnGap={2}
            >
              {displayTrueScores.map((score, i) => (
                <EditableCard key={i} data={score} />
              ))}
            </Grid>
          </Grid>

          {/* COMMENTS */}
          <Stack spacing={4} marginTop={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={
                convertedReportConfig.comments.display
                  ? 'space-between'
                  : 'flex-end'
              }
              mt={5}
              pl={{ xs: 1, md: 0 }}
            >
              <Title
                sx={{
                  justifySelf: 'center',
                  display: convertedReportConfig.comments.display
                    ? 'flex'
                    : 'none'
                }}
                textSize={22}
                text={'Comments'}
              />
            </Stack>

            {convertedReportConfig.comments.display ? (
              <Stack
                sx={{
                  position: 'relative'
                }}
              >
                {result.selfComment ? (
                  <Typography pb={2}>
                    <strong>Self Reflection: </strong>
                    <ReactMarkdown>{result.selfComment}</ReactMarkdown>
                  </Typography>
                ) : null}
                {/* render all comments */}
                <Box>
                  <CustomCollapse
                    collapsedSize={80}
                    blur
                    dataGaid="show-comments"
                  >
                    {result.comments.map((comment, index) => {
                      return (
                        <Comment key={index} comment={comment} index={index} />
                      )
                    })}
                    {noComment && (
                      <Typography
                        sx={{
                          textAlign: 'center'
                        }}
                      >
                        You didn't receive any written feedback for this
                        behavior.
                      </Typography>
                    )}
                  </CustomCollapse>
                </Box>
              </Stack>
            ) : null}
          </Stack>
        </CardContent>
      </Card>
    )
  }
)

ResultCard.propTypes = {
  result: PropTypes.object.isRequired,
  getScoreCardElementsRefs: PropTypes.func,
  reportConfig: PropTypes.array.isRequired
}

const useResultCard = ({ result, reportConfig, dataConfig }) => {
  // Convert report config to object for easy access
  const convertedReportConfig = createObjectFromArrayOfObjects({
    array: reportConfig,
    fieldToKey: 'name'
  })

  // Parse scores
  const parsedScores = employeeResultsModal.prepareEmployeeResultData({
    result,
    reportConfig,
    dataConfig
  })

  return {
    convertedReportConfig,
    displayTrueScores: parsedScores
  }
}
