import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormHelperText, Stack, SvgIcon, Typography } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import { tableTypes } from '/src/components/users-table/constants/table-types'
import { UsersTable } from '/src/components/users-table/users-table'
import { CONFIG_FIELDS } from '/src/constants/evaluation-config'
import { USER_KEYS } from '/src/constants/user'
import DescriptionBox from '/src/components/description-box'
import FeedbackIcon from '@mui/icons-material/Feedback'
import SecondaryButton from '/src/components/buttons/secondary-button'
import PrimaryButton from '/src/components/buttons/primary-button'

const {
  SENIORITY,
  JOB_LEVEL,
  FULL_NAME,
  TEAM,
  DIVISION,
  JOB_TITLE,
  LOCATION,
  COHORT
} = USER_KEYS

export const columns = [
  {
    key: FULL_NAME,
    label: 'Name',
    minWidth: 150,
    filter: true,
    sort: true
  },
  {
    key: TEAM,
    label: 'Team',
    minWidth: 100,
    filter: true,
    sort: true
  },
  {
    key: COHORT,
    label: 'Cohort',
    minWidth: 100,
    filter: true,
    sort: true
  },
  {
    key: DIVISION,
    label: 'Division',
    minWidth: 150,
    filter: true,
    sort: true
  },
  {
    key: SENIORITY,
    label: 'Seniority',
    minWidth: 100,
    filter: true,
    sort: true
  },
  {
    key: JOB_LEVEL,
    label: 'Job Level',
    minWidth: 50,
    sort: true,
    filter: true,
    numeric: true
  },
  {
    key: JOB_TITLE,
    label: 'Job Title',
    minWidth: 150,
    filter: true,
    sort: true
  },
  {
    key: LOCATION,
    label: 'Location',
    minWidth: 150,
    filter: true,
    sort: true
  }
]

export const EvaluationEmployeesStep = (props) => {
  const { onBack, onNext, formik, ...other } = props

  const filteredEmployees = formik.values.companyEmployees.filter((user) =>
    formik.values.users.includes(user.uid)
  )
  const [selected, setSelected] = useState(filteredEmployees)

  const handleClick = (_, userObj) => {
    // If the user is already selected, remove them from the list
    // Otherwise, add them to the list
    const selectedUids = selected.map((user) => user.uid)

    const newUsers = selectedUids.includes(userObj.uid)
      ? selected.filter((user) => user.uid !== userObj.uid)
      : [...selected, userObj]

    const newUserIds = newUsers.map((user) => user.uid)

    setSelected(newUsers)
    return formik.setFieldValue('users', newUserIds)
  }

  const handleSelectAll = (e, users) => {
    if (e.target.checked) {
      const usersUids = users.map((user) => user.uid)

      setSelected((prev) => {
        formik.setFieldValue('users', usersUids)

        return users
      })
    } else {
      setSelected(() => {
        formik.setFieldValue('users', [])
        return []
      })
    }
  }

  const disabledSelection =
    formik.values.formSettings[CONFIG_FIELDS.EMPLOYEE_SELECTION]?.disabled

  return (
    <Stack spacing={3} {...other}>
      <div>
        <Typography variant="subtitle1">
          Who would you like to include in this review process?
        </Typography>
      </div>
      {disabledSelection ? (
        <DescriptionBox
          icon={
            <SvgIcon color="warning">
              <FeedbackIcon />
            </SvgIcon>
          }
          description="You can't add or remove employees since the review process has started already."
        />
      ) : null}
      <UsersTable
        type={disabledSelection ? tableTypes.PROGRESS : tableTypes.SELECTION}
        data={formik.values.companyEmployees}
        columns={columns}
        title="Company Employees"
        selected={selected || []}
        onSelect={handleClick}
        onSelectAll={handleSelectAll}
      />
      <FormHelperText
        error={Boolean(formik.touched.users && formik.errors.users)}
        touched={formik.touched.users}
        sx={{
          fontSize: 15
        }}
      >
        {formik.touched.users && formik.errors.users}
      </FormHelperText>
      <Stack alignItems="center" direction="row" spacing={2}>
        <SecondaryButton onClick={onBack}>Back</SecondaryButton>
        <PrimaryButton
          startIcon={
            <SvgIcon>
              <SaveIcon />
            </SvgIcon>
          }
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}
        >
          Save
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}

EvaluationEmployeesStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func
}
