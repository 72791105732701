import React from 'react'
import { ButtonProps } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import TextButton from '/src/components/buttons/text-button'

const BackButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <TextButton startIcon={<ArrowBackIcon />} {...props}>
      Back
    </TextButton>
  )
}

export default BackButton
