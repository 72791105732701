import { filterModesForNumericFields } from '/src/components/users-table/constants/filter-modes'
import { capitalizeCamelCase } from '/src/utils/capitalize-camelCaseString'

// get all unique values for each filter type
const getFilterButtonsWithOptions = ({ filterTypes, users, columns }) => {
  if (!filterTypes) return []

  const filterTypesKeys = Object.keys(filterTypes)

  const uniqueValues = filterTypesKeys.reduce((acc, key) => {
    const values = users.map((user) => user[key])

    const unique = [...new Set(values)]

    if (!unique.length) return acc

    return { ...acc, [key]: unique }
  }, {})

  // remove empty field
  Object.keys(uniqueValues).forEach((key) => {
    // filter out empty values and not applicable
    uniqueValues[key] = uniqueValues[key].filter(
      (value) => value !== '' && value !== undefined && value !== null
    )

    const isEmpty = uniqueValues[key].length === 0

    return isEmpty && delete uniqueValues[key]
  })

  const converted = convertObjectToArrayOfObject(uniqueValues, columns)

  return converted
}

// get options for each filter type
const convertObjectToArrayOfObject = (object, columns) => {
  const keys = Object.keys(object)
  const values = Object.values(object)

  const converted = keys.map((key, index) => {
    const column = columns.find((column) => column.key === key)

    // Push numeric filters to the end of the array (so they appear last in the chips)
    if (column?.numeric) {
      return {
        name: column.label,
        key,
        type: 'numeric',
        options: filterModesForNumericFields.map((mode) => ({
          label: mode.label,
          sign: mode.sign,
          value: mode.name
        }))
      }
    }

    // Push text filters to the beginning of the array (so they appear first in the chips)
    return {
      name: column.label,
      key,
      type: 'text',
      options: values[index].map((value) => ({
        label: column.label === 'Status' ? capitalizeCamelCase(value) : value,
        value: value
      }))
    }
  })

  return converted
}

export { getFilterButtonsWithOptions }
