import React, { useCallback } from 'react'
import {
  Stack,
  Typography,
  Card,
  CardActionArea,
  useTheme
} from '@mui/material'
import { Scrollbar } from '../scrollbar'

const ReviewSelectCards = (props) => {
  const { defaultValues, label, values, type, setFilters } = props
  const theme = useTheme()

  const handleSelect = useCallback(
    (value) => {
      setFilters((prev) => {
        const properFilter = prev[type]
        // if value is already selected, remove it
        if (properFilter.values.includes(value)) {
          return {
            ...prev,
            [type]: {
              ...prev[type],
              values: properFilter.values.filter((val) => val !== value)
            }
          }
        }

        // otherwise add it
        return {
          ...prev,
          [type]: {
            ...prev[type],
            values: [...properFilter.values, value]
          }
        }
      })
    },
    [type, setFilters]
  )

  return (
    <Stack spacing={2} flexGrow={1}>
      <Typography color="inherit" variant="subtitle2">
        {label}
      </Typography>
      <Scrollbar
        sx={{
          'minWidth': '100%',
          'maxWidth': '50vh',
          '& .simplebar-track': {
            marginBottom: -1.5
          }
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          minWidth={defaultValues.length * 80}
        >
          {defaultValues.map((value, index) => (
            <Card sx={{ borderRadius: 1 }} key={type + index}>
              <CardActionArea
                sx={{
                  width: 100,
                  height: 75,
                  py: 0.5,
                  border:
                    theme.palette.mode === 'light'
                      ? '1px solid #E0E0E0'
                      : '1px solid #2B2B2B',
                  backgroundColor:
                    theme.palette.mode === 'light'
                      ? values.includes(value.uid)
                        ? 'primary.main'
                        : 'neutral.100'
                      : values.includes(value.uid)
                      ? 'primary.main'
                      : 'neutral.800'
                }}
                onClick={() => handleSelect(value.uid)}
              >
                <Scrollbar
                  sx={{
                    'height': '100%',
                    'overflowX': 'hidden',
                    '& .simplebar-track': {
                      display: 'none'
                    },
                    '& .simplebar-content': {
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }
                  }}
                >
                  <Typography
                    fontSize={12}
                    px={1}
                    color={
                      values.includes(value.uid)
                        ? 'customColors.textColor'
                        : 'text.primary'
                    }
                    textAlign="center"
                    variant="subtitle2"
                  >
                    {value.name}
                  </Typography>
                </Scrollbar>
              </CardActionArea>
            </Card>
          ))}
        </Stack>
      </Scrollbar>
    </Stack>
  )
}

export default ReviewSelectCards
