// MUI Components
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography
} from '@mui/material'

// Custom Components
import { AnimatedLogo } from '/src/components/animated-logo'
import { Skeletons } from '/src/components/skeletons'
import { Seo } from '/src/components/seo'
import { LoadingError } from '/src/components/loading-error'
import { ResultCards } from '/src/components/result-card/result-card'
import { BasicPdfButton } from '/src/components/pdf-previewer/components/basic-pdf-button'
import LoadingOverlay from '/src/components/loading-overlay'
import ResultNotesModal from '/src/sections/dashboard/reviews/results/result-notes-modal'

// HOCS
import { withReviewGuard } from '/src/hocs/with-review-guard'

// Hooks
import { useParams } from 'react-router'
import { useUser } from '/src/hooks/use-user'
import { useEffect } from 'react'
import { ROLES } from '/functions/shared/constants/roles'
import useEmployeeResultsPdf from '/src/components/pdf-previewer/hooks/use-employee-results-pdf'
import useGetRequest, { Endpoints } from '/src/hooks/requests/use-get-request'

// Others
import { findObjectInArray } from '/src/utils/find-object-in-array'
import Proptypes from 'prop-types'
import { surveyAliasMappings } from '/src/refiner/constants'
import { showSurvey } from '/src/refiner/functions'
import EmployeeSuggestionsModal from '/src/sections/dashboard/reviews/results/employee-suggestions-modal'

const PageErrorContent = ({ error }) => (
  <Stack pt={10}>
    <LoadingError message={error.message || 'Something went wrong'} />
  </Stack>
)

PageErrorContent.propTypes = {
  error: Proptypes.object.isRequired
}

const PageLoadingContent = () => (
  <>
    <Stack
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      sx={{
        p: 3
      }}
    >
      <Skeletons variant="rounded" count={1} width={250} height={50} />
      <Skeletons variant="rounded" count={1} width={90} height={40} />
    </Stack>
    <Divider />
    <Stack
      sx={{
        pl: {
          xs: 0,
          md: 3
        }
      }}
    >
      <LoadingOverlay pt={10}>
        <AnimatedLogo width={60} />
      </LoadingOverlay>
    </Stack>
  </>
)

const PageContent = ({
  currentEvaluationResult,
  user,
  currentEvaluationConfig,
  currentEvaluationQuestions
}) => {
  // Get status of did user received any feedback
  const userReceivedFeedback = currentEvaluationResult.resultsByQuestion.some(
    (result) => result.reviewsReceived
  )

  const {
    generateEmployeeResultsPDF,
    isLoading: pdfLoading,
    dataCardsRef
  } = useEmployeeResultsPdf({
    result: currentEvaluationResult,
    fullName: user.fullName,
    isEmployee: true
  })

  const handlePdfDownload = async () =>
    await generateEmployeeResultsPDF({
      withComments: currentEvaluationConfig.reportConfig.find(
        (config) => config.name === 'comments'
      ).display
    })

  // Show survey when the last card is in view
  useEffect(() => {
    if (
      !dataCardsRef.current ||
      Object.keys(dataCardsRef.current).length === 0
    ) {
      return
    }

    const lastElement = Object.keys(dataCardsRef.current).length - 1
    const targetElement = dataCardsRef.current[lastElement].current

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            showSurvey(surveyAliasMappings.EMPLOYEE_RESULTS_SURVEY)
          }, 20000) // Adjust the delay (in milliseconds) as needed
        }
      })
    }

    const observerOptions = {
      root: null, // Use the viewport as the container
      rootMargin: '0px',
      threshold: 0.9 // Trigger callback when 90% of the element is in view
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions)

    observer.observe(targetElement)

    return () => {
      observer.unobserve(targetElement)
    }
  }, [dataCardsRef])

  return (
    <>
      <Box sx={{ py: 3 }}>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Stack direction="column" spacing={2}>
            <Typography variant="h3">My Feedback</Typography>
            <Typography variant="h6" color="text.secondary">
              {currentEvaluationResult.evaluationName}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            {userReceivedFeedback ? (
              <>
                <EmployeeSuggestionsModal
                  user={user}
                  result={currentEvaluationResult}
                  evaluationQuestions={currentEvaluationQuestions}
                />
                <ResultNotesModal
                  data={{
                    evaluationConfig: currentEvaluationResult.evaluationConfig,
                    notes: currentEvaluationResult.notes,
                    resultId: currentEvaluationResult.uid,
                    userRole: ROLES.EMPLOYEE,
                    userId: currentEvaluationResult.userId,
                    fullName: user.fullName
                  }}
                />
                <BasicPdfButton
                  data-gaid="download-pdf-for-employee"
                  isLoading={pdfLoading}
                  disabled={pdfLoading}
                  onClick={handlePdfDownload}
                  tooltipText={pdfLoading ? 'Loading PDF...' : 'View as PDF'}
                />
              </>
            ) : null}
          </Stack>
        </Stack>
      </Box>
      <Divider />
      <Stack>
        {/* result components */}
        <Stack my={2}>
          {userReceivedFeedback ? (
            <Stack
              mt={{
                xs: 0,
                md: 5
              }}
              spacing={5}
            >
              <ResultCards
                results={currentEvaluationResult}
                dataCardsRef={dataCardsRef}
              />
            </Stack>
          ) : (
            <Card sx={{ mt: 4, textAlign: 'center' }}>
              <CardContent>
                <Typography color="text.primary" variant="body1">
                  You didn't receive any feedback in this review cycle.
                </Typography>
              </CardContent>
            </Card>
          )}
        </Stack>
      </Stack>
    </>
  )
}

PageContent.propTypes = {
  currentEvaluationResult: Proptypes.object.isRequired,
  user: Proptypes.object.isRequired,
  currentEvaluationConfig: Proptypes.object.isRequired,
  currentEvaluationQuestions: Proptypes.array.isRequired
}

const Page = () => {
  const { isLoading, data, error } = useGetRequest({
    endpoint: Endpoints.GET_RESULTS_FOR_USER
  })

  const { evaluationId } = useParams()

  const currentEvaluationResult =
    !isLoading &&
    findObjectInArray({
      array: data?.results,
      key: 'evaluationId',
      value: evaluationId
    })

  // Getting the user
  const { user } = useUser()

  // Getting reportConfig
  const currentEvaluationConfig =
    !isLoading && currentEvaluationResult?.evaluationConfig
  const currentEvaluationQuestions =
    !isLoading && currentEvaluationResult?.evaluationQuestions

  return (
    <>
      <Seo title="Results" />
      <Box
        component="main"
        sx={{
          position: 'relative',
          flexGrow: 1,
          overflowX: 'hidden'
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            overflowY: 'hidden'
          }}
        >
          {/* The loading components */}
          {isLoading && <PageLoadingContent />}
          {/* The error components */}
          {!isLoading && error && <PageErrorContent error={error} />}
          {/* The content components */}
          {!isLoading && !error && currentEvaluationResult && (
            <PageContent
              {...{
                currentEvaluationResult,
                user,
                currentEvaluationConfig,
                currentEvaluationQuestions
              }}
            />
          )}
          {
            // if there is no result, then show a warning
            !isLoading && !error && !currentEvaluationResult && (
              <Stack pt={10}>
                <Typography
                  variant="h3"
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  There are no results for this review cycle.
                </Typography>
              </Stack>
            )
          }
        </Container>
      </Box>
    </>
  )
}

export default withReviewGuard(Page)
