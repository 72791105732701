import { createComponents } from './create-components'
import { createPalette } from './create-palette'
import { createShadows } from './create-shadows'

export const createOptions = ({ customizationConfig }) => {
  const palette = createPalette({ customizationConfig })
  const components = createComponents({ palette })
  const shadows = createShadows()

  return {
    components,
    palette,
    shadows
  }
}
