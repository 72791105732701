// Components
import {
  Popover,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@mui/material'
import CommentIcon from '@mui/icons-material/Comment'
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled'

// Hooks
import { useState } from 'react'

// Utils
import PropTypes from 'prop-types'
import { BasicPdfButton } from './basic-pdf-button'
import { convertToSlug } from '/src/utils/convert-to-slug'

const buttonNames = {
  DOWNLOAD_WITH_COMMENTS: 'download-with-comments-pdf-button',
  DOWNLOAD_WITHOUT_COMMENTS: 'download-without-comments-pdf-button'
}

const downloadPdfButtons = [
  {
    name: buttonNames.DOWNLOAD_WITH_COMMENTS,
    icon: <CommentIcon />,
    text: 'Download with comments'
  },
  {
    name: buttonNames.DOWNLOAD_WITHOUT_COMMENTS,
    icon: <CommentsDisabledIcon />,
    text: 'Download without comments'
  }
]

const AdminAndManagerEmployeeResultsPdfButton = ({
  generateEmployeeResultsPDF,
  disabled,
  downloadWithComments
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'pdf-popover' : undefined

  const handlePdfDownload = async (e) =>
    await generateEmployeeResultsPDF({
      withComments:
        e.currentTarget.getAttribute('name') ===
        buttonNames.DOWNLOAD_WITH_COMMENTS
    })

  return (
    <div>
      <BasicPdfButton
        data-gaid="download-pdf-button"
        aria-describedby={id}
        onClick={handleClick}
        disableTooltip
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              PDF Options
            </ListSubheader>
          }
        >
          {downloadPdfButtons.map((button) => {
            if (
              !downloadWithComments &&
              button.name === buttonNames.DOWNLOAD_WITH_COMMENTS
            ) {
              return null
            }

            return (
              <ListItemButton
                key={button.name}
                onClick={handlePdfDownload}
                name={button.name}
                disabled={disabled}
                data-gaid={`pdf-button-${convertToSlug(button.name)}`}
              >
                <ListItemIcon>{button.icon}</ListItemIcon>
                <ListItemText primary={button.text} />
              </ListItemButton>
            )
          })}
        </List>
      </Popover>
    </div>
  )
}

AdminAndManagerEmployeeResultsPdfButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  generateEmployeeResultsPDF: PropTypes.func.isRequired
}

export default AdminAndManagerEmployeeResultsPdfButton
