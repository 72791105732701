const drawerTypes = {
  DASHBOARD_ANALYSES: 'analysis',
  DASHBOARD_REWARDS: 'rewards',
  DASHBOARD_INSIGHTS: 'insights',
  DASHBOARD_DEFAULT: 'default'
}

const componentTypes = {
  REVIEW_SELECT: 'reviewSelect',
  SLIDER: 'slider',
  SELECT: 'select',
  CHIPS: 'chips'
}

export { drawerTypes, componentTypes }
