import React from 'react'
import { Button, ButtonProps, SxProps } from '@mui/material'

interface GreyOutlinedButtonProps extends ButtonProps {
  isActive?: boolean
  sx?: SxProps
}

const GreyOutlinedButton: React.FC<GreyOutlinedButtonProps> = ({
  isActive,
  sx,
  children,
  ...otherProps
}) => (
  <Button
    sx={{
      borderRadius: '8px',
      borderColor: (theme) =>
        theme.palette.mode === 'light'
          ? isActive
            ? 'primary.main'
            : 'neutral.200'
          : isActive
          ? 'primary.main'
          : 'neutral.700',

      color: (theme) =>
        theme.palette.mode === 'light'
          ? isActive
            ? 'primary.main'
            : 'grey.700'
          : isActive
          ? 'primary.main'
          : 'neutral.400',
      ...sx
    }}
    variant="outlined"
    {...otherProps}
  >
    {children}
  </Button>
)

export default GreyOutlinedButton
