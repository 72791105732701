import makeRequest from './index'

const reviewsProvider = {
  getReview: async (data) => {
    const result = await makeRequest({ data, endpoint: 'getReview' })

    return result.data
  },
  getReviews: async () => {
    const result = await makeRequest({ endpoint: 'getReviews' })

    return result.data
  },
  updateReview: async (data) => {
    const result = await makeRequest({ data, endpoint: 'updateReview' })

    return result.data
  }
}

export default reviewsProvider
