import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import { Seo } from '/src/components/seo'
import { RequestsInbox } from '/src/components/requests-inbox'
import { ROLES } from '/functions/shared/constants/roles'
import { ReviewsTable } from '/src/components/reviews-table/reviews-table'
import useGetRequest, { Endpoints } from '/src/hooks/requests/use-get-request'

const Page = () => {
  const {
    isLoading: isRequestsLoading,
    data: requests,
    error: requestsError,
    refetch
  } = useGetRequest({
    endpoint: Endpoints.GET_EMPLOYEE_REQUESTS,
    data: { userRole: ROLES.MANAGER }
  })

  const {
    data: evaluations,
    isLoading: isEvaluationsLoading,
    error: evaluationsError
  } = useGetRequest({
    endpoint: Endpoints.GET_EVALUATIONS,
    data: { userRole: ROLES.MANAGER }
  })

  return (
    <>
      <Seo title="My Team - Review Cycles" />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            mb: 5
          }}
        >
          <Box sx={{ p: 3, pt: 0 }}>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="flex-start"
              spacing={1}
            >
              <div>
                <Typography variant="h3">My Team - Review Cycles</Typography>
              </div>
            </Stack>
          </Box>
          <Divider />
          <Container maxWidth="lg">
            <Grid container rowGap={3}>
              <Grid item xs={12} mt={3}>
                <ReviewsTable
                  title="Reviews"
                  isLoading={isEvaluationsLoading}
                  error={evaluationsError}
                  reviews={evaluations}
                  refetch={refetch}
                  role={ROLES.MANAGER}
                />
              </Grid>
              <Grid item xs={12}>
                <RequestsInbox
                  userRole={ROLES.MANAGER}
                  isLoading={isRequestsLoading}
                  error={requestsError}
                  refetch={refetch}
                  requests={requests}
                />
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Box>
    </>
  )
}

export default Page
