import { SeverityPill } from '/src/components/severity-pill'
import { COMPLETION_STATES } from '/src/constants/statuses'

export const renderReviewStatus = (value) => {
  const statusMap = {
    [COMPLETION_STATES.COMPLETED]: 'success',
    [COMPLETION_STATES.NOT_STARTED]: 'error',
    [COMPLETION_STATES.PARTIALLY_COMPLETED]: 'warning'
  }

  return <SeverityPill color={statusMap[value]}>{value}</SeverityPill>
}
