import { useCallback, useState, useMemo } from 'react'
import { EvaluationDetailsStep } from './evaluation-details-step'
import { EvaluationEmployeesStep } from './evaluation-employees-step'
import evaluationFormActions from './evaluation-form-actions'
import { useFormik } from 'formik'
import { finalValidationSchema, validationSteps } from './evaluation-schema'
import { toast } from 'react-toastify'
import { EvaluationBehaviorsStep } from './evaluation-behaviors-step'
import { EVALUATION_FORM_METHODS } from '/src/constants/evaluation-form-methods'
import { useRouter } from '/src/hooks/use-router'
import { paths } from '/src/constants/paths'

export const useStepper = ({
  evaluation,
  method,
  behaviors,
  companyEmployees
}) => {
  const router = useRouter()
  const [activeStep, setActiveStep] = useState(0)

  const initialValues = useMemo(
    () =>
      evaluationFormActions.getInitialValues({
        evaluation,
        behaviors,
        companyEmployees
      }),
    [evaluation, behaviors, companyEmployees]
  )

  // useFormik
  const formik = useFormik({
    initialValues,
    validationSchema: validationSteps[activeStep],
    onSubmit: async (values, { resetForm }) => {
      // validate form
      return finalValidationSchema
        .validate(values)
        .then(async () => {
          // Parse values to submit
          const valuesToSubmit =
            evaluationFormActions.parseValuesToSubmit(values)

          // Proceed with submission logic
          const response = await evaluationFormActions.handleAction({
            values: valuesToSubmit,
            method
          })

          if (response) {
            if (method === EVALUATION_FORM_METHODS.CREATE) {
              // Redirect to the reviews page
              router.replace(paths.dashboard.admin.reviews.index)
            } else if (method === EVALUATION_FORM_METHODS.UPDATE) {
              // replace the current evaluation with the updated one
              resetForm({
                values: evaluationFormActions.getInitialValues({
                  evaluation: response.evaluation,
                  behaviors: response.evaluation.questions,
                  companyEmployees: companyEmployees
                })
              })
            }
          }
        })
        .catch((err) => {
          // Handle validation errors
          console.log(err)
          toast.error(err.message)
        })
    }
  })

  const checkValidation = useCallback(async () => {
    const failedFields = await formik.validateForm()
    formik.setTouched(failedFields)

    return !Object.keys(failedFields).length
  }, [formik])

  const handleNext = useCallback(async () => {
    const isValid = await checkValidation()

    if (isValid) {
      setActiveStep((prevState) => prevState + 1)
    }
  }, [checkValidation])

  const handleBack = useCallback(() => {
    setActiveStep((prevState) => prevState - 1)
  }, [])

  const handleSetActiveStep = useCallback(
    (step) => async () => {
      // if user is trying to go back, just go back
      if (step < activeStep) {
        setActiveStep(step)
      } else {
        // otherwise, check validation
        const isValid = await checkValidation()

        // if valid, go to next step
        if (isValid) {
          setActiveStep(step)
        }

        // otherwise, do nothing
      }
    },
    [activeStep, checkValidation]
  )

  const steps = useMemo(() => {
    return [
      {
        label: 'Review Cycle Settings',
        content: (
          <EvaluationDetailsStep
            formik={formik}
            onBack={handleBack}
            onNext={handleNext}
          />
        )
      },
      {
        label: 'Behaviors',
        content: (
          <EvaluationBehaviorsStep
            formik={formik}
            onBack={handleBack}
            onNext={handleNext}
            behaviors={formik.values.behaviors}
            evaluationStatus={evaluation?.status}
          />
        )
      },
      {
        label: 'Participants',
        content: <EvaluationEmployeesStep formik={formik} onBack={handleBack} />
      }
    ]
  }, [handleBack, handleNext, evaluation?.status, formik])

  return {
    activeStep,
    steps,
    handleSetActiveStep,
    formik
  }
}
