import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '/src/hooks/use-auth'
import { useRouter } from '/src/hooks/use-router'
import { paths } from '/src/constants/paths'
import { useLocation } from 'react-router-dom'
import { getSearchParams } from '/src/utils/guards/path'

export const GuestGuard = (props) => {
  const { children } = props
  const { isAuthenticated, user } = useAuth()
  const router = useRouter()
  const location = useLocation()
  const [checked, setChecked] = useState(false)

  const check = useCallback(
    (searchParams) => {
      if (isAuthenticated) {
        if (user?.status === 'DEMO_SETUP') {
          // Handle demo setup
          router.replace(paths.demoAccount)
        } else {
          // Otherwise redirect to the app dashboard
          router.replace(paths.dashboard.index)
        }
      } else {
        router.replace(location.pathname, {
          state: { ...location.state, ...searchParams }
        })

        setChecked(true)
      }
    },
    [isAuthenticated, router, user?.status, location.pathname, location.state]
  )

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      const searchParams = getSearchParams({ search: location.search })
      check(searchParams)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (!checked) {
    return null
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // not authenticated / authorized.

  return <>{children}</>
}

GuestGuard.propTypes = {
  children: PropTypes.node
}
