import {
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  SvgIcon,
  Typography,
  useMediaQuery
} from '@mui/material'
import ScatterGridChart from '/src/components/charts/scatter-grid'
import { LightTooltip } from '/src/components/light-tooltip'
import InfoIcon from '@mui/icons-material/Info'

const useDashboardGrid = (mergedArray, isError, filteredBehaviorsWeights) => {
  const competencyBehaviorWeights = filteredBehaviorsWeights.filter(
    (behavior) => behavior.type === 'Competency'
  )

  const valueBehaviorWeights = filteredBehaviorsWeights.filter(
    (behavior) => behavior.type === 'Value'
  )

  const competencies = competencyBehaviorWeights.map((item) => item.behavior)
  const values = valueBehaviorWeights.map((item) => item.behavior)

  // Iterate through the array and calculate the total competency and value scores
  for (const obj of mergedArray) {
    let cumulativeCompetencyPercentile = 0
    let cumulativeValuePercentile = 0
    let totalApplicableCompetencyWeights = 0
    let totalApplicableValueWeights = 0

    for (const [behaviorName, percentile] of Object.entries(obj.percentiles)) {
      if (competencies.includes(behaviorName)) {
        const competencyWeight = competencyBehaviorWeights.find(
          (behavior) => behavior.behavior === behaviorName
        ).weight
        cumulativeCompetencyPercentile +=
          percentile.peerfulScore * competencyWeight
        totalApplicableCompetencyWeights += competencyWeight
      }
      if (values.includes(behaviorName)) {
        const valueWeight = valueBehaviorWeights.find(
          (behavior) => behavior.behavior === behaviorName
        ).weight
        cumulativeValuePercentile += percentile.peerfulScore * valueWeight
        totalApplicableValueWeights += valueWeight
      }
    }

    obj.competencyScore = (
      cumulativeCompetencyPercentile / totalApplicableCompetencyWeights
    ).toFixed(0)
    obj.valueScore = (
      cumulativeValuePercentile / totalApplicableValueWeights
    ).toFixed(0)
  }
  // [
  //     {name: 'Alice', competency: 5, value: 8},
  //     {name: 'Bob', competency: 7, value: 5}, ...
  // ];
  // Create an employee data set with the format above for each employee in the results
  let employees = mergedArray.map((obj) => {
    const employee = {
      name: obj.fullName,
      uid: obj.uid,
      xAxisScoreName: 'Competency',
      xAxisScoreType: 'Percentile',
      yAxisScoreName: 'Value',
      yAxisScoreType: 'Percentile',
      xAxis: !isError ? obj.competencyScore : 0,
      yAxis: !isError ? obj.valueScore : 0
    }

    return employee
  })

  return { employees }
}

const OverviewGrid = ({ employees, behaviors, onClick, sx }) => {
  const behaviorWeights =
    behaviors.map((behavior, index) => ({
      behavior: behavior,
      weight: 1,
      type: index < behaviors.length / 2 ? 'Value' : 'Competency'
    })) || []

  const { employees: employeeDataForGrid } = useDashboardGrid(
    employees,
    false,
    behaviorWeights
  )
  const up900px = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <Card
      sx={{
        position: 'relative',
        ...sx
      }}
    >
      <LightTooltip
        title={
          <Typography variant="body2" sx={{ maxWidth: 300 }}>
            This is a scatter grid chart that shows the overall competency and
            value scores of employees. The X-axis corresponds to competency
            percentile, while the Y-axis corresponds to value percentile.
          </Typography>
        }
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 2,
            right: 5
          }}
          color="primary"
        >
          <SvgIcon>
            <InfoIcon />
          </SvgIcon>
        </IconButton>
      </LightTooltip>
      <CardContent
        sx={{
          cursor: 'pointer',
          height: 416
        }}
      >
        <CardActionArea
          sx={{
            '& .MuiCardActionArea-focusHighlight': {
              backgroundColor: 'transparent'
            }
          }}
          disableRipple
          disableTouchRipple
          onClick={() => onClick(1)}
        >
          <ScatterGridChart
            employees={employeeDataForGrid}
            levelTags={null}
            axisTags={null}
            sx={{
              transform: 'translateY(-160px)',
              scale: up900px ? '0.65' : '0.7',
              scatterGridBackground: {
                left: 40
              }
            }}
          />
        </CardActionArea>
      </CardContent>
    </Card>
  )
}

export default OverviewGrid
