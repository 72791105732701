import { TableCell } from '@mui/material'
import Proptypes from 'prop-types'
import { CellRenderer } from './cell-renderer'
import useOverflowTooltip from '/src/hooks/use-overflow-tooltip'

export const EnhancedTableCell = ({ id, column, index, row }) => {
  const isTooltipActive = useOverflowTooltip(id)

  return (
    <TableCell id={id}>
      <CellRenderer column={column} row={row} tooltip={isTooltipActive} />
    </TableCell>
  )
}

EnhancedTableCell.propTypes = {
  id: Proptypes.string,
  column: Proptypes.object,
  index: Proptypes.number,
  row: Proptypes.object
}
