import { useCallback, useEffect, useRef } from 'react'
import { usePathname } from '/src/hooks/use-pathname'
import { history } from '/src/components/router/history'
import { sendEventToGoogleAnalytics } from '/src/utils/react-ga'
import consoleLog from '/src/utils/console-log'
import { Location, Action } from 'history'
import TimeMe from 'timeme.js'

type useComponentEngagementTrackerArgs = {
  eventParameters: {
    eventName: string
    action: string
    category: string
    [key: string]: any
  }
  dependencies: any[]
  trackerId?: string
}

// Mask dynamic paths
const uuidRegex =
  /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gi
const maskDynamicPaths = (pathname: string): string =>
  pathname.replace(uuidRegex, '**')

const calculateTimeSpent = (startTime: number): number => {
  const endTime = Date.now()
  const timeSpentInMilliseconds = endTime - startTime
  return Math.round(timeSpentInMilliseconds / 1000) // Convert to seconds
}

const logTimeSpent = ({
  eventName,
  action,
  category,
  totalTimeSpentInSeconds,
  activeTimeSpentInSeconds,
  timemeActiveSpentInSeconds,
  pathname,
  customParams,
  to
}: {
  eventName: string
  action: string
  category: string
  totalTimeSpentInSeconds: number
  activeTimeSpentInSeconds: number
  pathname: string
  timemeActiveSpentInSeconds: number
  customParams?: any
  to?: string
}) => {
  sendEventToGoogleAnalytics(eventName, {
    action,
    category,
    total_spent_in_seconds: totalTimeSpentInSeconds,
    active_spent_in_seconds: activeTimeSpentInSeconds,
    page_path: maskDynamicPaths(pathname),
    timeme_active_spent_in_seconds: timemeActiveSpentInSeconds,
    ...(to ? { to: maskDynamicPaths(to) } : {}),
    ...(customParams || {})
  })

  consoleLog(
    '\n' +
      `Event: ${eventName}\n` +
      `TimeMe active time spent: ${timemeActiveSpentInSeconds} seconds\n` +
      `Total time spent: ${totalTimeSpentInSeconds} seconds\n` +
      `Active time spent: ${activeTimeSpentInSeconds} seconds`
  )
}

export const useEngagementTimeTracker = ({
  eventParameters,
  dependencies = [],
  trackerId
}: useComponentEngagementTrackerArgs): null => {
  const { eventName, action, category, ...customParams } = eventParameters
  const pathname = usePathname()
  const startTotalTimeRef = useRef(Date.now())
  const startActiveTimeRef = useRef(Date.now())
  const activeTimeRef = useRef({ spentInSeconds: 0 })

  const resetTimers = useCallback(() => {
    startActiveTimeRef.current = Date.now()
    startTotalTimeRef.current = Date.now()
    activeTimeRef.current = { spentInSeconds: 0 }
  }, [])

  const handleFocusBlurChange = useCallback(() => {
    if (document.hasFocus()) {
      startActiveTimeRef.current = Date.now()
    } else {
      const timeSpentInSeconds = calculateTimeSpent(startActiveTimeRef.current)
      consoleLog(`timeSpentInSeconds: ${timeSpentInSeconds}`)
      activeTimeRef.current.spentInSeconds += timeSpentInSeconds
    }
  }, [])

  const handleNavigation = useCallback(
    ({
      location,
      action: navigationAction
    }: {
      location: Location
      action: Action
    }) => {
      const totalTimeSpentInSeconds = calculateTimeSpent(
        startTotalTimeRef.current
      )
      const activeTimeSpentInSeconds =
        calculateTimeSpent(startActiveTimeRef.current) +
        activeTimeRef.current.spentInSeconds

      logTimeSpent({
        eventName,
        action,
        category,
        totalTimeSpentInSeconds,
        activeTimeSpentInSeconds,
        timemeActiveSpentInSeconds: Math.round(
          TimeMe.getTimeOnPageInSeconds(trackerId ?? pathname) || 0
        ),
        pathname,
        to: location.pathname,
        customParams
      })

      resetTimers()
    },
    [
      eventName,
      action,
      category,
      customParams,
      pathname,
      resetTimers,
      trackerId
    ]
  )

  const calculateOnUnload = useCallback(() => {
    const totalTimeSpentInSeconds = calculateTimeSpent(
      startTotalTimeRef.current
    )
    const activeTimeSpentInSeconds =
      activeTimeRef.current.spentInSeconds +
      (document.hasFocus() ? calculateTimeSpent(startActiveTimeRef.current) : 0)

    logTimeSpent({
      eventName,
      action,
      category,
      totalTimeSpentInSeconds,
      activeTimeSpentInSeconds,
      timemeActiveSpentInSeconds: Math.round(
        TimeMe.getTimeOnPageInSeconds(trackerId ?? pathname) || 0
      ),
      pathname,
      customParams
    })
  }, [eventName, action, category, customParams, pathname, trackerId])

  useEffect(() => {
    const unListenNavigation = history.listen(handleNavigation)

    window.addEventListener('focus', handleFocusBlurChange)
    window.addEventListener('blur', handleFocusBlurChange)
    window.addEventListener('beforeunload', calculateOnUnload)

    return () => {
      window.removeEventListener('focus', handleFocusBlurChange)
      window.removeEventListener('blur', handleFocusBlurChange)
      window.removeEventListener('beforeunload', calculateOnUnload)
      unListenNavigation()
    }
  }, [handleNavigation, handleFocusBlurChange, calculateOnUnload])

  useEffect(() => {
    // Initialize another TimeMe instance to track time spent when specific dependencies change
    if (trackerId) {
      // Initialize TimeMe for tracking time on this page
      TimeMe.initialize({
        currentPageName: trackerId // A unique name to track time spent when dependencies change
      })
    }

    return () => {
      if (dependencies.length === 0) {
        return // No need to track time spent when dependencies change
      }

      const totalTimeSpentInSeconds = calculateTimeSpent(
        startTotalTimeRef.current
      )
      const activeTimeSpentInSeconds =
        activeTimeRef.current.spentInSeconds +
        calculateTimeSpent(startActiveTimeRef.current)

      if (totalTimeSpentInSeconds) {
        let timeMeTimeSpent
        if (trackerId) {
          timeMeTimeSpent = Math.round(
            TimeMe.getTimeOnPageInSeconds(trackerId) || 0
          )
          TimeMe.stopTimer(trackerId)
          TimeMe.resetRecordedPageTime(trackerId)
        }

        consoleLog('Dependencies changed, logging time spent')
        logTimeSpent({
          eventName,
          action,
          category,
          totalTimeSpentInSeconds,
          activeTimeSpentInSeconds,
          timemeActiveSpentInSeconds: timeMeTimeSpent || 0,
          pathname,
          customParams
        })

        resetTimers()
      }
    }
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps

  // TimeMe to track time spent on the page
  useEffect(() => {
    // Initialize TimeMe for tracking time on this page
    TimeMe.initialize({
      currentPageName: pathname // A unique name for the page
    })

    // Cleanup and stop the timer when the component is unmounted
    return () => {
      TimeMe.stopAllTimers()
      TimeMe.resetAllRecordedPageTimes()
    }
  }, [pathname])

  return null
}
