export const INSIGHTS_IDS = {
  REVIEW_STATISTICS: 'reviewStatistics',
  DISTRIBUTION_OF_EMPLOYEES_BY_REVIEW_COUNT:
    'distributionOfEmployeesByReviewCount',
  DISTRIBUTION_OF_SCORES: 'distributionOfScores',
  PEERFUL_SCORES_BY_DIVISION_AND_BEHAVIOR: 'peerfulScoresByDivisionAndBehavior',
  PEERFUL_SCORES_BY_JOB_LEVEL_AND_BEHAVIOR:
    'peerfulScoresByJobLevelAndBehavior',
  BEHAVIOR_AVERAGE_SCORES_BY_SENIORITY: 'behaviorAverageScoresBySeniority',
  STABILITY_OF_SCORES: 'stabilityOfScores',
  TOP_PERFORMERS: 'topPerformers',
  WORST_PERFORMERS: 'worstPerformers',
  FLIGHT_RISKS: 'flightRisks',
  HIGH_DEVIATIONS: 'highDeviations',
  HIGH_DIGRESSIONS_SELF_VS_PEER: 'highDigressionsSelfVsPeer',
  HIGH_DIGRESSIONS_MANAGER_VS_PEER: 'highDigressionsManagerVsPeer',
  WORD_CLOUD: 'wordCloud'
}
