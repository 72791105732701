import PropTypes from 'prop-types'
import { Grid, Stack } from '@mui/material'
import { executiveOverviewModal } from '/src/data/modals/workforce-results-modal'
import PaginatedDashboardCard from './paginated-dashboards-card'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined'
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined'
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined'
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined'
import DashboardInfoCard from './dashboard-info-card'
import OverviewGrid from './dashboard-overview-grid'
import { Diamond, Star, TrendingDown } from '@mui/icons-material'
import { INSIGHTS_IDS } from '/src/constants/insights-ids'

export const ExecutiveOverview = (props) => {
  const {
    workforceResults,
    evaluations,
    changeDefaultInsight,
    handleTabChange,
    gridData,
    behaviors
  } = props

  const { lastReviewData, lastTwoReviewsData, overallAnalysis } =
    executiveOverviewModal.generateExecutiveOverviewData({
      workforceResults,
      evaluations
    })
  // Setting the data for the dashboard info cards
  const dashboardInfoCardData = [
    {
      title: 'Participation Rate',
      value: overallAnalysis.completionRate + '%',
      description:
        'The participation rate is the average percentage of employees who participated the review cycles.',
      ...(evaluations.length > 1 && {
        appendix:
          'Last Participation Rate: ' + lastReviewData.completionRate + '%'
      })
    },
    {
      title:
        'Total Number of Participants Across ' +
        overallAnalysis.totalReviews +
        ' Review Cycles',
      value: overallAnalysis.totalUniqueParticipants,
      description:
        'This the total number of unique employees who participated in the review cycles.'
    },
    {
      title: 'Data Points Collected',
      value:
        overallAnalysis.approximateTotalNumberOfDataPoints.toLocaleString(),
      description:
        'This is the total number of data points collected across all review cycles. Each data point represents a pairwise comparison of colleagues.',
      appendix:
        Math.round(
          overallAnalysis.approximateTotalNumberOfDataPoints /
            overallAnalysis.totalUniqueParticipants,
          0
        ) + ' colleague opinions collected per employee.'
    },
    {
      title: 'Average Number of Reviewers',
      value: overallAnalysis.averageReviewers,
      description:
        'This average number of people who selected each reviewed employee, across review cycles.'
    },
    {
      title: 'Average Number of Comments',
      value: overallAnalysis.averageComments,
      description:
        'The average number of comments each reviewed employee received, across review cycles.'
    },
    overallAnalysis?.averageSelectedPeopleChange && {
      title: 'Change in Selected People Across Review Cycles',
      value: overallAnalysis.averageSelectedPeopleChange + '%',
      description:
        'The average change in their selection colleagues across review cycles. This could be a sign of turnover in the organization.'
    }
  ].filter((card) => card) // Remove undefined & null values

  // Setting the data for the paginated dashboard cards
  const paginatedDashboardCardData = [
    {
      title: 'Top Scores',
      icon: <Star />,
      nameField: 'fullName',
      performanceList: lastReviewData.topPerformers,
      description: `The employees below were scored the highest scores
        across all behaviors in the most recent review cycle.`,
      changeDefaultInsight: () =>
        changeDefaultInsight(INSIGHTS_IDS.TOP_PERFORMERS),
      isPaginated: true,
      renderJobTitle: true,
      highlight: 'juniors'
    },
    {
      title: 'Low Scores',
      icon: <TrendingDown />,
      nameField: 'fullName',
      performanceList: lastReviewData.worstPerformers,
      description: `The employees below received the lowest scores across all behaviors in the most recent review cycle.`,
      changeDefaultInsight: () =>
        changeDefaultInsight(INSIGHTS_IDS.WORST_PERFORMERS),
      isPaginated: true,
      renderJobTitle: true,
      highlight: 'seniors'
    },
    {
      title: 'Hidden Gems?',
      icon: <Diamond />,
      nameField: 'fullName',
      performanceList: lastReviewData.flightRisks,
      description:
        'Some employees received very few reviews in the most recent review cycle but those reviews scored them very favorably.',
      changeDefaultInsight: () =>
        changeDefaultInsight(INSIGHTS_IDS.FLIGHT_RISKS),
      isPaginated: false
    },
    {
      title: 'High Deviations',
      nameField: 'fullName',
      icon: <BalanceOutlinedIcon />,
      performanceList: lastReviewData.highDeviations,
      description:
        'Some employees received a mix of high and low scores from their colleagues in the most recent review cycle. This might indicate some issues within their teams.',
      changeDefaultInsight: () =>
        changeDefaultInsight(INSIGHTS_IDS.HIGH_DEVIATIONS),
      isPaginated: false
    },
    {
      title: 'Top Divisions',
      icon: <GroupsOutlinedIcon />,
      nameField: 'division',
      performanceList: lastReviewData.topDivisions,
      description:
        'The divisions below received the highest scores on average in the most recent review cycle.',
      changeDefaultInsight: () =>
        changeDefaultInsight(
          INSIGHTS_IDS.PEERFUL_SCORES_BY_DIVISION_AND_BEHAVIOR
        ),
      isPaginated: false
    },
    {
      title: 'Top Teams',
      icon: <PeopleOutlinedIcon />,
      nameField: 'team',
      performanceList: lastReviewData.topTeams,
      description:
        'The teams below received the highest scores on average in the most recent review cycle.',
      changeDefaultInsight: () =>
        changeDefaultInsight(INSIGHTS_IDS.REVIEW_STATISTICS),
      isPaginated: false
    },
    {
      title: 'Highly Connected People',
      icon: <ConnectWithoutContactOutlinedIcon />,
      nameField: 'fullName',
      performanceList: lastReviewData.highlyConnectedPeople,
      description:
        'Some people received a lot of reviews in the most recent review cycle - these highly connected people could be top collaborators and be cultural influencers.',
      changeDefaultInsight: () =>
        changeDefaultInsight(
          INSIGHTS_IDS.DISTRIBUTION_OF_EMPLOYEES_BY_REVIEW_COUNT
        ),
      isPaginated: true,
      highlight: 'juniors'
    },
    {
      title: 'Employees Who Moved Up',
      icon: <ArrowUpwardOutlinedIcon />,
      nameField: 'fullName',
      performanceList: lastTwoReviewsData.usersThatMovedUp,
      description: 'Some people scored higher in the last review cycle.',
      changeDefaultInsight: () =>
        changeDefaultInsight(INSIGHTS_IDS.TOP_PERFORMERS),
      isPaginated: false
    },
    {
      title: 'Employees Who Moved Down',
      icon: <ArrowDownwardOutlinedIcon />,
      nameField: 'fullName',
      performanceList: lastTwoReviewsData.usersThatMovedDown,
      description: 'Some people scored lower in the last review cycle.',
      changeDefaultInsight: () =>
        changeDefaultInsight(INSIGHTS_IDS.WORST_PERFORMERS),
      isPaginated: false
    }
  ].filter((card) => card.performanceList.length) // Remove empty lists

  return (
    <Stack>
      <Stack
        direction="row"
        rowGap={2}
        sx={{
          flexWrap: { xs: 'wrap', md: 'nowrap' },
          mb: 4
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            order: {
              xs: 10,
              md: -1
            },
            mr: {
              md: 2
            }
          }}
        >
          {dashboardInfoCardData.map((card, index) => (
            <Grid
              key={index}
              item
              xs={6}
              md={dashboardInfoCardData.length >= 5 ? 4 : 6}
            >
              <DashboardInfoCard {...card} />
            </Grid>
          ))}
        </Grid>
        <OverviewGrid
          employees={gridData}
          behaviors={behaviors}
          onClick={handleTabChange}
          sx={{
            width: { xs: '100%', md: 'min-content' }
          }}
        />
      </Stack>
      <Grid container spacing={2}>
        {paginatedDashboardCardData.map((card, index) => (
          <PaginatedDashboardCard key={index} {...card} />
        ))}
      </Grid>
    </Stack>
  )
}

ExecutiveOverview.propTypes = {
  workforceResults: PropTypes.array.isRequired
}
