import { usePathname } from '/src/hooks/use-pathname'
import NotFound from '/src/pages/404'
import { paths } from '/src/constants/paths'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router'
import { useUser } from '/src/hooks/use-user'
import { checkAccessControl } from '/src/hooks/use-access-control'
import { ROLES } from '/functions/shared/constants/roles'
import { ACCESS_CONTROLS } from '../constants/access-control-settings'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const LOGIN = 'login'

export const RoleBasedGuard = ({ children }) => {
  const pathname = usePathname()
  const { user, signingOut } = useUser()
  const [searchParams, setSearchParams] = useSearchParams()

  const userRoles = user?.userRoles || []

  // conditions for each role
  const isAdminIncluded = userRoles.includes(ROLES.ADMIN)
  const isManagerIncluded = userRoles.includes(ROLES.MANAGER)
  const isEmployeeIncluded = userRoles.includes(ROLES.EMPLOYEE)

  // conditions for each path
  const isStartingWithAdmin = pathname.startsWith(paths.dashboard.admin.index)
  const isStartingWithManager = pathname.startsWith(
    paths.dashboard.manager.index
  )

  // conditions for common pages
  const isCommonPage = pathname.includes(LOGIN)

  // conditions for each role and path combination
  const isAdminPathAndUserHasAdminRole =
    isStartingWithAdmin &&
    checkAccessControl({ user, accessControl: ACCESS_CONTROLS.ADMIN_PAGES })
  const isManagerPathAndUserHasManagerRole =
    isStartingWithManager &&
    checkAccessControl({ user, accessControl: ACCESS_CONTROLS.MANAGER_PAGES })
  const isEmployeePathAndUserHasEmployeeRole =
    !isStartingWithAdmin &&
    !isStartingWithManager &&
    checkAccessControl({ user, accessControl: ACCESS_CONTROLS.EMPLOYEE_PAGES })
  // Case when user is not admin but has permissions to view admin insights page
  const isAdminInsightsPathAndUserHasPermissions =
    pathname === paths.dashboard.admin.index &&
    checkAccessControl({
      user,
      accessControl: ACCESS_CONTROLS.ADMIN_OR_SPECIAL_PERMISSION_PAGES
    })

  const canView =
    isAdminInsightsPathAndUserHasPermissions ||
    isAdminPathAndUserHasAdminRole ||
    isManagerPathAndUserHasManagerRole ||
    isEmployeePathAndUserHasEmployeeRole ||
    isCommonPage

  const isHomePageForAdmin =
    pathname === paths.dashboard.index && isAdminIncluded

  const isHomePageForManager =
    pathname === paths.dashboard.index && isManagerIncluded

  // Remove email param from URL if it exists
  // We add email param to the URL when we send the link to the user via email
  // and handle auto sign in on the same page
  // after the user has signed in, remove the email param from the URL
  useEffect(() => {
    if (searchParams.get('email')) {
      setSearchParams({})
    }
  }, [searchParams, setSearchParams])

  if (!canView && !isHomePageForAdmin && !isHomePageForManager) {
    // Do not show anything if user is signing out
    if (signingOut) {
      return null
    }

    return <NotFound />
  } else if (
    pathname === paths.dashboard.index &&
    isAdminIncluded &&
    !isEmployeeIncluded
  ) {
    return <Navigate to={paths.dashboard.admin.index} />
  } else if (
    pathname === paths.dashboard.index &&
    isManagerIncluded &&
    !isEmployeeIncluded
  ) {
    return <Navigate to={paths.dashboard.manager.index} />
  }

  return <>{children}</>
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node.isRequired
}
