import React, { useCallback } from 'react'
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material'

const MultiselectDropdown = (props) => {
  const { defaultValues, label, values, type, setFilters } = props

  const handleSelect = useCallback(
    (event) => {
      const newValues = event.target.value
      setFilters((prev) => ({
        ...prev,
        [type]: {
          ...prev[type],
          values: newValues
        }
      }))
    },
    [type, setFilters]
  )

  return (
    <Grid item xs={4}>
      <FormControl sx={{ m: 1, width: 125 }}>
        <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={values}
          onChange={handleSelect}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => (
            <Typography variant="caption" color="primary.main">
              {selected.join(', ')}
            </Typography>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                width: 'auto'
              }
            }
          }}
        >
          {defaultValues.sort().map((value) => (
            <MenuItem key={value} value={value}>
              <ListItemText primary={value} />
              <Checkbox checked={values.includes(value)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

export default MultiselectDropdown
