// Components
import {
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import PrimaryButton from '/src/components/buttons/primary-button'
import SecondaryButton from '/src/components/buttons/secondary-button'
import { LightTooltip } from '/src/components/light-tooltip'
import Editor from '/src/components/editor'

// Icons
import {
  Save as SaveIcon,
  Cancel as CancelIcon,
  Add as AddIcon,
  Backspace as BackspaceIcon,
  Info as InfoIcon
} from '@mui/icons-material'

// Others
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCallback, useRef } from 'react'
import Proptypes from 'prop-types'

const linkRegexPattern =
  /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ // eslint-disable-line

const validationSchema = Yup.object().shape({
  title: Yup.string().max(100).required('Name is required'),
  description: Yup.string().optional(),
  helperLink: Yup.string()
    .matches(linkRegexPattern, {
      message: 'Link must be a valid URL'
    })
    .optional(),
  questionId: Yup.string().optional(),
  leadershipBehavior: Yup.boolean().required()
})

const BehaviorForm = ({
  index,
  behavior,
  method,
  evaluationStatus,
  toggleEditMode,
  handleAddBehavior,
  handleUpdateBehavior
}) => {
  const editorRef = useRef(null)

  const onSubmit = useCallback(
    async (values, actions) => {
      if (method === 'UPDATE') {
        handleUpdateBehavior(values, index)
        toggleEditMode()
      } else if (method === 'CREATE') {
        await handleAddBehavior(values)
        actions.resetForm()
        // Markdown value needs to be updated
        editorRef.current.setMarkdown('')
      }
    },
    [handleAddBehavior, handleUpdateBehavior, method, toggleEditMode, index]
  )

  const initialValues = {
    title: behavior?.title || '',
    helperLink: behavior?.helperLink || '',
    description: behavior?.description || '',
    questionId: behavior?.questionId || '',
    leadershipBehavior: behavior?.leadershipBehavior || false
  }

  const localFormik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <Grid
      container
      component="form"
      onSubmit={localFormik.handleSubmit}
      spacing={2}
    >
      <Grid item xs={12} sm={3}>
        <Stack spacing={2}>
          <TextField
            label="Name"
            name="title"
            value={localFormik.values.title}
            onChange={localFormik.handleChange}
            error={
              localFormik.touched.title && Boolean(localFormik.errors.title)
            }
            disabled={!!localFormik.values.questionId}
            helperText={localFormik.touched.title && localFormik.errors.title}
            fullWidth
            inputProps={{ 'data-testid': 'title-input' }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextField
          label="Link"
          name="helperLink"
          value={localFormik.values.helperLink}
          onChange={localFormik.handleChange}
          error={
            localFormik.touched.helperLink &&
            Boolean(localFormik.errors.helperLink)
          }
          helperText={
            localFormik.touched.helperLink && localFormik.errors.helperLink
          }
          fullWidth
          inputProps={{ 'data-testid': 'helperLink-input' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Editor
          ref={editorRef}
          placeholder="Description"
          defaultMarkdown={localFormik.values.description}
          onChange={(value) => {
            localFormik.setFieldValue('description', value)
          }}
          wrapperProps={{
            sx: {
              minHeight: 200,
              maxHeight: 400
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <LightTooltip
              title={
                <Typography variant="body2" sx={{ maxWidth: 300 }}>
                  Leadership behaviors are behaviors that apply only to managers
                  in the organization. When a behavior is marked as a leadership
                  behavior, reviewers will be asked to review only managers for
                  that behavior.
                </Typography>
              }
            >
              <IconButton color="primary">
                <SvgIcon>
                  <InfoIcon />
                </SvgIcon>
              </IconButton>
            </LightTooltip>
            <Typography variant="subtitle2">
              Is this a Leadership Behavior?
            </Typography>
          </Stack>
          <FormControlLabel
            control={
              <Switch
                name="leadershipBehavior"
                checked={localFormik.values.leadershipBehavior}
                onChange={localFormik.handleChange}
                disabled={evaluationStatus && evaluationStatus !== 'TEMPLATE'}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack flexDirection="row" justifyContent="end" columnGap={2}>
          {method === 'CREATE' ? (
            <>
              <PrimaryButton
                type="submit"
                startIcon={<AddIcon />}
                disabled={localFormik.isSubmitting}
                name="add"
                data-testid="add-button"
              >
                Add
              </PrimaryButton>
              <SecondaryButton
                onClick={localFormik.handleReset}
                startIcon={<BackspaceIcon />}
                data-testid="clear-button"
              >
                Clear
              </SecondaryButton>
            </>
          ) : null}
          {method === 'UPDATE' ? (
            <>
              <Tooltip title="Save">
                <IconButton
                  disabled={localFormik.isSubmitting}
                  edge="end"
                  type="submit"
                  name="save"
                  data-testid="save-button"
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  edge="end"
                  onClick={toggleEditMode}
                  data-testid="cancel-button"
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : null}
        </Stack>
      </Grid>
    </Grid>
  )
}

BehaviorForm.propTypes = {
  index: Proptypes.number,
  behavior: Proptypes.object,
  method: Proptypes.string.isRequired,
  toggleEditMode: Proptypes.func,
  handleAddBehavior: Proptypes.func.isRequired,
  handleUpdateBehavior: Proptypes.func.isRequired
}

export default BehaviorForm
