import { Container } from '@mui/material'
import { AnimatedLogo } from './animated-logo'
import LoadingOverlay from './loading-overlay'

const DashboardLoading = () => {
  return (
    <Container maxWidth="lg">
      <LoadingOverlay mx={5} my={3} height={50}>
        <AnimatedLogo width={60} />
      </LoadingOverlay>
    </Container>
  )
}

export default DashboardLoading
