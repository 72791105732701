import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Stack,
  SvgIcon
} from '@mui/material'
import { toast } from 'react-toastify'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { capitalizeFirstLetter } from '/src/utils/capitalize-first-letter'
import countWords from '/src/utils/count-words'
import SaveIcon from '@mui/icons-material/Save'
import TextButton from '/src/components/buttons/text-button'
import PrimaryButton from '/src/components/buttons/primary-button'
import Editor from '/src/components/editor'
import CustomCollapse from '/src/components/collapse'
import ReactMarkdown from 'react-markdown'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { useMutation } from 'react-query'
import aiRepo from '/src/data/repos/ai-repo'
import { LightTooltip } from '/src/components/light-tooltip'

const minimumWordCount = 10

const commentModalHelperTexts = [
  'Add positive and negative examples that would help this colleague grow.',
  'Give specific examples of what you have seen evidence of.',
  'Be descriptive, constructive, and solution-oriented.',
  'Focus on behavior and impact, not personality.'
]

export default function CommentModal(props) {
  const {
    disabled,
    handleSave,
    value,
    person,
    currentQuestion,
    ...otherProps
  } = props

  const [open, setOpen] = React.useState(false)
  const [comment, setComment] = React.useState(value)
  const [aiFeedback, setAiFeedback] = React.useState('')

  const isSelfComment = person.uid === 'self'

  const handleOpen = () => setOpen(true)
  const handleClose = (option) => () => {
    const wordCount = countWords(comment)

    // check minimum length of comment before closing, it must be at least 10 characters, otherwise, only close if user click on the Close without saving button
    if (
      option === 'update' &&
      wordCount < minimumWordCount &&
      comment.trim().length > 0
    ) {
      // show a toast message to the user
      toast(
        `Looks like this comment is too short, under ${minimumWordCount} words. ${
          !isSelfComment
            ? 'Your colleague can greatly benefit from constructive feedback.'
            : ''
        }`,
        {
          icon: <span>👋</span>
        }
      )
    } else if (comment.trim().length === 0) {
      // If the comment consists only of spaces and the user clicks on the save button, reset the comment and close the modal.
      setOpen(false)
      setComment('')
      handleSave('')
      setAiFeedback('')
    } else if (option === 'cancel') {
      setOpen(false)
      setComment(value)
      setAiFeedback('')
    } else {
      setOpen(false)
      handleSave(comment)
      setAiFeedback('')
    }
  }

  const { isLoading, mutateAsync: improveFeedback } = useMutation({
    mutationFn: async (data) => {
      const response = await aiRepo.queryGemini(data)
      return response[response.length - 1]?.parts[0]?.text
    },
    onSuccess: (data) => {
      setAiFeedback(data)
    },
    onError: (error) => {
      console.error('Error in generating suggestions: ', error)
    }
  })

  const handleImproveFeedback = async () => {
    await toast.promise(
      improveFeedback({
        systemPrompt: `You are an AI and an expert career coach. You specialize in assisting 
            people on how to give constructive feedback. You have a friendly tone. You are kind, precise,
            constructive, sympathetic, and very succinct. When you make recommendations, they are grounded. 
            You provide examples when appropriate.`,
        contents: [
          {
            role: 'user',
            parts: [
              {
                text: `I typed the following feedback for my colleague in a 360 review, for our company value 
                titled ${currentQuestion.title}, with description ${currentQuestion.description}. 
                I want to improve this feedback. Give me specific suggestions. If the comment is constructive enough, 
                let me know very supportively without further suggestions.
                \n My Feedback: ${comment}`
                // person.previousData?.comment ? `I also provided this
                // feedback to my colleague in the previous 360 reviews: [Old Feedback Start] ${person.previousData.comment} [Old Feedback End]
                // I can't change this old feedback but you can use it for suggestions.` : ''
              }
            ]
          }
        ]
      }),
      {
        error: 'Oops! Something went wrong.'
      }
    )
  }

  React.useEffect(() => {
    setComment(value)
  }, [value])

  const isSameText = comment === value

  return (
    <Box {...otherProps}>
      <TextButton
        data-gaid={
          person.comment && person.comment.length > 0
            ? isSelfComment
              ? 'done'
              : 'feedback-given'
            : isSelfComment
            ? 'add-reflection'
            : 'give-feedback'
        }
        onClick={handleOpen}
        disabled={disabled}
        sx={{
          minWidth: '165px'
        }}
        endIcon={
          person.comment &&
          person.comment.length > 0 && <CheckCircleOutlineIcon />
        }
      >
        {person.comment && person.comment.length > 0
          ? isSelfComment
            ? 'Done'
            : 'Feedback Given'
          : isSelfComment
          ? 'Add Reflection'
          : 'Give Feedback'}
      </TextButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose('cancel')}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              outline: 'none'
            }}
          >
            <Stack
              sx={{
                'py': 4,
                'px': 6,
                'overflowY': 'auto',
                'overflowX': 'hidden',
                'maxHeight': '95vh',
                'width': {
                  xs: '95vw',
                  sm: '95vw',
                  md: '90vw',
                  lg: '90vw'
                },
                'maxWidth': 1500,
                'bgcolor': 'background.paper',
                'borderRadius': 2,
                'position': 'relative',
                // remove on focus outline
                '&:focus': {
                  outline: 'none'
                }
              }}
              justifyContent="space-between"
              spacing={3}
            >
              <Stack alignItems="center" justifyContent="center">
                <Typography id="transition-modal-title" variant="h5">
                  {`How do you see "${capitalizeFirstLetter(
                    currentQuestion.title
                  )}" showing up for ${
                    isSelfComment ? 'yourself' : person.fullName
                  }?`}
                </Typography>
              </Stack>
              <Divider />
              <Stack
                spacing={5}
                direction={{ md: 'column', lg: 'row' }}
                alignItems="center"
              >
                <Stack
                  width={aiFeedback ? { md: '100%', lg: '50%' } : '100%'}
                  spacing={3}
                >
                  <Stack>
                    {!isSelfComment ? (
                      <>
                        <Typography variant="subtitle1" pb={1}>
                          What do they do well? What could they do better?
                          Remember to:
                        </Typography>
                        {commentModalHelperTexts.map((text, index) => (
                          <li key={index}>
                            <Typography variant="subtitle2" component="span">
                              {text}
                            </Typography>
                          </li>
                        ))}
                      </>
                    ) : (
                      <Typography variant="subtitle2">
                        Reflect on your behavior and its impact on your goals.
                      </Typography>
                    )}
                  </Stack>
                  {person.previousData?.comment ? (
                    <Card
                      sx={{
                        borderRadius: 1,
                        padding: 2
                      }}
                      elevation={2}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 'bold', mb: 1 }}
                      >
                        Here is your last feedback to {person.fullName}. Make
                        sure to build on it:
                      </Typography>
                      <CustomCollapse
                        collapsedSize={80}
                        dataGaid="question-description-show-more"
                        expandMoreLabel="Show more"
                        expandLessLabel="Show less"
                        blur
                        sx={{
                          fontStyle: 'italic',
                          fontSize: (theme) => theme.typography.body2.fontSize
                        }}
                      >
                        <ReactMarkdown>
                          {person.previousData.comment}
                        </ReactMarkdown>
                      </CustomCollapse>
                    </Card>
                  ) : null}
                  <Editor
                    id="comment input"
                    placeholder="Type your feedback here..."
                    onChange={setComment}
                    defaultMarkdown={comment}
                    wrapperProps={{
                      sx: {
                        minHeight: '280px',
                        maxHeight: '400px'
                      }
                    }}
                  />
                </Stack>
                {aiFeedback && (
                  <Box
                    sx={{
                      backgroundColor: (theme) =>
                        `${theme.palette.primary.main}10`, // 80 in HEX is 50% in opacity
                      borderRadius: '16px', // Rounded edges
                      p: 3,
                      width: { md: '100%', lg: '50%' },
                      flexGrow: 1,
                      height: '100%',
                      maxHeight: 'xvh',
                      overflowY: 'auto'
                    }}
                  >
                    <ReactMarkdown>{aiFeedback}</ReactMarkdown>
                  </Box>
                )}
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <TextButton
                  onClick={handleClose('cancel')}
                  sx={{
                    minWidth: 'max-content'
                  }}
                  disabled={disabled}
                >
                  Back
                </TextButton>
                <LightTooltip title="Click for suggestions on how to improve this feedback.">
                  <div>
                    <TextButton
                      onClick={handleImproveFeedback}
                      startIcon={
                        isLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <SvgIcon>
                            <AutoAwesomeIcon />
                          </SvgIcon>
                        )
                      }
                      data-gaid="generate-ai-improve-comment"
                    >
                      Improve Feedback
                    </TextButton>
                  </div>
                </LightTooltip>
                <PrimaryButton
                  startIcon={<SaveIcon />}
                  onClick={handleClose('update')}
                  sx={{
                    minWidth: 'max-content',
                    display: isSameText ? 'none' : 'flex'
                  }}
                  disabled={disabled}
                >
                  Save
                </PrimaryButton>
              </Stack>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </Box>
  )
}
