import { Checkbox, Stack } from '@mui/material'
import React, { memo } from 'react'
import { LightTooltip } from '/src/components/light-tooltip'
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion'
import { alpha } from '@mui/system'
import { useTheme } from '@mui/material'

const CheckboxColumn = ({
  handleApplicable,
  feedbacks,
  disableCheckboxes,
  skippablePersonCount
}) => {
  const theme = useTheme()
  return (
    <Stack justifyContent={'space-between'} alignItems="center">
      <LayoutGroup>
        <AnimatePresence>
          {feedbacks.map((person, i) => {
            return (
              <motion.div key={person.uid} layout exit={{ opacity: 0 }}>
                <LightTooltip
                  title={`${
                    !person.isApplicable
                      ? `Click to add ${person.fullName}.`
                      : skippablePersonCount !== 0
                      ? `Click to skip ${person.fullName}.`
                      : ''
                  }  ${
                    skippablePersonCount > 1
                      ? ` You can skip ${skippablePersonCount} more colleagues.`
                      : skippablePersonCount === 1
                      ? ` You can skip ${skippablePersonCount} more colleague.`
                      : "You can't skip more people."
                  }`}
                  key={person.uid}
                  placement="left"
                >
                  <span>
                    <Checkbox
                      data-gaid="skip-user-checkbox"
                      disabled={disableCheckboxes && person.isApplicable}
                      checked={person.isApplicable}
                      onChange={handleApplicable(person)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        'transition': 'all 0.3s ease-in-out',
                        '&.Mui-disabled': {
                          color: alpha(theme.palette.primary.main, 0.5) // Lighten the primary color
                        }
                      }}
                      data-testid={`checkbox-${i}`}
                    />
                  </span>
                </LightTooltip>
              </motion.div>
            )
          })}
        </AnimatePresence>
      </LayoutGroup>
    </Stack>
  )
}

export default memo(CheckboxColumn)
