import PropTypes from 'prop-types'
import { Box, ThemeProvider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSettings } from '/src/hooks/use-settings'
import consoleLog from '/src/utils/console-log'
import { defaultLightColors } from '/src/theme/colors'
import { createTheme } from '/src/theme'

const LayoutRoot = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  minHeight: '100vh'
}))

const backgrounds = {
  lightImage:
    // 'radial-gradient(100% 100% at 100% 100%, rgb(255, 156, 156) 0%, rgb(255, 255, 255) 100%)'
    `radial-gradient(at 0% 90%, ${defaultLightColors.lightest} 0, ${defaultLightColors.main} 100%)`
}

export const Layout = (props) => {
  const { children } = props
  const settings = useSettings()

  const theme = createTheme({
    direction: 'ltr',
    paletteMode: 'light',
    responsiveFontSizes: true
  })

  consoleLog('settings', settings)

  return (
    <ThemeProvider theme={theme}>
      <LayoutRoot
        sx={{
          backgroundImage: backgrounds.lightImage
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flex: '1 1 auto'
          }}
        >
          {children}
        </Box>
      </LayoutRoot>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}
