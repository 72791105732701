import { getMedian } from '/src/utils/get-median'
import { USER_KEYS } from '/src/constants/user'
import { createObjectFromKeys } from '/src/utils/create-object-from-keys'
import { SCORES } from '/src/constants/evaluation-config'
import { RISKY_WORDS, STOP_WORDS } from '/src/constants/nlp'

// Destructuring the keys of the user object to be used in the code
const {
  JOB_LEVEL,
  SENIORITY,
  JOB_TITLE,
  GENDER,
  RACE,
  TENURE,
  TEAM,
  DIVISION,
  MANAGER
} = USER_KEYS

const {
  PERCENTILE,
  PEERFUL_SCORE,
  MANAGER_SCORE,
  SELF_SCORE,
  REVIEWS_RECEIVED,
  STANDARD_DEVIATION,
  RAW_AVERAGE_SCORE
} = SCORES

/* COMMON FUNCTIONS THAT CAN BE USED IN MODALS */

export const isValidScore = (value) => {
  return typeof value === 'number' && value >= 0
}

const validatedScore = (value) => {
  return isValidScore(value) ? value : undefined
}

const round = (score, decimals = 0) => {
  return Math.round(score * 10 ** decimals) / 10 ** decimals
}

const mean = ({ values, decimals = 1 }) => {
  return round(
    values.reduce((acc, curr) => acc + curr, 0) / values.length,
    decimals
  )
}

// Creates min, max and mean values given an array of values
export const getMinMaxMean = ({
  values,
  decimals = 1,
  defaultValue = undefined
}) => {
  // If the array is empty, return 0 for all
  if (values.length === 0) {
    return {
      min: defaultValue,
      max: defaultValue,
      mean: defaultValue
    }
  }

  // Calculating the min, max and mean values
  return {
    min: round(Math.min(...values), decimals),
    max: round(Math.max(...values), decimals),
    mean: mean({ values, decimals })
  }
}

// Gets the desired scores as an array from the result
const getValidScoresFromResult = ({ result, scoreField }) => {
  // Getting the scores
  const userScores = result.resultsByQuestion
    .map((result) => result.scores.find((s) => s.name === scoreField)?.value)
    .filter((score) => isValidScore(score))
  return userScores
}

export const getAverageOfScores = (scores) => {
  const averageOfScores = {}

  // Iterate over each field in the input object
  Object.entries(scores).forEach(([scoreName, scoreValues]) => {
    const calculatedValue = round(mean({ values: scoreValues }))
    // Store the calculated value in the target object
    averageOfScores[scoreName] = calculatedValue
  })

  return averageOfScores
}

// This function filters the last generated and newly calculated user properties
const filterByUserProperties = ({ workforceResults, filters }) => {
  // This function filters the last generated and newly calculated user properties
  const filteringFunction = (user) => {
    // Check if the user is passing all the filters
    return Object.entries(filters).every(([key, value]) => {
      // Pass the evaluations and behaviors because they are not in the user object and filtered before
      if (key === 'evaluations') return true
      else if (key === 'behaviors') return true
      // Pass if there is no filters selected
      else if (value.values.length === 0) return true

      // Check if values is containing number if it does this means that the filter is for a range
      if (typeof value.values[0] === 'number') {
        // Check if the user[key] is present in the values (jobLevel is an exception)
        if (key === JOB_LEVEL) {
          return value.values.includes(user[key])
        }

        // Check if the user[key] is between the values
        return value.values[0] <= user[key] && user[key] <= value.values[1]
      }

      // Exceptional case for manager if the manager is an object and it is not null
      if (key === MANAGER && typeof user[key] === 'object' && user[key]) {
        return value.values.includes(user[key].fullName)
      }

      return value.values.includes(user[key])
    })
  }

  const filteredData = []

  workforceResults.forEach((workforceResultForUser) => {
    const { userResults } = workforceResultForUser

    // If the user has no results, return
    if (!userResults.length || !userResults) return

    // Get users unconditionally when there is no filter
    if (!filters) {
      filteredData.push(workforceResultForUser)
      return
    }

    // CONDITION 1: If the user did not select any evaluation
    if (!filters.evaluations.values.length) {
      // Check if the user is passing all the filters
      if (filteringFunction(workforceResultForUser))
        filteredData.push(workforceResultForUser)
    }

    // CONDITION 2: If the user selected one evaluation
    else if (filters.evaluations.values.length === 1) {
      // Get the user properties from the selected evaluation (evaluations[0])
      const savedProperties = userResults[0]?.user

      const workforceResultForUserWithEvalProps = {
        ...workforceResultForUser,
        team: savedProperties?.team,
        division: savedProperties?.division,
        jobLevel: savedProperties?.jobLevel,
        seniority: savedProperties?.seniority,
        jobTitle: savedProperties?.jobTitle,
        manager: savedProperties?.manager
      }

      // Check if the user is passing all the filters
      if (filteringFunction(workforceResultForUserWithEvalProps)) {
        filteredData.push(workforceResultForUserWithEvalProps)
      }
    }

    // CONDITION 3: If the user selected multiple evaluations
    // Filter results and get the user properties from the selected evaluation
    else {
      const filteredUserResults = userResults.filter((userResult) => {
        const workforceResultForUserWithEvalProps = {
          ...workforceResultForUser,
          ...userResult.user
        }
        // Check if the user is passing all the filters
        return filteringFunction(workforceResultForUserWithEvalProps)
      })

      if (filteredUserResults.length) {
        const savedProperties =
          filteredUserResults[filteredUserResults.length - 1]?.user

        const workforceResultForUserWithEvalProps = {
          ...workforceResultForUser,
          team: savedProperties?.team,
          division: savedProperties?.division,
          jobLevel: savedProperties?.jobLevel,
          seniority: savedProperties?.seniority,
          jobTitle: savedProperties?.jobTitle,
          manager: savedProperties?.manager
        }

        filteredData.push(workforceResultForUserWithEvalProps)
      }
    }
  })

  return filteredData
}

// This function filters out the users based on selected evaluation and behaviors (used in the analysis & reward tab)
const filterWorkforceResultsByBehaviorAndEvaluation = ({
  workforceResults,
  filters
}) => {
  // Filter by behaviors and evaluations
  const filteredData = workforceResults.map((user) => {
    const filteredByEvaluation = user.userResults.filter(
      (result) =>
        !filters ||
        !filters.evaluations.values.length ||
        filters.evaluations.values.includes(result.evaluationId)
    )
    const filterByEvaluationAndBehavior = filteredByEvaluation
      .map(
        (result) => {
          return {
            ...result,
            resultsByQuestion: result.resultsByQuestion.filter(
              (r) =>
                !filters ||
                !filters.behaviors.values.length ||
                filters.behaviors.values.includes(r.behavior)
            )
          }
        }
        // Filter out the user results that have no resultsByQuestion
      )
      .filter((result) => result.resultsByQuestion.length)

    return {
      ...user,
      userResults: filterByEvaluationAndBehavior
    }
  })

  return filteredData
}

/* MODAL CLASSES FOR ADMIN INDEX PAGE TABS */

// This class includes all the functions that are used to generate insights
class InsightsModal {
  constructor() {
    this.FLIGHT_RISK_THRESHOLD = 70
    this.LOW_PERFORMERS_THRESHOLD = 30
    this.TOP_PERFORMERS_THRESHOLD = 80
  }

  // - COMMON FUNCTIONS -

  // - REFORMATTING WORKFORCE RESULTS TO EVALUATION BASED RESULTS -

  // Reformats the user-based results to evaluation-based results (workforceResult => workforceResultsByEvaluation)
  getWorkforceResultsByEvaluation = ({ workforceResults }) => {
    // Getting all unique evaluationIds from the workforceResults
    const evaluationIds = [
      ...new Set(
        workforceResults
          .map((workforceResult) =>
            workforceResult.userResults.map(
              (userResult) => userResult.evaluationId
            )
          )
          .flat()
      )
    ]

    const workforceResultsByEvaluation = []

    // Iterating over uniqueEvaluationIds
    for (const evaluationId of evaluationIds) {
      // Finding one workforceResult that has the evaluationId
      const workforceResult = workforceResults.find((workforceResult) =>
        workforceResult.userResults.find(
          (userResult) => userResult.evaluationId === evaluationId
        )
      )

      // Getting behaviors for the evaluation
      const behaviorIdToBehaviors = {}
      workforceResult.userResults.forEach((userResult) => {
        userResult.resultsByQuestion.map(
          (resultByQuestion) =>
            (behaviorIdToBehaviors[resultByQuestion.questionId] = {
              behavior: resultByQuestion.behavior,
              questionDescription: resultByQuestion.questionDescription,
              questionId: resultByQuestion.questionId
            })
        )
      })
      const behaviors = Object.values(behaviorIdToBehaviors)

      const evaluationResults = []
      workforceResults.forEach((workforceResult) => {
        const userResult = workforceResult.userResults.find(
          (userResult) => userResult.evaluationId === evaluationId
        )

        // Check if the user has results for this evaluation or the evaluation is not filtered out
        if (userResult) {
          const finalUserResult = {
            // Adding all the user related fields ( - including "userResults" - )
            ...workforceResult,
            notes: userResult.notes,
            resultsByQuestion: userResult.resultsByQuestion
          }

          // Deleting the unnecessary "userResults"
          delete finalUserResult.userResults

          evaluationResults.push(finalUserResult)
        }
      })

      // Getting sample evaluation result from a random user
      const evaluation = workforceResult.userResults.find(
        (userResult) => userResult.evaluationId === evaluationId
      )

      const evalResult = {
        evaluationId: evaluation.evaluationId,
        evaluationName: evaluation.evaluationName,
        evaluationConfig: evaluation.evaluationConfig,
        behaviors: behaviors,
        endDate: new Date(
          evaluation.endDate._seconds * 1e3 +
            evaluation.endDate._nanoseconds / 1e6
        ),
        evaluationResults: evaluationResults
      }

      workforceResultsByEvaluation.push(evalResult)
    }

    // Sorting the workforceResultsByEvaluation from recent to oldest
    workforceResultsByEvaluation.sort((a, b) => b.endDate - a.endDate)

    return workforceResultsByEvaluation
  }

  // - GENERATING THE MACRO DATA -

  // Calculating the insights for macroData
  #getMacroData = ({ workforceResults, workforceResultsByEvaluation }) => {
    // Getting distribution of employees by review count and scores
    // final data needs to be in the form of { reviewsCount/score: employeesCount }
    const distributionOfEmployeesByReviewCount = {}
    const distributionOfScores = {}

    // Calculating the insights for each user
    workforceResults.forEach((workforceResult) => {
      const reviewsReceivedList = []
      const scoreList = []
      workforceResult.userResults.forEach((result) => {
        // Get the reviews received by the user in the review cycle
        const reviewsReceivedInReview = round(
          mean({
            values: result.resultsByQuestion.map((r) => r.reviewsReceived)
          })
        )
        reviewsReceivedList.push(reviewsReceivedInReview)

        // Getting the average peerful of the user
        const averagePeerfulScoreInReview = mean({
          values: getValidScoresFromResult({
            result,
            scoreField: PEERFUL_SCORE
          }),
          decimals: 0
        })
        scoreList.push(averagePeerfulScoreInReview)
      })

      const metrics = [
        [reviewsReceivedList, distributionOfEmployeesByReviewCount],
        [scoreList, distributionOfScores]
      ]

      metrics.forEach(([list, distribution]) => {
        const average = round(mean({ values: list }))
        if (isValidScore(average)) {
          distribution[average] = (distribution[average] || 0) + 1
        }
      })
    })

    // {reviewStats: { submitted: X, totalUser: Y, peopleReceivedReviews: Z,
    // reviewReceived:{min: A, max: B, mean: C}, reviewGiven:{min: D, max: E, mean: F},
    // commentStats: {min: G, max: H, mean: I}
    //  }
    const { reviewStats, reviewsReceived, reviewsGiven, reviewers } =
      this.#formatReviewStats({
        reviewStats: this.getReviewStats({ workforceResultsByEvaluation })
      })
    const commentStats = this.#formatCommentStats({
      commentStats: this.#getCommentStats({ workforceResultsByEvaluation })
    })

    // Skip 0 in review distribution chart - it breaks leadership only behaviors/evaluations
    delete distributionOfEmployeesByReviewCount['0']

    return {
      distributionOfEmployeesByReviewCountSeries:
        this.#formatDistributionSeries({
          data: distributionOfEmployeesByReviewCount,
          name: 'Number of Employees'
        }),
      distributionOfScoresSeries: this.#formatDistributionSeries({
        data: distributionOfScores,
        name: 'Number of Employees'
      }),
      commentStats,
      reviewStats,
      reviewsReceived,
      reviewsGiven,
      reviewers
    }
  }

  getReviewStats = ({ workforceResultsByEvaluation }) => {
    const statsForCycles = {
      minGiven: [],
      maxGiven: [],
      meanGiven: [],
      maxReceived: [],
      minReceived: [],
      meanReceived: [],
      maxReviewers: [],
      minReviewers: [],
      meanReviewers: [],
      numberOfPeopleWhoReceivedReviews: [],
      numberOfPeopleWhoGaveReviews: [],
      total: []
    }

    //Calculating for all the evaluations
    for (const workforceResultByEvaluation of workforceResultsByEvaluation) {
      // Getting min, max, mean of reviews received and given for each evaluation
      const reviewsReceivedAndGivenMinMaxMeanTotals =
        this.#calculateReviewsReceivedAndGivenMinMaxMeanTotal({
          evaluationResults: workforceResultByEvaluation.evaluationResults
        })

      // Pushing the values to calculate the average
      for (const key in reviewsReceivedAndGivenMinMaxMeanTotals) {
        statsForCycles[key].push(reviewsReceivedAndGivenMinMaxMeanTotals[key])
      }
    }

    // Simplify sum calculations with a generic reducer function
    const sum = (acc, curr) => acc + curr
    const totalPeopleWhoReceivedReviews =
      statsForCycles.numberOfPeopleWhoReceivedReviews.reduce(sum, 0)
    const totalPeopleWhoGaveReviews =
      statsForCycles.numberOfPeopleWhoGaveReviews.reduce(sum, 0)
    const totalSum = statsForCycles.total.reduce(sum, 0)

    // Adding review stats for all the evaluations
    return {
      notSubmitted: totalSum - totalPeopleWhoGaveReviews,
      submitted: totalPeopleWhoGaveReviews,
      totalUser: totalSum,
      peopleReceivedReviews: totalPeopleWhoReceivedReviews,
      reviewsGiven: {
        min: round(mean({ values: statsForCycles.minGiven })),
        max: round(mean({ values: statsForCycles.maxGiven })),
        mean: mean({ values: statsForCycles.meanGiven })
      },
      reviewsReceived: {
        min: round(mean({ values: statsForCycles.minReceived })),
        max: round(mean({ values: statsForCycles.maxReceived })),
        mean: mean({ values: statsForCycles.meanReceived })
      },
      reviewers: {
        min: round(mean({ values: statsForCycles.minReviewers })),
        max: round(mean({ values: statsForCycles.maxReviewers })),
        mean: mean({ values: statsForCycles.meanReviewers })
      }
    }
  }

  // Calculates the min, max and mean of reviews received and given per evaluation
  #calculateReviewsReceivedAndGivenMinMaxMeanTotal = ({
    evaluationResults
  }) => {
    const reviewsReceivedList = []
    const reviewsGivenList = []
    const reviewersList = []

    evaluationResults.forEach((result) => {
      // Getting the reviews received and given by the user, mean over all reviews
      const reviewsReceivedByUser = result.resultsByQuestion.map(
        (resultByQuestion) => resultByQuestion.reviewsReceived
      )
      const reviewsGivenByUser = result.resultsByQuestion.map(
        (resultByQuestion) => resultByQuestion.reviewsGiven
      )

      // Calculating the average reviews received and given by the user.
      reviewsReceivedList.push(
        getMinMaxMean({
          values: reviewsReceivedByUser
        }).mean
      )
      reviewsGivenList.push(
        getMinMaxMean({
          values: reviewsGivenByUser
        }).mean
      )
      // We use max to get number of reviewers for review cycle
      reviewersList.push(
        getMinMaxMean({
          values: reviewsReceivedByUser
          // Max instead of mean:
        }).max
      )
    })

    // Getting min, max, mean reviews received
    const receivedMinMaxMean = getMinMaxMean({
      values: reviewsReceivedList.filter((n) => n)
    })

    // Getting min, max, mean of reviews given
    const givenMinMaxMean = getMinMaxMean({
      values: reviewsGivenList.filter((n) => n)
    })

    // Getting min, max, mean of reviewers
    const reviewersMinMaxMean = getMinMaxMean({
      values: reviewersList.filter((n) => n)
    })

    return {
      minGiven: givenMinMaxMean.min,
      maxGiven: givenMinMaxMean.max,
      meanGiven: givenMinMaxMean.mean,
      minReceived: receivedMinMaxMean.min,
      maxReceived: receivedMinMaxMean.max,
      meanReceived: receivedMinMaxMean.mean,
      minReviewers: reviewersMinMaxMean.min,
      maxReviewers: reviewersMinMaxMean.max,
      meanReviewers: reviewersMinMaxMean.mean,
      numberOfPeopleWhoReceivedReviews: reviewsReceivedList.filter((n) => n)
        .length,
      numberOfPeopleWhoGaveReviews: reviewsGivenList.filter((n) => n).length,
      total: reviewsReceivedList.length
    }
  }

  // Calculates the min, max and mean of comments across all evaluations
  #getCommentStats = ({ workforceResultsByEvaluation }) => {
    const commentStatsForEvaluations = {
      min: [],
      max: [],
      mean: []
    }

    //Calculating for all the evaluations
    for (const workforceResultByEvaluation of workforceResultsByEvaluation) {
      // Calculates the min, max and mean of comments per evaluation
      const commentsCount = {}
      workforceResultByEvaluation.evaluationResults.forEach((result) => {
        // Getting the comments count for each user who received reviews
        if (result.resultsByQuestion.some((r) => r.reviewsReceived > 0)) {
          commentsCount[result.userId] = result.resultsByQuestion.reduce(
            (acc, curr) => acc + curr.comments.length,
            0
          )
        }
      })

      // Getting min, max, mean of the comments
      const { min, max, mean } = getMinMaxMean({
        values: Object.values(commentsCount)
      })
      commentStatsForEvaluations.min.push(min)
      commentStatsForEvaluations.max.push(max)
      commentStatsForEvaluations.mean.push(mean)
    }

    // Calculating the average for all the evaluations
    return {
      min: round(mean({ values: commentStatsForEvaluations.min })),
      max: round(mean({ values: commentStatsForEvaluations.max })),
      mean: mean({ values: commentStatsForEvaluations.mean })
    }
  }

  // Rounds scores or # of reviews received and sums them up
  #formatDistributionSeries = ({ data, name }) => {
    // if data is empty return null
    if (!data) return null

    const { average, median } = this.#calculateMedianAndWeightedAverage({
      data: data
    })

    // Create rounded key-sums over the data points
    let roundedKeysToSums = {}
    for (const [key, value] of Object.entries(data)) {
      // Rounding the key to an integer
      const roundedIntegerKey = Math.round(Number(key))

      // Adding the value to the roundedKeysToSums
      if (roundedIntegerKey in roundedKeysToSums) {
        roundedKeysToSums[roundedIntegerKey] += value
      } else {
        roundedKeysToSums[roundedIntegerKey] = value
      }
    }

    // Get min and max integer keys from roundedKeysToSums
    const { min, max } = getMinMaxMean({
      values: Object.keys(roundedKeysToSums),
      defaultValue: 0
    })

    // Create categories from keys and series from values
    const seriesCategories = [...Array(max - min + 1).keys()].map(
      (i) => i + min
    )

    // Iterate over series categories to create series values when data is available in sums
    // If exists, use the value from roundedKeysToSums, otherwise use null
    let seriesValues = seriesCategories.map((category) => ({
      x: category,
      y: category in roundedKeysToSums ? roundedKeysToSums[category] : null
    }))
    // Returning with categories and series to be used in the charts
    return {
      series: [
        {
          name: name,
          data: seriesValues
        }
      ],
      calculations: { average, median }
    }
  }

  // Calculates the median and weighted average of the scores
  #calculateMedianAndWeightedAverage = ({ data }) => {
    // Getting the average and median of the data
    const keys = Object.keys(data).map((key) => Number(key))
    const sortedKeys = keys.sort((a, b) => a - b)
    // Getting the values of data in the sorted order
    const values = sortedKeys.map((key) => data[key])

    // Getting the weighted average
    const weightedAverage = round(
      sortedKeys.reduce((acc, curr, i) => acc + curr * values[i], 0) /
        values.reduce((acc, curr) => acc + curr, 0),
      1
    )

    // Getting the median
    const median = getMedian(sortedKeys)

    return { average: weightedAverage, median: median }
  }

  // Parses the review stats
  #formatReviewStats = ({ reviewStats }) => {
    const {
      notSubmitted,
      submitted,
      totalUser,
      peopleReceivedReviews,
      reviewsReceived,
      reviewsGiven,
      reviewers
    } = reviewStats

    return {
      reviewStats: {
        description: 'Overall completion and participation statistics.',
        title: 'Completion',
        values: [
          {
            label: 'Submitted',
            value: `${submitted}/${totalUser}`
          },
          {
            label: 'Not Submitted',
            value: `${notSubmitted}/${totalUser}`
          },
          {
            label: 'People Received Reviews',
            value: `${peopleReceivedReviews}/${totalUser}`
          }
        ]
      },
      reviewsReceived: {
        description:
          'Min, max, and average number of reviews received by each reviewed employee, across behaviors.',
        title: 'Reviews Received',
        values: [
          {
            label: 'Min',
            value: reviewsReceived.min
          },
          {
            label: 'Max',
            value: reviewsReceived.max
          },
          {
            label: 'Mean',
            value: reviewsReceived.mean
          }
        ]
      },
      reviewsGiven: {
        description:
          'Min, max, and average number of reviews given by each participant who have submitted a review, across behaviors.',
        title: 'Reviews Given',
        values: [
          {
            label: 'Min',
            value: reviewsGiven.min
          },
          {
            label: 'Max',
            value: reviewsGiven.max
          },
          {
            label: 'Mean',
            value: reviewsGiven.mean
          }
        ]
      },
      // We aren't showing this in the chart - but it's used in Macro Data
      reviewers: {
        description:
          'Min, max, and average number of comments received by each reviewed employee, across behaviors.',
        title: 'Number of Reviewers',
        values: [
          {
            label: 'Min',
            value: reviewers.min
          },
          {
            label: 'Max',
            value: reviewers.max
          },
          {
            label: 'Mean',
            value: reviewers.mean
          }
        ]
      }
    }
  }

  // Parses the comment stats
  #formatCommentStats = ({ commentStats }) => {
    const { min, max, mean } = commentStats

    return {
      description: 'Here you can see the statistics of comments.',
      title: 'Number of Comments',
      values: [
        {
          label: 'Min',
          value: min
        },
        {
          label: 'Max',
          value: max
        },
        {
          label: 'Mean',
          value: mean
        }
      ]
    }
  }

  // - GENERATING THE EVALUATION BASED INSIGHTS -

  // Generates the evaluation based insights
  #getEvaluationBasedInsights = ({ workforceResultsByEvaluation }) => {
    const insights = {}

    // The list of insights of each evaluation (to be used to calculate the average)
    const scoresByDivisionAndBehaviorAcrossCycles = []
    const scoresBySeniorityAndBehaviorAcrossCycles = []
    const scoresByJobLevelAndBehaviorAcrossCycles = []

    // Calculating the insights for each evaluation
    workforceResultsByEvaluation.forEach((workforceResultByEvaluation) => {
      // Filter results out results that are greater than 0 for reviewReceived
      const resultsList = workforceResultByEvaluation.evaluationResults
        .map((result) => {
          return {
            ...result,
            resultsByQuestion: result.resultsByQuestion.filter(
              (resultByQuestion) => resultByQuestion.reviewsReceived
            )
          }
        })
        .filter((result) => result.resultsByQuestion.length)

      // Getting all the results formatted with the necessary fields
      const results = resultsList
        .map((result) => {
          return result.resultsByQuestion.map((resultByQuestion) => ({
            behavior: resultByQuestion.behavior,
            manager: result.manager,
            fullName: result.fullName,
            scores: resultByQuestion.scores,
            // Optional fields
            ...createObjectFromKeys({
              object: result,
              keys: [
                TEAM,
                DIVISION,
                GENDER,
                TENURE,
                SENIORITY,
                JOB_LEVEL,
                RACE,
                JOB_TITLE
              ]
            })
          }))
        })
        .flat()

      const { behaviors, divisions, seniorities, jobLevels } =
        this.getUniqueCategories({ resultsList })

      const categoriesInCycle = [
        [divisions, DIVISION, scoresByDivisionAndBehaviorAcrossCycles],
        [seniorities, SENIORITY, scoresBySeniorityAndBehaviorAcrossCycles],
        [jobLevels, JOB_LEVEL, scoresByJobLevelAndBehaviorAcrossCycles]
      ]

      categoriesInCycle.forEach(([categoryList, categoryName, list]) => {
        // Check if we have category data for org
        if (categoryList.length) {
          // Calculating the scores by division and behavior
          const scoresByCategoryAndBehavior =
            this.#calculateCategoryAverageScore({
              results,
              behaviors,
              categoryList,
              categoryName,
              scoreField: PEERFUL_SCORE
            })
          list.push(scoresByCategoryAndBehavior)
        }
      })
    })

    // - GETTING THE AVERAGE OF THE INSIGHTS ACROSS ALL EVALUATIONS -
    // - AND CREATING THE SERIES -

    // Getting the unique categories across all evaluations
    const { divisions, seniorities, jobLevels, behaviors } =
      this.getUniqueCategories({
        resultsList: workforceResultsByEvaluation
          .map((e) => e.evaluationResults)
          .flat()
      })

    const categoriesAcrossCycle = [
      [divisions, DIVISION, scoresByDivisionAndBehaviorAcrossCycles],
      [seniorities, SENIORITY, scoresBySeniorityAndBehaviorAcrossCycles],
      [jobLevels, JOB_LEVEL, scoresByJobLevelAndBehaviorAcrossCycles]
    ]
    categoriesAcrossCycle.forEach(
      ([categoryList, categoryField, scoresList]) => {
        if (scoresList.length) {
          const data = this.#calculateTheAverageCategoriesWithBehaviors({
            categoryList,
            behaviors,
            peerfulScoresByCategoryAndBehaviorList: scoresList,
            categoryField
          })
          insights[categoryField] = this.#floatScoresAndCreateSeries({ data })
        }
      }
    )

    return {
      peerfulScoresByDivisionAndBehaviorSeries: insights[DIVISION],
      peerfulScoresBySeniorityAndBehaviorSeries: insights[SENIORITY],
      peerfulScoresByJobLevelAndBehaviorSeries: insights[JOB_LEVEL]
    }
  }

  // Gets the unique categories (divisions, seniorities, jobLevels) per evaluation
  getUniqueCategories = ({ resultsList }) => {
    const behaviors = new Set()
    const divisions = new Set()
    const seniorities = new Set()
    const jobLevels = new Set()
    const teams = new Set()

    resultsList.forEach((result) => {
      result.resultsByQuestion.forEach(({ behavior }) =>
        behaviors.add(behavior)
      )
      if (result.division) divisions.add(result.division)
      if (result.seniority) seniorities.add(result.seniority)
      if (result.jobLevel) jobLevels.add(result.jobLevel)
      if (result.team) teams.add(result.team)
    })

    return {
      behaviors: [...behaviors],
      divisions: [...divisions],
      seniorities: [...seniorities],
      jobLevels: [...jobLevels],
      teams: [...teams]
    }
  }

  // Calculating the insights for division, seniority and job level by behavior
  // [{division: X, behavior:Y (5 + average), score: Z}, {...}, {...}]
  #calculateCategoryAverageScore = ({
    results,
    behaviors,
    categoryList,
    categoryName,
    scoreField
  }) => {
    // Ex: results = allResults, behaviors = behaviors
    // categoryList = divisions, categoryName = division, scoreField = peerfulScore
    const averageResults = []

    categoryList.forEach((category) => {
      // Filtering the division results
      const categoryResults = results.filter(
        (result) => result[categoryName] === category
      )

      let categoryTotal = 0
      // Iterating through the behaviors
      behaviors.forEach((behavior) => {
        // Getting the behavior results
        const behaviorResults = categoryResults.filter(
          (result) => result.behavior === behavior
        )

        // Calculating the behavior average
        const behaviorTotal = behaviorResults.reduce(
          (acc, curr) =>
            acc + curr.scores.find((s) => s.name === scoreField).value,
          0
        )
        categoryTotal += behaviorTotal

        // Creating the behavior average result
        const behaviorAverageResult = {
          behavior: behavior,
          score: round(behaviorTotal / behaviorResults.length, 1)
        }
        behaviorAverageResult[categoryName] = category

        averageResults.push(behaviorAverageResult)
      })

      // Creating the category average result
      const categoryAverageResult = {
        behavior: 'Average',
        score: round(categoryTotal / categoryResults.length, 1)
      }
      categoryAverageResult[categoryName] = category

      averageResults.push(categoryAverageResult)
    })

    return averageResults
  }

  // Calculates the average of the insights for each category (division, seniority, jobLevel)
  #calculateTheAverageCategoriesWithBehaviors = ({
    categoryList,
    behaviors,
    peerfulScoresByCategoryAndBehaviorList,
    categoryField
  }) => {
    // Ex: categoryList = divisions (unique divisions across all evaluations)
    // behaviors = behaviors (unique behaviors across all evaluations)
    // peerfulScoresByCategoryAndBehaviorList = peerfulScoresByDivisionAndBehaviorList (peerfulScoresByDivisionAndBehaviorList across all evaluations)
    // categoryField = division (division or seniority or jobLevel)
    const categoryInsights = []

    // Getting the average
    categoryList.forEach((category) => {
      // Getting the average of the behavior-category insights (adding summary)
      ;[...behaviors, 'Average'].forEach((behavior) => {
        const insightsOfCategory = []
        peerfulScoresByCategoryAndBehaviorList.forEach(
          (categoryBehaviorInsight) => {
            const insight = categoryBehaviorInsight.find(
              (insight) =>
                insight.behavior === behavior &&
                insight[categoryField] === category
            )
            if (insight) {
              insightsOfCategory.push(insight)
            }
          }
        )

        // Calculating the average of the behavior-category insights
        const averageOfCategory = round(
          insightsOfCategory.reduce((acc, curr) => acc + curr.score, 0) /
            insightsOfCategory.length,
          1
        )

        // Getting the behavior-category insight
        categoryInsights.push({
          behavior,
          [categoryField]: category,
          score: averageOfCategory
        })
      })
    })

    return categoryInsights
  }

  // Floats scores in data and creates series for each insight
  #floatScoresAndCreateSeries = ({ data }) => {
    // if data is empty return null
    if (!data) return null

    // float scores in data that has structure like this: { division: "X", behavior: "Y" score: 58.34 }
    data.forEach((insight) => {
      insight.score = round(insight.score, 2)
    })

    // create series for each insight
    return this.#createSeries({ data })
  }

  // Creates the series for the insights
  #createSeries = ({ data }) => {
    // if data is empty return null
    if (!data || data.length < 2) return null

    // get fields without using an explicit name except score
    // an example datum: { division: "Sales", behavior: "Teamwork", score: 58.34 }
    // an example comparisonFields: ["division", "behavior"]
    const comparisonFields = data.reduce((acc, datum) => {
      const fields = Object.keys(datum).filter((field) => field !== 'score')
      fields.forEach((field) => {
        if (!acc.includes(field)) {
          acc.push(field)
        }
      })
      return acc
    }, [])

    // get all values for each comparison field in data array
    // an example comparisonValues: { division: ["Sales", "Marketing"], behavior: ["Teamwork", "Leadership"] }
    const comparisonValues = comparisonFields.reduce((acc, field) => {
      const values = data.reduce((acc, datum) => {
        if (!acc.includes(datum[field]) && datum[field]) {
          if (acc.length === 1 && datum.behavior === 'Average') {
            return acc
          }
          acc.push(datum[field])
        }
        return acc
      }, [])
      acc[field] = values
      return acc
    }, {})

    // get all keys from comparisonValues
    const comparisonValuesKeys = Object.keys(comparisonValues)
    // sort ascending comparisonValuesKeys
    const firstComparisonValues =
      comparisonValues[comparisonValuesKeys[0]].sort()
    const secondComparisonValues =
      comparisonValues[comparisonValuesKeys[1]].sort()

    // the key comparisonValuesKeys[0] will have comparisonValuesKeys[1] values as series and vice versa
    const comparisonValuesScoresSeries1 = firstComparisonValues.map((value) => {
      return {
        name: value,
        data: secondComparisonValues.map((secondValue) => {
          const datum = data.find(
            (datum) =>
              datum[comparisonValuesKeys[0]] === value &&
              datum[comparisonValuesKeys[1]] === secondValue
          )
          return isNaN(datum.score) ? null : datum.score
        })
      }
    })
    const comparisonValuesScoresSeries2 = secondComparisonValues.map(
      (value) => {
        return {
          name: value,
          data: firstComparisonValues.map((firstValue) => {
            const datum = data.find(
              (datum) =>
                datum[comparisonValuesKeys[0]] === firstValue &&
                datum[comparisonValuesKeys[1]] === value
            )
            return isNaN(datum.score) ? null : datum.score
          })
        }
      }
    )

    // the key comparisonValuesKeys[0] will have comparisonValuesKeys[1] values as series and vice versa
    const comparisonValuesScoresSeries = {
      [comparisonValuesKeys[1]]: comparisonValuesScoresSeries1,
      [comparisonValuesKeys[0]]: comparisonValuesScoresSeries2
    }

    // options are the keys of object in data array
    const options = Object.keys(comparisonValues)

    return {
      options,
      comparisonValuesScoresSeries,
      comparisonValues
    }
  }

  // - GENERATING EMPLOYEE INSIGHTS -

  // Generates the user based insights
  #getEmployeeInsights = ({ workforceResults }) => {
    const insights = {
      performers: [],
      flightRisks: [],
      highDeviations: [],
      highDigressions: []
    }
    const scoreFields = [
      PERCENTILE,
      REVIEWS_RECEIVED,
      RAW_AVERAGE_SCORE,
      MANAGER_SCORE,
      STANDARD_DEVIATION,
      SELF_SCORE,
      PEERFUL_SCORE
    ]
    // Calculating the insights for each user
    workforceResults.forEach((workforceResult) => {
      // Dictionary to store mean scores
      const meanLists = Object.fromEntries(scoreFields.map((s) => [s, []]))

      workforceResult.userResults.forEach((result) => {
        // ** Calculating the means within review for insights
        for (const scoreField of scoreFields) {
          let score
          // Special case for review received, as it's store in the resultsByQuestion
          if (scoreField === REVIEWS_RECEIVED) {
            // Getting the reviews received of the user
            const userReviewsReceived = result.resultsByQuestion.map(
              (result) => result.reviewsReceived
            )
            // Getting the score reviews received of the user
            score = round(mean({ values: userReviewsReceived }))
          }
          // Check if the self scoring is enabled if self score
          else if (
            scoreField === SELF_SCORE &&
            result.evaluationConfig.dataConfig.enableSelfScoring
          ) {
            // Getting the average selfScore of the user, special filter
            score = mean({
              values: getValidScoresFromResult({ result, scoreField }).filter(
                // Filter 0s
                (score) => score
              )
            })
          }
          // Default case is all same for rest
          else {
            score = mean({
              values: getValidScoresFromResult({ result, scoreField })
            })
          }
          // Check if the score is valid, then add to array
          if (isValidScore(score)) meanLists[scoreField].push(score)
        }
      })

      // Create final averages for the user across reviews, for each score
      const averages = Object.fromEntries(
        Object.entries(meanLists).map(([key, value]) => [
          key,
          mean({ values: value })
        ])
      )

      if (
        isValidScore(averages[PERCENTILE]) &&
        isValidScore(averages[PEERFUL_SCORE]) &&
        isValidScore(averages[REVIEWS_RECEIVED])
      ) {
        insights.performers.push({
          uid: workforceResult.userId,
          manager: workforceResult.manager?.fullName,
          fullName: workforceResult.fullName,
          percentile: round(averages[PERCENTILE], 1),
          peerfulScore: round(averages[PEERFUL_SCORE], 1),
          reviewsReceived: round(averages[REVIEWS_RECEIVED]),
          // optional fields
          ...createObjectFromKeys({
            object: workforceResult,
            keys: [TEAM, DIVISION, TENURE, SENIORITY, JOB_LEVEL, JOB_TITLE]
          })
        })
      }

      // Check if reviewsReceived and rawAverageScore for this user exists
      if (
        isValidScore(averages[REVIEWS_RECEIVED]) &&
        isValidScore(averages[RAW_AVERAGE_SCORE])
      ) {
        // Add users with less than 4 reviews and at least one review
        if (
          averages[REVIEWS_RECEIVED] < 4 &&
          averages[REVIEWS_RECEIVED] &&
          averages[RAW_AVERAGE_SCORE] >= this.FLIGHT_RISK_THRESHOLD
        ) {
          insights.flightRisks.push({
            uid: workforceResult.userId,
            fullName: workforceResult.fullName,
            rawAverageScore: round(averages[RAW_AVERAGE_SCORE], 1),
            managerScore: round(averages[MANAGER_SCORE]),
            reviewsReceived: round(averages[REVIEWS_RECEIVED]),
            // optional fields
            ...createObjectFromKeys({
              object: workforceResult,
              keys: [TEAM, DIVISION, TENURE, SENIORITY, JOB_LEVEL]
            })
          })
        }
      }

      // Check if standardDeviation for this user exists
      if (isValidScore(averages[STANDARD_DEVIATION])) {
        // we add every user to the standardDeviationScores array, then adding a threshold filter while rendering the table in insights tab
        insights.highDeviations.push({
          uid: workforceResult.userId,
          fullName: workforceResult.fullName,
          standardDeviation: round(averages[STANDARD_DEVIATION], 1),
          reviewsReceived: round(averages[REVIEWS_RECEIVED]),
          // optional fields
          ...createObjectFromKeys({
            object: workforceResult,
            keys: [TEAM, DIVISION, TENURE, SENIORITY, JOB_LEVEL]
          })
        })
      }

      // Check if selfScore and peerfulScore for this user exists
      if (
        isValidScore(averages[SELF_SCORE]) &&
        isValidScore(averages[PEERFUL_SCORE])
      ) {
        // Getting the digression
        const averageDigression = averages[PEERFUL_SCORE] - averages[SELF_SCORE]
        // we add every user to the highDigressions array, then adding a threshold filter while rendering the table in insights tab
        insights.highDigressions.push({
          uid: workforceResult.userId,
          fullName: workforceResult.fullName,
          digression: round(averageDigression),
          peerfulScore: round(averages[PEERFUL_SCORE]),
          selfScore: round(averages[SELF_SCORE]),
          manager: workforceResult?.manager?.fullName,
          // optional fields
          ...createObjectFromKeys({
            object: workforceResult,
            keys: [TEAM, DIVISION, TENURE, SENIORITY, JOB_LEVEL]
          })
        })
      }
    })

    return insights
  }

  getNetworkChangePerUser = ({ userResults }) => {
    // Check if there are more than 2 evaluations
    if (userResults.length < 2) {
      return {
        averageSelectedPeopleChangePerUser: null,
        averageReviewerPeopleChangePerUser: null
      }
    }

    // Calculate selected people change across evaluations for user , then take averages.
    // iterate through pairs for consecutive evaluations
    // then take the average of the averages

    const selectedPeopleByEvaluations = [] // Array of arrays of selected people by evaluations
    const reviewerPeopleByEvaluations = [] // Array of arrays of reviewer people by evaluations

    // selectedPeopleByEvaluations = [['uid','uid','uid'], ['uid'...], ['uid'..]] respectively

    for (const result of userResults) {
      selectedPeopleByEvaluations.push(result.selectedPeople)
      reviewerPeopleByEvaluations.push(result.reviewerPeople)
    }

    // Calculate the selected and reviewer people change across evaluations for each user (change of mutual selected people) , then take averages.
    const selectedPeopleChangePercentages = []
    const reviewerPeopleChangePercentages = []

    for (let i = 0; i < selectedPeopleByEvaluations.length - 1; i++) {
      if (i === selectedPeopleByEvaluations.length - 1) {
        break
      }

      // Calculate only if there is consecutive data
      if (
        selectedPeopleByEvaluations[i].length &&
        selectedPeopleByEvaluations[i + 1].length
      ) {
        // Get the common selected people
        const commonSelectedPeople = selectedPeopleByEvaluations[i + 1].filter(
          (uid) => selectedPeopleByEvaluations[i].includes(uid)
        )
        selectedPeopleChangePercentages.push(
          (1 -
            commonSelectedPeople.length /
              selectedPeopleByEvaluations[i + 1].length) *
            100
        )
      }

      // Calculate only if there is consecutive data
      if (
        reviewerPeopleByEvaluations[i].length &&
        reviewerPeopleByEvaluations[i + 1].length
      ) {
        // Get the common reviewer people
        const commonReviewerPeople = reviewerPeopleByEvaluations[i + 1].filter(
          (uid) => reviewerPeopleByEvaluations[i].includes(uid)
        )
        // Calculate the change in reviewers
        reviewerPeopleChangePercentages.push(
          (1 -
            commonReviewerPeople.length /
              reviewerPeopleByEvaluations[i + 1].length) *
            100
        )
      }
    }

    // Return averages for user
    return {
      averageSelectedPeopleChangePerUser: mean({
        values: selectedPeopleChangePercentages
      }),
      averageReviewerPeopleChangePerUser: mean({
        values: reviewerPeopleChangePercentages
      })
    }
  }

  #getNetworkChange = ({ workforceResults, workforceResultsByEvaluation }) => {
    // Check if there are more than 2 evaluations
    if (workforceResultsByEvaluation.length < 2) {
      return {
        averageSelectedPeopleChange: null,
        averageReviewerPeopleChanges: null
      }
    }

    // Calculate selected and reviewer people change across evaluations for each user , then take averages.
    // iterate through pairs for consecutive evaluations
    // then take the average of the averages

    const averageSelectedPeopleChanges = []
    const averageReviewerPeopleChanges = []

    // iterate through pairs for consecutive evaluations
    workforceResults.forEach((workforceResult) => {
      const {
        averageSelectedPeopleChangePerUser,
        averageReviewerPeopleChangePerUser
      } = this.getNetworkChangePerUser({
        userResults: workforceResult.userResults
      })

      if (isValidScore(averageSelectedPeopleChangePerUser)) {
        averageSelectedPeopleChanges.push(averageSelectedPeopleChangePerUser)
      }

      if (isValidScore(averageReviewerPeopleChangePerUser)) {
        averageReviewerPeopleChanges.push(averageReviewerPeopleChangePerUser)
      }
    })

    // Return average of averages
    return {
      averageSelectedPeopleChange: mean({
        values: averageSelectedPeopleChanges
      }),
      averageReviewerPeopleChanges: mean({
        values: averageReviewerPeopleChanges
      })
    }
  }

  #calculateStabilityOfPeerfulScoresForLastTwoEvaluations = ({
    workforceResultsByEvaluation
  }) => {
    if (workforceResultsByEvaluation.length < 2) {
      return null
    }

    // We compare the change between the last evaluations
    const lastTwoEvaluations = workforceResultsByEvaluation.slice(-2)

    const seriesOfStabilityByUserIdWithAxisValues = [[], []]
    const axisLabels = {
      behaviors: new Set(),
      divisions: new Set()
    }

    lastTwoEvaluations.forEach((workforceResults, index) => {
      workforceResults.behaviors.forEach((behavior) => {
        axisLabels.behaviors.add(behavior.behavior)
      })

      workforceResults.evaluationResults.forEach((result) => {
        if (result.division) {
          axisLabels.divisions.add(result.division)
          seriesOfStabilityByUserIdWithAxisValues[index].push({
            userId: result.userId,
            division: result.division,
            behaviorToScore: result.resultsByQuestion.reduce((acc, curr) => {
              acc[curr.behavior] = curr.scores.find(
                (score) => score.name === PEERFUL_SCORE
              ).value
              return acc
            }, {})
          })
        }
      })
    })

    // Now seriesOfStabilityByUserIdWithAxisValues has the structure:
    // [[{userId: 'uid', division: 'division', behaviors: {behavior: score}}, ...], ...]]
    // First array is old evaluation, second array is new evaluation

    // Calculate the stability of peerful scores for each user
    const seriesOfSwings = []
    seriesOfStabilityByUserIdWithAxisValues[1].forEach((newResult) => {
      const oldResult = seriesOfStabilityByUserIdWithAxisValues[0].find(
        (oldResult) =>
          oldResult.userId === newResult.userId &&
          oldResult.division === newResult.division
      )

      if (oldResult) {
        const swings = {}
        for (const behavior of axisLabels.behaviors) {
          const newScore = newResult.behaviorToScore[behavior]
          const oldScore = oldResult.behaviorToScore[behavior]
          if (isValidScore(newScore) && isValidScore(oldScore)) {
            swings[behavior] = Math.abs(newScore - oldScore)
          }
        }

        // Add average swing to the series
        if (Object.values(swings).length) {
          swings['Average'] = mean({ values: Object.values(swings) })
        }

        // iterate over behavior and swing:
        for (const [behavior, swing] of Object.entries(swings)) {
          seriesOfSwings.push({
            userId: newResult.userId,
            division: newResult.division,
            behavior,
            swing
          })
        }
      }
    })

    // Now seriesOfStability has the structure: [{userId: 'uid', division: 'division', stability: {behavior: score}}, ...]
    // Calculate the average swing per behavior for each division
    // Additionally calculate average swing for everyone like % people swinged less than 10 point % people swinged less between 10 to 20 point and % people swinged more than 20 point

    const finalDivisions = [...axisLabels.divisions].toSorted()

    const seriesOfSwingsByBehaviorByDivision = []
    for (const behavior of [...axisLabels.behaviors, 'Average']) {
      const seriesOfSwingsByDivision = []
      for (const division of finalDivisions) {
        const swingForDivisionAndBehavior = mean({
          values: seriesOfSwings
            .filter((s) => s.division === division && s.behavior === behavior)
            .map((s) => s.swing)
        })

        // Get average swing for the each division in swings
        seriesOfSwingsByDivision.push(swingForDivisionAndBehavior)
      }
      seriesOfSwingsByBehaviorByDivision.push({
        name: behavior,
        data: seriesOfSwingsByDivision
      })
    }

    const swingCount = [
      { value: 0, text: `Less than 10 points:` },
      { value: 0, text: `Between 10 to 20 points:` },
      { value: 0, text: `More than 20 points:` }
    ]

    seriesOfSwings
      .filter((s) => s.behavior === 'Average')
      .forEach((stability) => {
        const swing = stability.swing
        if (swing < 10) {
          swingCount[0].value++
        } else if (swing < 20) {
          swingCount[1].value++
        } else {
          swingCount[2].value++
        }
      })

    const sum = swingCount.reduce((acc, curr) => acc + curr.value, 0)
    swingCount.forEach((swing) => {
      swing.value = round((swing.value / sum) * 100, 1)
    })

    return {
      series: seriesOfSwingsByBehaviorByDivision,
      categories: finalDivisions,
      averageSwings: swingCount
    }
  }

  #generateWordCloudData = ({ workforceResults }) => {
    const wordCounts = {}
    const riskyWordInstances = []

    workforceResults.forEach((workforceResult) => {
      workforceResult.userResults.forEach((userResult) => {
        userResult.resultsByQuestion.forEach((result) => {
          result.comments.forEach((comment) => {
            const words = comment.comment
              .toLowerCase()
              .split(/\s+/)
              .map((word) => word.replace(/[,.]$/, ''))
            words.forEach((word) => {
              // Ignore short words
              if (word.length > 3 && !STOP_WORDS.has(word)) {
                wordCounts[word] = (wordCounts[word] || 0) + 1
                if (RISKY_WORDS.has(word)) {
                  riskyWordInstances.push({
                    word,
                    comment: comment.comment,
                    user: workforceResult.fullName,
                    behavior: result.behavior
                  })
                }
              }
            })
          })
        })
      })
    })

    const wordCloudData = Object.entries(wordCounts)
      .map(([text, value]) => ({ value: text, count: value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 30)

    return {
      wordCloudData,
      riskyWordInstances
    }
  }

  // Function that generates the insights
  generateInsights = ({ workforceResults, filters }) => {
    const filteredByBehaviorAndEvaluation =
      filterWorkforceResultsByBehaviorAndEvaluation({
        workforceResults,
        filters
      })

    const filteredWorkforceResults = filterByUserProperties({
      workforceResults: filteredByBehaviorAndEvaluation,
      filters
    })

    // const insightSeries = {}
    const workforceResultsByEvaluation = this.getWorkforceResultsByEvaluation({
      workforceResults: filteredWorkforceResults
    })

    // Chart 1: Review Stats (Completion, Received Reviews, Given Reviews, Comment Stats)
    // Chart 2: Distribution of Employees by Review Received
    // Chart 3: Distribution of Scores
    const macroData = this.#getMacroData({
      workforceResults: filteredWorkforceResults,
      workforceResultsByEvaluation
    })

    // Chart 1: Scores by Division and Behavior
    // Chart 2: Scores by Seniority and Behavior
    // Chart 3: Scores by Job Level and Behavior
    const {
      peerfulScoresByDivisionAndBehaviorSeries,
      peerfulScoresBySeniorityAndBehaviorSeries,
      peerfulScoresByJobLevelAndBehaviorSeries
    } = this.#getEvaluationBasedInsights({ workforceResultsByEvaluation })

    // Chart 1: Top Performers
    // Chart 2: Worst Performers
    // Chart 3: Flight Risks
    // Chart 4: High Deviations
    // Chart 5: Digressions
    const { performers, flightRisks, highDeviations, highDigressions } =
      this.#getEmployeeInsights({ workforceResults: filteredWorkforceResults })

    // Chart 1: Change in Selected People
    const { averageSelectedPeopleChange, averageReviewerPeopleChanges } =
      this.#getNetworkChange({
        workforceResults,
        workforceResultsByEvaluation
      })

    // Chart 1: Stability of Peerful Scores
    const stabilityOfPeerfulScoresByDivisionAndBehaviorSeries =
      this.#calculateStabilityOfPeerfulScoresForLastTwoEvaluations({
        workforceResultsByEvaluation
      })

    const { wordCloudData, riskyWordInstances } = this.#generateWordCloudData({
      workforceResults: filteredWorkforceResults
    })

    return {
      macroData: macroData,
      peerfulScoresByDivisionAndBehaviorSeries,
      peerfulScoresBySeniorityAndBehaviorSeries,
      peerfulScoresByJobLevelAndBehaviorSeries,
      stabilityOfPeerfulScoresByDivisionAndBehaviorSeries,
      performers: performers,
      flightRisks: flightRisks,
      highDeviations: highDeviations,
      highDigressions: highDigressions,
      averageSelectedPeopleChange: averageSelectedPeopleChange,
      averageReviewerPeopleChanges: averageReviewerPeopleChanges,
      wordCloudData,
      riskyWordInstances
    }
  }
}

export const insightsModal = new InsightsModal()

// Extending the Insights class to get the insights
class ExecutiveOverviewModal extends InsightsModal {
  #filterWorkforceResultsByEvaluation = ({
    workforceResults,
    evaluationId
  }) => {
    // Getting only evaluations with this specific evaluationId
    return workforceResults.map((workforceResult) => {
      return {
        ...workforceResult,
        userResults: workforceResult.userResults.filter(
          (userResult) => userResult.evaluationId === evaluationId
        )
      }
    })
  }

  #getDataBasedOnPriority = ({ data, priority }) => {
    for (let i = 0; i < Object.keys(priority).length; i++) {
      const priorityKey = Object.keys(priority).find(
        (key) => priority[key] === i + 1
      )
      if (data[priorityKey].length) {
        return data[priorityKey]
      }
    }
  }

  #getTopPerformers = ({ performers }) => {
    const possibleTopPerformerData = {
      topPerformers: [],
      topJuniorPerformers: []
    }
    const priority = {
      topPerformers: 2,
      topJuniorPerformers: 1
    }

    // Getting the top performers
    possibleTopPerformerData.topPerformers = performers.sort(
      (a, b) => b.peerfulScore - a.peerfulScore
    )

    // Getting the result based on priority
    const prioritizedTopPerformers = this.#getDataBasedOnPriority({
      data: possibleTopPerformerData,
      priority
    })

    return prioritizedTopPerformers
  }

  #generateLastReviewData = ({
    insights,
    lastWorkforceResults,
    lastWorkforceResultsByEvaluation
  }) => {
    // The max amount of data to be returned to executive overview
    const ARRAY_COUNT = 5

    // Getting the top 5 performers
    const topPerformers = this.#getTopPerformers({
      performers: [...insights.performers]
    })
    const top5Performers = topPerformers.slice(0, ARRAY_COUNT)

    // Getting the worst 5 performers
    const worstPerformers = [...topPerformers].reverse()
    const worst5Performers = worstPerformers.slice(0, ARRAY_COUNT)

    // Getting the top 5 high deviations (Optional)
    const top5HighDeviations =
      insights?.highDeviations
        ?.sort((a, b) => b.standardDeviation - a.standardDeviation)
        ?.slice(0, ARRAY_COUNT) || []

    // Getting the top 5 flight risks (Optional)
    const flightRisks =
      insights?.flightRisks?.sort(
        (a, b) => b.rawAverageScore - a.rawAverageScore
      ) || []

    const top5FlightRisks = flightRisks?.slice(0, ARRAY_COUNT) || []

    const { divisions, teams } = this.getUniqueCategories({
      resultsList: lastWorkforceResultsByEvaluation
        .map((e) => e.evaluationResults)
        .flat()
    })

    const divisionAverageResults = []
    const teamAverageResults = []
    const categories = [
      [divisions, DIVISION, divisionAverageResults],
      [teams, TEAM, teamAverageResults]
    ]
    categories.forEach(([categoryList, categoryName, categoryAverages]) => {
      // Calculating the average score for each team and division by iterating
      // through unique team and divisions gotten from unique categories
      for (const category of categoryList) {
        // Getting the division user results that includes results for this evaluation
        const results = lastWorkforceResults.filter(
          (workforceResult) =>
            workforceResult[categoryName] === category &&
            workforceResult.userResults.length === 1
        )

        const employeeAverages = []
        for (const result of results) {
          // Getting the average peerfulScore of the user
          const averageScore = mean({
            values: getValidScoresFromResult({
              result: result.userResults[0],
              scoreField: PEERFUL_SCORE
            })
          })
          if (isValidScore(averageScore)) {
            employeeAverages.push(averageScore)
          }
        }
        const average = mean({ values: employeeAverages })
        categoryAverages.push({
          [categoryName]: category,
          average: average
        })
      }
      // Sort
      categoryAverages.sort((a, b) => b.average - a.average)
    })

    const highlyConnectedPeople = []

    for (const workforceResult of lastWorkforceResults.filter(
      (r) => r.userResults.length === 1
    )) {
      const userResult = workforceResult.userResults[0]
      const totalReviewsReceived = userResult.resultsByQuestion.reduce(
        (acc, curr) => {
          return acc + curr.reviewsReceived
        },
        0
      )

      highlyConnectedPeople.push({
        ...workforceResult,
        totalReviewsReceived
      })
    }

    // Sorting the highly connected juniors and seniors in descending order
    highlyConnectedPeople
      .sort((a, b) => b.totalReviewsReceived - a.totalReviewsReceived)
      .splice(ARRAY_COUNT)

    const reviewStats = this.getReviewStats({
      workforceResultsByEvaluation: lastWorkforceResultsByEvaluation
    })
    const completionRate = round(
      (reviewStats.submitted / reviewStats.totalUser) * 100,
      1
    )

    return {
      topPerformers: top5Performers,
      worstPerformers: worst5Performers,
      highDeviations: top5HighDeviations,
      flightRisks: top5FlightRisks,
      topDivisions: divisionAverageResults,
      topTeams: teamAverageResults,
      highlyConnectedPeople: highlyConnectedPeople,
      completionRate: completionRate
    }
  }

  #getAverageScoreChange = ({ workforceResultsByEvaluation }) => {
    // Calculate rawAverageScore score change across for each user, then take averages.
    // iterate through pairs for consecutive evaluations
    // calculate the average score change for each user for each behavior in each eval
    // then take the average of the averages

    // Filter workforceResultsByEvaluation to only include evaluations with rawAverageScore
    const filteredWorkforceResultsByEvaluation =
      workforceResultsByEvaluation.filter((evaluation) => {
        return evaluation.evaluationResults.some((result) => {
          return result.resultsByQuestion.some((result) => {
            return result.scores.some(
              (score) => score.name === RAW_AVERAGE_SCORE
            )
          })
        })
      })

    const averageAbsDifferencesBetweenConsecutiveEvaluations = []

    // iterate through pairs for consecutive evaluations
    for (const i of Array(filteredWorkforceResultsByEvaluation.length).keys()) {
      if (i === filteredWorkforceResultsByEvaluation.length - 1) {
        break
      }
      const firstEval = filteredWorkforceResultsByEvaluation[i]
      const secondEval = filteredWorkforceResultsByEvaluation[i + 1]

      const absoluteDiffsBetweenEvalPair = []

      // Iterate through user results in first evaluation
      for (const firstResults of firstEval.evaluationResults) {
        // Go through each result by behavior
        for (const firstResult of firstResults.resultsByQuestion) {
          // Get raw average fo first eval behavior
          const firstRawScore = firstResult.scores.find(
            (s) => s.name === RAW_AVERAGE_SCORE
          ).value

          // Find and get the raw average score for the same behavior in the second evaluation
          const secondRawScore = secondEval.evaluationResults
            .find((r) => r.userId === firstResults.userId)
            ?.resultsByQuestion?.find(
              (r) => r.behavior === firstResult.behavior
            )
            ?.scores.find((s) => s.name === RAW_AVERAGE_SCORE)?.value

          // Check if the secondRawScore is defined and not N/A
          if (isValidScore(firstRawScore) && isValidScore(secondRawScore)) {
            // Track the absolute difference between the two evaluations for this behavior
            absoluteDiffsBetweenEvalPair.push(
              Math.abs(firstRawScore - secondRawScore)
            )
          }
        }
      }

      averageAbsDifferencesBetweenConsecutiveEvaluations.push(
        mean({ values: absoluteDiffsBetweenEvalPair })
      )
    }

    // Returning null if there are no evaluations with rawAverageScore
    if (averageAbsDifferencesBetweenConsecutiveEvaluations.length === 0) {
      return null
    }

    // Return the average of the averages
    return mean({
      values: averageAbsDifferencesBetweenConsecutiveEvaluations
    })
  }

  #getUsersThatMovedUpAndDown = ({
    firstWorkforceResults,
    secondWorkforceResults
  }) => {
    // The max amount of data to be returned to executive overview
    const ARRAY_COUNT = 5

    const changeInUserScores = []

    // Getting the users that moved up
    firstWorkforceResults.forEach((firstWorkforceResult) => {
      const { userResults, userId } = firstWorkforceResult

      // Getting the second workforce result
      const secondWorkforceResult = secondWorkforceResults.find(
        (secondWorkforceResult) => secondWorkforceResult.userId === userId
      )

      // If the user is not in the second workforce results, then skip
      if (!secondWorkforceResult) return

      // Getting the second user result
      const secondUserResult = secondWorkforceResult.userResults[0]

      // Getting the first user result
      const firstUserResult = userResults[0]

      // Skip if the user does not have a result
      if (!firstUserResult || !secondUserResult) return

      // Getting the first user average score
      const firstUserAverageScore = mean({
        values: getValidScoresFromResult({
          result: firstUserResult,
          scoreField: PEERFUL_SCORE
        })
      })

      // Getting the second user average score
      const secondUserAverageScore = mean({
        values: getValidScoresFromResult({
          result: secondUserResult,
          scoreField: PEERFUL_SCORE
        })
      })

      const averageScoreDifference = round(
        firstUserAverageScore - secondUserAverageScore,
        1
      )

      // If the user moved up, then add to the usersThatMovedUp
      changeInUserScores.push({
        userId,
        fullName: firstWorkforceResult.fullName,
        firstUserAverageScore,
        secondUserAverageScore,
        changeInUserScores: averageScoreDifference
      })
    })

    // Getting the users that moved up and down
    const usersThatMovedUp = changeInUserScores.filter(
      (u) => u.changeInUserScores
    )
    const usersThatMovedDown = changeInUserScores.filter(
      (u) => u.changeInUserScores < 0
    )

    // Sorting the users that moved up and down
    usersThatMovedUp
      .sort((a, b) => b.changeInUserScores - a.changeInUserScores)
      .splice(ARRAY_COUNT)
    usersThatMovedDown
      .sort((a, b) => a.changeInUserScores - b.changeInUserScores)
      .splice(ARRAY_COUNT)

    return { usersThatMovedUp, usersThatMovedDown }
  }

  #generateDifferenceBetweenLastTwoReviewsData = ({
    firstWorkforceResults,
    secondWorkforceResults
  }) => {
    // Users that moved up in the company
    const { usersThatMovedUp, usersThatMovedDown } =
      this.#getUsersThatMovedUpAndDown({
        firstWorkforceResults,
        secondWorkforceResults
      })

    return {
      usersThatMovedUp,
      usersThatMovedDown
    }
  }

  #generateOverallAnalysis = ({
    overallInsights,
    workforceResultsByEvaluation
  }) => {
    // Getting the total number of reviews
    const totalReviews = workforceResultsByEvaluation.length

    // Getting the average number of reviews given by participants
    const averageReviewsGiven =
      overallInsights.macroData.reviewsGiven.values.find(
        (v) => v.label === 'Mean'
      ).value

    // Getting the average number of reviewers
    const averageReviewers = overallInsights.macroData.reviewers.values.find(
      (v) => v.label === 'Mean'
    ).value

    // Getting the average comments given
    const averageComments = overallInsights.macroData.commentStats.values.find(
      (v) => v.label === 'Mean'
    ).value

    // Get the average change in selected people
    const averageSelectedPeopleChange =
      overallInsights.averageSelectedPeopleChange

    // Get the average change in reviewer people
    const averageReviewerPeopleChange =
      overallInsights.averageReviewerPeopleChanges

    const reviewStats = this.getReviewStats({ workforceResultsByEvaluation })
    const completionRate = round(
      (reviewStats.submitted / reviewStats.totalUser) * 100,
      1
    )

    const averageScoreChange = this.#getAverageScoreChange({
      workforceResultsByEvaluation
    })

    // The total number of unique participants
    const totalUniqueParticipants = [
      ...new Set(
        workforceResultsByEvaluation
          .map((workforceResults) =>
            workforceResults.evaluationResults.map((r) => r.userId)
          )
          .flat()
      )
    ].length

    // Approx number of data points  total submitted reviews x (N choose 2) x numBehaviors
    const numberOfBehaviors = Number(
      workforceResultsByEvaluation[0]?.evaluationResults[0]?.resultsByQuestion
        .length
    )
    const approximateTotalNumberOfDataPoints = round(
      ((reviewStats.submitted *
        averageReviewsGiven *
        (averageReviewsGiven - 1)) /
        2) *
        numberOfBehaviors
    )

    return {
      totalReviews,
      totalUniqueParticipants,
      averageReviewers,
      averageComments,
      averageScoreChange,
      averageSelectedPeopleChange,
      averageReviewerPeopleChange,
      approximateTotalNumberOfDataPoints,
      completionRate
    }
  }

  generateExecutiveOverviewData = ({ workforceResults, evaluations }) => {
    // - DATA PREPARATION -

    // Getting the last review workforce results
    const lastWorkforceResults = this.#filterWorkforceResultsByEvaluation({
      workforceResults,
      evaluationId: evaluations[evaluations.length - 1]?.uid
    })

    // Getting the second last review workforce results
    const secondLastWorkforceResults = this.#filterWorkforceResultsByEvaluation(
      {
        workforceResults,
        evaluationId: evaluations[evaluations.length - 2]?.uid
      }
    )

    // Calculating the last review (first) insights
    const lastInsights = this.generateInsights({
      workforceResults: lastWorkforceResults
    })

    // Calculating the overall insights
    const overallInsights = this.generateInsights({ workforceResults })

    // Preparing the last workforce result by evaluation for last evaluation
    const lastWorkforceResultsByEvaluation =
      this.getWorkforceResultsByEvaluation({
        workforceResults: lastWorkforceResults
      })

    // Preparing the workforce results by evaluation (overall)
    const workforceResultsByEvaluation = this.getWorkforceResultsByEvaluation({
      workforceResults
    })

    // - DATA CALCULATION -

    // Getting the last review data
    const lastReviewData = this.#generateLastReviewData({
      insights: lastInsights,
      lastWorkforceResults,
      lastWorkforceResultsByEvaluation
    })

    // Getting the difference between the last and second last review data
    const lastTwoReviewsData =
      this.#generateDifferenceBetweenLastTwoReviewsData({
        firstWorkforceResults: lastWorkforceResults,
        secondWorkforceResults: secondLastWorkforceResults
      })

    const overallAnalysis = this.#generateOverallAnalysis({
      overallInsights,
      workforceResultsByEvaluation
    })

    return { lastReviewData, lastTwoReviewsData, overallAnalysis }
  }
}

export const executiveOverviewModal = new ExecutiveOverviewModal()

class ResultsModal {
  parseOriginalResultFromModalData = (modalData, user) => {
    return {
      // --- Results Data ---
      resultsByQuestion: modalData.resultsByQuestion
        .filter(
          (question) =>
            question.reviewsReceived && question.behavior !== 'Average'
        )
        .map((question) => ({ ...question, resultId: modalData.uid })),
      resultId: modalData.uid,
      notes: modalData.notes,
      evaluationConfig: modalData.evaluationConfig,
      // --- User Data ---
      fullName: user.fullName,
      userId: user.userId,
      manager: user.manager,
      // --- Evaluation Data ---
      evaluationId: modalData.evaluationId,
      evaluationName: modalData.evaluationName
    }
  }

  #prepareResultsForAnalytics = ({ workforceResults }) => {
    const processedSummaryResults = workforceResults.map((user) => {
      // Average the following:
      const reviewsReceivedList = []
      const reviewsGivenList = []
      const scoresListsMap = {
        peerfulScore: [],
        percentile: [],
        rawAverageScore: [],
        confidence: [],
        standardDeviation: [],
        managerScore: [],
        selfScore: []
      }

      // Get behavior percentiles/scores for Grid
      const behaviorPercentiles = {}
      const behaviorScoresMap = {}

      const { userResults } = user
      userResults.forEach((result) => {
        const { resultsByQuestion, evaluationName } = result
        result.resultsByQuestion = resultsByQuestion.map((resultByQuestion) => {
          const behavior = resultByQuestion.behavior
          const scores = {}

          resultByQuestion.scores.forEach((score) => {
            const value = isValidScore(score.value)
              ? Math.round(score.value)
              : undefined
            scores[score.name] = value
            score.value = value
          })

          // Sum up counting values
          reviewsGivenList.push(resultByQuestion.reviewsGiven)
          reviewsReceivedList.push(resultByQuestion.reviewsReceived)

          // Aggregate valid scores
          resultByQuestion.scores.forEach((score) => {
            if (isValidScore(score.value) && score.name in scoresListsMap) {
              scoresListsMap[score.name].push(score.value)
              // Set default array if not exists
              behaviorScoresMap[behavior] = behaviorScoresMap[behavior] || {}
              behaviorScoresMap[behavior][score.name] =
                behaviorScoresMap[behavior][score.name] || []
              behaviorScoresMap[behavior][score.name].push(score.value)

              if (isValidScore(score.percentile)) {
                // Set default array if not exists
                behaviorPercentiles[behavior] =
                  behaviorPercentiles[behavior] || {}
                behaviorPercentiles[behavior][score.name] =
                  behaviorPercentiles[behavior][score.name] || []
                behaviorPercentiles[behavior][score.name].push(score.percentile)
              }
            }
          })

          return {
            ...resultByQuestion,
            ...scores,
            evaluationName
          }
        })
      })

      // Calculate average for scores
      const finalScores = {}
      for (const key in scoresListsMap) {
        finalScores[key] = mean({ values: scoresListsMap[key] })
      }
      finalScores.reviewsGiven = mean({ values: reviewsGivenList })
      finalScores.reviewsReceived = mean({ values: reviewsReceivedList })

      // Calculate average for behavior based percentiles and scores
      for (const behaviorMap of [behaviorPercentiles, behaviorScoresMap]) {
        for (const behavior in behaviorMap) {
          for (const score in behaviorMap[behavior]) {
            behaviorMap[behavior][score] = mean({
              values: behaviorMap[behavior][score]
            })
          }
        }
      }

      // Calculate average selected people change
      const {
        averageSelectedPeopleChangePerUser,
        averageReviewerPeopleChangePerUser
      } = insightsModal.getNetworkChangePerUser({
        userResults
      })

      const averageSelectedPeopleChange = validatedScore(
        averageSelectedPeopleChangePerUser
      )
      const averageReviewerPeopleChange = validatedScore(
        averageReviewerPeopleChangePerUser
      )
      // Placing N/A for optional fields that is object
      const manager = user?.manager?.fullName

      return {
        ...user,
        percentiles: behaviorPercentiles,
        scores: behaviorScoresMap,
        uid: user.userId,
        manager,
        peerfulScore: finalScores.peerfulScore,
        rawAverageScore: finalScores.rawAverageScore,
        averageSelectedPeopleChange,
        averageReviewerPeopleChange,
        percentile: finalScores.percentile,
        confidence: finalScores.confidence,
        selfScore: finalScores.selfScore,
        managerScore: finalScores.managerScore,
        standardDeviation: finalScores.standardDeviation,
        reviewsGiven: finalScores.reviewsGiven,
        reviewsReceived: finalScores.reviewsReceived
      }
    })

    const processedResults = []
    for (const result of processedSummaryResults) {
      processedResults.push({
        ...result,
        behavior: 'Average'
      })

      // Default values for each row, excluding the scores
      const defaultValues = Object.fromEntries(
        Object.entries(result).filter(
          ([key]) => !Object.values(SCORES).includes(key)
        )
      )
      // Initialize objects to store the arrays of each field for each behavior and for the summary
      const scoresForBehavior = {}

      // Iterate over each user result to collect all scores by behavior
      for (const evaluationResults of result.userResults) {
        // Iterate over each result by question
        for (const resultByQuestion of evaluationResults.resultsByQuestion) {
          // Get the behavior from the result by question
          const behavior = resultByQuestion.behavior

          // Initialize arrays for each behavior if not already done
          if (!scoresForBehavior[behavior]) {
            scoresForBehavior[behavior] = {}
          }

          // * WARNING: We skip comments for summary
          // * If you want to include comments in the summary, collect them here
          // Iterate over each field in the result by question
          Object.entries(resultByQuestion).forEach(([field, fieldValue]) => {
            if (isValidScore(fieldValue)) {
              if (!scoresForBehavior[behavior][field]) {
                scoresForBehavior[behavior][field] = []
              }
              scoresForBehavior[behavior][field].push(fieldValue)
            }
          })
        }
      }

      // Calculate the averages for each behavior and the summary
      Object.entries(scoresForBehavior).forEach(
        ([behavior, behaviorScores]) => {
          // Iterate over each field in the input object
          const behaviorAverages = getAverageOfScores(behaviorScores)
          processedResults.push({
            behavior,
            ...defaultValues,
            ...behaviorAverages
          })
        }
      )
    }

    return processedResults
  }

  addPercentilesToWorkforceResults = ({ workforceResults }) => {
    const scores = [SELF_SCORE, MANAGER_SCORE, RAW_AVERAGE_SCORE, PEERFUL_SCORE]
    // First, get all scores for all results across all users. Then sort them.
    // initialize with scores for each behavior
    const behaviorScoresByType = {}
    for (const workforceResult of workforceResults) {
      for (const userResult of workforceResult.userResults) {
        for (const resultByQuestion of userResult.resultsByQuestion) {
          for (const score of resultByQuestion.scores) {
            if (scores.includes(score.name) && isValidScore(score.value)) {
              const behavior = resultByQuestion.questionId
              behaviorScoresByType[score.name] =
                behaviorScoresByType[score.name] || {}
              behaviorScoresByType[score.name][behavior] =
                behaviorScoresByType[score.name][behavior] || []
              behaviorScoresByType[score.name][behavior].push(score.value)
            }
          }
        }
      }
    }

    // Sort score arrays
    for (const scoreName in behaviorScoresByType) {
      for (const behavior in behaviorScoresByType[scoreName]) {
        behaviorScoresByType[scoreName][behavior].sort((a, b) => a - b)
      }
    }

    const workforceResultsWithPercentiles = workforceResults.map(
      (workforceResult) => ({
        ...workforceResult,
        userResults: workforceResult.userResults.map((userResult) => ({
          ...userResult,
          resultsByQuestion: userResult.resultsByQuestion.map(
            (resultByQuestion) => ({
              ...resultByQuestion,
              scores: resultByQuestion.scores.map((score) => {
                if (scores.includes(score.name) && isValidScore(score.value)) {
                  // Get the rank of the score in the sorted array to calculate the percentile
                  const behavior = resultByQuestion.questionId
                  const allScores = behaviorScoresByType[score.name][behavior]
                  const rank = allScores.indexOf(score.value) + 1
                  const percentile = round((rank / allScores.length) * 100)
                  return {
                    ...score,
                    percentile
                  }
                }
                return score
              })
            })
          )
        }))
      })
    )

    return workforceResultsWithPercentiles
  }

  generateAnalyticsData = ({ workforceResults, filters }) => {
    const filteredWorkforceResults =
      filterWorkforceResultsByBehaviorAndEvaluation({
        workforceResults,
        filters
      })

    const processedResults = this.#prepareResultsForAnalytics({
      workforceResults: filteredWorkforceResults
    })

    const filteredData = filterByUserProperties({
      workforceResults: processedResults,
      filters
    })

    return filteredData
  }
}

export const resultsModal = new ResultsModal()
