import { array, object, string } from 'yup'
import { responsesSchema } from './responses.js'

export const reviewSchema = object()
  .shape({
    evaluation: string().strict().required('Evaluation id field is required.'),
    people: array().of(string()).required('People field is required.'),
    responses: responsesSchema.required('Responses field is required.'),
    reviewId: string().strict().required('Review id field is required.'),
    reviewer: string().strict().required('Reviewer id field is required.'),
    status: string()
      .strict()
      .required('Status field is required.')
      .oneOf(['COMPLETED', 'ONGOING'])
  })
  .noUnknown()
