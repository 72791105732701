import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Menu01Icon from '@untitled-ui/icons-react/build/cjs/Menu01'
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  SvgIcon,
  useMediaQuery
} from '@mui/material'
import { toast } from 'react-toastify'
import { alpha } from '@mui/material/styles'
import { AccountButton } from '/src/layouts/dashboard/account-button'
import { FAQButton } from '/src/components/faq/faq-button'
import { useSettings } from '/src/hooks/use-settings'
import { SIDE_NAV_WIDTH } from '.'
import { useUser } from '/src/hooks/use-user'
import demoRepo from '/src/data/repos/demo-repo'
import PrimaryButton from '/src/components/buttons/primary-button'

const TOP_NAV_HEIGHT = 64

const initialState = {
  capturing: false,
  loadButton: false,
  isUpgradeRequested: false
}

export const TopNav = (props) => {
  const { onMobileNavOpen, ...other } = props
  const [state, setState] = useState({ ...initialState })
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const settings = useSettings()
  const { user } = useUser()

  // Check local storage on component mount
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isUpgradeRequested: user?.upgradeRequested ? true : false,
      loadButton: true
    }))
  }, [user?.upgradeRequested])

  const handleUpgrade = async () => {
    // Disabling the button if loading
    if (state.capturing) {
      return
    }

    // Updating the state
    setState((prevState) => ({
      ...prevState,
      capturing: true
    }))

    try {
      await demoRepo.upgradeRequest({})

      setState((prevState) => ({
        ...prevState,
        capturing: false,
        isUpgradeRequested: true
      }))
    } catch (error) {
      console.warn('upgradeRequest error: ', error)
      toast.error('Error sending the upgrade request.')
      setState((prevState) => ({
        ...prevState,
        capturing: false
      }))
      return
    }
  }

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`
        },
        top: 0,
        width: '100%',
        zIndex: (theme) => theme.zIndex.appBar
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          pl: 1
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          sx={{
            justifyContent: 'flex-end',
            flexGrow: 1,
            mr: 2
          }}
          spacing={2}
        >
          {user.status === 'DEMO' && state.loadButton && (
            <PrimaryButton
              sx={{
                width: '100%',
                maxWidth: '200px'
              }}
              onClick={handleUpgrade}
              disabled={state.isUpgradeRequested}
            >
              {state.capturing ? (
                <CircularProgress
                  sx={{
                    color: '#fff',
                    padding: 0.25
                  }}
                  size={24}
                />
              ) : state.isUpgradeRequested ? (
                'Requested Full Version'
              ) : (
                'Request Full Version'
              )}
            </PrimaryButton>
          )}
          <FAQButton settings={settings} />
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  )
}

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
}
