import { Box, Container, Divider, Stack, Typography } from '@mui/material'
import { Seo } from '/src/components/seo'
import { useUser } from '/src/hooks/use-user'
import { ROLES } from '/functions/shared/constants/roles'
import { ReviewsTable } from '/src/components/reviews-table/reviews-table'
import useGetRequest, { Endpoints } from '/src/hooks/requests/use-get-request'

const Page = () => {
  const { user } = useUser()
  const {
    data: evaluations,
    isLoading,
    error,
    refetch
  } = useGetRequest({
    endpoint: Endpoints.GET_EVALUATIONS,
    data: { userRole: ROLES.ADMIN }
  })

  return (
    <>
      <Seo title="Overview" />
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          flexGrow: 1
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            mb: 5
          }}
        >
          <Box sx={{ p: 3, pt: 0 }}>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="flex-start"
              spacing={1}
            >
              <div>
                <Typography variant="h3">{user.companyName} Reviews</Typography>
              </div>
            </Stack>
          </Box>
          <Divider />
          <Box mt={3}>
            <ReviewsTable
              title="Reviews"
              isLoading={isLoading}
              error={error}
              reviews={evaluations}
              refetch={refetch}
              role={ROLES.ADMIN}
            />
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Page
