import { common } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'
import {
  error,
  info,
  success,
  warning,
  neutral,
  withAlphas,
  autoColorsByCustomColor,
  defaultLightColors
} from '/src/theme/colors'
import { getColorVariants } from '/src/utils/color'

export const createPalette = (config) => {
  const { customizationConfig } = config

  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      tableHead: neutral[50],
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.09),
      selected: alpha(neutral[900], 0.12)
    },
    background: {
      default: common.white,
      paper: common.white
    },
    divider: '#F2F4F7',
    error,
    info,
    mode: 'light',
    customColors: autoColorsByCustomColor(
      customizationConfig?.themeColor || defaultLightColors.main
    ),
    neutral,
    primary: withAlphas(
      getColorVariants(
        customizationConfig?.themeColor || defaultLightColors.main
      )
    ),
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38)
    },
    warning
  }
}
