import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import { useDialog } from '/src/hooks/use-dialog'
import { IconButton, styled } from '@mui/material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

interface CustomDialogProps {
  children: React.ReactNode
  title: string
  actions: React.ReactNode
  controller: ReturnType<typeof useDialog>
  openButton: React.ReactNode
  dividers?: boolean
  fullWidth?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  children,
  title,
  actions,
  controller,
  openButton,
  dividers = false,
  fullWidth = true,
  maxWidth = 'sm'
}) => {
  const { handleClose, open } = controller

  return (
    <div>
      {openButton}
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers={dividers}>{children}</DialogContent>
        {actions ? <DialogActions>{actions}</DialogActions> : null}
      </BootstrapDialog>
    </div>
  )
}

export default CustomDialog
