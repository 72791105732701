import { object, bool, array } from 'yup'

export const ingestEmployeeData = object()
  .shape({
    isPreview: bool().strict().required(),
    preview: object().when('isPreview', {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired()
    }),
    updatedUsers: array().when('isPreview', {
      is: true,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required()
    }),
    validations: object().required()
  })
  .noUnknown() // to prevent extra fields from being added to the object and activating striptUnknown

export const updateEmployee = object()
  .shape({
    updatedUsers: array().required()
  })
  .noUnknown() // to prevent extra fields from being added to the object and activating striptUnknown
