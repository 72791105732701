import {
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const CustomSwitch = ({
  children,
  direction = 'row',
  alignItems = 'center',
  justifyContent = 'space-between',
  ...otherProps
}) => {
  return (
    <Stack
      direction={direction}
      alignItems={alignItems}
      justifyContent={justifyContent}
      {...otherProps}
    >
      {children}
    </Stack>
  )
}

CustomSwitch.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string
}

const CustomSwitchText = ({
  variant = 'subtitle1',
  children,
  ...otherProps
}) => (
  <Typography variant={variant} {...otherProps}>
    {children}
  </Typography>
)

CustomSwitchText.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node
}

const CustomSwitchFormControl = ({
  checked,
  onChange,
  switchProps,
  width = 50,
  sx = {},
  inputProps,
  ...otherProps
}) => (
  <FormControlLabel
    sx={{
      width: width,
      ...sx
    }}
    control={
      <Switch
        // if onChange and checked are provided, make the switch controlled
        {...(onChange &&
          typeof checked === 'boolean' && {
            inputProps: { 'aria-label': 'controlled', ...inputProps },
            onChange,
            checked
          })}
        {...switchProps}
      />
    }
    {...otherProps}
  />
)

CustomSwitchFormControl.propTypes = {
  checked: PropTypes.bool,
  switchProps: PropTypes.object,
  width: PropTypes.number,
  sx: PropTypes.object,
  inputProps: PropTypes.object
}
const CustomSwitchTextField = ({
  label = '',
  visible = false,
  sx = {},
  ...otherProps
}) => (
  <TextField
    label={label}
    sx={{
      display: visible ? 'block' : 'none',
      ...sx
    }}
    {...otherProps}
  />
)

CustomSwitchTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  visible: PropTypes.bool,
  sx: PropTypes.object
}

CustomSwitch.Text = CustomSwitchText
CustomSwitch.FormControl = CustomSwitchFormControl
CustomSwitch.TextField = CustomSwitchTextField

export default CustomSwitch
