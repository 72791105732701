import { allFiltersMapToType } from '../constants/filter-drawer-types'

// group by component type for each filter type
const groupFilterTypes = ({ localFilters, drawerType, componentType }) => {
  const allFilters = allFiltersMapToType[drawerType]
  const group = Object.entries(localFilters).reduce((acc, [key, value]) => {
    // Get the proper filter option from constants
    const currentFilter = allFilters.find((column) => column.key === key)

    // Check if the filter is the proper component type
    const isProperType = currentFilter?.componentType === componentType

    // Get the label, sign, and minmax from the filter if they are exists
    const label = currentFilter?.label
    const sign = currentFilter?.sign
    const minmax = currentFilter?.minmax

    // If the filter is the proper type and has a label, add it to the group
    if (isProperType && label) {
      return {
        ...acc,
        [key]: { ...value, label, componentType, key, sign, minmax }
      }
    }

    // Otherwise, return the accumulator
    return acc
  }, {})

  return group
}

export { groupFilterTypes }
