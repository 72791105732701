import ReactDOM from 'react-dom/client'
import { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { App } from '/src/app'
import * as Sentry from '@sentry/react'
import { CustomRouter } from '/src/components/router/custom-router'
import { history } from '/src/components/router/history'
import { CaptureConsole } from '@sentry/integrations'
import ErrorBoundary from '/src/components/sentry-error-boundary'
import ReactGA from 'react-ga4'
import { initRefinerSurvey } from './refiner/config'

initRefinerSurvey()

// initialize sentry as early as possible
Sentry.init({
  dsn: 'https://71e1165a6025454cb571f54976ae6dee@o4504433927979008.ingest.sentry.io/4504434284888064',
  // // Below two are the configurations for session replay
  // // This sets the sample rate to be 10%. You may want this to be 100% while
  // // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,
  // // If the entire session is not sampled, use the below sample rate to sample
  // // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing(),
    new CaptureConsole({
      levels: ['error', 'warn']
    })
    // TODO: Make sentry session replay prod only available later
    // new Sentry.Replay({
    //   maskAllText: true,
    //   blockAllMedia: true
    // })
  ],
  environment: import.meta.env.VITE_FIREBASE_PROJECT_ID,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  initialScope: {
    user: {
      id: 'User not logged in yet',
      username: 'User not logged in yet',
      email: 'User not logged in yet',
      roles: 'User not logged in yet'
    }
  }
})

ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ErrorBoundary>
    <HelmetProvider>
      <CustomRouter history={history}>
        <Suspense>
          <App />
        </Suspense>
      </CustomRouter>
    </HelmetProvider>
  </ErrorBoundary>
)
