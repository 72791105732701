import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'

function DebounceInput(props) {
  const { handleDebounce, debounceTimeout, ...rest } = props
  const [value, setValue] = useState(rest.value)
  const timerRef = useRef()

  useEffect(() => {
    setValue(rest.value) // Update local state if parent state changes
  }, [rest.value])

  const handleChange = (event) => {
    setValue(event.target.value)
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      handleDebounce(event.target.value)
    }, debounceTimeout)
  }

  return <TextField {...rest} value={value} onChange={handleChange} />
}

DebounceInput.propTypes = {
  debounceTimeout: PropTypes.number.isRequired,
  handleDebounce: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export default DebounceInput
