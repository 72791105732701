import { Box, CssBaseline, Stack, Typography } from '@mui/material'
import { Logo } from './logo'
import { motion } from 'framer-motion'
import { useAuth } from '/src/hooks/use-auth'
import SecondaryButton from './buttons/secondary-button'

const UnderMaintenance = () => {
  const { signOut } = useAuth()
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        bgcolor: '#fafafa'
      }}
    >
      <CssBaseline />
      <motion.div
        initial={{ rotate: 0 }}
        animate={{ rotate: [15, -30, 15] }}
        transition={{
          duration: 3,
          ease: 'easeInOut',
          repeat: Infinity
        }}
      >
        <Logo width={100} />
      </motion.div>
      <Stack alignItems="center" mx={4}>
        <Typography variant="h4" sx={{ mt: 3, textAlign: 'center' }}>
          We'are creating something awesome for you.
        </Typography>
        <Typography variant="h6" sx={{ mt: 3 }}>
          And we can't wait to show it to you.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1 }}>
          Please check back soon.
        </Typography>
      </Stack>
      <SecondaryButton
        sx={{
          mt: 3
        }}
        onClick={signOut}
      >
        Sign Out
      </SecondaryButton>
    </Box>
  )
}

export default UnderMaintenance
