import { useCallback } from 'react'
import PropTypes from 'prop-types'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { usePopover } from '/src/hooks/use-popover'

export const MultiSelect = (props) => {
  const {
    label: buttonName,
    onChange,
    type,
    options,
    value = [],
    ...other
  } = props
  const popover = usePopover()

  const handleValueChange = useCallback(
    (event, type, sign, label) => {
      let newValue = [...value]

      if (type === 'numeric') {
        if (!event?.target?.value) {
          newValue = newValue.filter((item) => item.label !== label)
        }

        newValue.push({
          type: buttonName,
          label,
          sign,
          value: event.target.value
        })
      } else if (type === 'text') {
        if (event.target.checked) {
          newValue.push(event.target.value)
        } else {
          newValue = newValue.filter((item) => item !== event.target.value)
        }
      }

      onChange?.(newValue)
    },
    [onChange, value, buttonName]
  )

  // asc sorting by option.label
  const sortedOptions = options.sort((a, b) => {
    let fa = a?.label,
      fb = b?.label

    if (fa < fb) {
      return -1
    }
    if (fa > fb) {
      return 1
    }
    return 0
  })

  return (
    <>
      <IconButton
        size="small"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        {...other}
      >
        <MoreVertOutlinedIcon fontSize="10px" />
      </IconButton>
      <Menu
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        PaperProps={{ style: { minWidth: 125, maxHeight: 450 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {sortedOptions.map((option) => (
          <MenuItem
            key={option.label}
            sx={{
              width: type === 'numeric' ? 180 : 'auto'
            }}
          >
            {type === 'text' ? (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={value.includes(option.value)}
                    onChange={(event) => handleValueChange(event, type)}
                    value={option.value}
                  />
                }
                label={<Typography fontSize="14px">{option.label}</Typography>}
                sx={{
                  flexGrow: 1,
                  mr: 0
                }}
              />
            ) : null}
            {type === 'numeric' ? (
              <FormControl
                component="form"
                onSubmit={(event) => event.preventDefault()}
                onWheel={(event) => event.target.blur()}
                onChange={(event) =>
                  handleValueChange(event, type, option.sign, option.label)
                }
                fullWidth
              >
                <Stack direction="row" spacing={2}>
                  <Typography fontSize="14px">{option.sign}</Typography>
                  <Typography fontSize="14px">{option.label}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <TextField
                    fullWidth
                    name={option.value}
                    value={
                      value.find((item) => item.label === option.label)
                        ?.value || ''
                    }
                    type="number"
                    variant="standard"
                    sx={{
                      fontSize: '14px',
                      my: 1,
                      input: {
                        '&::-webkit-outer-spin-button': {
                          WebkitAppearance: 'none',
                          margin: 0
                        },
                        '&::-webkit-inner-spin-button': {
                          WebkitAppearance: 'none',
                          margin: 0
                        }
                      }
                    }}
                  />
                </Stack>
              </FormControl>
            ) : null}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired
}
