import Error401Page from '/src/pages/401'
import Error404Page from '/src/pages/404'
import Error500Page from '/src/pages/500'
import { authRoutes } from '/src/routes/auth'
import { demoRoutes } from './demo'
import { dashboardRoutes } from '/src/routes/dashboard'

export const routes = [
  ...authRoutes,
  ...demoRoutes,
  ...dashboardRoutes,
  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: '*',
    element: <Error404Page />
  }
]
