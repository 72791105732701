// Components
import ScoringTableBody from './scoring-table/body'
import QuestionCardHeader from './question-card-header'
import CheckboxColumn from './scoring-table/checkbox-column'
import FullNameColumn from './scoring-table/fullname-column'
import SliderColumn from './scoring-table/slider-column'
import CommentColumn from './scoring-table/comment-column'
import WhiteCard from '/src/components/common/WhiteCard/WhiteCard'
import CardNavigation from './card-navigation'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { SelfScoring } from './self-scoring'
import { Divider, Stack } from '@mui/material'
import { ReviewProgress } from '/src/components/review-progress'
import { Seo } from '/src/components/seo'
import { AnimatePresence, motion } from 'framer-motion'

// Hooks
import { useEffect, useMemo, useState, useRef } from 'react'
import { useDimensions } from '/src/hooks/use-dimensions'
import { useSaveOnLeave } from './hooks/use-save-on-leave'
import { useUser } from '/src/hooks/use-user'
import { useEngagementTimeTracker } from '/src/hooks/react-ga4/use-engagement-time-tracker'
import { useParams } from 'react-router'
import { useQuestion } from './hooks/use-question'

// Utils and constants
import consoleLog from '/src/utils/console-log'
import { ROLES } from '/functions/shared/constants/roles'
import { unloadService } from '/src/services/before-unload-service'

const questionCardId = 'question-card'

const QuestionCard = ({ data: review }) => {
  const {
    handleChange,
    handleApplicable,
    handleNext,
    handlePrevious,
    handleCommentSave,
    updateResponses,
    handleReorder,
    handleQuestionChange,
    isChanged,
    isDisabled,
    currentQuestionIndex,
    currentQuestion,
    questions,
    topElement,
    allConditions,
    disableCheckboxes,
    skippablePersonCount,
    displayCheckIcon
  } = useQuestion(review)
  const { evaluationId } = useParams()
  const { user } = useUser()

  const { feedbacks, selfScore } = currentQuestion

  consoleLog('current question: ', currentQuestion)

  const questionTitle = useMemo(
    () => currentQuestion.title,
    [currentQuestion.title]
  )

  const questionCardRef = useRef(null)
  useEngagementTimeTracker({
    eventParameters: {
      eventName: 'time_spent_on_question',
      action: 'review',
      category: 'engagement',
      behavior: questionTitle,
      evaluation_id: evaluationId,
      user_uid: user.uid,
      company_name: user.companyName
    },
    dependencies: [questionTitle],
    trackerId: questionTitle
  })

  // Check if the user should self score. Self scoring should be enabled, and
  // if the question is a leadership behavior, only managers should self score
  const askSelfScore =
    review.dataConfig.enableSelfScoring &&
    (!currentQuestion.leadershipBehavior ||
      (currentQuestion.leadershipBehavior &&
        user.userRoles.includes(ROLES.MANAGER)))

  // if any changes happen, save the review on leave to avoid losing data for the react router navigation
  useSaveOnLeave(isChanged, updateResponses)

  // To prevent accidental navigation
  if (isChanged) {
    unloadService.enablePrevention()
  }

  const [fullnameColumnRef, fullnameColumnDimensions] = useDimensions()

  useEffect(() => {
    // Unmount logic
    return () => {
      unloadService.disablePrevention()
    }
  }, [])

  // To determine the direction of the animation
  const [isNext, setIsNext] = useState(false)
  const onNext = (e) => {
    setIsNext(true)
    // Use setTimeout to defer the execution of handleNext
    setTimeout(() => {
      handleNext(e)
    }, 0)
  }
  const onPrevious = (e) => {
    setIsNext(false)
    // Use setTimeout to defer the execution of handlePrevious
    setTimeout(() => {
      handlePrevious(e)
    }, 0)
  }

  return (
    <>
      <Seo title="Questions" />
      <Stack spacing={2} mb={5} id={questionCardId} ref={questionCardRef}>
        <ReviewProgress
          activeStepIndex={currentQuestionIndex + 2}
          review={{
            ...review,
            questions
          }}
          onQuestionStepClick={handleQuestionChange}
          ref={topElement}
        />
        <AnimatePresence mode="wait">
          {displayCheckIcon ? (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.2 }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh'
              }}
            >
              <CheckCircleIcon sx={{ color: 'success.main', fontSize: 100 }} />
            </motion.div>
          ) : (
            <motion.div
              // Trigger slide animation whenever the currentQuestionIndex changes
              key={currentQuestionIndex}
              initial={{ x: isNext ? 300 : -300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: isNext ? -300 : 300, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <WhiteCard
                sx={{
                  minHeight: '70vh'
                }}
                justifyContent="space-between"
                mb={6}
              >
                <QuestionCardHeader
                  commentMin={review.dataConfig.commentMin}
                  currentQuestion={currentQuestion}
                  allConditions={allConditions}
                  disabled={isDisabled}
                  thereIsLastRating={feedbacks.some(
                    (feedback) => feedback.previousData
                  )}
                />
                <ScoringTableBody
                  mt={{
                    xs: 10,
                    md: 7
                  }}
                >
                  <CheckboxColumn
                    feedbacks={feedbacks}
                    handleApplicable={handleApplicable}
                    disableCheckboxes={disableCheckboxes}
                    skippablePersonCount={skippablePersonCount}
                  />
                  <FullNameColumn
                    feedbacks={feedbacks}
                    ref={fullnameColumnRef}
                    displaySeniorityInReview={
                      review.dataConfig.displaySeniorityInReview
                    }
                  />
                  <SliderColumn
                    feedbacks={feedbacks}
                    grades={review.dataConfig.grades}
                    handleChange={handleChange}
                    handleReorder={
                      review?.dataConfig?.enableReorderingByScore
                        ? handleReorder
                        : () => {}
                    }
                  />
                  <CommentColumn
                    commentMin={review.dataConfig.commentMin}
                    feedbacks={feedbacks}
                    handleCommentSave={handleCommentSave}
                    currentQuestion={currentQuestion}
                  />
                </ScoringTableBody>
                <Divider />
                {askSelfScore ? (
                  <SelfScoring
                    handleChange={handleChange}
                    handleCommentSave={handleCommentSave}
                    feedback={selfScore}
                    currentQuestion={currentQuestion}
                    nameWidth={fullnameColumnDimensions.width}
                    commentMin={review.dataConfig.commentMin}
                  />
                ) : null}
                <CardNavigation
                  {...{
                    handleNext: onNext,
                    handlePrevious: onPrevious,
                    isDisabled,
                    currentQuestionIndex
                  }}
                />
              </WhiteCard>
            </motion.div>
          )}
        </AnimatePresence>
      </Stack>
    </>
  )
}

export default QuestionCard
