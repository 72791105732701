import {
  Tooltip,
  TooltipProps,
  ButtonProps,
  SxProps,
  Popper,
  PopperProps
} from '@mui/material'
import GreyOutlinedButton from '/src/components/buttons/grey-outlined-button'

interface SecondaryIconButtonProps extends ButtonProps {
  tooltipText?: string
  tooltipProps?: TooltipProps
  sx?: SxProps
  isActive?: boolean
  icon: React.ReactElement
}

const CustomPopper: React.FC<PopperProps> = (props) => (
  <Popper data-testid="secondary-outlined-icon-button-tooltip" {...props} />
)

const SecondaryIconButton: React.FC<SecondaryIconButtonProps> = ({
  tooltipText,
  tooltipProps,
  icon,
  sx,
  isActive,
  ...otherProps
}) => (
  <Tooltip
    title={tooltipText}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10]
            }
          }
        ]
      }
    }}
    disableHoverListener={!tooltipText}
    PopperComponent={CustomPopper}
    {...tooltipProps}
  >
    <span>
      <GreyOutlinedButton
        isActive={isActive}
        data-testid="secondary-outlined-icon-button"
        sx={{
          height: '100%',
          ...sx
        }}
        {...otherProps}
      >
        {icon}
      </GreyOutlinedButton>
    </span>
  </Tooltip>
)

export default SecondaryIconButton
