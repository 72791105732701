// Class to prevent the user from leaving the page (Singleton structure)
class BeforeUnloadService {
  static instance

  constructor() {
    if (BeforeUnloadService.instance) {
      return BeforeUnloadService.instance
    }
    BeforeUnloadService.instance = this
  }

  enablePrevention() {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  disablePrevention() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  }

  handleBeforeUnload(event) {
    event.preventDefault()
    event.returnValue = ''
  }
}

// Initializing the service
export const unloadService = new BeforeUnloadService()
