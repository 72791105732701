import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextButton from '/src/components/buttons/text-button'
import PrimaryButton from '/src/components/buttons/primary-button'
import Proptypes from 'prop-types'

export default function AlertDialog({
  title,
  content,
  firstButton,
  secondButton,
  open,
  onClose,
  onContinue,
  disabled,
  ...otherProps
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...otherProps}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {firstButton ? (
          <TextButton onClick={onClose} disabled={disabled}>
            {firstButton}
          </TextButton>
        ) : null}
        {secondButton ? (
          <PrimaryButton onClick={onContinue} disabled={disabled}>
            {secondButton}
          </PrimaryButton>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

AlertDialog.propTypes = {
  title: Proptypes.string.isRequired,
  content: Proptypes.string.isRequired,
  firstButton: Proptypes.string,
  secondButton: Proptypes.string,
  open: Proptypes.bool.isRequired,
  onClose: Proptypes.func.isRequired,
  onContinue: Proptypes.func.isRequired
}
