import makeRequest from './index'

const usersProvider = {
  getUser: async () => {
    const result = await makeRequest({ endpoint: 'getUser' })

    return result.data
  },
  updateTutorials: async (data) => {
    const result = await makeRequest({ data, endpoint: 'updateTutorials' })

    return result.data
  },
  getCompanyPeople: async (data) => {
    const result = await makeRequest({ data, endpoint: 'batchGetUsers' })

    return result.data
  },
  setUserPermissions: async (data) => {
    const result = await makeRequest({ data, endpoint: 'setUserPermissions' })

    return result.data
  },
  deleteUser: async (data) => {
    const result = await makeRequest({ data, endpoint: 'deleteUser' })

    return result.data
  }
}

export default usersProvider
