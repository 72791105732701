import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import ReactMarkdown from 'react-markdown'

const Comment = ({ comment, index }) => {
  const theme = useTheme()

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'flex-start'
      }}
    >
      <Typography fontSize={16} fontWeight={700}>{`${index + 1}.`}</Typography>
      <Typography
        ml={3}
        mb={1}
        sx={{
          position: 'relative',
          padding: '10px 15px',
          borderRadius: '0 10px 10px 10px',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? 'rgba(157, 164, 174, 0.4)'
              : 'ghostwhite'
        }}
        component="div"
      >
        <ReactMarkdown>{comment.comment}</ReactMarkdown>
      </Typography>
    </Stack>
  )
}

export default Comment
