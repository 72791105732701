// Components
import { AccessControlPanel } from './access-control-panel'
import { Box } from '@mui/material'
import { FloatSaveButton } from './float-save-button'

// Hooks
import { useFormik } from 'formik'

// Data Fetching
import evaluationsRepo from '/src/data/repos/evaluations-repo'

// Constants
import { evaluationConfigSchema } from '/functions/shared/schemas/common/evaluation'
import { evaluationFormSettings } from './evaluation-form-actions'
import {
  defaultDataConfigOptions,
  defaultReportConfigOptions
} from '/src/constants/evaluation-config'
import { USER_KEYS } from '/src/constants/user'

// Utils
import consoleLog from '/src/utils/console-log'
import { object } from 'yup'
import { toast } from 'react-toastify'
import Proptypes from 'prop-types'

const { SENIORITY, JOB_LEVEL, COHORT } = USER_KEYS

const AccessControls = ({ evaluation, employees }) => {
  const formik = useFormik({
    initialValues: {
      evaluationConfig: {
        // merge over default data config
        dataConfig: {
          ...defaultDataConfigOptions,
          ...evaluation?.evaluationConfig?.dataConfig
        },
        // merge over default report config by checking if the name exists the active evaluation config.
        // if it exists, use that, otherwise use the default.
        reportConfig: defaultReportConfigOptions.map((config) => ({
          ...config,
          ...evaluation?.evaluationConfig?.reportConfig?.find(
            (e) => e.name === config.name
          )
        }))
      },
      formSettings: evaluationFormSettings[evaluation?.status] || {}
    },
    validationSchema: object().shape({
      evaluationConfig: evaluationConfigSchema
    }),
    onSubmit: async (values, { resetForm }) => {
      const valuesToSubmit = {
        evaluationId: evaluation.uid,
        name: evaluation.name,
        description: evaluation.description,
        evaluationConfig: {
          dataConfig: values.evaluationConfig.dataConfig,
          reportConfig: values.evaluationConfig.reportConfig
        }
      }

      consoleLog('values in before submitting', valuesToSubmit)

      const response = await toast.promise(
        evaluationsRepo.updateEvaluation(valuesToSubmit),
        {
          pending: 'Updating...',
          success: 'New configuration saved successfully!',
          error: 'Failed to save new configuration. Please try again.'
        }
      )

      consoleLog('response in onSubmit: ', response)

      resetForm({
        values: {
          evaluationConfig: response.evaluation.evaluationConfig,
          formSettings: evaluationFormSettings[response.status] || {}
        }
      })
    }
  })

  // Check if company has seniority/jobLevel/cohort field
  const isCohortCompatible = employees?.some(
    (employee) => employee[SENIORITY] || employee[JOB_LEVEL] || employee[COHORT]
  )

  return (
    <Box>
      <AccessControlPanel
        {...{
          formik,
          isCohortCompatible
        }}
      />
      {formik.dirty ? (
        <FloatSaveButton
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}
        />
      ) : null}
    </Box>
  )
}

AccessControls.propTypes = {
  evaluation: Proptypes.object.isRequired,
  employees: Proptypes.array.isRequired
}

export default AccessControls
