// Components
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  Fade,
  Backdrop,
  Box,
  Modal
} from '@mui/material'
import { EditableNote } from '/src/components/editable-note'
import PrimaryButton from '/src/components/buttons/primary-button'
import GreyOutlinedButton from '/src/components/buttons/grey-outlined-button'
// MUI Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveIcon from '@mui/icons-material/Save'
import DescriptionBox from '/src/components/description-box'

// Hooks
import { useFormik } from 'formik'
import { useUser } from '/src/hooks/use-user'
import { useState, useCallback, useMemo } from 'react'

// Others
import * as Yup from 'yup'
import { ROLES } from '/functions/shared/constants/roles'
import { toast } from 'react-toastify'
import resultsRepo from '/src/data/repos/results-repo'
import Proptypes from 'prop-types'

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none'
  }
}

const validationSchema = Yup.object().shape({
  selfNote: Yup.string().optional(),
  managerNote: Yup.string().optional()
})

const ResultNotesModal = ({ data, userRole, modalSize, ...otherProps }) => {
  const {
    userId,
    manager,
    notes,
    fullName: fullNameInResult,
    resultId,
    evaluationConfig
  } = data
  const [open, setOpen] = useState(false)
  const { user } = useUser()
  const guidelines = evaluationConfig?.dataConfig?.guidelines
  const isSelf = userRole === ROLES.EMPLOYEE || userId === user.uid
  const isAdmin = userRole === ROLES.ADMIN && !isSelf
  const isManager = userRole === ROLES.MANAGER && !isSelf
  const isDirectManager = isManager && user.fullName === manager
  const isIndirectManager = isManager && user.fullName !== manager
  const descriptions = useMemo(
    () => ({
      isSelf:
        guidelines[ROLES.EMPLOYEE] ||
        'You can use this panel to leave yourself a note, visible to your managers, and see if your manager left a note for you. You can use these notes in your performance conversations.',
      isIndirectManager: `You can use this panel to see ${fullNameInResult}'s note for themselves and their manager's note for them. You can use these notes in the performance conversations.`,
      isDirectManager:
        guidelines[ROLES.MANAGER] ||
        `You can use this panel to leave ${fullNameInResult} a note and see if they left a note for themself. You can use these notes in your performance conversations.`,
      isAdmin: `You can use this panel to see ${fullNameInResult}'s note for themself and their manager's note for them. You can use these notes in the performance conversations.`
    }),
    [fullNameInResult, guidelines]
  )
  const descriptionGiver = useCallback(
    ({ isSelf, isDirectManager, isIndirectManager, isAdmin }) => {
      if (isSelf) return descriptions.isSelf
      else if (isIndirectManager) return descriptions.isIndirectManager
      else if (isDirectManager) return descriptions.isDirectManager
      else if (isAdmin) return descriptions.isAdmin
    },
    [descriptions]
  )
  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const onSubmit = useCallback(
    async (values) => {
      const { selfNote, managerNote } = values
      const note = isSelf ? selfNote : managerNote
      await toast.promise(
        resultsRepo.updateResultNotes({
          resultId,
          note
        }),
        {
          pending: 'Saving your notes...',
          success: {
            render({ data }) {
              // Place the returned data into old data
              notes.selfNote = data.notes.selfNote
              notes.managerNote = data.notes.managerNote
              handleClose()
              return 'Saved! 🎉'
            }
          },
          error: {
            render({ data: err }) {
              console.warn(err)
              return 'Something went wrong. Please try again.'
            }
          }
        }
      )
    },
    [isSelf, resultId, notes, handleClose]
  )

  const initialValues = useMemo(
    () => ({
      selfNote: notes.selfNote || '',
      managerNote: notes.managerNote || ''
    }),
    [notes]
  )

  const localFormik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const handleSelfNoteChange = useCallback(
    (newValue) => localFormik.setFieldValue('selfNote', newValue),
    [localFormik]
  )
  const handleManagerNoteChange = useCallback(
    (newValue) => localFormik.setFieldValue('managerNote', newValue),
    [localFormik]
  )

  const isSameText = isSelf
    ? notes.selfNote === localFormik.values.selfNote
    : notes.managerNote === localFormik.values.managerNote

  return (
    <Box {...otherProps}>
      <GreyOutlinedButton
        data-gaid="open-notes-modal"
        startIcon={<RateReviewOutlinedIcon />}
        onClick={handleOpen}
        sx={{
          minWidth: '180px',
          minHeight: '50px'
        }}
      >
        Development Plan
      </GreyOutlinedButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            style: {
              background: modalSize && 'transparent'
            },
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Card
            component={Stack}
            sx={{
              ...styles.box,
              height: modalSize?.height,
              width: modalSize?.width || {
                xs: '90vw',
                sm: '90vw',
                md: '80vw'
              },
              maxWidth: 1600,
              overflow: 'auto',
              maxHeight: '95vh'
            }}
          >
            <Stack
              sx={{
                p: 5,
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <Button
                variant={'text'}
                onClick={handleClose}
                startIcon={<ArrowBackIcon />}
                sx={{
                  minWidth: 'max-content'
                }}
              >
                Back
              </Button>
              <Stack
                sx={{
                  flexGrow: 1,
                  justifyContent: 'center'
                }}
              >
                <Typography textAlign="center" variant="h5" pr={10}>
                  {isSelf ? 'My ' : ``}Development Plan
                  {isSelf ? '' : ` for ${fullNameInResult}`}
                </Typography>
              </Stack>
            </Stack>
            <CardContent
              component={Stack}
              spacing={4}
              sx={{
                px: 6,
                pt: 0,
                flexGrow: 1
              }}
            >
              <DescriptionBox
                description={descriptionGiver({
                  isSelf,
                  isAdmin,
                  isDirectManager,
                  isIndirectManager
                })}
                icon={<InfoOutlinedIcon />}
                sx={{
                  minWidth: '100%'
                }}
              />
              <Stack direction="row" spacing={4} flexGrow={1}>
                {/* USER NOTE */}
                <Box
                  sx={{
                    flexBasis: '50%'
                  }}
                >
                  <EditableNote
                    title={
                      isSelf ? 'Your Notes' : `${fullNameInResult}'s Notes`
                    }
                    editingPermission={isSelf}
                    value={localFormik.values.selfNote}
                    onChange={handleSelfNoteChange}
                    userFullname={fullNameInResult}
                    emptyNoteText={
                      isSelf
                        ? 'You have not written a note yet.'
                        : `${fullNameInResult} has not written a note yet.`
                    }
                    NotePaperProps={{
                      sx: {
                        backgroundColor: (theme) =>
                          `${theme.palette.success.main}10`
                      }
                    }}
                  />
                </Box>
                {/* MANAGER NOTE */}
                <Box
                  sx={{
                    flexBasis: '50%'
                  }}
                >
                  <EditableNote
                    title={
                      isDirectManager
                        ? `Your Notes`
                        : data?.manager
                        ? `${data.manager}'s Notes`
                        : isSelf
                        ? "Your Manager's Notes"
                        : `${fullNameInResult}'s Manager's Notes`
                    }
                    editingPermission={isDirectManager}
                    value={localFormik.values.managerNote}
                    onChange={handleManagerNoteChange}
                    userFullname={fullNameInResult}
                    emptyNoteText={
                      isDirectManager
                        ? `You have not written a note yet.`
                        : data?.manager
                        ? `${data.manager} has not written a note yet.`
                        : isSelf
                        ? 'Your manager has not written a note yet.'
                        : `${fullNameInResult}'s manager has not written a note yet.`
                    }
                    NotePaperProps={{
                      sx: {
                        backgroundColor: (theme) =>
                          `${theme.palette.info.main}10`
                      }
                    }}
                  />
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="flex-end">
                <PrimaryButton
                  disabled={localFormik.isSubmitting}
                  startIcon={<SaveIcon />}
                  onClick={localFormik.handleSubmit}
                  sx={{
                    display: isSameText ? 'none' : 'flex'
                  }}
                >
                  Save
                </PrimaryButton>
              </Stack>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </Box>
  )
}

ResultNotesModal.propTypes = {
  data: Proptypes.object,
  userRole: Proptypes.string,
  modalSize: Proptypes.object
}

export default ResultNotesModal
