import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '/src/hooks/use-auth'
import { useRouter } from '/src/hooks/use-router'
import { Issuer } from '/src/utils/auth'
import { paths } from '/src/constants/paths'
import { useLocation } from 'react-router-dom'
import { getSearchParams } from '/src/utils/guards/path'

const loginPaths = {
  [Issuer.Firebase]: paths.login
}

export const AuthGuard = (props) => {
  const { children } = props
  const router = useRouter()
  const { isAuthenticated, issuer, user } = useAuth()
  const [checked, setChecked] = useState(false)
  // Location imported to get the state
  const location = useLocation()

  const check = useCallback(
    (searchParams) => {
      if (!isAuthenticated) {
        const href = loginPaths[issuer]
        // State is used passed and used inside other pages
        router.replace(href, {
          state: { from: location?.state?.from, ...searchParams }
        })
      } else {
        // Handling demo setup
        if (user?.status === 'DEMO_SETUP') {
          // State is used passed and used inside other pages
          router.replace(paths.demoAccount, {
            state: { from: location?.state?.from }
          })
        }

        setChecked(true)
      }
    },
    [isAuthenticated, issuer, router, user?.status, location?.state?.from]
  )

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  // ** I added check to the deps array, so previous comment is not true anymore.
  useEffect(
    () => {
      const searchParams = getSearchParams({ search: location.search })
      check(searchParams)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // I added check to the deps array, because otherwise if the user signs out it does not redirect to the login page so useNavigate does not work.
    // I think this component prevents redirecting to the login page when the user signs out.
    // TODO: Figure out why this is happening and fix it.
    [check, location?.search]
  )

  if (!checked) {
    return null
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node
}
