import * as React from 'react'
import { Box, Slider as SliderRoot } from '@mui/material'
import { styled } from '@mui/material/styles'

import colorBetween from 'color-between'
import { useCallback } from 'react'
import Confetti from '/src/components/confetti-animation'

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'

const IOSSlider = styled(SliderRoot)(({ theme }) => ({
  'transition': 'all 0.3s ease-in-out',
  'padding': '6px 0',
  'height': '18%',
  '& .MuiSlider-thumb': {
    'transition': 'all 0.3s ease-in-out',
    'height': 25,
    'width': 25,
    'backgroundColor': '#fff',
    'boxShadow': iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      'boxShadow':
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow
      }
    },
    'zIndex': 100
  },
  '& .MuiSlider-valueLabel': {
    'transition': 'all 0.3s ease-in-out',
    'fontSize': 12,
    'fontWeight': 'normal',
    'top': -6,
    'backgroundColor': 'unset',
    'color': theme.palette.text.primary,
    '&:before': {
      display: 'none'
    },
    '& *': {
      transition: 'all 0.3s ease-in-out',
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000'
    }
  },
  '& .MuiSlider-track': {
    transition: 'all 0.3s ease-in-out',
    border: 'none',
    height: 6
  },
  '& .MuiSlider-rail': {
    transition: 'all 0.3s ease-in-out',
    height: 6,
    opacity: 0.5,
    backgroundColor: '#bfbfbf'
  },
  '& .MuiSlider-mark': {
    'transition': 'all 0.3s ease-in-out',
    'backgroundColor': '#bfbfbf',
    'height': 10,
    'width': 1,
    '&.MuiSlider-markActive': {
      transition: 'all 0.3s ease-in-out',
      opacity: 1,
      backgroundColor: 'currentColor'
    }
  }
}))

const getConditionalStyles = ({
  currentValue,
  isApplicable,
  previousScoreColor,
  previousScore
}) => ({
  'color':
    currentValue === 0
      ? '#bfbfbf'
      : colorBetween('#f44336', '#4caf50', currentValue / 100, 'hex'),
  '& .MuiSlider-thumb': {
    left: `${currentValue || 0}% !important`,
    opacity: isApplicable ? 1 : 0
  },
  '& .MuiSlider-track': {
    width: `${currentValue || 0}% !important`
  },
  // set the color of the mark active
  '& .MuiSlider-mark': {
    '&.MuiSlider-markActive': {
      backgroundColor:
        currentValue === 0 || currentValue === undefined
          ? '#bfbfbf'
          : 'currentColor'
    }
  },
  '&::before': {
    content: '"My Last Rating"',
    position: 'absolute',
    left: `${previousScore - 5 || 0}%`,
    width: 'max-content',
    fontSize: 10,
    transform: ' translateY(-175%)',
    zIndex: 1000,
    color: previousScoreColor || '#bfbfbf',
    fontWeight: 700,
    display: previousScore ? 'block' : 'none'
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    zIndex: 2,
    width: '3px',
    height: '20px',
    backgroundColor: previousScoreColor || '#bfbfbf',
    borderRadius: '20px',
    left: `${previousScore - 0.25 || 0}%`,
    transform: 'translateY(-50%)',
    display: previousScore ? 'block' : 'none'
  }
})

const Slider = ({
  onChange,
  person,
  handleReorder = () => {},
  ...otherProps
}) => {
  const { marks, value: currentValue, isApplicable } = person

  const animationRef = React.useRef(null)

  const handleConfettiAnimation = useCallback(() => {
    animationRef.current?.clap()
    setTimeout(() => {
      handleReorder()
    }, 300)
  }, [handleReorder])

  const handleChange = useCallback(
    (e, value) => {
      onChange(value, e)
    },
    [onChange]
  )

  let previousScore
  let previousScoreColor
  if (person.previousData) {
    previousScore = person.previousData.score
    previousScoreColor = colorBetween(
      '#f44336',
      '#4caf50',
      person.previousData.score / 100,
      'hex'
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative'
      }}
    >
      <IOSSlider
        aria-label="people slider"
        step={null}
        marks={marks}
        value={currentValue}
        onChange={handleChange}
        onChangeCommitted={handleConfettiAnimation}
        sx={getConditionalStyles({
          currentValue,
          isApplicable,
          previousScoreColor,
          previousScore
        })}
        disabled={person.isApplicable ? false : true}
        {...otherProps}
      />
      <Confetti
        ref={animationRef}
        sx={{
          position: 'absolute',
          left: `${currentValue - 1.5 || 0}%`,
          top: 8,
          zIndex: -1,
          width: 20,
          height: 20,
          borderRadius: '50%',
          backgroundColor: 'transparent'
        }}
      />
    </Box>
  )
}

export default Slider
