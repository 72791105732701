import {
  Box,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon
} from '@mui/material'
import { useCallback, useTransition } from 'react'
import SearchMdIcon from '@untitled-ui/icons-react/build/cjs/SearchMd'
import { useUsersTableContext } from '/src/components/users-table/use-users-table-context'

export const UserListSearch = ({ sx, ...props }) => {
  const { handleUsersFiltering, columns } = useUsersTableContext()
  const [isPending, startTransition] = useTransition()

  const handleSearchChange = useCallback(
    (e) => {
      startTransition(() => {
        // update filtered array in context
        handleUsersFiltering({
          type: columns,
          payload: e.target.value,
          action: 'search'
        })
      })
    },
    [handleUsersFiltering, columns]
  )

  return (
    <Stack
      sx={{
        ...sx
      }}
      alignItems="center"
      direction="row"
      flexWrap="wrap"
    >
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <OutlinedInput
          data-gaid="users-table-search"
          defaultValue=""
          fullWidth
          placeholder="Search"
          onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <SvgIcon>
                <SearchMdIcon />
              </SvgIcon>
            </InputAdornment>
          }
          data-testid="user-list-search"
        />
        {isPending ? (
          <FormHelperText
            sx={{
              mt: 1,
              ml: 1,
              color: 'text.secondary',
              fontSize: '0.75rem',
              fontWeight: 500,
              visibility: 'visible',
              position: 'absolute'
            }}
          >
            Searching...
          </FormHelperText>
        ) : (
          <FormHelperText
            sx={{
              mt: 1,
              ml: 1,
              color: 'text.secondary',
              fontSize: '0.75rem',
              fontWeight: 500,
              visibility: 'hidden',
              position: 'absolute'
            }}
          >
            Searching...
          </FormHelperText>
        )}
      </Box>
    </Stack>
  )
}
