// Components
import { Box, Stack, SvgIcon, Typography } from '@mui/material'
import Editor from './editor'
import NotesIcon from '@mui/icons-material/Notes'
import { Scrollbar } from './scrollbar'
import ReactMarkdown from 'react-markdown'

const NotePaper = ({
  note = '',
  emptyNoteText = 'There is no note here.',
  sx = {},
  ...otherProps
}) => (
  <Box
    sx={{
      backgroundColor: (theme) => `${theme.palette.warning.main}10`,
      borderRadius: 1,
      ...sx
    }}
    {...otherProps}
  >
    <Scrollbar
      sx={{
        p: 2,
        maxHeight: '47vh',
        overflow: 'auto'
      }}
    >
      {note.trim() !== '' ? (
        <ReactMarkdown>{note}</ReactMarkdown>
      ) : (
        <Typography variant="body2" color="text.secondary">
          <i>{emptyNoteText}</i>
        </Typography>
      )}
    </Scrollbar>
  </Box>
)

// EditableNote Types
type EditableNoteProps = {
  title?: string
  value?: string
  onChange?: (value: string) => void
  emptyNoteText?: string
  editingPermission?: boolean
  NotePaperProps?: object
}

export const EditableNote = ({
  title,
  value = '',
  onChange = () => {},
  emptyNoteText = 'There is no note here.',
  editingPermission = false,
  NotePaperProps = {}
}: EditableNoteProps) => (
  <Stack spacing={2}>
    {title ? (
      <Stack direction="row" alignItems="center" spacing={1}>
        <SvgIcon fontSize="small">
          <NotesIcon />
        </SvgIcon>
        <Typography variant="h6">{title}</Typography>
      </Stack>
    ) : null}
    <Box>
      {editingPermission ? (
        <Editor
          wrapperProps={{
            sx: {
              minHeight: 300,
              maxHeight: '47vh'
            }
          }}
          defaultMarkdown={value}
          placeholder="Type here..."
          debounceTimeout={300}
          handleDebounce={onChange}
        />
      ) : (
        <NotePaper
          note={value}
          emptyNoteText={emptyNoteText}
          {...NotePaperProps}
        />
      )}
    </Box>
  </Stack>
)
