import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router'
import { ROLES } from '/functions/shared/constants/roles'
import { useAuth } from '/src/hooks/use-auth'
import { usePathname } from '/src/hooks/use-pathname'

export const ReviewGuard = ({ children }) => {
  const { state } = useLocation()
  const { user } = useAuth()
  const pathname = usePathname()

  // we are passing isRoutingProper state by using react router state while navigating
  // if user is using app navigation, state.isRoutingProper will be true
  // otherwise, if user is trying to access page by typing url, state.isRoutingProper will be undefined
  // in this case, we are checking if user is logged in and if he is, we are redirecting him to proper main page route by his role
  const isAdminAndAdminRoute =
    user?.userRoles.includes(ROLES.ADMIN) && pathname.includes('admin')
  const isManagerAndManagerRoute =
    user?.userRoles.includes(ROLES.MANAGER) && pathname.includes('manager')

  if (!state?.isRoutingProper) {
    // if user has admin role and he is trying to access admin route, we are redirecting him to admin main page
    if (isAdminAndAdminRoute) {
      return <Navigate to="/admin" />
    }
    // if user has manager role and he is trying to access manager route, we are redirecting him to manager main page
    if (isManagerAndManagerRoute) {
      return <Navigate to="/manager" />
    }

    // if user has no both admin and manager role and he is trying to access admin or manager route, we are redirecting him to home page
    return <Navigate to="/" />
  }

  // if isRoutingProper is true, we are rendering children
  return <>{children}</>
}

ReviewGuard.propTypes = {
  children: PropTypes.node.isRequired
}
