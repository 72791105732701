import { Suspense, lazy } from 'react'
import { IssuerGuard } from '/src/guards/issuer-guard'
import { GuestGuard } from '/src/guards/guest-guard'
import { Layout as AuthLayout } from '/src/layouts/auth/classic-layout'
import { Issuer } from '/src/utils/auth'
import { AnimatedLogo } from '/src/components/animated-logo'
import LoadingOverlay from '/src/components/loading-overlay'

// Firebase
const FirebaseLoginPage = lazy(() => import('/src/pages/auth/login'))

export const authRoutes = [
  {
    path: 'login',
    element: (
      <IssuerGuard issuer={Issuer.Firebase}>
        <GuestGuard>
          <AuthLayout>
            <Suspense
              fallback={
                <LoadingOverlay mx={10} my={4} height={50}>
                  <AnimatedLogo width={60} />
                </LoadingOverlay>
              }
            >
              <FirebaseLoginPage isLogin={true} />
            </Suspense>
          </AuthLayout>
        </GuestGuard>
      </IssuerGuard>
    )
  },
  {
    path: 'signup',
    element: (
      <IssuerGuard issuer={Issuer.Firebase}>
        <GuestGuard>
          <AuthLayout>
            <Suspense
              fallback={
                <LoadingOverlay mx={10} my={4} height={50}>
                  <AnimatedLogo width={60} />
                </LoadingOverlay>
              }
            >
              <FirebaseLoginPage isLogin={false} />
            </Suspense>
          </AuthLayout>
        </GuestGuard>
      </IssuerGuard>
    )
  }
]
