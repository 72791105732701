import { useCallback, useState } from 'react'
import consoleLog from '/src/utils/console-log'

export function useDialog() {
  const [state, setState] = useState({
    open: false,
    data: undefined
  })

  const handleOpen = useCallback((data) => {
    setState(() => ({
      open: true,
      data
    }))
  }, [])

  const handleClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      open: false
    }))
  }, [])

  consoleLog('state in useDialog ', state)

  return {
    data: state.data,
    handleClose,
    handleOpen,
    open: state.open
  }
}
