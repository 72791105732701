import { Stack } from '@mui/material'
import React from 'react'

const Center = ({ children, ...otherProps }) => (
  <Stack
    justifyContent="center"
    alignItems="center"
    flexGrow={1}
    {...otherProps}
  >
    {children}
  </Stack>
)

export default Center
