import * as React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import consoleLog from '/src/utils/console-log'
import UserAnalysisReport from './user-analysis-report'
import Proptypes from 'prop-types'

const style = {
  'position': 'absolute',
  'top': '50%',
  'left': '50%',
  'transform': 'translate(-50%, -50%)',
  'width': '100%',
  'maxWidth': 1600,
  'bgcolor': 'transparent',
  'boxShadow': 24,
  'scale': 0.8,
  'p': 4,
  '&:focus': { outline: 'none' }
}

export const UserAnalysisReportModal = ({
  open,
  onClose,
  modalData,
  user,
  userRole
}) => {
  // Check if modalData is missing
  if (open && !modalData) {
    console.warn('UserAnalysisReportModal: Modal data is missing.', {
      modalData,
      user,
      userRole,
      open
    })

    return null
  }

  consoleLog('modalData in UserAnalysisReportModal', modalData)

  return (
    <>
      <Modal
        aria-labelledby="report-modal-title"
        aria-describedby="report-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <UserAnalysisReport
              onClose={onClose}
              userRole={userRole}
              modalData={modalData}
              user={user}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

UserAnalysisReportModal.propTypes = {
  open: Proptypes.bool.isRequired,
  onClose: Proptypes.func.isRequired,
  modalData: Proptypes.object.isRequired,
  user: Proptypes.object.isRequired,
  userRole: Proptypes.string.isRequired
}
