// MUI components
import { Stack, Typography } from '@mui/material'

// Custom components
import ScoreBar from '/src/components/score-bar'

// Others
import Proptypes from 'prop-types'
import { SCORES } from '/src/constants/evaluation-config'

type BarWithRangeProps = {
  score: number
  top: number
  bottom: number
  grades: Array<string>
  scoreType: string
}

const BarWithRange = ({
  score,
  top,
  bottom,
  grades,
  scoreType
}: BarWithRangeProps) => {
  return (
    <Stack spacing={2} py={3}>
      <Stack direction="row">
        {grades.map((label, index, arr) => {
          return (
            <Typography
              key={index}
              variant="caption"
              fontWeight="bold"
              sx={{
                // set the width of each label to be 100% divided by the number of labels
                width: `${100 / arr.length}%`,
                // if the label is the first one, set the text align to left, if it's the last one, set it to right, otherwise, center
                textAlign:
                  index === 0
                    ? 'left'
                    : index === arr.length - 1
                    ? 'right'
                    : 'center'
              }}
            >
              {label}
            </Typography>
          )
        })}
      </Stack>
      <ScoreBar
        score={score}
        errorBar={{
          values: {
            top,
            bottom
          },
          labels: {
            start: '25%',
            end: '75%*'
          },
          helperText:
            SCORES.COMPANY === scoreType
              ? '*50% of all colleagues show up here.'
              : '*50% of your cohort shows up here.'
        }}
      />
    </Stack>
  )
}

BarWithRange.propTypes = {
  score: Proptypes.number.isRequired,
  top: Proptypes.number.isRequired,
  bottom: Proptypes.number.isRequired
}

export default BarWithRange
