import { drawerTypes, componentTypes } from './filter-drawer-filters'
import { SCORES } from '/src/constants/evaluation-config'

/* key field value and the key in the employee object must be the same (except evaluations and behaviors) */
export const commonFilters = [
  {
    key: 'evaluations',
    label: 'Reviews',
    componentType: componentTypes.REVIEW_SELECT
  },
  {
    key: 'manager',
    label: 'Manager',
    componentType: componentTypes.SELECT,
    parser: (value) => value?.fullName
  },
  {
    key: 'tenure',
    label: 'Tenure (years)',
    componentType: componentTypes.SLIDER
  },
  {
    key: 'team',
    label: 'Team',
    componentType: componentTypes.SELECT
  },
  {
    key: 'division',
    label: 'Division',
    componentType: componentTypes.SELECT
  },
  {
    key: 'seniority',
    label: 'Seniority',
    componentType: componentTypes.SELECT
  },
  {
    key: 'jobLevel',
    label: 'Job Level',
    componentType: componentTypes.SELECT
  },
  {
    key: 'cohort',
    label: 'Cohort',
    componentType: componentTypes.SELECT
  },
  {
    key: 'jobTitle',
    label: 'Job Title',
    componentType: componentTypes.SELECT
  },
  {
    key: 'gender',
    label: 'Gender',
    componentType: componentTypes.SELECT
  },
  {
    key: 'race',
    label: 'Race',
    componentType: componentTypes.SELECT
  },
  {
    key: 'location',
    label: 'Location',
    componentType: componentTypes.SELECT
  }
]

// Behaviors filter exists in all dashboards except (DASHBOARD_ANALYSES)
const behaviorFilter = {
  key: 'behaviors',
  label: 'Behaviors',
  componentType: componentTypes.CHIPS
}

export const allFiltersMapToType = {
  [drawerTypes.DASHBOARD_ANALYSES]: [
    ...commonFilters,
    {
      key: SCORES.PERCENTILE,
      label: 'Percentile',
      componentType: componentTypes.SLIDER,
      minmax: [0, 100]
    },
    {
      key: SCORES.PEERFUL_SCORE,
      label: 'Score',
      componentType: componentTypes.SLIDER,
      minmax: [0, 100]
    },
    {
      key: SCORES.REVIEWS_RECEIVED,
      label: 'Reviews Received',
      componentType: componentTypes.SLIDER,
      minmax: [0, 40]
    }
  ],
  [drawerTypes.DASHBOARD_REWARDS]: [
    ...commonFilters,
    behaviorFilter,
    {
      key: 'salary',
      label: 'Salary',
      componentType: componentTypes.SLIDER,
      sign: '$'
    },
    {
      key: SCORES.PEERFUL_SCORE,
      label: 'Score',
      componentType: componentTypes.SLIDER,
      minmax: [0, 100]
    }
  ],
  [drawerTypes.DASHBOARD_DEFAULT]: [...commonFilters],
  [drawerTypes.DASHBOARD_INSIGHTS]: [...commonFilters, behaviorFilter] // Insight filters are the same as default filters for now but can be changed in the future
}
