import { Box, Stack, Typography } from '@mui/material'
import { forwardRef, memo } from 'react'
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion'

// setWidth with default
const FullNameColumn = forwardRef(
  ({ feedbacks, displaySeniorityInReview }, ref) => {
    return (
      <Stack
        sx={{
          marginRight: 4
        }}
        justifyContent="space-around"
        ref={ref}
      >
        <LayoutGroup>
          <AnimatePresence>
            {feedbacks.map((person, i) => {
              return (
                <motion.div key={person.uid} layout exit={{ opacity: 0 }}>
                  <Box
                    sx={{
                      lineHeight: 1,
                      opacity: person.isApplicable ? 1 : 0.5,
                      transition: 'all 0.3s ease-in-out'
                    }}
                    data-testid={person.uid}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        lineHeight: 'inherit'
                      }}
                    >
                      {person.fullName}
                    </Typography>
                    {displaySeniorityInReview ? (
                      <Typography
                        variant="caption"
                        sx={{
                          lineHeight: 'inherit',
                          color: 'text.secondary'
                        }}
                      >
                        {person.seniority}
                      </Typography>
                    ) : null}
                  </Box>
                </motion.div>
              )
            })}
          </AnimatePresence>
        </LayoutGroup>
      </Stack>
    )
  }
)

export default memo(FullNameColumn)
