import { Box, Stack, SvgIcon, Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { UsersTable } from '/src/components/users-table/users-table'
import { tableTypes } from '/src/components/users-table/constants/table-types'
import DashboardUserAnalysis, {
  MIN_REVIEWS_RECEIVED,
  RECEIVED_FEW_REVIEWS_INFO_TEXT
} from './user-analysis/user-analysis'
import { useDialog } from '/src/hooks/use-dialog'
import FilteringDrawer from '/src/components/filtering-drawer'
import { drawerTypes } from '/src/components/filtering-drawer/constants/filter-drawer-filters'
import { USER_KEYS } from '/src/constants/user'
import { tenureParser } from '/src/utils/tenure-parser'
import { ROLES } from '/functions/shared/constants/roles'
import InfoIcon from '@mui/icons-material/Info'
import { LightTooltip } from '/src/components/light-tooltip'
import { SCORES } from '/src/constants/evaluation-config'
import { SeverityPill } from '/src/components/severity-pill'

const useCurrentUserData = (data, item) => {
  return useMemo(() => {
    if (!item) {
      return undefined
    }

    return data.find((user) => user.userId === item.userId)
  }, [data, item])
}

const tableType = {
  [ROLES.ADMIN]: tableTypes.ADMIN_DASHBOARD,
  [ROLES.MANAGER]: tableTypes.MANAGER_DASHBOARD
}

const DashboardAnalysis = ({
  analysisTabData,
  filters,
  setFilters,
  userRole
}) => {
  const dialog = useDialog()

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
  const userData = useCurrentUserData(analysisTabData, dialog.data)

  const gridData = useMemo(
    () => analysisTabData.filter((r) => r.behavior === 'Average'),
    [analysisTabData]
  )

  const columnsByRole = useMemo(
    () => ({
      [ROLES.ADMIN]: columns,
      [ROLES.MANAGER]: columns.filter(
        (col) =>
          ![
            SCORES.PERCENTILE,
            GENDER,
            RACE,
            LOCATION,
            COHORT,
            SCORES.REVIEWS_GIVEN
          ].includes(col.key)
      )
    }),
    []
  )

  const handleOpenDrawerFilters = useCallback(() => {
    setOpenFilterDrawer(true)
  }, [])

  const handleCloseDrawerFilters = useCallback(() => {
    setOpenFilterDrawer(false)
  }, [])

  return (
    <Box>
      <UsersTable
        handleOpenDrawerFilters={handleOpenDrawerFilters}
        drawerFilters={filters}
        updateOnDataChange
        dialog={dialog}
        type={tableType[userRole]}
        data={analysisTabData}
        columns={columnsByRole[userRole]}
        askAnything={userRole === ROLES.ADMIN}
        gridData={gridData}
      />
      <DashboardUserAnalysis
        filters={filters}
        onClose={dialog.handleClose}
        open={dialog.open}
        data={userData}
        userRole={userRole}
      />
      <FilteringDrawer
        drawerType={drawerTypes.DASHBOARD_ANALYSES}
        open={openFilterDrawer}
        onClose={handleCloseDrawerFilters}
        filters={filters}
        setFilters={setFilters}
      />
    </Box>
  )
}

export default DashboardAnalysis

const {
  TENURE,
  SENIORITY,
  JOB_LEVEL,
  FULL_NAME,
  TEAM,
  DIVISION,
  JOB_TITLE,
  MANAGER,
  GENDER,
  RACE,
  LOCATION,
  COHORT,
  STATUS
} = USER_KEYS

const columns = [
  // key field value and the key in the employee object must be the same
  {
    key: FULL_NAME,
    label: 'Name',
    minWidth: 150,
    sort: true,
    // We use this parser only here to show a info icon when the user has less than 4 reviews
    parser: ({ row, value, column }) => {
      // Check is user deleted
      const isDeleted = row?.[STATUS] === 'DELETED'
      const statusChip = (
        <SeverityPill
          color="error"
          style={{
            scale: '0.7'
          }}
        >
          Deleted
        </SeverityPill>
      )

      // Check if user received less than 4 reviews
      if (row?.reviewsReceived < MIN_REVIEWS_RECEIVED) {
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="body2">{value}</Typography>
              {isDeleted ? statusChip : null}
            </Box>
            <LightTooltip
              sx={{
                '& .MuiTooltip-tooltip': {
                  maxWidth: 500
                }
              }}
              title={RECEIVED_FEW_REVIEWS_INFO_TEXT}
            >
              <SvgIcon
                sx={{
                  fontSize: 18
                }}
                color="primary"
              >
                <InfoIcon />
              </SvgIcon>
            </LightTooltip>
          </Stack>
        )
      }

      if (isDeleted) {
        return (
          <Box>
            <Typography variant="body2">{value}</Typography>
            {statusChip}
          </Box>
        )
      }

      return value
    }
  },
  {
    key: 'behavior',
    label: 'Behavior',
    minWidth: 130,
    sort: true,
    filter: true
  },
  {
    key: SCORES.PEERFUL_SCORE,
    label: 'Score',
    minWidth: 50,
    sort: true,
    numeric: true
  },
  {
    key: SCORES.PERCENTILE,
    label: 'Percentile',
    minWidth: 50,
    sort: true,
    numeric: true
  },
  {
    key: SCORES.MANAGER_SCORE,
    label: 'Manager Score',
    minWidth: 30,
    sort: true,
    numeric: true
  },
  {
    key: SCORES.SELF_SCORE,
    label: 'Self Score',
    minWidth: 30,
    sort: true,
    numeric: true
  },
  {
    key: MANAGER,
    label: 'Manager',
    minWidth: 100,
    sort: true
  },
  {
    key: TEAM,
    label: 'Team',
    minWidth: 100,
    sort: true
  },
  {
    key: COHORT,
    label: 'Cohort',
    minWidth: 100,
    sort: true
  },
  {
    key: DIVISION,
    label: 'Division',
    minWidth: 100,
    sort: true
  },
  {
    key: JOB_TITLE,
    label: 'Job Title',
    minWidth: 150,
    sort: true
  },
  {
    key: SENIORITY,
    label: 'Seniority',
    minWidth: 100,
    sort: true
  },
  {
    key: JOB_LEVEL,
    label: 'Job Level',
    minWidth: 50,
    sort: true,
    numeric: true
  },
  {
    key: TENURE,
    label: 'Tenure',
    minWidth: 100,
    sort: true,
    numeric: true,
    parser: ({ row, value, column }) => tenureParser(value)
  },
  {
    key: SCORES.REVIEWS_RECEIVED,
    label: 'Reviews Received',
    minWidth: 50,
    sort: true,
    numeric: true
  },
  {
    key: SCORES.REVIEWS_GIVEN,
    label: 'Reviews Given',
    minWidth: 50,
    sort: true,
    numeric: true
  },
  {
    key: GENDER,
    label: 'Gender',
    minWidth: 100,
    sort: true
  },
  {
    key: RACE,
    label: 'Race',
    minWidth: 150,
    sort: true
  },
  {
    key: LOCATION,
    label: 'Location',
    minWidth: 150,
    sort: true
  }
]
