import makeRequest from './index'

const appConfigProvider = {
  getAppConfig: async () => {
    const result = await makeRequest({ endpoint: 'getAppConfig' })

    return result.data
  }
}

export default appConfigProvider
