import { Stack } from '@mui/material'
import React from 'react'

const WhiteCard = (props) => {
  const { children } = props
  return (
    <Stack borderRadius={3} p={5} boxShadow={5} {...props}>
      {children}
    </Stack>
  )
}

export default WhiteCard
